// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { markOrderAsArtworkChangeRequested } from "../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../PendingStatusView";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import withSnackbar from "../withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +isOpen: boolean,
  +onRequestClose: () => void,
  +orderId: string
|};

type MutationErrors = {|
  descripion: $ReadOnlyArray<string>,
  orderId: $ReadOnlyArray<string>
|};

type State = {|
  isRequestingArtworkChange: boolean,
  mutationErrors: MutationErrors,
  notesInputValue: string
|};

const styles = theme => ({
  content: {
    paddingTop: theme.spacing(2)
  }
});

const defaultMutationErrors = {
  descripion: [],
  orderId: []
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("RequestArtworkChangeModal"),

  markOrderAsArtworkChangeRequested,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(
    ({
      isRequestingArtworkChange: false,
      mutationErrors: defaultMutationErrors,
      notesInputValue: ""
    }: State),
    {
      setIsRequestingArtworkChange: () => (
        isRequestingArtworkChange: boolean
      ) => ({
        isRequestingArtworkChange
      }),

      setMutationErrors: () => (mutationErrors: MutationErrors) => ({
        mutationErrors
      }),

      setNotesInputValue: () => (notesInputValue: string) => ({
        notesInputValue
      })
    }
  ),

  withHandlers({
    handleNotesInputChange: ({ setNotesInputValue }) => (
      event: SyntheticInputEvent<HTMLInputElement>
    ) => {
      setNotesInputValue(event.target.value);
    },

    handleCancelButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleSubmitButtonClick: ({
      orderId,
      markOrderAsArtworkChangeRequested,
      notesInputValue: description,
      onRequestClose,
      setIsRequestingArtworkChange,
      setMutationErrors,
      showErrorSnackbar
    }) => () => {
      setIsRequestingArtworkChange(true);
      markOrderAsArtworkChangeRequested({
        variables: { description, orderId }
      })
        .then(
          ({
            data: {
              markOrderAsArtworkChangeRequested: { errors, succeeded }
            }
          }) => {
            if (succeeded) {
              onRequestClose();
            } else {
              setMutationErrors(errors);
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(e.message);
        })
        .finally(() => setIsRequestingArtworkChange(false));
    }
  })
);

const RequestArtworkChangeModal = ({
  classes,
  handleCancelButtonClick,
  handleNotesInputChange,
  handleSubmitButtonClick,
  isOpen,
  isRequestingArtworkChange,
  mutationErrors: { description: descriptionErrors, orderId: orderIdErrors },
  notesInputValue,
  onRequestClose
}) => (
  <Dialog
    aria-describedby="Request Artwork Change"
    aria-labelledby="RequestArtworkChangeModal"
    open={isOpen}
    onClose={onRequestClose}
    fullWidth={true}
  >
    <DialogTitle>Request Artwork Change</DialogTitle>
    <DialogContent className={classes.content}>
      {isRequestingArtworkChange ? (
        <PendingStatusView status="Loading" />
      ) : (
        <>
          <TextField
            id="outlined-simple-start-adornment"
            variant="outlined"
            multiline={true}
            error={descriptionErrors && descriptionErrors.length > 0}
            helperText={
              descriptionErrors &&
              descriptionErrors.length > 0 &&
              descriptionErrors.join(", ")
            }
            label="Description"
            onChange={handleNotesInputChange}
            value={notesInputValue}
            fullWidth={true}
            rows={5}
          />
          {orderIdErrors.length > 0 && (
            <Typography color="error">{orderIdErrors.join(", ")}</Typography>
          )}
        </>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelButtonClick} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmitButtonClick} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
);

export default enhancer(RequestArtworkChangeModal);
