// @flow

import * as React from "react";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withState
} from "recompose";
import { getAbbreviationForProductionJobMethod } from "../../helpers/getAbbreviationForProductionJobMethod";
import { getAbbreviationForProductionJobMethodLocation } from "../../helpers/getAbbreviationForProductionJobMethodLocation";
import QRCode from "qrcode";
import ReceivingLabel from "./components/ReceivingLabel";
import type { HOC } from "recompose";

type Props = {|
  +autoPrint?: boolean,
  +createdStockContainerId?: string,
  +customerName: string,
  +dueBy: ?string,
  +express: boolean,
  +hidden: boolean,
  +isArtworkApproved: boolean,
  +isResolution: boolean,
  +orderNumber: string,
  +printRef: string,
  +priority?: boolean,
  +productionDate: ?string,
  +productionJobs: Array<{}>,
  +productionStationAssignment?: string,
  +totalProductionJobs: number
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ReceivingLabel"),
  withState("shouldAutoPrint", "setShouldAutoPrint", ({ autoPrint }) =>
    autoPrint ? autoPrint : false
  ),
  withState("rendered", "setRendered", false),
  withState("ready", "setReady", false),
  withState("stockContainerBarcodes", "setStockContainerBarcodes", null),

  withProps(({ productionJobs }) => {
    if (productionJobs) {
      return {
        productionJobs: productionJobs.map(productionJob => ({
          ...productionJob,
          printLocationsByMethod: productionJob.customizationAreas.reduce(
            (accumulator, customizationArea) => {
              const method = getAbbreviationForProductionJobMethod(
                customizationArea.method
              );
              const location = getAbbreviationForProductionJobMethodLocation(
                customizationArea.location
              );
              const a = accumulator.find(i => i.method === method);
              if (a) {
                a.locations.push(location);
              } else {
                accumulator.push({
                  method: method,
                  locations: [location]
                });
              }
              return accumulator;
            },
            []
          )
        }))
      };
    }
  }),

  withProps(({ hidden, style }) => {
    if (hidden) {
      return { style: { ...style, display: "none" } };
    }
  }),

  withHandlers(() => {
    let pdfRef;
    return {
      handlePdfRef: () => ref => {
        pdfRef = ref;
      },
      handlePrint:
        ({ rendered }) =>
        () => {
          if (rendered && pdfRef) {
            pdfRef.focus();
            (pdfRef.contentWindow || pdfRef.contentDocument).print();
          }
        },
      handleRender:
        ({ setRendered, setShouldAutoPrint, shouldAutoPrint }) =>
        () => {
          setTimeout(() => {
            setRendered(true);
            if (shouldAutoPrint) {
              pdfRef.focus();
              (pdfRef.contentWindow || pdfRef.contentDocument).print();
              setShouldAutoPrint(false);
            }
          }, 1000);
        }
    };
  }),

  lifecycle({
    componentDidMount() {
      const {
        printRef,
        handlePrint,
        setStockContainerBarcodes,
        productionJobs,
        createdStockContainerId,
        setReady
      } = this.props;
      if (printRef) {
        printRef(handlePrint);
      }
      if (createdStockContainerId) {
        QRCode.toDataURL(
          JSON.stringify({
            variant: "OBJECT",
            type: "StockContainer",
            id: createdStockContainerId
          })
        ).then(barcode => {
          setStockContainerBarcodes({ [createdStockContainerId]: barcode });
          setReady(true);
        });
      } else if (productionJobs && !createdStockContainerId) {
        const stockContainers = productionJobs
          .map(({ stockContainers }) => stockContainers.map(({ id }) => id))
          .flat();
        const stockContainerBarcodePromises = stockContainers.map(id => {
          return QRCode.toDataURL(
            JSON.stringify({
              variant: "OBJECT",
              type: "StockContainer",
              id: id
            })
          ).then(barcode => ({
            id,
            barcode
          }));
        });
        Promise.all(stockContainerBarcodePromises).then(stockContainers => {
          const stockContainerBarcodes = stockContainers.reduce(
            (accumulator, { barcode, id }) => {
              if (barcode) {
                accumulator[id] = barcode;
                return accumulator;
              }
              return null;
            },
            {}
          );
          setStockContainerBarcodes(stockContainerBarcodes);
          setReady(true);
        });
      }
    }
  })
);

const ReceivingLabelViewer = ({
  brand,
  createdStockContainerId,
  customerName,
  dueBy,
  handlePdfRef,
  handleRender,
  isArtworkApproved,
  isResolution,
  orderNumber,
  priority,
  productionDate,
  productionJobs,
  productionStationAssignment,
  ready,
  stockContainerBarcodes,
  style,
  totalProductionJobs,
  express
}) => {
  if (ready) {
    return (
      <PDFViewer
        innerRef={handlePdfRef}
        style={style}
        width="100%"
        height="1000"
      >
        <Document onRender={handleRender}>
          {productionJobs.map(productionJob => (
            <React.Fragment key={productionJob.id}>
              {productionJob.stockContainers.map(stockContainer => (
                <React.Fragment key={stockContainer.id + "-wrapper"}>
                  {(!createdStockContainerId ||
                    createdStockContainerId === stockContainer.id) && (
                    <Page size={[288, 166.5]} key={stockContainer.id}>
                      <ReceivingLabel
                        express={express}
                        productionJob={productionJob}
                        customerName={customerName}
                        brand={brand}
                        isResolution={isResolution}
                        priority={priority}
                        isArtworkApproved={isArtworkApproved}
                        orderNumber={orderNumber}
                        barcode={stockContainerBarcodes[stockContainer.id]}
                        productionDate={productionDate}
                        dueBy={dueBy}
                        totalProductionJobs={totalProductionJobs}
                        productionStationAssignment={
                          productionStationAssignment
                        }
                        lineItemsOld={productionJob.lineItemBreakdownByProduct
                          .map(({ product: { style }, lineItems }) => {
                            return lineItems
                              .map(({ sizes, color: { name: color } }) => {
                                return sizes.map(
                                  ({ quantity, size: { code: size } }) => {
                                    return {
                                      identifier: "GTIN", // TODO:
                                      countryOfOrigin: "CN", // TODO:
                                      description: `${style}-${color}-${size}`,
                                      quantity: quantity
                                    };
                                  }
                                );
                              })
                              .reduce(
                                (accumulator, value) => [
                                  ...accumulator,
                                  ...value
                                ],
                                []
                              );
                          })
                          .reduce(
                            (accumulator, value) => [...accumulator, ...value],
                            []
                          )}
                      />
                    </Page>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Document>
      </PDFViewer>
    );
  }
  return <div />;
};

export default enhancer(ReceivingLabelViewer);
