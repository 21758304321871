// @flow

import { gql } from "@apollo/client";

export default {
  inkColor: gql`
    fragment InkColorDetails_inkColor on InkColor {
      color {
        formatted(format: HEX_WITH_POUND)
      }
      id
      name
    }
  `,
  pantoneColor: gql`
    fragment InkColorDetails_pantoneColor on PantoneColor {
      code
      color {
        formatted(format: HEX_WITH_POUND)
      }
      id
    }
  `
};
