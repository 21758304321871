// @flow

import { fragments as customizationAreaListItemFragments } from "./../components/CustomizationAreaListItem/graph";
import { gql } from "@apollo/client";

export default {
  customizationAreas: gql`
    fragment CustomizationAreaList_customizationAreas on OrderProductionJobCustomizationArea {
      id
      ...CustomizationAreaListItem_customizationArea
    }
    ${customizationAreaListItemFragments.customizationArea}
  `
};
