// @flow

import "./index.css";
import "typeface-roboto";
import App from "./application";
import React from "react";
import ReactDOM from "react-dom";
import invariant from "invariant";
import registerServiceWorker from "./registerServiceWorker";

const rootElement = document.getElementById("root");
invariant(rootElement != null, "rootElement should not be null");

ReactDOM.render(<App />, rootElement);
registerServiceWorker();
