// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createCustomsShipmentPallet: any = graphql(
  gql`
    mutation ($customsShipmentId: ID!, $shippingCarrierId: ID) {
      createCustomsShipmentPallet(
        customsShipmentId: $customsShipmentId
        shippingCarrierId: $shippingCarrierId
      ) {
        createdCustomsShipmentPallet {
          id
          shipment {
            id
            pallets {
              id
            }
          }
        }
        errors {
          customsShipmentId
          shippingCarrierId
        }
        succeeded
      }
    }
  `,
  {
    name: "createCustomsShipmentPallet"
  }
);

export default createCustomsShipmentPallet;
