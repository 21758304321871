// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const requestFileUploadAllocation: any = graphql(
  gql`
    mutation {
      requestFileUploadAllocation {
        fileIdentifier
        url {
          formatted
        }
      }
    }
  `,
  {
    name: "requestFileUploadAllocation"
  }
);

export default requestFileUploadAllocation;
