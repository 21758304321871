// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment TransfersStandardOrderStatus_order on Order {
      id
      transfersStandardStatus
      canViewerStartTransfersStandardManualRipping
      canViewerStartTransfersStandardManualPrinting
      canViewerCompleteTransfersStandardManualRipping
      canViewerCompleteTransfersStandardManualPrinting
    }
  `
};
