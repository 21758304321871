// @flow

import { gql } from "@apollo/client";

export default gql`
  query customsShipments(
    $first: Int!
    $after: String
    $activeFilters: CustomsShipmentsToBeShippedFilters
    $shippedFilters: CustomsShipmentsShippedFilters
    $archivedFilters: CustomsShipmentsArchivedFilters
    $active: Boolean!
    $shipped: Boolean!
    $archived: Boolean!
  ) {
    customsShipmentsToBeShipped(
      first: $first
      after: $after
      filters: $activeFilters
    ) @include(if: $active) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          canonicalId
          createdAt
          pallets {
            id
          }
          shippedOn
          archivedOn
          processedOn
          processedBy
          primarySealNumber
          secondarySealNumber
          controlNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
          canViewerSetFormalEntryCode
        }
      }
    }
    customsShipmentsShipped(
      first: $first
      after: $after
      filters: $shippedFilters
    ) @include(if: $shipped) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          canonicalId
          createdAt
          pallets {
            id
          }
          shippedOn
          archivedOn
          processedOn
          processedBy
          controlNumber
          primarySealNumber
          secondarySealNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
          canViewerSetFormalEntryCode
        }
      }
    }
    customsShipmentsArchived(
      first: $first
      after: $after
      filters: $archivedFilters
    ) @include(if: $archived) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          canonicalId
          createdAt
          pallets {
            id
          }
          shippedOn
          archivedOn
          processedOn
          processedBy
          controlNumber
          primarySealNumber
          secondarySealNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
          canViewerSetFormalEntryCode
        }
      }
    }
  }
`;
