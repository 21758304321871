// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

import type { HOC } from "recompose";

type LineItem = {|
  +color: {|
    +color: ?{|
      +formatted: string
    |},
    +name: string
  |},
  +id: string,
  +primaryImage: {|
    +asset: ?{|
      +url: ?{|
        +formatted: string
      |}
    |},
    +name: string
  |},
  +sizes: $ReadOnlyArray<{|
    +quantity: number,
    +size: {|
      +code: string
    |}
  |}>,
  +styleSubstitution: ?{|
    +color: string
  |},
  +totalQuantity: number
|};
type Props = {|
  +classes: {|
    +cell: string,
    +colorSwatch: string,
    +name: string,
    +noColorSwatch: string,
    +popper: string,
    +tableRow: string,
    +tooltip: string,
    +zoomedImage: string
  |},
  +lineItems: $ReadOnlyArray<LineItem>
|};

const colorSwatchSize = 25;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobLineItemBreakdownTable")
);

const styles = theme => ({
  cell: {
    padding: theme.spacing(1)
  },

  colorSwatch: {
    width: colorSwatchSize,
    height: colorSwatchSize,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    marginRight: theme.spacing(1)
  },

  image: {
    maxHeight: 50
  },

  noColorSwatch: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    width: colorSwatchSize,
    height: colorSwatchSize,
    marginRight: theme.spacing(1)
  },

  name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  popper: { opacity: 1 },

  tableRow: {
    height: 0
  },

  tableWrapper: {
    paddingBottom: theme.spacing(1)
  },

  tooltip: {
    backgroundColor: "#ffffff",
    padding: 0,
    display: "flex",
    flexWrap: "nowrap",
    width: "700px"
  },

  zoomedImage: {
    width: "300px",
    height: "auto"
  }
});

const ProductionJobLineItemBreakdownTable = ({ classes, lineItems }) => {
  const availableSizes = lineItems.reduce((allSizes, item) => {
    item.sizes.forEach(itemSize => {
      if (itemSize.size && allSizes.indexOf(itemSize.size.code) === -1) {
        allSizes.push(itemSize.size.code);
      }
    });
    return allSizes;
  }, []);

  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.cell} />
            <TableCell className={classes.cell}>Colors</TableCell>
            {availableSizes.map((size, index) => (
              <TableCell
                key={index}
                align="right"
                component="th"
                scope="column"
                className={classes.cell}
              >
                {size}
              </TableCell>
            ))}
            <TableCell align="right" className={classes.cell}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map(lineItem => (
            <TableRow key={lineItem.id}>
              <TableCell component="th" scope="row" className={classes.cell}>
                {lineItem.primaryImage && (
                  <Tooltip
                    classes={{
                      popper: classes.popper,
                      tooltip: classes.tooltip
                    }}
                    placement="right-start"
                    title={
                      <img
                        alt={`${lineItem.color.name} Zoomed`}
                        className={classes.zoomedImage}
                        src={lineItem.primaryImage.asset.url.formatted}
                      />
                    }
                  >
                    <img
                      alt={`${lineItem.color.name} Preview`}
                      className={classes.image}
                      src={lineItem.primaryImage.asset.url.formatted}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell component="th" scope="row" className={classes.cell}>
                <div className={classes.name}>
                  {lineItem.styleSubstitution ? (
                    <div>{lineItem.styleSubstitution.color}</div>
                  ) : (
                    <React.Fragment>
                      <div
                        className={
                          lineItem.color
                            ? classes.colorSwatch
                            : classes.noColorSwatch
                        }
                        style={{
                          backgroundColor: lineItem.color
                            ? lineItem.color.color.formatted
                            : "transparent"
                        }}
                      />
                      <div>{lineItem.color.name}</div>
                    </React.Fragment>
                  )}
                </div>
              </TableCell>
              {availableSizes.map((sizeCode, index) => (
                <TableCell align="right" className={classes.cell} key={index}>
                  {lineItem.sizes.filter(size => size.size.code === sizeCode)
                    .length > 0 ? (
                    lineItem.sizes
                      .filter(size => size.size.code === sizeCode)
                      .map((size, index) => (
                        <React.Fragment key={index}>
                          {size.quantity}
                        </React.Fragment>
                      ))
                  ) : (
                    <React.Fragment>0</React.Fragment>
                  )}
                </TableCell>
              ))}
              <TableCell align="right" className={classes.cell}>
                {lineItem.totalQuantity}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(
  enhancer(ProductionJobLineItemBreakdownTable)
);
