// @flow
import { gql } from "@apollo/client";

export default {
  lineItems: gql`
    fragment ProductionJobLineItemBreakdownTable_lineItems on OrderProductionJobLineItem {
      id
      color {
        id
        color {
          formatted(format: HEX_WITH_POUND)
        }
        name
      }
      primaryImage {
        asset {
          url {
            formatted
          }
        }
      }
      sizes {
        id
        quantity
        size {
          id
          code
        }
      }
      styleSubstitution {
        id
        color
      }
      totalQuantity
    }
  `
};
