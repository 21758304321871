// @flow

import { gql } from "@apollo/client";

export default {
  proof: gql`
    fragment DesignProofPreview_proof on Proof {
      asset {
        url {
          formatted
        }
      }
    }
  `
};
