// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

type Props = {|
  +classes: {|
    +noteSpacing: string,
    +noteWrapperSpacing: string
  |},
  +notesCollection: $ReadOnlyArray<{|
    +author: string,
    +createdAt: string,
    +note: string
  |}>
|};

const styles = theme => ({
  noteWrapperSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },

  noteSpacing: {
    marginBottom: theme.spacing(2)
  }
});

const CustomizationAreaNotes = ({ classes, notesCollection }: Props) => (
  <div className={classes.noteWrapperSpacing}>
    <Typography variant="body1">Notes:</Typography>
    {notesCollection.length > 0 ? (
      <div>
        {notesCollection.map(collection => (
          <div key={collection.createdAt} className={classes.noteSpacing}>
            <Typography>{collection.note}</Typography>
            <Typography>
              {`-${collection.author} (${DateTime.fromISO(
                collection.createdAt,
                { setZone: true }
              ).toLocaleString(DateTime.DATETIME_SHORT)})`}
            </Typography>
          </div>
        ))}
      </div>
    ) : (
      <Typography> N/A </Typography>
    )}
  </div>
);

export default withStyles(styles)(CustomizationAreaNotes);
