// @flow

import { gql } from "@apollo/client";

export default gql`
  query OrderProductionJobsToBeReceivedList($labelStartsWith: String!) {
    orderProductionJobsToBeManuallyReceivedList(
      labelStartsWith: $labelStartsWith
    ) {
      id
      inventoryOrders {
        id
        poNumber
        items {
          id
          description
        }
      }
      label
      order {
        id
        orderNumber
      }
    }
  }
`;
