// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as notesWrapperOrderFragment } from "../../components/NotesWrapper/graph";

const addNoteToOrderProductionJobCustomizationArea: any = graphql(
  gql`
    mutation($orderProductionJobCustomizationAreaId: ID!, $note: String!) {
      addNoteToOrderProductionJobCustomizationArea(
        orderProductionJobCustomizationAreaId: $orderProductionJobCustomizationAreaId
        note: $note
      ) {
        errors {
          orderProductionJobCustomizationAreaId
          note
        }
        updatedOrderProductionJobCustomizationArea {
          ...NotesWrapper_orderProductionJobCustomizationArea
        }
        succeeded
      }
    }
    ${notesWrapperOrderFragment.orderProductionJobCustomizationArea}
  `,
  {
    name: "addNoteToOrderProductionJobCustomizationArea"
  }
);

export default addNoteToOrderProductionJobCustomizationArea;
