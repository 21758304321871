// @flow

import { fragments as employeeChipFragments } from "../../../../../../../components/EmployeeChip/graph";
import { gql } from "@apollo/client";
import { fragments as inkColorDetailsFragments } from "../../../../../../../components/InkColorDetails/graph";

export default {
  productionJob: gql`
    fragment OrderAppProductionJobListItem_productionJob on OrderProductionJob {
      customizationAreas {
        id
        location
      }
      id
      label
      mediumColors {
        inkColor {
          ...InkColorDetails_inkColor
        }
        pantoneColor {
          ...InkColorDetails_pantoneColor
        }
      }
    }
    ${inkColorDetailsFragments.inkColor}
    ${inkColorDetailsFragments.pantoneColor}
  `,
  productionStationAssignments: gql`
    fragment OrderAppProductionJobListItem_productionStationAssignments on OrderProductionStationAssignment {
      id
      employee {
        ...EmployeeChip_order
      }
      productionStation {
        id
        name
      }
    }
    ${employeeChipFragments.employee}
  `
};
