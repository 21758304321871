// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +tabs: string
  |},
  +onRequestTabChange: (tabValue: string) => void,
  +selectedTab: string,
  +tabItems: $ReadOnlyArray<string>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TabNavigator"),
  withHandlers({
    handleTabChange: ({ onRequestTabChange }) => (event, value) => {
      onRequestTabChange(value);
    }
  })
);

const styles = theme => ({
  tab: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
});

const TabNavigator = ({ classes, selectedTab, handleTabChange, tabItems }) => {
  const tabs = tabItems.map((item, index) => {
    let value = item;
    let label = item;

    if (typeof item === 'object') {
      value = item.value;
      label = item.label;
    }

    return (
      <Tab className={classes.tab} key={index} value={value} label={label} />
    )
  });

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTabChange}
      value={selectedTab}
      textColor="secondary"
    >
      {tabs}
    </Tabs>
  )
};

export default withStyles(styles)(enhancer(TabNavigator));
