// @flow

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { possibleTypes } from "./possibleTypes.json";
import config from "./config";

const oAuth2ClientId = config.getOAuth2ClientId();

const clientOptions = {
  cache: new InMemoryCache({ possibleTypes }),
  fetchOptions: {
    credentials: "include"
  },
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    new HttpLink({
      credentials: "include",
      headers: { "x-oauth-client-id": oAuth2ClientId },
      uri: config.getFlynetCoreProductionGraphUrl()
    })
  ])
};

const client = new ApolloClient<InMemoryCache>(clientOptions);

export default client;
export { clientOptions };
