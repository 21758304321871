import { compose, setDisplayName, withStateHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { useEffect, useState } from "react";

const enhancer = compose(
  setDisplayName("FindInventoryOrderModal"),

  withStateHandlers(
    {
      inventoryOrderPoNumber: ""
    },
    {
      setInventoryOrderPoNumber: () => inventoryOrderPoNumber => ({
        inventoryOrderPoNumber
      })
    }
  ),

  graphql(query, {
    skip: ({ inventoryOrderPoNumber }) => inventoryOrderPoNumber.length <= 5,
    options: ({ inventoryOrderPoNumber }) => ({
      variables: {
        poNumber: inventoryOrderPoNumber
      },
      fetchPolicy: "network-only"
    })
  })
);

const FindInventoryOrderModal = ({
  isOpen,
  onRequestClose,
  onComplete,
  setInventoryOrderPoNumber,
  inventoryOrderPoNumber,
  data,
  scannedCartonNumber
}) => {
  const [cartonNumber, setCartonNumber] = useState("");
  const selectedInventoryOrder = data && data.latestInventoryOrderByPoNumber;
  console.log("selectedInventoryOrder");
  console.log(selectedInventoryOrder);

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setInventoryOrderPoNumber("");
      onRequestClose();
    }
  };

  useEffect(() => {
    if (scannedCartonNumber) {
      setCartonNumber(scannedCartonNumber);
    }
  }, [scannedCartonNumber, setCartonNumber]);

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      console.log("selectedInventoryOrder");
      console.log(selectedInventoryOrder);
      const { items, productionJob } = selectedInventoryOrder;
      onComplete({
        cartonNumber,
        lineItems: items.map(
          ({ quantity, countryOfOriginOptions, gtin, description }) => ({
            quantity: quantity,
            countryOfOrigin: countryOfOriginOptions[0],
            identifier: gtin,
            description: description
          })
        ),
        orderProductionJobId: productionJob.id,
        inventoryOrderId: selectedInventoryOrder.id
      });

      setInventoryOrderPoNumber("");
    }
  };

  const handlePoNumberChange = ({ target: { value } }) => {
    setInventoryOrderPoNumber(value);
  };

  const handleCartonNumberChange = ({ target: { value } }) => {
    setCartonNumber(value);
  };

  return (
    <Dialog
      aria-describedby="Find Inventory Order"
      aria-labelledby="Find Inventory Order Modal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Find Inventory Order</DialogTitle>
      <DialogContent style={{ minHeight: 250 }}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "1em" }}
        >
          <InputLabel>Carton Number</InputLabel>
          <OutlinedInput
            type="text"
            value={cartonNumber}
            onChange={handleCartonNumberChange}
            label="Carton Number"
            fullWidth
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>PO Number</InputLabel>
          <OutlinedInput
            type="text"
            value={inventoryOrderPoNumber}
            onChange={handlePoNumberChange}
            endAdornment={
              selectedInventoryOrder ||
              (inventoryOrderPoNumber && inventoryOrderPoNumber.length > 0) ? (
                <InputAdornment position="end">
                  {selectedInventoryOrder && (
                    <CheckCircleIcon color="success" />
                  )}
                  {!selectedInventoryOrder && (
                    <HighlightOffIcon color="error" />
                  )}
                </InputAdornment>
              ) : null
            }
            label="PO Number"
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={!selectedInventoryOrder}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(FindInventoryOrderModal);
