// @flow

import { gql } from "@apollo/client";

export default {
  statusHistory: gql`
    fragment OrderStatusHistoryTable_statusHistory on OrderStatusHistory {
      changedAt
      employee {
        id
        name_orderStatusHistoryTable: name(format: FIRST_NAME_LAST_NAME)
      }
      id
      status {
        id
        name
      }
    }
  `
};
