// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import CustomerStatusChip, {
  statuses
} from "../../../../apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip";
import EmployeeChip from "../../../../../components/EmployeeChip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../../../../../assets/logo.png";

type Props = {|
  +classes: {|
    +base: string,
    +customerName: string,
    +customerNameStatusWrapper: string,
    +logo: string,
    +relationshipOwnerLifetimeValueWrapper: string
  |},
  +customer: {|
    +lifetimeValue: {|
      +formatted: string
    |},
    +name_customerArchiveHeader: string,
    +relationshipOwner: {|
      +name_employeeChipModule: string
    |},
    +status: $Keys<typeof statuses>,
    +totalOrders: number
  |}
|};

const styles = theme => ({
  base: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(2)
  },

  customerName: {
    marginRight: theme.spacing(2)
  },

  customerNameStatusWrapper: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  logo: {
    height: 40,
    marginRight: theme.spacing(2),
    width: 40
  },

  relationshipOwnerLifetimeValueWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  panel: {
    margin: "0 10px"
  }
});

const CustomerArchiveHeader = ({
  classes,
  customer: {
    lifetimeValue,
    name_customerArchiveHeader,
    primaryPhoneNumber,
    totalOrders,
    relationshipOwner,
    status
  }
}: Props) => (
  <div className={classes.base}>
    <Toolbar>
      <div>
        <div className={classes.customerNameStatusWrapper}>
          <img className={classes.logo} src={logo} alt="logo" />
          <Typography
            color="inherit"
            className={classes.customerName}
            variant="h4"
          >
            {`${name_customerArchiveHeader} (${totalOrders})`}
          </Typography>
          <CustomerStatusChip borderColor={"#fff"} status={status} />
        </div>
        <div className={classes.relationshipOwnerLifetimeValueWrapper}>
          <div className={classes.panel}>
            <Typography color="inherit" variant="subtitle2">
              Account Owner
            </Typography>
            <EmployeeChip employee={relationshipOwner} />
          </div>
          <div className={classes.panel}>
            <Typography color="inherit" variant="subtitle2">
              Phone Number
            </Typography>
            <Typography color="inherit" variant="body2">
              {primaryPhoneNumber.formatted}
            </Typography>
          </div>
          <div className={classes.panel}>
            <Typography color="inherit" variant="subtitle2">
              Lifetime Value
            </Typography>
            <Typography color="inherit" variant="body2">
              {lifetimeValue.formatted}
            </Typography>
          </div>
        </div>
      </div>
    </Toolbar>
  </div>
);

export default withStyles(styles)(CustomerArchiveHeader);
