// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../ProductionJobLineItemBreakdownTable/graph";
import { withStyles } from "@mui/styles";
import ProductionJobLineItemBreakdownTable from "../ProductionJobLineItemBreakdownTable";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +descriptionList: string,
    +descriptionListItem: string,
    +descriptionTag: string
  |},
  +lineItems: $ReadOnlyArray<{|
    +color: {|
      +color: ?{|
        +formatted: string
      |},
      +name: string
    |},
    +id: string,
    +sizes: $ReadOnlyArray<{|
      +quantity: number,
      +size: {|
        +code: string
      |}
    |}>,
    +styleSubstitution: ?{|
      +color: string,
      +description: string,
      +id: string,
      +style: string,
      +vendor: string
    |},
    +totalQuantity: number
  |}>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("StyleSubstitutionLineItemBreakdown")
);

const styles = theme => ({
  descriptionList: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  descriptionListItem: {
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  }
});

const StyleSubstitutionLineItemBreakdown = ({ classes, lineItems }) => (
  <div>
    {lineItems.map(lineItem => (
      <div key={lineItem.id}>
        <dl className={classes.descriptionList} key={lineItem.id}>
          <div className={classes.descriptionListItem}>
            <Typography
              className={classes.descriptionTag}
              component="dt"
              variant="subtitle2"
              color="textSecondary"
            >
              Vendor:
            </Typography>
            <Typography align="left" component="dd" variant="body2">
              {lineItem.styleSubstitution.vendor}
            </Typography>
          </div>
          <div className={classes.descriptionListItem}>
            <Typography
              className={classes.descriptionTag}
              component="dt"
              variant="subtitle2"
              color="textSecondary"
            >
              Style:
            </Typography>
            <Typography align="left" component="dd" variant="body2">
              {lineItem.styleSubstitution.style}
            </Typography>
          </div>
          <div className={classes.descriptionListItem}>
            <Typography
              className={classes.descriptionTag}
              component="dt"
              variant="subtitle2"
              color="textSecondary"
            >
              Description:
            </Typography>
            <Typography align="left" component="dd" variant="body2">
              {lineItem.styleSubstitution.description}
            </Typography>
          </div>
        </dl>
        <ProductionJobLineItemBreakdownTable
          lineItems={filter(
            productionJobLineItemBreakdownTableFragments.lineItems,
            [lineItem]
          )}
        />
      </div>
    ))}
  </div>
);

export default withStyles(styles)(enhancer(StyleSubstitutionLineItemBreakdown));
