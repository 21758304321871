// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const setSouthboundCustomsShipmentControlNumber: any = graphql(
  gql`
    mutation (
      $customsShipmentId: ID!
      $controlNumber: String!
      $processedBy: String!
    ) {
      setSouthboundCustomsShipmentControlNumber(
        customsShipmentId: $customsShipmentId
        controlNumber: $controlNumber
        processedBy: $processedBy
      ) {
        errors {
          customsShipmentId
          controlNumber
          processedBy
        }
        updatedCustomsShipment {
          id
          canonicalId
          createdAt
          shippedOn
          archivedOn
          controlNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
          processedBy
        }
        succeeded
      }
    }
  `,
  {
    name: "setSouthboundCustomsShipmentControlNumber"
  }
);

export default setSouthboundCustomsShipmentControlNumber;
