import { compose, setDisplayName, withStateHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const enhancer = compose(
  setDisplayName("FindOrderByOrderNumberModal"),
  withApollo,
  withStateHandlers(
    {
      orderNumber: "",
      selectedOrder: null
    },
    {
      setOrderNumber: () => orderNumber => ({
        orderNumber
      }),

      setSelectedOrder: () => selectedOrder => ({
        selectedOrder
      })
    }
  ),

  graphql(query, {
    skip: ({ isOpen, orderNumber }) => !isOpen || orderNumber.length < 7 || !orderNumber.match(/^\d+$/),
    options: ({ orderNumber }) => ({
      variables: {
        canonicalId: parseInt(orderNumber)
      },
      fetchPolicy: "network-only"
    })
  })
);

const FindOrderByOrderNumberModal = ({
  isOpen,
  onComplete,
  onRequestClose,
  orderNumber,
  setOrderNumber,
  data
}) => {
  const selectedOrder = data && data.orderByCanonicalId;

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setOrderNumber("");
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      const selectedOrderProductionJobId = selectedOrder.productionJobs.length === 1 ? selectedOrder.productionJobs[0].id : null;
      onComplete({
        selectedOrderId: selectedOrder.id,
        selectedOrderProductionJobId: selectedOrderProductionJobId
      });
    }
    onRequestClose();
  };

  const handleOrderNumberChange = ({ target: { value } }) => {
    setOrderNumber(value);
  };

  return (
    <Dialog
      aria-describedby="Select Order"
      aria-labelledby="Select Order Modal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Order Search</DialogTitle>
      <DialogContent style={{ minHeight: 125 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Order Number</InputLabel>
          <OutlinedInput
            type="text"
            value={orderNumber}
            onChange={handleOrderNumberChange}
            endAdornment={
              selectedOrder ||
              (orderNumber && orderNumber.length > 0) ? (
                <InputAdornment position="end">
                  {selectedOrder && (
                    <CheckCircleIcon color="success" />
                  )}
                  {!selectedOrder && (
                    <HighlightOffIcon color="error" />
                  )}
                </InputAdornment>
              ) : null
            }
            label="Order Number"
            fullWidth
          />
        </FormControl>
        {/* <Button onClick={handleSearchButtonClick} color="primary">
          Search
        </Button> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={!selectedOrder}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(FindOrderByOrderNumberModal);
