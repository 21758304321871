// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createCustomsShipmentPalletCarton: any = graphql(
  gql`
    mutation (
      $customsShipmentPalletId: ID!
      $inventoryOrderCartonNumber: String
      $inventoryOrderCartonTrackingNumber: String
      $stockContainerId: ID
      $shippingPackageId: ID
      $lineItems: [CustomsShipmentPalletCartonLineItemInput!]!
    ) {
      createCustomsShipmentPalletCarton(
        customsShipmentPalletId: $customsShipmentPalletId
        inventoryOrderCartonNumber: $inventoryOrderCartonNumber
        inventoryOrderCartonTrackingNumber: $inventoryOrderCartonTrackingNumber
        stockContainerId: $stockContainerId
        shippingPackageId: $shippingPackageId
        lineItems: $lineItems
      ) {
        createdCustomsShipmentPalletCarton {
          id
          pallet {
            id
            cartons {
              id
              lineItems {
                id
                identifier
                countryOfOrigin
                embroidery
                quantity
                description
                carrierName
                weight
              }
            }
          }
        }
        errors {
          customsShipmentPalletId
          inventoryOrderCartonNumber
          inventoryOrderCartonTrackingNumber
          shippingPackageId
        }
        succeeded
      }
    }
  `,
  {
    name: "createCustomsShipmentPalletCarton"
  }
);

export default createCustomsShipmentPalletCarton;
