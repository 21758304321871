// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const deleteCustomsShipmentPalletCarton: any = graphql(
  gql`
    mutation ($customsShipmentPalletCartonId: ID!) {
      deleteCustomsShipmentPalletCarton(
        customsShipmentPalletCartonId: $customsShipmentPalletCartonId
      ) {
        errors {
          customsShipmentPalletCartonId
        }
        succeeded
      }
    }
  `,
  {
    name: "deleteCustomsShipmentPalletCarton"
  }
);

export default deleteCustomsShipmentPalletCarton;
