// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

type Props = {|
  +classes: {|
    +artworkWrapperSpacing: string,
    +figure: string,
    +gallery: string,
    +image: string
  |},
  +customerArtwork: $ReadOnlyArray<{|
    +file: {|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |}
    |},
    +id: string
  |}>
|};

const styles = theme => ({
  artworkWrapperSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },

  figure: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  gallery: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },

  image: {
    maxWidth: "250px",
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid"
  }
});

const CustomerArtworkPreview = ({ customerArtwork, classes }: Props) => {
  const customerArtworkUrlArray = customerArtwork.map(
    (
      {
        file: {
          asset: {
            url: { formatted }
          }
        }
      },
      id
    ) => ({ formatted, id })
  );
  return (
    <div className={classes.artworkWrapperSpacing}>
      <Typography variant="body1">Customer Artwork:</Typography>
      <div className={classes.gallery}>
        {customerArtworkUrlArray.length > 0 ? (
          customerArtworkUrlArray.map(artworkUrl => (
            <figure key={artworkUrl.id} className={classes.figure}>
              <img
                className={classes.image}
                src={artworkUrl.formatted}
                alt="Customer Artwork"
              />
            </figure>
          ))
        ) : (
          <Typography> N/A </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomerArtworkPreview);
