// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import BarcodePivot from "../components/BarcodePivot";
import ClientOAuth2 from "client-oauth2";
import PendingStatusView from "../components/PendingStatusView";
import config from "./config";

import type { HOC } from "recompose";

type Props = {|
  +children: any
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("Root"),
  graphql(gql`
    query {
      viewer {
        id
        isAuthenticated
      }
    }
  `)
);

const Root = props => {
  if (!props.data || props.data.loading) {
    return <PendingStatusView status="Authenticating" />;
  }

  if (!props.data.viewer || !props.data.viewer.isAuthenticated) {
    const oauth2 = new ClientOAuth2({
      clientId: config.getOAuth2ClientId(),
      authorizationUri: config.getOAuth2AuthorizationTokenUrl(),
      redirectUri: config.getOAuth2RedirectUrl()
    });

    window.location = oauth2.code.getUri({
      query: { return_to: window.location.href }
    });

    return null;
  }

  return <BarcodePivot>{props.children}</BarcodePivot>;
};

export default enhancer(Root);
