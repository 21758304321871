// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment AutomatedNotesWrapper_order on Order {
      automatedNotesCollection {
        __typename
        ... on CountValidatedNote {
          author
          createdAt
          label
        }
        ... on ExactReorderNote {
          author
          createdAt
          orderNumber
        }
        ... on ProductionFileLocationNote {
          author
          filePath
          createdAt
        }
        ... on ResolutionReasonNote {
          author
          reason
          createdAt
        }
        ... on ScreensMadeNote {
          author
          createdAt
        }
        ... on SimilarReorderNote {
          author
          changes
          orderNumber
          createdAt
        }
        ... on StationAssignmentNote {
          author
          employeeName
          station
          createdAt
        }
        ... on StockReceivedNote {
          author
          label
          createdAt
        }
        ... on StockStagedNote {
          author
          labels
          createdAt
        }
      }
    }
  `
};
