// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForTransfersStandardStatus } from "../../../../../helpers";
import {
  markOrderAsTransfersStandardManualPrintingComplete,
  markOrderAsTransfersStandardManualPrintingPaused,
  markOrderAsTransfersStandardManualPrintingStarted,
  markOrderAsTransfersStandardManualRippingComplete,
  markOrderAsTransfersStandardManualRippingStarted
} from "../../../../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +canViewerCompleteHighDefinitionDigital: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +canViewerStartHighDefinitionDigital: boolean,
    +highDefinitionDigitalStatus: string,
    +id: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean
|};

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TransfersStandardOrderStatus"),

  markOrderAsTransfersStandardManualRippingComplete,

  markOrderAsTransfersStandardManualRippingStarted,

  markOrderAsTransfersStandardManualPrintingComplete,

  markOrderAsTransfersStandardManualPrintingPaused,

  markOrderAsTransfersStandardManualPrintingStarted,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(({ isMutatingOrder: false }: State), {
    setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
      isMutatingOrder
    })
  }),

  withHandlers({

    handleStartRippingButtonClick:
      ({
        markOrderAsTransfersStandardManualRippingStarted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsTransfersStandardManualRippingStarted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersStandardManualRippingStarted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleCompleteRippingButtonClick:
      ({
        markOrderAsTransfersStandardManualRippingCompleted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsTransfersStandardManualRippingCompleted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersStandardManualRippingCompleted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleStartPrintingButtonClick:
        ({
          markOrderAsTransfersStandardManualPrintingStarted,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersStandardManualPrintingStarted({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersStandardManualPrintingStarted: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

        handlePausePrintingButtonClick:
        ({
          markOrderAsTransfersStandardManualPrintingPaused,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersStandardManualPrintingPaused({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersStandardManualPrintingPaused: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

        handleCompletePrintingButtonClick:
            ({
              markOrderAsTransfersStandardManualPrintingCompleted,
              order: { id: orderId },
              setIsMutatingOrder,
              showErrorSnackbar,
              showSuccessSnackbar
            }) =>
            () => {
              setIsMutatingOrder(true);
              markOrderAsTransfersStandardManualPrintingCompleted({ variables: { orderId } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersStandardManualPrintingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(`The order has been updated`);
                    } else {
                      showErrorSnackbar(
                        `Something went wrong: ${errors.orderId.join(", ")}`
                      );
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(`Something went wrong: ${e.message}`);
                })
                .finally(() => setIsMutatingOrder(false));
            },
  })
);

const HDDOrderStatus = ({
  classes,
  handleCompleteRippingButtonClick,
  handleStartRippingButtonClick,
  handleCompletePrintingButtonClick,
  handlePausePrintingButtonClick,
  handleStartPrintingButtonClick,
  isMutatingOrder,
  order: {
    transfersStandardStatus,
    canViewerStartTransfersStandardManualRipping,
    canViewerCompleteTransfersStandardManualRipping,
    canViewerStartTransfersStandardManualPrinting,
    canViewerPauseTransfersStandardManualPrinting,
    canViewerCompleteTransfersStandardManualPrinting
  }
}) => (
  <div>
    <div className={classes.spaceBetweenContainer}>
      <JobStatusIcon status={transfersStandardStatus} />
      <div className={classes.jobStatus}>
        <Typography variant="h6">Transfers - Standard</Typography>
        <Typography variant="body2">
          {getLabelForTransfersStandardStatus(transfersStandardStatus)}
        </Typography>
      </div>
    </div>
    {isMutatingOrder ? (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    ) : (
      <div className={classes.buttonContainer}>
        {canViewerStartTransfersStandardManualRipping && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartRippingButtonClick}
          >
            Start Ripping
          </Button>
        )}
        {canViewerCompleteTransfersStandardManualRipping && ( // TODO: Fix this bit in graph
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompleteRippingButtonClick}
          >
            Complete Ripping
          </Button>
        )}
        {canViewerStartTransfersStandardManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartPrintingButtonClick}
          >
            Start Printing
          </Button>
        )}
        {canViewerPauseTransfersStandardManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handlePausePrintingButtonClick}
          >
            Pause Printing
          </Button>
        )}
        {canViewerCompleteTransfersStandardManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompletePrintingButtonClick}
          >
            Complete Printing
          </Button>
        )}
      </div>
    )}
  </div>
);

export default enhancer(HDDOrderStatus);
