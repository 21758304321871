// @flow

import * as React from "react";
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { fragments as customizationAreaArtworkListFragments } from "../../../CustomizationAreaArtworkList/graph";
import { filter } from "graphql-anywhere";
import { getAbbreviationForProductionJobMethod } from "../../../../helpers/getAbbreviationForProductionJobMethod";
import { getAttributesList } from "../../../../helpers/getAttributesList";
import { getIconForProductionJobMethod } from "../../../../helpers/getIconForProductionJobMethod";
import { fragments as inkColorDetailsFragments } from "../../../InkColorDetails/graph";
import { updateOrderProductionJobCustomizationAreaColors } from "../../../../graph";
import { withStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddNoteForm from "../../../AddNoteForm";
import Button from "@mui/material/Button";
import CustomerArtworkPreview from "../../../ProductionJobDetail/components/CustomerArtworkPreview";
import CustomizationAreaArtworkList from "../../../CustomizationAreaArtworkList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import InkColorDetails from "../../../InkColorDetails";
import InkColorModal from "../../../InkColorModal";
import NotesCollectionBreakdown from "../../../NotesCollectionBreakdown";
import Typography from "@mui/material/Typography";
import UpdateCustomizationAreaModal from "../../../UpdateCustomizationAreaModal";

import type { HOC } from "recompose";
import type { InkColor } from "../../../../types/Color";
import type { InkColorInputErrors } from "../../../../types/InkColorInputErrors";
import type { PantoneColor } from "../../../../types/Color";

type UnformattedNoteType = {|
  +__typename: "UnformattedNote",
  +note: string
|};

type FormattedNoteType = {|
  +__typename: "FormattedNote",
  +author: string,
  +createdAt: string,
  +note: string
|};

type NoteType = FormattedNoteType | UnformattedNoteType;

type State = {|
  generalErrors: $ReadOnlyArray<string>,
  inkColorInputErrors: InkColorInputErrors,
  isAddingColors: boolean,
  isUpdatingCustomizationArea: boolean
|};

const defaultInkColorInputErrors = {
  colors: [],
  colorsInput: {
    inkColorId: [],
    pantoneColorId: []
  },
  orderProductionJobCustomizationAreaInkColorId: []
};

const defaultState: State = {
  isAddingColors: false,
  generalErrors: [],
  inkColorInputErrors: defaultInkColorInputErrors,
  isUpdatingCustomizationArea: false
};

type Props = {|
  +customizationArea: {|
    +artwork: $ReadOnlyArray<{|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string,
      +originalFilename: string
    |}>,
    +autobase: boolean,
    +colorCount: number,
    +distressed: boolean,
    +flatten: boolean,
    +halftones: boolean,
    +id: string,
    +imageRepair: boolean,
    +inkColors: $ReadOnlyArray<{|
      +inkColor: ?{|
        +color: {|
          +formatted: string
        |},
        +id: string,
        +name: string
      |},
      +pantoneColor: ?{|
        +code: string,
        +color: {|
          +formatted: string
        |},
        +id: string
      |}
    |}>,
    +location: string,
    +method: string,
    +notesCollection: $ReadOnlyArray<NoteType>,
    +recreate: boolean,
    +splitIntoColors: boolean,
    +sponsorback: boolean,
    +template: {|
      +description: string,
      +id: string
    |},
    +vinylConfiguration: ?{|
      +namesColor: ?{|
        +id: string,
        +inkColor: {|
          +name: string
        |}
      |},
      +namesColorId: string,
      +namesFont: ?{|
        +alias: string,
        +id: string
      |},
      +namesFontId: string,
      +numbersColor: ?{|
        +id: string,
        +inkColor: {|
          +name: string
        |}
      |},
      +numbersColorId: string,
      +numbersFont: ?{|
        +alias: string,
        +id: string
      |},
      +numbersFontId: string,
      +sizeInteger: ?number
    |}
  |},
  +productionJobId: string,
  +shouldShowCustomerArtworkWithNotes: boolean,
  +shouldShowInkColors: boolean
|};

const styles = theme => ({
  container: {
    display: "flex",
    width: "100%"
  },

  colorDetailsContainer: {
    marginBottom: theme.spacing(1)
  },

  customizationAreaItem: {
    marginBottom: theme.spacing(2)
  },

  descriptionList: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between"
  },

  descriptionTag: {
    fontWeight: theme.typography.fontWeightMedium
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    listStyleType: "none",
    marginTop: 0,
    marginBottom: theme.spacing(1),
    paddingLeft: 0
  },

  methodContainer: {
    display: "flex"
  },

  leftContainer: {
    flex: "1 1 40%",
    marginRight: theme.spacing(2)
  },

  panel: {
    square: true
  },

  rightContainer: {
    flex: "1 1 60%"
  },

  root: {
    width: "100%"
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderProductionJobCustomizationAreaListItem"),

  updateOrderProductionJobCustomizationAreaColors,

  mapProps(
    ({
      customizationArea,
      productionJobId,
      customizationArea: {
        autobase,
        distressed,
        flatten,
        halftones,
        imageRepair,
        recreate,
        sponsorback
      },
      ...props
    }) => ({
      ...props,
      productionJobId,
      customizationArea: { ...customizationArea },
      attributesList: getAttributesList(
        autobase,
        distressed,
        flatten,
        halftones,
        imageRepair,
        recreate,
        sponsorback
      )
    })
  ),

  withStateHandlers(() => defaultState, {
    setIsAddingColors: () => (isAddingColors: boolean) => ({ isAddingColors }),

    setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
      generalErrors
    }),

    setIsUpdatingCustomizationAreaModal: () => (
      isUpdatingCustomizationArea: boolean
    ) => ({
      isUpdatingCustomizationArea
    }),

    setInkColorInputErrors: () => (
      inkColorInputErrors: InkColorInputErrors
    ) => ({
      inkColorInputErrors
    })
  }),

  withHandlers({
    handleAddColorsModalColorsAdded: ({
      updateOrderProductionJobCustomizationAreaColors,
      setIsAddingColors,
      setGeneralErrors,
      setInkColorInputErrors,
      customizationArea: { id }
    }) => (
      inkColors: $ReadOnlyArray<InkColor>,
      pantoneColors: $ReadOnlyArray<PantoneColor>
    ) => {
      setIsAddingColors(true);
      updateOrderProductionJobCustomizationAreaColors({
        variables: {
          colors: [
            ...inkColors.map(inkColor => ({
              inkColorId: inkColor.id
            })),
            ...pantoneColors.map(pantoneColor => ({
              pantoneColorId: pantoneColor.id
            }))
          ],
          orderProductionJobCustomizationAreaId: id
        }
      })
        .then(
          ({
            data: {
              updateOrderProductionJobCustomizationAreaColors: {
                succeeded,
                errors
              }
            }
          }) => {
            if (!succeeded) {
              setInkColorInputErrors(...errors);
            }
          }
        )
        .catch(e => {
          setGeneralErrors(e.message);
        })
        .finally(() => setIsAddingColors(false));
    },

    handleUpdateColorsButtonClick: ({ setIsAddingColors }) => () => {
      setIsAddingColors(true);
    },

    handleAddColorsModalRequestClose: ({ setIsAddingColors }) => () => {
      setIsAddingColors(false);
    },

    handleUpdateCustomizationAreaModalRequestClose: ({
      setIsUpdatingCustomizationAreaModal
    }) => () => {
      setIsUpdatingCustomizationAreaModal(false);
    },

    handleEditCustomizationAreaButtonClick: ({
      setIsUpdatingCustomizationAreaModal
    }) => () => {
      setIsUpdatingCustomizationAreaModal(true);
    }
  })
);

const OrderProductionJobCustomizationAreaListItem = ({
  attributesList,
  classes,
  customizationArea,
  productionJobId,
  customizationArea: {
    artwork,
    customerArtwork,
    colorCount,
    id,
    inkColors,
    location,
    method,
    notesCollection,
    vinylConfiguration
  },
  handleAddColorsModalColorsAdded,
  handleEditCustomizationAreaButtonClick,
  handleUpdateColorsButtonClick,
  handleAddColorsModalRequestClose,
  inkColorInputErrors,
  isAddingColors,
  isUpdatingCustomizationArea,
  handleUpdateCustomizationAreaModalRequestClose,
  shouldShowCustomerArtworkWithNotes,
  shouldShowInkColors
}) => (
  <div className={classes.root}>
    <Accordion defaultExpanded square={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
        <div className={classes.heading}>
          <div>
            <Typography variant="h6">{location}</Typography>
            <div className={classes.methodContainer}>
              {getIconForProductionJobMethod(method)}
              <Typography variant="subtitle2">
                {getAbbreviationForProductionJobMethod(method)}
              </Typography>
            </div>
          </div>
          <FormControlLabel
            label=""
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            labelPlacement="end"
            control={
              <Button
                className={classes.spacerRight}
                color="secondary"
                variant="contained"
                onClick={handleEditCustomizationAreaButtonClick}
              >
                Edit Customization Area
              </Button>
            }
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <div className={classes.customizationAreaItem}>
              <Typography className={classes.title} variant="subtitle2">
                Artwork:
              </Typography>
              <CustomizationAreaArtworkList
                proofs={filter(
                  customizationAreaArtworkListFragments.proofs,
                  artwork
                )}
              />
              {shouldShowCustomerArtworkWithNotes && (
                <CustomerArtworkPreview customerArtwork={customerArtwork} />
              )}
            </div>
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.customizationAreaItem}>
              {attributesList && (
                <div className={classes.customizationAreaItem}>
                  <Typography className={classes.title} variant="subtitle2">
                    Attributes:
                  </Typography>
                  <Typography>{attributesList}</Typography>
                </div>
              )}
              {method === "CUT_VINYL" && vinylConfiguration ? (
                <React.Fragment>
                  <Typography className={classes.title} variant="subtitle2">
                    Vinyl Configuration:
                  </Typography>
                  <dl className={classes.descriptionList}>
                    <Typography
                      component="dt"
                      variant="body2"
                      className={classes.descriptionTag}
                    >
                      Size:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      {vinylConfiguration.sizeInteger
                        ? vinylConfiguration.sizeInteger === 4
                          ? "Small"
                          : "Large"
                        : "N/A"}
                    </Typography>
                  </dl>
                  <dl className={classes.descriptionList}>
                    <Typography
                      component="dt"
                      variant="body2"
                      className={classes.descriptionTag}
                    >
                      Color:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      {vinylConfiguration.namesColor
                        ? vinylConfiguration.namesColor.inkColor.name
                        : vinylConfiguration.numbersColor
                        ? vinylConfiguration.numbersColor.inkColor.name
                        : "N/A"}
                    </Typography>
                  </dl>
                  <dl className={classes.descriptionList}>
                    <Typography
                      component="dt"
                      variant="body2"
                      className={classes.descriptionTag}
                    >
                      Font:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      {vinylConfiguration.namesFont
                        ? vinylConfiguration.namesFont.alias
                        : vinylConfiguration.numbersFont
                        ? vinylConfiguration.numbersFont.alias
                        : "N/A"}
                    </Typography>
                  </dl>
                </React.Fragment>
              ) : (
                shouldShowInkColors && (
                  <React.Fragment>
                    <Typography className={classes.title} variant="subtitle2">
                      Colors:
                    </Typography>
                    <div className={classes.colorDetailsContainer}>
                      {inkColors.length > 0 ? (
                        <ul className={classes.list}>
                          {inkColors.map(
                            ({ inkColor, pantoneColor }, index) => (
                              <li className={classes.item} key={index}>
                                <InkColorDetails
                                  inkColor={
                                    inkColor
                                      ? filter(
                                          inkColorDetailsFragments.inkColor,
                                          inkColor
                                        )
                                      : null
                                  }
                                  pantoneColor={
                                    pantoneColor
                                      ? filter(
                                          inkColorDetailsFragments.pantoneColor,
                                          pantoneColor
                                        )
                                      : null
                                  }
                                />
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <Typography>{`Count: ${colorCount}`}</Typography>
                      )}
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleUpdateColorsButtonClick}
                    >
                      Update Colors
                    </Button>
                    <InkColorModal
                      inputErrors={inkColorInputErrors}
                      isOpen={isAddingColors}
                      onRequestClose={handleAddColorsModalRequestClose}
                      onColorsAdded={handleAddColorsModalColorsAdded}
                      title="Update Ink Colors"
                    />
                  </React.Fragment>
                )
              )}
            </div>
            <div className={classes.customizationAreaItem}>
              <Typography className={classes.title} variant="subtitle2">
                Notes:
              </Typography>
              <NotesCollectionBreakdown notesCollection={notesCollection} />
              <AddNoteForm
                placeholder="Enter a print area note here..."
                customizationAreaId={id}
                isAddingCustomizationAreaNote={true}
              />
            </div>
          </div>
          <UpdateCustomizationAreaModal
            customizationArea={customizationArea}
            isOpen={isUpdatingCustomizationArea}
            onRequestClose={handleUpdateCustomizationAreaModalRequestClose}
            orderProductionJobId={productionJobId}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  </div>
);

export default withStyles(styles)(
  enhancer(OrderProductionJobCustomizationAreaListItem)
);
