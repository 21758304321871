// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../PendingStatusView";
import SearchableColorCombobox from "../SearchableColorCombobox";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";
import type { InkColor } from "../../types/Color";
import type { InkColorInputErrors } from "../../types/InkColorInputErrors";
import type { PantoneColor } from "../../types/Color";

type Props = {|
  +inputErrors: InkColorInputErrors,
  +isOpen: boolean,
  +onColorsAdded?: (
    inkColors: $ReadOnlyArray<InkColor>,
    pantoneColors: $ReadOnlyArray<PantoneColor>
  ) => void,
  +onRequestClose: () => void,
  +title?: string
|};

type State = {|
  selectedInkColors: $ReadOnlyArray<InkColor>,
  selectedPantoneColors: $ReadOnlyArray<PantoneColor>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("InkColorModal"),
  graphql(query, {
    skip: ({ isOpen }) => !isOpen
  }),
  withStateHandlers(
    ({ initialInkColors = [], initialPantoneColors = [] }) =>
      ({
        selectedInkColors: initialInkColors,
        selectedPantoneColors: initialPantoneColors
      }: State),
    {
      setSelectedInkColors: () => (
        selectedInkColors: $ReadOnlyArray<InkColor>
      ) => ({
        selectedInkColors
      }),

      setSelectedPantoneColors: () => (
        selectedPantoneColors: $ReadOnlyArray<PantoneColor>
      ) => ({
        selectedPantoneColors
      })
    }
  ),

  withHandlers({
    handleInkColorModalCancelButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleInkColorModalRequestClose: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleFormSubmit: ({
      onColorsAdded,
      onRequestClose,
      selectedInkColors,
      selectedPantoneColors
    }) => () => {
      if (selectedInkColors === null && selectedPantoneColors === null) {
        onRequestClose();
      } else {
        onColorsAdded(selectedInkColors, selectedPantoneColors);
      }
    },

    handleInkColorsComboboxSelect: ({
      data: { inkColors },
      setSelectedInkColors
    }) => (selection: $ReadOnlyArray<string>) => {
      const selectedInkColors = inkColors.filter(
        ({ id }) => selection.indexOf(id) !== -1
      );
      setSelectedInkColors(selectedInkColors);
    },

    handlePantoneColorsComboboxSelect: ({
      data: { pantoneColors },
      setSelectedPantoneColors
    }) => (selection: $ReadOnlyArray<string>) => {
      const selectedPantoneColors = pantoneColors.filter(
        ({ id }) => selection.indexOf(id) !== -1
      );
      setSelectedPantoneColors(selectedPantoneColors);
    }
  })
);

const InkColorModal = ({
  data,
  handleInkColorModalCancelButtonClick,
  handleInkColorModalRequestClose,
  handleFormSubmit,
  handleInkColorsComboboxSelect,
  handlePantoneColorsComboboxSelect,
  inputErrors,
  isOpen,
  title
}) => (
  <Dialog
    aria-describedby="Ink Color Modal"
    aria-labelledby="InkColorModal"
    open={isOpen}
    onClose={handleInkColorModalRequestClose}
    fullWidth={true}
  >
    <DialogTitle>{title ? title : "Add Ink Colors"}</DialogTitle>
    <DialogContent>
      {!data ? (
        <PendingStatusView status="Waiting" />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography color="error" variant="body2">
          {data.error.message}
        </Typography>
      ) : !data.inkColors && !data.pantoneColors ? (
        <Typography color="error" variant="body2">
          There are no colors available
        </Typography>
      ) : inputErrors.length > 0 ? (
        <Typography color="error" variant="body2">
          {inputErrors.join(", ")}
        </Typography>
      ) : (
        <React.Fragment>
          <SearchableColorCombobox
            onSelect={handleInkColorsComboboxSelect}
            placeholder="Search Ink Colors"
            colorOptions={data.inkColors.map(({ name: label, ...props }) => ({
              ...props,
              label
            }))}
            multiple
          />
          <SearchableColorCombobox
            onSelect={handlePantoneColorsComboboxSelect}
            placeholder="Search Pantone Colors"
            colorOptions={data.pantoneColors.map(
              ({ code: label, ...props }) => ({
                ...props,
                label
              })
            )}
            multiple
          />
          <DialogActions>
            <Button
              color="secondary"
              onClick={handleInkColorModalCancelButtonClick}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </DialogContent>
  </Dialog>
);

export default enhancer(InkColorModal);
