// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { ThemeContextConsumer } from "../../../../../application/themeContext";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { getLogoForBrandUrl } from "../../../../../helpers";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../../../components/ProductionJobLineItemBreakdownTable/graph";
import { query } from "./graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../components/StyleSubstitutionLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../../../../components/CenteredSpinner";
import Checked from "@mui/icons-material/Done";
import CustomerStatusChip from "../../../../apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip";
import Divider from "@mui/material/Divider";
import ExpeditingLabelViewer from "../../../../../components/ExpeditingLabelViewer";
import NoBrandTagIcon from "../../../../../assets/no-brand-tag.png";
import NotesCollectionBreakdown from "../../../../../components/NotesCollectionBreakdown";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import ProductionJobLineItemBreakdownTable from "../../../../../components/ProductionJobLineItemBreakdownTable";
import StyleSubstitutionLineItemBreakdown from "../../../../../components/StyleSubstitutionLineItemBreakdown";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +button: string,
    +descriptionList: string,
    +descriptionTag: string,
    +divider: string,
    +primaryCustomerBlindShippedWrapper: string,
    +productionDueDateWrapper: string,
    +productionJobLabel: string,
    +productionJobLineItemBreakdownTable: string,
    +wrapper: string
  |},
  +isPullingFromStock: boolean,
  +onRequestLocalInventory: (productionJobId: string) => void,
  +productionJobId: ?string
|};

const styles = theme => ({
  button: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },

  descriptionList: {
    alignItems: "center",
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  primaryCustomerInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },

  primaryCustomerName: {
    marginRight: theme.spacing(1)
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  productionJobLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },

  productionJobLineItemBreakdownTable: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  wrapper: {
    padding: theme.spacing(2)
  }
});

const isBlindShipped = "BLIND_SHIPPED";

const enhancer: HOC<*, Props> = compose(
  setDisplayName("LocalInventoryDetailView"),

  withStyles(styles),

  withHandlers(() => {
    let printRef;
    return {
      handlePrintRef: () => ref => {
        printRef = ref;
      },
      handleLocalInventoryButtonClick:
        ({ onRequestLocalInventory }) =>
        event => {
          event.stopPropagation();
          onRequestLocalInventory(event.currentTarget.id);
        },
      handlePrintLabelButtonClick:
        () => (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          if (printRef) {
            printRef();
          }
        }
    };
  }),

  graphql(query, {
    skip: ({ productionJobId }) => !productionJobId
  })
);

const pluralize = require("pluralize");

const LocalInventoryDetailView = ({
  classes,
  data,
  handlePrintRef,
  handleLocalInventoryButtonClick,
  handlePrintLabelButtonClick,
  isPullingFromStock
}) => (
  <div>
    {!data ? (
      <div />
    ) : data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        {data.error.message}
      </Typography>
    ) : !data.productionJob ? (
      <Typography align="center" variant="body2" color="error">
        Production job could not be located.
      </Typography>
    ) : (
      <div className={classes.wrapper}>
        <div>
          <div className={classes.primaryCustomerInfoWrapper}>
            <div
              style={{ height: "34px", maxWidth: "95px", marginRight: "8px" }}
            >
              {data.productionJob.order.primaryCustomer.businessUnit.name && (
                <ThemeContextConsumer>
                  {context => (
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%"
                      }}
                      src={getLogoForBrandUrl(
                        data.productionJob.order.primaryCustomer.businessUnit
                          .name,
                        context.type
                      )}
                      alt={
                        data.productionJob.order.primaryCustomer.businessUnit
                          .name
                      }
                    />
                  )}
                </ThemeContextConsumer>
              )}
            </div>

            <CustomerStatusChip
              status={data.productionJob.order.primaryCustomer.status}
            />
            {!data.productionJob.order.primaryCustomer.insideBrandTagging && (
              <img
                style={{ width: "36px", margin: "0 8px" }}
                src={NoBrandTagIcon}
                alt="No Brand Tagging"
              />
            )}
          </div>

          <div className={classes.primaryCustomerInfoWrapper}>
            <Typography className={classes.primaryCustomerName} variant="body1">
              {data.productionJob.order.primaryCustomer.name}
              {` (${data.productionJob.order.primaryCustomer.totalOrders}) `}
            </Typography>
          </div>
        </div>
        <div className={classes.orderNumberTotalBlindShippedWrapper}>
          <div>
            <Typography color="textSecondary" variant="subtitle2">
              {data.productionJob.order.orderNumber}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2">
              {`${pluralize(
                "Piece",
                data.productionJob.order.totalProductVariantQuantity,
                true
              )}`}
            </Typography>
          </div>
          <div className={classes.descriptionList}>
            {data.productionJob.order.primaryCustomer.status ===
              isBlindShipped && (
              <React.Fragment>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Blind Shipped:
                </Typography>
                <Typography variant="subtitle2">
                  <Checked />
                </Typography>
              </React.Fragment>
            )}
          </div>
        </div>
        <Divider light={true} className={classes.divider} />
        {isPullingFromStock ? (
          <CenteredSpinner />
        ) : (
          <React.Fragment>
            {data && data.productionJob && (
              <ExpeditingLabelViewer
                isResolution={data.productionJob.order.isResolution}
                productionJobs={[data.productionJob]}
                totalProductionJobs={
                  data.productionJob.order.productionJobs.length
                }
                printRef={handlePrintRef}
                hidden={true}
                shipMissCosts={data.productionJob.order.shipMissCosts}
                productionStationAssignment={
                  data.productionJob.order.productionStationAssignments.length >
                  0
                    ? data.productionJob.order.productionStationAssignments[0]
                        .productionStation.name
                    : undefined
                }
                priority={data.productionJob.order.priority}
                includeCatalogInShipment={data.productionJob.order.includeCatalogInShipment}
              />
            )}
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handlePrintLabelButtonClick}
            >
              Print Label
            </Button>
            <Button
              id={data.productionJob.id}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleLocalInventoryButtonClick}
            >
              Receive
            </Button>
          </React.Fragment>
        )}
        <div className={classes.productionDueDateWrapper}>
          {data.productionJob.order.productionDate && (
            <div className={classes.descriptionList}>
              <Typography
                className={classes.descriptionTag}
                color="textSecondary"
                variant="subtitle2"
              >
                Production Date:
              </Typography>
              <PriorityChip
                date={data.productionJob.order.productionDate}
                priority={data.productionJob.order.priority}
              />
            </div>
          )}
          {data.productionJob.order.dueBy.dateTime && (
            <div className={classes.descriptionList}>
              <Typography
                className={classes.descriptionTag}
                color="textSecondary"
                variant="subtitle2"
              >
                Due Date:
              </Typography>
              <Typography variant="subtitle2">
                {DateTime.fromISO(
                  data.productionJob.order.dueBy.dateTime
                ).toLocaleString(DateTime.DATE_SHORT)}
              </Typography>
            </div>
          )}
        </div>
        {data.productionJob.order.productionStationAssignments.map(
          (assignment, index) => (
            <div className={classes.descriptionList} key={index}>
              <Typography
                className={classes.descriptionTag}
                color="textSecondary"
                variant="subtitle2"
              >
                Station Assignment:
              </Typography>
              <Typography variant="subtitle2">
                {assignment.productionStation.name}
              </Typography>
            </div>
          )
        )}
        <Divider light={true} className={classes.divider} />
        <Typography className={classes.productionJobLabel} variant="body1">
          {data.productionJob.label}
        </Typography>
        <Typography color="textSecondary" variant="subtitle2">
          {`${pluralize(
            "Piece",
            data.productionJob.totalProductVariantQuantity,
            true
          )}`}
        </Typography>
        {data.productionJob.lineItemBreakdownByProduct.map(
          productionJobLineItemBreakdown => (
            <div
              className={classes.productionJobLineItemBreakdownTable}
              key={productionJobLineItemBreakdown.product.id}
            >
              <Typography variant="body2">
                {`${productionJobLineItemBreakdown.product.style} - `}
                {productionJobLineItemBreakdown.product.fullDisplayName}
              </Typography>
              {productionJobLineItemBreakdown.product.isStyleSubstitution ? (
                <StyleSubstitutionLineItemBreakdown
                  lineItems={filter(
                    styleSubstitutionLineItemBreakdownFragments.lineItems,
                    productionJobLineItemBreakdown.lineItems
                  )}
                />
              ) : (
                <ProductionJobLineItemBreakdownTable
                  lineItems={filter(
                    productionJobLineItemBreakdownTableFragments.lineItems,
                    productionJobLineItemBreakdown.lineItems
                  )}
                />
              )}
            </div>
          )
        )}
        <Typography color="textSecondary" variant="subtitle2">
          Production Notes:
        </Typography>
        <NotesCollectionBreakdown
          notesCollection={data.productionJob.order.productionNotesCollection}
        />
      </div>
    )}
  </div>
);

export default enhancer(LocalInventoryDetailView);
