// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ReceivingTable_orderProductionJob on OrderProductionJob {
      id
      label
      hasStockBeenReceived
      stockReceivedAt
      stockStatusLabel
      order {
        id
        express
        includeCatalogInShipment
        orderNumber
        primaryCustomer {
          name(format: FIRST_NAME_LAST_NAME)
          id
          status
        }
        priority
        productionDate
        productionStationAssignments {
          id
          productionStation {
            id
            name
          }
        }
      }
      totalProductVariantQuantity
    }
  `
};
