// @flow

import { gql } from "@apollo/client";

export default gql`
  query FixPieceRequestDetail($fixPieceRequestId: ID!) {
    fixPieceRequest: node(id: $fixPieceRequestId) {
      ... on FixPieceRequest {
        canViewerPurchase
        id
        description
        hasBeenOrdered
        itemBreakdown {
          inventoryItem {
            id
            description
          }
          quantityNeeded
          quantityOrdered
          quantityRequested
        }
        orderedAt
        orderProductionJob {
          label
          order {
            dueBy {
              dateTime
            }
            id
            orderNumber
            priority
            productionDate
          }
        }
        requestedByEmployee{
          name(format:LAST_NAME_FIRST_INITIAL)
        }
      }
    }
    manualOrderVendors {
      id
      organization {
        id
        name
      }
    }
  }
`;
