import { compose, setDisplayName } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import PendingStatusView from "../../../PendingStatusView";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const enhancer = compose(
  setDisplayName("CarrierPalletCountModal"),
  
  graphql(query, {
    skip: ({ id, isOpen }) => !isOpen || !id,
    options: ({ id }) => ({
      variables: {
        id
      },
      fetchPolicy: "network-only"
    })
  })
);


const CarrierPalletCountModal = ({
  id,
  isOpen,
  onRequestClose,
  onComplete,
  data
}) => {
  const [carrierPalletCount, setCarrierPalletCount] = useState({});

  if (!data) {
    return <div />;
  }

  const { loading, error, node } = data;
  if (loading) {
    return <PendingStatusView status="Loading" />;
  }

  if (error) {
    return null;
  }

  const { pallets } = node;

  console.log("pallets", pallets);

  let carriers = pallets
    .reduce(
      (acc1, { cartons }) => 
      (
        [
          ...acc1, 
          ...cartons.reduce(
            (acc2, { lineItems }) => 
            (
              [
                ...acc2, 
                ...lineItems.map(({ carrierName }) => carrierName)
              ]
            ), 
            []
          ), 
        ]
      ),
      []
    )
    .filter((item, index, arr) => item && arr.indexOf(item) === index);
  
  console.log("carriers", carriers);

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setCarrierPalletCount({});
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      onComplete({
        id,
        carrierPalletCount
      });
      setCarrierPalletCount({});
    }
  };

  const handleCarrierPalletCountChange = (carrier, value) => {
    setCarrierPalletCount({
      ...carrierPalletCount,
      [carrier]: value
    })
  }

  return (
    <Dialog
      aria-describedby="Carrier Pallet Count"
      aria-labelledby="Carrier Pallet Count"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Carrier Pallet Count</DialogTitle>
      <DialogContent>
        <FormGroup>
          {carriers && carriers.length > 0 && (
            <>
              {carriers.map(carrier => (
                <TextField
                  label={carrier}
                  value={carrierPalletCount[carrier] || ""}
                  onChange={({ target: { value } }) => handleCarrierPalletCountChange(carrier, value) }
                  fullWidth
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
              ))}
            </>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={Object.keys(carrierPalletCount).length === 0}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(CarrierPalletCountModal);
