// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

type Props = {|
  +artworkChanges: $ReadOnlyArray<{|
    +changedAt: string,
    +classes?: {|
      +tableCell: string
    |},
    +description: string,
    +employee: {
      +name_orderArtworkChangesTable: string
    },
    +id: string
  |}>
|};

const styles = theme => ({
  tableCell: {
    padding: theme.spacing(2)
  }
});

const OrderArtworkChangesTable = ({ classes, artworkChanges }: Props) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>Changes</TableCell>
        <TableCell className={classes.tableCell}>User</TableCell>
        <TableCell className={classes.tableCell}>Date</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {artworkChanges.length === 0 ? (
        <TableRow>
          <TableCell align="center" className={classes.tableCell} colSpan={3}>
            There are no artwork changes for this order
          </TableCell>
        </TableRow>
      ) : (
        artworkChanges.map(
          ({
            changedAt,
            description,
            id,
            employee: { name_orderArtworkChangesTable }
          }) => (
            <TableRow key={id}>
              <TableCell className={classes.tableCell}>{description}</TableCell>
              <TableCell className={classes.tableCell}>
                {name_orderArtworkChangesTable}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {DateTime.fromISO(changedAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </TableCell>
            </TableRow>
          )
        )
      )}
    </TableBody>
  </Table>
);

export default withStyles(styles)(OrderArtworkChangesTable);
