// @flow

import { gql } from "@apollo/client";

export default {
  viewer: gql`
    fragment CurrentViewers_viewer on Viewer {
      id
      me {
        id
        name(format: FIRST_NAME_LAST_NAME)
      }
    }
  `
};
