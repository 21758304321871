// @flow

import { gql } from "@apollo/client";

export default gql`
  query customsShipment($id: ID!) {
    customsShipment: node(id: $id) {
      ... on CustomsShipment {
        id
        canonicalId
        createdAt
        pallets {
          id
          createdAt
          cartons {
            id
          }
          id
          createdAt
          lineItems {
            identifier
            countryOfOrigin
            embroidery
            quantity
            description
          }
          shipment {
            id
            createdAt
          }
          shippingCarrier {
            id
            name
          }
          cartons {
            id
            lineItems {
              id
              identifier
              countryOfOrigin
              embroidery
              quantity
              description
            }
            stockContainer {
              id
              orderProductionJob {
                id
                label
                index
                totalProductVariantQuantity
                order {
                  id
                  orderNumber
                  isArtworkApproved
                  isResolution
                  dueBy {
                    dateTime
                  }
                  priority
                  productionDate
                  primaryCustomer {
                    id
                    name(format: FIRST_NAME_LAST_NAME)
                  }
                  productionStationAssignments {
                    id
                    productionStation {
                      id
                      name
                    }
                  }
                  productionJobs {
                    id
                  }
                }
                customizationAreas {
                  id
                  location
                  method
                }
              }
            }
          }
        }
      }
    }
  }
`;
