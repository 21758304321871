// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const deleteCustomsShipment: any = graphql(
  gql`
    mutation ($customsShipmentId: ID!) {
      deleteCustomsShipment(customsShipmentId: $customsShipmentId) {
        errors {
          customsShipmentId
        }
        succeeded
      }
    }
  `,
  {
    name: "deleteCustomsShipment"
  }
);

export default deleteCustomsShipment;
