// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment OrderFulfillmentSummary_order on Order {
      dueBy {
        date
        dateTime
      }
      isInHouse
      vendor {
        id
        organization {
          id
          name
        }
      }
      productionDate
      salesRepresentatives {
        id
        name_orderSummaryModule: name(format: FIRST_NAME_LAST_NAME)
      }
    }
  `
};
