// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../../../../../components/ProductionJobLineItemBreakdownTable/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
import ProductionJobLineItemBreakdownTable from "../../../../../../../components/ProductionJobLineItemBreakdownTable";
import StyleSubstitutionLineItemBreakdown from "../../../../../../../components/StyleSubstitutionLineItemBreakdown";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

const pluralize = require("pluralize");

type Props = {|
  +classes: {|
    +name: string,
    +tableContainer: string,
    +textWrapper: string
  |},
  +productionJobLineItemBreakdown: {|
    +lineItems: $ReadOnlyArray<{||}>,
    +product: {|
      +fullDisplayName: string,
      +style: string
    |},
    +totalProductVariantQuantity: number
  |}
|};

const styles = theme => ({
  name: {
    marginRight: theme.spacing(1)
  },

  tableContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },

  textWrapper: {
    display: "flex",
    flexDirection: "row"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobLineItemBreakdown")
);

const ProductionJobLineItemBreakdown = ({
  classes,
  productionJobLineItemBreakdown
}) => (
  <div className={classes.tableContainer}>
    <div className={classes.textWrapper}>
      <Typography className={classes.name} variant="h6" color="textSecondary">
        {productionJobLineItemBreakdown.product.fullDisplayName}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {`(${pluralize(
          "piece",
          productionJobLineItemBreakdown.totalProductVariantQuantity,
          true
        )})`}
      </Typography>
    </div>
    {productionJobLineItemBreakdown.product.isStyleSubstitution ? (
      <StyleSubstitutionLineItemBreakdown
        lineItems={filter(
          styleSubstitutionLineItemBreakdownFragments.lineItems,
          productionJobLineItemBreakdown.lineItems
        )}
      />
    ) : (
      <ProductionJobLineItemBreakdownTable
        lineItems={filter(
          productionJobLineItemBreakdownTableFragments.lineItems,
          productionJobLineItemBreakdown.lineItems
        )}
      />
    )}
  </div>
);

export default withStyles(styles)(enhancer(ProductionJobLineItemBreakdown));
