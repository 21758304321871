// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { fragments as embroideryOrderStatusFragments } from "../EmbroideryOrderStatus/graph";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import EmbroideryOrderStatus from "../EmbroideryOrderStatus";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("EmbroideryOrderDetail"),

  withRouter,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withHandlers({
    handleAppBarRequestBack: ({ router }) => () => {
      router.push("/apps/production/embroidery");
    },

    handleOrderDetailRequestClose: ({ router }) => () => {
      router.push("/apps/production/embroidery");
    }
  })
);

const EmbroideryOrderDetail = ({
  data,
  handleAppBarRequestBack,
  handleOrderDetailRequestClose
}) => (
  <OrderDetailWithStatusHeader
    errorMessage={data.error ? data.error.message : null}
    loading={data.loading}
    onRequestClose={handleOrderDetailRequestClose}
    order={data.order}
    orderStatusView={
      data && data.order ? (
        <EmbroideryOrderStatus
          order={filter(embroideryOrderStatusFragments.order, data.order)}
        />
      ) : (
        <CircularProgress />
      )
    }
    shouldShowCustomerArtworkWithNotes={true}
    shouldShowInkColors={false}
    title="Embroidery"
    viewer={data.viewer}
  >
    <AppBar title="Embroidery" onRequestBack={handleAppBarRequestBack} />
  </OrderDetailWithStatusHeader>
);

export default enhancer(EmbroideryOrderDetail);
