// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +checkedIcon: string,
    +icon: string,
    +root: string
  |},
  +iconMethod: (customizationAreaTemplate: string) => any,
  +onSelect: (customizationAreaTemplateId: ?string) => void,
  +options: $ReadOnlyArray<{|
    +icon: string,
    +id: string,
    +label: string
  |}>,
  +selectionId: ?string
|};

const styles = theme => ({
  iconRadioButtonContainer: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaRadioList"),

  withStyles(styles),

  withHandlers({
    handleRadioButtonOnChange: ({ onSelect }) => event => {
      onSelect(event.target.id);
    }
  })
);

const CustomizationAreaRadioList = ({
  classes,
  handleRadioButtonOnChange,
  iconMethod,
  options,
  selectionId
}) => {
  return options.map(({ id, label, icon }) => (
    <div className={classes.iconRadioButtonContainer} key={id}>
      <div className={classes.icon}>{iconMethod(icon)}</div>
      <FormControlLabel
        value={label}
        control={
          <Radio
            checked={selectionId === id}
            id={id}
            color="primary"
            name="customization-area-radio-button"
            onChange={handleRadioButtonOnChange}
            value={label}
          />
        }
        label={label}
      />
    </div>
  ));
};

export default enhancer(CustomizationAreaRadioList);
