// @flow

import { fragments as customerArchiveListItemFragments } from "../../CustomerArchiveListItem/graph";
import { gql } from "@apollo/client";

export default {
  orders: gql`
    fragment CustomerArchiveList_orders on Order {
      id
      ...CustomerArchiveListItem_order
    }
    ${customerArchiveListItemFragments.order}
  `
};
