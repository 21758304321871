// @flow

import * as React from "react";
import { compose, mapProps } from "recompose";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import invariant from "invariant";

import type { HOC } from "recompose";
import type { Size } from "../types";

type Props = {|
  +classes: {|
    +avatar: string,
    +chip: string
  |},
  +name: string,
  +photographUrl: string,
  +size: Size
|};

const enhancer: HOC<*, Props> = compose(
  mapProps(({ classes, size, ...props }) => {
    let sizeClass = {};
    switch (size) {
      case "avatar":
        sizeClass = classes.avatar;
        break;
      case "chip":
        sizeClass = classes.chip;
        break;
      default:
        sizeClass = null;
        break;
    }

    invariant(sizeClass != null, "sizeClass should not be null");

    return { ...props, sizeClass };
  })
);

const styles = () => ({
  avatar: {
    height: 40,
    width: 40
  },

  chip: {
    height: 32,
    width: 32
  }
});

const PersonImageAvatar = ({ name, photographUrl, sizeClass }) => (
  <Avatar src={photographUrl} alt={name} className={sizeClass} />
);

export default withStyles(styles)(enhancer(PersonImageAvatar));
