// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Checked from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +blindShipColumn: string,
    +contactColumn: string,
    +fulfillmentDateColumn: string,
    +poColumn: string,
    +poWrapper: string,
    +stationColumn: string,
    +statusColumn: string,
    +totalProductVariantQuantityColumn: string
  |},
  +loadingProductionJobIds: $ReadOnlyArray<string>,
  +onRequestOrderReceived: (productionJobId: string) => void,
  +onRequestSort: (orderSortFilter: string) => void,
  +onRowClicked: (productionJobId: string) => void,
  +onScrolledToBottom: () => void,
  +orderProductionJobs: $ReadOnlyArray<{|
    +hasStockBeenReceived: boolean,
    +id: string,
    +label: string,
    +order: {|
      +id: string,
      +orderNumber: string,
      +primaryCustomer: {|
        +name: string,
        +status: string
      |},
      +priority: ?string,
      +productionDate: ?string,
      +productionStationAssignments: $ReadOnlyArray<{|
        +productionStation: {|
          +name: string
        |}
      |}>
    |},
    +stockReceivedAt: ?boolean,
    +stockStatusLabel: ?string,
    +totalProductVariantQuantity: number
  |}>
|};

const styles = theme => ({
  blindShipColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  fulfillmentDateColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  poColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  totalProductVariantQuantityColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  contactColumn: {
    width: 225,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  stationColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  statusColumn: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

const isBlindShipped = "BLIND_SHIPPED";

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ReceivingTable"),

  withStyles(styles),

  withHandlers({
    handleReceiveButtonClick: ({ onRequestOrderReceived }) => event => {
      event.stopPropagation();
      onRequestOrderReceived(event.currentTarget.id);
    },

    handleTableRowClicked: ({ onRowClicked }) => (id: string) => {
      onRowClicked(id);
    },

    handleSortRequest: ({ onRequestSort }) => event => {
      onRequestSort(event.currentTarget.id);
    }
  })
);

const ReceivingTable = ({
  classes,
  handleReceiveButtonClick,
  handleTableRowClicked,
  handleSortRequest,
  loadingProductionJobIds,
  onScrolledToBottom,
  orderProductionJobs
}) => {
  const isScrolledToBottom = useScrollPosition().isScrolledToBottom;
  React.useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell className={classes.contactColumn}>
            <div className={classes.contactColumn}>
              <Button id="CUSTOMER_NAME" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  Customer/Order #
                </Typography>
              </Button>
            </div>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.poColumn}>
            <Typography color="textSecondary" variant="overline">
              PO
            </Typography>
          </CustomTableCell>
          <CustomTableCell
            align="center"
            className={classes.totalProductVariantQuantityColumn}
          >
            <Button
              id="TOTAL_PRODUCT_VARIANT_QUANTITY"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                Quantity
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell
            align="center"
            className={classes.fulfillmentDateColumn}
          >
            <Button id="ORDER_PRODUCTION_DATE" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Production Date
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.stationColumn}>
            <Button
              id="ORDER_PRODUCTION_STATION_ASSIGNMENT"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                S/A
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.blindShipColumn}>
            <Button id="CUSTOMER_BLIND_SHIP" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Blind Ship
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.statusColumn}>
            <Typography color="textSecondary" variant="overline">
              Stock Received
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderProductionJobs.map(
          ({
            hasStockBeenReceived,
            id,
            label,
            order,
            stockReceivedAt,
            stockStatusLabel,
            totalProductVariantQuantity
          }) => (
            <TableRow key={id} onClick={handleTableRowClicked.bind(null, id)}>
              <TableCell className={classes.contactColumn} scope="row">
                <div className={classes.contactColumn}>
                  <Typography variant="h6">
                    {order.primaryCustomer.name}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {order.orderNumber}
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.poColumn}>
                <Typography align="center" variant="p">{label}</Typography>
              </TableCell>
              <TableCell
                align="center"
                className={classes.totalProductVariantQuantityColumn}
              >
                <Typography variant="p">{totalProductVariantQuantity}</Typography>
              </TableCell>
              <TableCell
                align="center"
                className={classes.fulfillmentDateColumn}
              >
                {order.productionDate && (
                  <PriorityChip
                    date={order.productionDate}
                    priority={order.priority}
                  />
                )}
              </TableCell>
              <TableCell align="center" className={classes.stationColumn}>
                {order.productionStationAssignments.map((assignment, index) => (
                  <Typography key={index} variant="p">
                    {assignment.productionStation.name}
                  </Typography>
                ))}
              </TableCell>
              <TableCell align="center" className={classes.blindShipColumn}>
                {order.primaryCustomer.status === isBlindShipped && <Checked />}
              </TableCell>
              <TableCell align="center" className={classes.statusColumn}>
                {hasStockBeenReceived ? (
                  <Button
                    disabled={true}
                    id={id}
                    variant="contained"
                    color="primary"
                  >
                    {`${stockStatusLabel}: ${DateTime.fromISO(
                      stockReceivedAt
                    ).toLocaleString(DateTime.DATETIME_SHORT)}`}
                  </Button>
                ) : loadingProductionJobIds.includes(id) ? (
                  <CircularProgress />
                ) : (
                  <Button
                    id={id}
                    variant="contained"
                    color="primary"
                    onClick={handleReceiveButtonClick}
                  >
                    Received
                  </Button>
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default enhancer(ReceivingTable);
