// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createCustomsShipment: any = graphql(
  gql`
    mutation ($direction: CustomsShipmentDirection!) {
      createCustomsShipment(direction: $direction) {
        createdCustomsShipment {
          id
        }
        errors {
          direction
        }
        succeeded
      }
    }
  `,
  {
    name: "createCustomsShipment"
  }
);

export default createCustomsShipment;
