// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Checked from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +action: string,
    +contactOrderNumberColumn: string,
    +contactOrderNumberWrapper: string,
    +dateColumn: string,
    +stationColumn: string,
    +totalProductVariantQuantityColumn: string
  |},
  +loadingProductionJobIds: $ReadOnlyArray<string>,
  +onRequestPullFromStock: (productionJobId: string) => void,
  +onRequestSort: (orderSortFilter: string) => void,
  +onRowClicked: (productionJobId: string) => void,
  +onScrolledToBottom: () => void,
  +productionJobs: $ReadOnlyArray<{|
    +id: string,
    +order: {|
      +id: string,
      +orderNumber: string,
      +primaryCustomer: {|
        +name: string,
        +status: string
      |},
      +priority: ?string,
      +productionDate: ?string,
      +productionStationAssignments: $ReadOnlyArray<{|
        +productionStation: {|
          +name: string
        |}
      |}>,
      +totalProductVariantQuantity: number
    |}
  |}>
|};

const styles = theme => ({
  action: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  blindShipColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  contactOrderNumberColumn: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  contactOrderNumberWrapper: {
    width: 225,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  dateColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  totalProductVariantQuantityColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  stationColumn: {
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  }
});

const isBlindShipped = "BLIND_SHIPPED";

const enhancer: HOC<*, Props> = compose(
  setDisplayName("PullFromStockTable"),

  withStyles(styles),

  withHandlers({
    handlePullFromStockButtonClick: ({ onRequestPullFromStock }) => event => {
      event.stopPropagation();
      onRequestPullFromStock(event.currentTarget.id);
    },

    handleTableRowClicked: ({ onRowClicked }) => (id: string) => {
      onRowClicked(id);
    },

    handleSortRequest: ({ onRequestSort }) => event => {
      onRequestSort(event.currentTarget.id);
    }
  })
);

const PullFromStockTable = ({
  classes,
  handlePullFromStockButtonClick,
  handleTableRowClicked,
  handleSortRequest,
  loadingProductionJobIds,
  onScrolledToBottom,
  productionJobs
}) => {
  const isScrolledToBottom = useScrollPosition().isScrolledToBottom;
  React.useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell className={classes.contactOrderNumberColumn}>
            <div className={classes.contactOrderNumberWrapper}>
              <Button id="CUSTOMER_NAME" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  Customer/Order #
                </Typography>
              </Button>
            </div>
          </CustomTableCell>
          <CustomTableCell
            align="center"
            className={classes.totalProductVariantQuantityColumn}
          >
            <Button
              id="TOTAL_PRODUCT_VARIANT_QUANTITY"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                Quantity
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.dateColumn}>
            <Button id="ORDER_PRODUCTION_DATE" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Production Date
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.stationColumn}>
            <Button
              id="ORDER_PRODUCTION_STATION_ASSIGNMENT"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                S/A
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.blindShipColumn}>
            <Button id="CUSTOMER_BLIND_SHIP" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Blind Ship
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center">
            <Typography color="textSecondary" variant="overline">
              Pull Stock
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productionJobs.map(({ id, order, totalProductVariantQuantity }) => (
          <TableRow key={id} onClick={handleTableRowClicked.bind(null, id)}>
            <TableCell className={classes.contactOrderNumberColumn} scope="row">
              <div className={classes.contactOrderNumberWrapper}>
                <Typography variant="h6">
                  {order.primaryCustomer.name}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {order.orderNumber}
                </Typography>
              </div>
            </TableCell>
            <TableCell
              align="center"
              className={classes.totalProductVariantQuantityColumn}
            >
              <Typography>{totalProductVariantQuantity}</Typography>
            </TableCell>
            <TableCell align="center" className={classes.dateColumn}>
              {order.productionDate && (
                <PriorityChip
                  date={order.productionDate}
                  priority={order.priority}
                />
              )}
            </TableCell>
            <TableCell align="center" className={classes.stationColumn}>
              {order.productionStationAssignments.map((assignment, index) => (
                <Typography key={index}>
                  {assignment.productionStation.name}
                </Typography>
              ))}
            </TableCell>
            <TableCell align="center" className={classes.blindShipColumn}>
              {order.primaryCustomer.status === isBlindShipped && <Checked />}
            </TableCell>
            <TableCell align="center" className={classes.action}>
              {loadingProductionJobIds.includes(id) ? (
                <CircularProgress />
              ) : (
                <Button
                  id={id}
                  variant="contained"
                  color="primary"
                  onClick={handlePullFromStockButtonClick}
                >
                  Pulled
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default enhancer(PullFromStockTable);
