// @flow

import * as React from "react";
import { colors } from "../../../../../../theme";
import { withStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";

const statuses = {
  ACTIVE: "Active",
  BLACK_LISTED: "Blacklisted",
  BLIND_SHIPPED: "Blind Shipped",
  COMPLICATED: "Complicated",
  HIGH_VALUE: "High Value"
};

type Props = {|
  +borderColor?: string,
  +classes: {|
    +active: string,
    +black_listed: string,
    +blind_shipped: string,
    +complicated: string,
    +high_value: string
  |},
  +status: $Keys<typeof statuses>
|};

const styles = theme => {
  return {
    active: {
      display: "none"
    },

    black_listed: {
      backgroundColor: colors.customer_status.black_listed,
      color: theme.palette.common.white
    },

    blind_shipped: {
      backgroundColor: colors.customer_status.blind_shipped,
      color: theme.palette.common.white
    },

    complicated: {
      backgroundColor: colors.customer_status.complicated,
      color: theme.palette.common.white
    },

    high_value: {
      backgroundColor: colors.customer_status.high_value,
      color: theme.palette.common.white
    }
  };
};

const CustomerStatusChip = ({ borderColor, classes, status }: Props) => {
  return (
    <Chip
      style={{ borderColor }}
      label={statuses[status]}
      color="primary"
      classes={{
        colorPrimary: classes[status.toLowerCase()]
      }}
      variant={borderColor ? "outlined" : "default"}
    />
  );
};

export default withStyles(styles)(CustomerStatusChip);
export { statuses };
