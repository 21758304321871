// @flow

import { gql } from "@apollo/client";
import { fragments as sidebarListItemFragments } from "../../../../../../components/Sidebar/SidebarListItem/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";

export default gql`
  query ordersToBeScreenPrinted(
    $first: Int!
    $after: String
    $filters: OrdersToBeScreenPrintedFilters
  ) {
    ordersConnection: ordersToBeScreenPrinted(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...SidebarListItem_order
          screenPrintingStatus
          primaryCustomer {
            id
            insideBrandTagging
          }
          productionJobs {
            design {
              id
              rushOrderTeesUrl {
                formatted
              }
              frontPreviewImageUrl: previewImageUrl(view: FRONT) {
                formatted
              }
              backPreviewImageUrl: previewImageUrl(view: BACK) {
                formatted
              }
            }
            customizationAreas {
              inkColors {
                id
                inkColor {
                  color {
                    formatted(format: HEX_WITH_POUND)
                  }
                  id
                  name
                }
                pantoneColor {
                  code
                  color {
                    formatted(format: HEX_WITH_POUND)
                  }
                  id
                }
              }
              template {
                description
                id
              }
              colorCount
              autobase
              distressed
              flatten
              halftones
              imageRepair
              recreate
              sponsorback
              splitIntoColors
              artwork {
                asset {
                  url {
                    formatted
                  }
                }
                originalFilename
                id
              }
              id
              location
              method
              customerArtwork {
                id
                file {
                  id
                  asset {
                    url {
                      formatted
                    }
                  }
                }
              }
              notesCollection {
                __typename
                ... on FormattedNote {
                  author
                  note
                  createdAt
                }
                ... on UnformattedNote {
                  note
                }
              }
              vinylConfiguration {
                id
                includeNames
                includeNumbers
                namesColor {
                  id
                  inkColor {
                    id
                    name
                    color {
                      formatted(format: HEX_WITH_POUND)
                    }
                  }
                }
                namesFont {
                  alias
                  id
                }
                numbersColor {
                  id
                  inkColor {
                    id
                    name
                    color {
                      formatted(format: HEX_WITH_POUND)
                    }
                  }
                }
                numbersFont {
                  alias
                  id
                }
                sizeInteger
              }
            }
            id
            lineItemBreakdownByProduct {
              lineItems {
                ...StyleSubstitutionLineItemBreakdown_lineItems
                product {
                  id
                  fullDisplayName
                  style
                  isStyleSubstitution
                }
              }

              totalProductVariantQuantity
            }
            filteredProofs {
              asset {
                url {
                  formatted
                }
              }
              originalFilename
              id
            }
            unclassifiedArtwork {
              asset {
                url {
                  formatted
                }
              }
              originalFilename
              id
            }
          }
        }
      }
    }
  }
  ${sidebarListItemFragments.order}
  ${styleSubstitutionLineItemBreakdownFragments.lineItems}
`;
