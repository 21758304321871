// @flow

import { gql } from "@apollo/client";

import { fragments as orderProductionJobCustomizationAreaListItemFragments } from "../components/OrderProductionJobCustomizationAreaListItem/graph";

export default {
  customizationAreas: gql`
    fragment OrderProductionJobCustomizationAreaList_customizationAreas on OrderProductionJobCustomizationArea {
      ...OrderProductionJobCustomizationAreaListItem_customizationArea
    }
    ${orderProductionJobCustomizationAreaListItemFragments.customizationArea}
  `
};
