export default ({ color, style, side }) => (
  <svg viewBox="0 0 1200 1130" width="100%">
    <mask id="mask">
      <rect x="0" y="0" width="1200" height="1130" fill="#fff" />
      <image
        x="0"
        y="0"
        width="1200"
        height="1130"
        xlinkHref={`https://s3.amazonaws.com/eztees-catalog/productmask/${style}_${
          side === "primary" ? "f" : "b"
        }_mask.png`}
      />
    </mask>
    <rect
      x="0"
      y="0"
      width="1200"
      height="1130"
      fill={color}
      mask="url(#mask)"
    />
    <image
      x="0"
      y="0"
      width="1200"
      height="1130"
      xlinkHref={`https://s3.amazonaws.com/eztees-catalog/productmask/${style}_${
        side === "primary" ? "f" : "b"
      }_texture.png`}
    />
  </svg>
);
