// @flow
import { gql } from "@apollo/client";
import { fragments as orderAppProductionJobListItemFragments } from "../OrderAppProductionJobListItem/graph";

export default {
  order: gql`
    fragment OrderAppProductionJobList_order on Order {
      productionJobs {
        ...OrderAppProductionJobListItem_productionJob
      }
      productionStationAssignments {
        ...OrderAppProductionJobListItem_productionStationAssignments
      }
    }
    ${orderAppProductionJobListItemFragments.productionJob}
    ${orderAppProductionJobListItemFragments.productionStationAssignments}
  `
};
