// @flow

import * as React from "react";
import { compose, mapProps, withProps } from "recompose";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import invariant from "invariant";

import type { HOC } from "recompose";
import type { Size } from "../types";

type Props = {|
  +classes: {|
    +avatar: string,
    +chip: string
  |},
  +name: string,
  +size: Size
|};

const enhancer: HOC<*, Props> = compose(
  mapProps(({ classes, size, ...props }) => {
    let sizeClass = {};
    switch (size) {
      case "avatar":
        sizeClass = classes.avatar;
        break;
      case "chip":
        sizeClass = classes.chip;
        break;
      default:
        sizeClass = null;
        break;
    }

    invariant(sizeClass != null, "sizeClass should not be null");

    return { ...props, sizeClass };
  }),
  withProps(({ name }) => ({ initials: generateInitialsFromName(name) }))
);

const generateInitialsFromName = (name: string): string => {
  const nameParts = name.split(" ");
  return nameParts
    .filter(
      (namePart, index) =>
        index === 0 || (index > 0 && index === nameParts.length - 1)
    )
    .map(namePart => namePart[0])
    .join("");
};

const styles = () => ({
  avatar: {
    height: 40,
    width: 40
  },

  chip: {
    height: 32,
    width: 32,
    fontSize: "1rem"
  }
});

const PersonInitialsAvatar = ({ initials, name, sizeClass }) => (
  <Avatar component="span" title={name} className={sizeClass}>
    {initials}
  </Avatar>
);

export default withStyles(styles)(enhancer(PersonInitialsAvatar));
