// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const deleteCustomsShipmentPallet: any = graphql(
  gql`
    mutation ($customsShipmentPalletId: ID!) {
      deleteCustomsShipmentPallet(
        customsShipmentPalletId: $customsShipmentPalletId
      ) {
        errors {
          customsShipmentPalletId
        }
        succeeded
      }
    }
  `,
  {
    name: "deleteCustomsShipmentPallet"
  }
);

export default deleteCustomsShipmentPallet;
