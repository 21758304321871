// @flow

import { fragments as customerArchiveHeaderFragments } from "../components/CustomerArchiveHeader/graph";
import { fragments as customerArchiveListFragments } from "../components/CustomerArchiveList/graph";
import { gql } from "@apollo/client";

export default gql`
  query CustomerArchiveApp($cursor: String, $customerId: ID!, $first: Int!) {
    customer: node(id: $customerId) {
      ... on Customer {
        ...CustomerArchiveHeader_customer
        orders(first: $first, after: $cursor) {
          edges {
            cursor
            node {
              ...CustomerArchiveList_orders
            }
          }
          pageInfo {
            endCursor
          }
        }
      }
    }
  }
  ${customerArchiveListFragments.orders}
  ${customerArchiveHeaderFragments.customer}
`;
