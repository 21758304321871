// @flow

import { gql } from "@apollo/client";
import { fragments as pullFromStockTableFragments } from "../components/PullFromStockTable/graph";

export default gql`
  query orderProductionJobsToBePulled(
    $first: Int!
    $after: String
    $filters: OrderProductionJobsToBePulledFilters
  ) {
    orderProductionJobsToBePulled(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...PullFromStockTable_productionJob
          order {
            id
            isResolution
            directToGarmentExpressEligible
            express
            includeCatalogInShipment
            highDefinitionDigitalExpressEligible
            shipMissCosts {
              date
              cost {
                formatted
              }
            }
          }
        }
      }
      productionDates(filters: $filters)
    }
  }
  ${pullFromStockTableFragments.productionJob}
`;
