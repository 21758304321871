// @flow

import { gql } from "@apollo/client";
import { fragments as namesAndNumbersFragments } from "../components/NamesAndNumbers/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../StyleSubstitutionLineItemBreakdown/graph";

export default {
  productionJob: gql`
    fragment ProductionJobDetail_productionJob on OrderProductionJob {
      ...NamesAndNumbers_orderProductionJob
      highDefinitionDigitalStatus
      design {
        id
        rushOrderTeesUrl {
          formatted
        }
        frontPreviewImageUrl: previewImageUrl(view: FRONT) {
          formatted
        }
        backPreviewImageUrl: previewImageUrl(view: BACK) {
          formatted
        }
      }

      latestBoxLocation {
        id
        location
      }
      latestScreenLocation {
        id
        location
      }
      boxLocationHistory {
        id
        createdAt
        createdBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        location
      }
      screenLocationHistory {
        id
        createdAt
        createdBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        location
      }
      stockContainers {
        id
        latestLocation {
          id
          location
        }
      }
      customizationAreas {
        orderProductionJobCustomizationAreaClaim {
          id
        }
        inkColors {
          id
          inkColor {
            color {
              formatted(format: HEX_WITH_POUND)
            }
            id
            name
          }
          pantoneColor {
            code
            color {
              formatted(format: HEX_WITH_POUND)
            }
            id
          }
        }
        template {
          description
          id
        }
        colorCount
        autobase
        distressed
        flatten
        halftones
        imageRepair
        recreate
        sponsorback
        splitIntoColors
        artwork {
          asset {
            url {
              formatted
            }
          }
          originalFilename
          id
        }
        id
        location
        method
        quantity
        height
        width
        precut
        gangSheet
        customerArtwork {
          id
          file {
            id
            asset {
              url {
                formatted
              }
            }
          }
        }
        notesCollection {
          __typename
          ... on FormattedNote {
            author
            note
            createdAt
          }
          ... on UnformattedNote {
            note
          }
        }
        vinylConfiguration {
          id
          includeNames
          includeNumbers
          namesColor {
            id
            inkColor {
              id
              name
              color {
                formatted(format: HEX_WITH_POUND)
              }
            }
          }
          namesFont {
            alias
            id
          }
          numbersColor {
            id
            inkColor {
              id
              name
              color {
                formatted(format: HEX_WITH_POUND)
              }
            }
          }
          numbersFont {
            alias
            id
          }
          sizeInteger
        }
      }
      id
      lineItemBreakdownByProduct {
        lineItems {
          ...StyleSubstitutionLineItemBreakdown_lineItems
        }
        product {
          id
          fullDisplayName
          style
          isStyleSubstitution
        }
        totalProductVariantQuantity
      }
      filteredProofs {
        asset {
          url {
            formatted
          }
        }
        originalFilename
        id
      }
      unclassifiedArtwork {
        asset {
          url {
            formatted
          }
        }
        originalFilename
        id
      }
    }
    ${styleSubstitutionLineItemBreakdownFragments.lineItems}
    ${namesAndNumbersFragments.orderProductionJob}
  `
};
