// @flow

import * as React from "react";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withPropsOnChange,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as sidebarListItemFragments } from "../Sidebar/SidebarListItem/graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import PendingStatusView from "../PendingStatusView";
import Sidebar from "../Sidebar";
import SidebarListItem from "../Sidebar/SidebarListItem";
import Typography from "@mui/material/Typography";
import withBarcodePivot from "../BarcodePivot/withBarcodePivot";

import type { HOC } from "recompose";

type Props = {|
  +children?: React.ChildrenArray<any>,
  +classes?: {|
    +content: string
  |},
  +data?: {||},
  +destinationRoute: string,
  +errorMessage: ?string,
  +fetchMore: () => void,
  +loading: boolean,
  +ordersConnection: ?{
    +edges: $ReadOnlyArray<{|
      +cursor: string,
      +node: {|
        +directToGarmentStatus: string,
        +embroideryStatus: string,
        +id: string,
        +orderNumber: string,
        +primaryCustomer: {|
          +id: string,
          +name_sidebarListItemCustomerName: string,
          +status: string
        |},
        +priority: string,
        +productionDate: string,
        +screenPrintingStatus: string,
        +vinylStatus: string
      |}
    |}>,
    +pageInfo: {|
      +endCursor: string,
      +hasNextPage: boolean
    |}
  }
|};

type State = {|
  hasNextPage: boolean,
  isLoadingMore: boolean,
  sidebarIsVisible: boolean
|};

const styles = theme => ({
  content: {
    height: "calc(100vh - 48px)",
    padding: theme.spacing(10)
  },

  spinnerWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionAppWithSidebar"),

  withRouter,

  withBarcodePivot,

  withStyles(styles),

  withStateHandlers(
    ({
      hasNextPage: true,
      isLoadingMore: false,
      sidebarIsVisible: true
    }: State),
    {
      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSidebarIsVisible: () => (sidebarIsVisible: boolean) => ({
        sidebarIsVisible
      })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router }) =>
      () => {
        router.go(-1);
      },

    handleSideBarItemSelected:
      ({ destinationRoute, ordersConnection, router, setSidebarIsVisible }) =>
      (selectedIndex: number) => {
        const orders = ordersConnection.edges;
        const selectedOrder = orders[selectedIndex].node;
        router.push(`${destinationRoute}/${selectedOrder.id}`);
        setSidebarIsVisible(false);
      },

    handleSideBarRequestClose:
      ({ setSidebarIsVisible }) =>
      () => {
        setSidebarIsVisible(false);
      },

    handleSideBarScrolledToBottom:
      ({
        fetchMore,
        hasNextPage,
        isLoadingMore,
        ordersConnection,
        setHasNextPage,
        setIsLoadingMore
      }) =>
      () => {
        if (isLoadingMore || !hasNextPage) {
          return;
        }

        setIsLoadingMore(true);
        fetchMore({
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.ordersConnection.edges;
            const pageInfo = fetchMoreResult.ordersConnection.pageInfo;
            setHasNextPage(pageInfo.hasNextPage);
            return newEdges.length
              ? {
                  ordersConnection: {
                    pageInfo,
                    __typename: previousResult.ordersConnection.__typename,
                    edges: [
                      ...previousResult.ordersConnection.edges,
                      ...newEdges
                    ]
                  }
                }
              : previousResult;
          },
          variables: {
            after: ordersConnection.pageInfo.endCursor
          }
        }).finally(() => setIsLoadingMore(false));
      }
  }),

  withPropsOnChange(
    ["lastOrderProductionJobScanned"],
    ({
      acknowledgeOrderProductionJob,
      destinationRoute,
      lastOrderProductionJobScanned,
      router
    }) => {
      if (lastOrderProductionJobScanned) {
        router.push(
          `${destinationRoute}/${lastOrderProductionJobScanned.order.id}?orderProductionJobId=${lastOrderProductionJobScanned.id}`
        );
        acknowledgeOrderProductionJob();
      }
    }
  ),

  withPropsOnChange(
    ["lastStockContainerScanned"],
    ({
      acknowledgeStockContainer,
      destinationRoute,
      lastStockContainerScanned,
      router
    }) => {
      if (lastStockContainerScanned) {
        router.push(
          `${destinationRoute}/${lastStockContainerScanned.orderProductionJob.order.id}?orderProductionJobId=${lastStockContainerScanned.orderProductionJob.id}`
        );
        acknowledgeStockContainer();
      }
    }
  ),

  lifecycle({
    componentDidUpdate() {
      const { data, destinationRoute, router } = this.props;
      if (data && !data.loading && !data.error) {
        if (data.ordersConnection && data.ordersConnection.edges.length === 1) {
          router.push(
            `${destinationRoute}/${data.ordersConnection.edges[0].node.id}`
          );
        }
      }
    }
  })
);

const ProductionAppWithSidebar = ({
  children,
  classes,
  errorMessage,
  hasNextPage,
  handleSideBarItemSelected,
  handleSideBarRequestClose,
  handleSideBarScrolledToBottom,
  isLoadingMore,
  loading,
  ordersConnection
}) => (
  <div>
    {children}
    {loading ? (
      <div>
        <PendingStatusView status="Loading" />
      </div>
    ) : errorMessage ? (
      <Typography align="center" variant="body2" color="error">
        {errorMessage}
      </Typography>
    ) : (
      <div>
        {ordersConnection && (
          <Sidebar
            isLoadingMoreItems={hasNextPage && isLoadingMore}
            isOpen={true}
            onItemSelected={handleSideBarItemSelected}
            onRequestClose={handleSideBarRequestClose}
            onScrolledToBottom={handleSideBarScrolledToBottom}
          >
            {ordersConnection.edges.map(edge => (
              <SidebarListItem
                key={edge.node.id}
                order={filter(sidebarListItemFragments.order, edge.node)}
              />
            ))}
          </Sidebar>
        )}
        {!ordersConnection && (
          <div className={classes.content}>
            <Typography align="center" variant="h6" color="inherit">
              Scan or search for order
            </Typography>
          </div>
        )}
      </div>
    )}
  </div>
);

export default enhancer(ProductionAppWithSidebar);
