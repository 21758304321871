// @flow
import { gql } from "@apollo/client";

import { fragments as productionJobLineItemBreakdownFragments } from "../../ProductionJobLineItemBreakdownTable/graph";

export default {
  lineItems: gql`
    fragment StyleSubstitutionLineItemBreakdown_lineItems on OrderProductionJobLineItem {
      ...ProductionJobLineItemBreakdownTable_lineItems
      styleSubstitution {
        color
        description
        id
        style
        vendor
      }
    }
    ${productionJobLineItemBreakdownFragments.lineItems}
  `
};
