// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { RedirectException, Route, makeRouteConfig } from "found";
import AppLauncher from "../application/apps/AppLauncher";
import ArtApp from "../application/apps/ArtApp";
import ArtOrderDetail from "../application/apps/ArtApp/components/ArtOrderDetail";
import ArtReadyApp from "../application/apps/ArtReadyApp";
import ArtworkProjectionApp from "../application/apps/ArtworkProjectionApp";
import BulkBoxLocationSetterApp from "../application/apps/BulkBoxLocationSetterApp";
import BulkFilmLocationSetter from "../application/apps/BulkFilmLocationSetter";
import CheckReturnStatusApp from "../application/apps/CheckReturnStatusApp";
import CustomerArchiveApp from "../application/apps/CustomerArchiveApp";
import CustomsShipmentDetailScreen from "../components/CustomsShipmentDetailScreen";
import CustomsShipmentListScreen from "../components/CustomsShipmentListScreen";
import CustomsSouthboundV2App from "../application/apps/CustomsSouthboundV2App";
import DTGApp from "../application/apps/DTGApp";
import DTGOrderDetail from "../application/apps/DTGApp/components/DTGOrderDetail";
import EmbroideryApp from "../application/apps/EmbroideryApp";
import EmbroideryOrderDetail from "../application/apps/EmbroideryApp/components/EmbroideryOrderDetail";
import FilmCheckApp from "../application/apps/FilmCheckApp";
import FixPieceApp from "../application/apps/FixPieceApp";
import HDDOrderDetail from "../application/apps/HDDPressApp/components/HDDOrderDetail";
import HDDPressApp from "../application/apps/HDDPressApp";
import HDDReadyApp from "../application/apps/HDDReadyApp";
import HomePageApp from "../application/apps/HomePageApp";
import LocalInventoryApp from "../application/apps/LocalInventoryApp";
import Mach6AppLauncher from "../application/apps/Mach6AppLauncher";
import NorthboundCustomsShipmentPalletDetailScreen from "../components/NorthboundCustomsShipmentPalletDetailScreen";
import OrderApp from "../application/apps/OrderApp";
import OrderDetailView from "../application/apps/StockStagedApp/components/OrderDetailView";
import ProductionListApp from "../application/apps/ProductionListApp";
import ProofingApp from "../application/apps/ProofingApp";
import PullFromStockApp from "../application/apps/PullFromStockApp";
import QaDashboardApp from "../application/apps/QaDashboardApp";
import QaDashboardOrderDetail from "../application/apps/QaDashboardApp/components/QaDashboardOrderDetail";
import ReceivingApp from "../application/apps/ReceivingApp";
import Root from "./Root";
import ScreenPrintingApp from "../application/apps/ScreenPrintingApp";
import ScreenPrintingOrderDetail from "../application/apps/ScreenPrintingApp/components/ScreenPrintingOrderDetail/ScreenPrintingOrderDetail";
import ScreenPrintingOrderSelection from "../application/apps/ScreenPrintingApp/components/ScreenPrintingOrderSelection/ScreenPrintingOrderSelection";
import ScreensMadeApp from "../application/apps/ScreensMadeApp";
import SouthboundCustomsShipmentPalletDetailScreen from "../components/SouthboundCustomsShipmentPalletDetailScreen";
import StockStagedApp from "../application/apps/StockStagedApp";
import TransferPrintingApp from "../application/apps/TransferPrintingApp";
import TransferPrintingOrderDetail from "../application/apps/TransferPrintingApp/components/TransferPrintingOrderDetail";
import UPSNorthboundCustomsShipmentPalletDetailScreen from "../components/UPSNorthboundCustomsShipmentPalletDetailScreen";
import VinylApp from "../application/apps/VinylApp";
import VinylOrderDetail from "../application/apps/VinylApp/components/VinylOrderDetail";

const routes = makeRouteConfig(
  <Route path="/">
    <Route Component={HomePageApp} />
    <Route path="apps/artwork-projection">
      <Route Component={ArtworkProjectionApp} />
    </Route>
    <Route path="apps/bulk-box-location-setter">
      <Route Component={BulkBoxLocationSetterApp} />
    </Route>
    <Route path="apps/bulk-film-location-setter">
      <Route Component={BulkFilmLocationSetter} />
    </Route>
    <Route path="apps/local-inventory">
      <Route Component={LocalInventoryApp} />
    </Route>
    <Route path="apps/production/hdd-merging">
      <Route Component={HDDReadyApp} />
    </Route>
    <Route path="apps/art-ready">
      <Route Component={ArtReadyApp} />
    </Route>
    <Route path="apps/check-return-status">
      <Route Component={CheckReturnStatusApp} />
    </Route>
    <Route path="apps/film-check">
      <Route Component={FilmCheckApp} />
    </Route>
    <Route path="apps/fix-pieces">
      <Route Component={FixPieceApp} />
    </Route>
    <Route path="apps/customs/southbound">
      <Route Component={CustomsSouthboundV2App} />
    </Route>
    <Route path="apps/customs/:direction">
      <Route
        Component={CustomsShipmentListScreen}
        render={({ match: { params }, Component }) => {
          return <Component {...params} />;
        }}
      />
      <Route path="shipment/:shipmentId">
        <Route
          Component={CustomsShipmentDetailScreen}
          render={({ match: { params }, Component }) => {
            return <Component {...params} />;
          }}
        />
      </Route>
      <Route path="pallet/:palletId">
        <Route
          render={({ match: { params } }) => {
            const { direction } = params;
            if (direction.toUpperCase() === "NORTHBOUND") {
              return (
                <NorthboundCustomsShipmentPalletDetailScreen {...params} />
              );
            } else if (direction.toUpperCase() === "SOUTHBOUND") {
              return (
                <SouthboundCustomsShipmentPalletDetailScreen {...params} />
              );
            }
            return null;
          }}
        />
      </Route>
    </Route>
    <Route path="apps/customs/ups/:direction">
      <Route
        Component={CustomsShipmentListScreen}
        render={({ match: { params }, Component }) => {
          return <Component {...params} />;
        }}
      />
      <Route path="shipment/:shipmentId">
        <Route
          Component={CustomsShipmentDetailScreen}
          render={({ match: { params }, Component }) => {
            return <Component {...params} />;
          }}
        />
      </Route>
      <Route path="pallet/:palletId">
        <Route
          render={({ match: { params } }) => {
            return (
              <UPSNorthboundCustomsShipmentPalletDetailScreen {...params} />
            );
          }}
        />
      </Route>
    </Route>
    <Route path="apps/customsv1/:direction">
      <Route
        Component={CustomsShipmentListScreen}
        render={({ match: { params }, Component }) => {
          return <Component {...params} />;
        }}
      />
      <Route path="shipment/:shipmentId">
        <Route
          Component={CustomsShipmentDetailScreen}
          render={({ match: { params }, Component }) => {
            return <Component {...params} />;
          }}
        />
      </Route>
      <Route path="pallet/:palletId">
        <Route
          render={({ match: { params } }) => {
            const { direction } = params;
            if (direction.toUpperCase() === "NORTHBOUND") {
              return (
                <NorthboundCustomsShipmentPalletDetailScreen {...params} />
              );
            } else if (direction.toUpperCase() === "SOUTHBOUND") {
              return (
                <SouthboundCustomsShipmentPalletDetailScreen {...params} />
              );
            }
            return null;
          }}
        />
      </Route>
    </Route>
    <Route path="expediting-label/order/:orderId">
      <Route
        Component={OrderDetailView}
        render={({ match, Component }) => {
          if (
            new Buffer(match.params.orderId, "base64")
              .toString("ascii")
              .startsWith("Order:")
          ) {
            return (
              <Component orderId={match.params.orderId} autoPrint={true} />
            );
          }
          const nodeId = new Buffer(`Order:${match.params.orderId}`).toString(
            "base64"
          );
          throw new RedirectException(`/expediting-label/order/${nodeId}`);
        }}
      />
    </Route>
    <Route path="apps" Component={Root}>
      <Route
        Component={AppLauncher}
        render={({ Component }) => {
          if (DateTime.now().offset / 60 === -8) {
            throw new RedirectException(`/mach6/apps`);
          }
          return <Component />;
        }}
      />
      <Route path="production/customers">
        <Route path=":customerId">
          <Route
            Component={CustomerArchiveApp}
            render={({ match, Component }) => (
              <Component customerId={match.params.customerId} />
            )}
          />
        </Route>
      </Route>
      <Route path="production/dtg">
        <Route Component={DTGApp} />
        <Route path="orders/:orderId">
          <Route
            Component={DTGOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/dtg/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="production/hdd-press">
        <Route Component={HDDPressApp} />
        <Route path="orders/:orderId">
          <Route
            Component={HDDOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/hdd-press/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="production/transfer-printing/:film">
        <Route
          Component={TransferPrintingApp}
          render={({ match, Component }) => (<Component film={match.params.film} />)}
        />
        <Route path="orders/:orderId">
          <Route
            Component={TransferPrintingOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} film={match.params.film} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/transfer-printing/${match.params.film}/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="production/embroidery">
        <Route Component={EmbroideryApp} />
        <Route path="orders/:orderId">
          <Route
            Component={EmbroideryOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/embroidery/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="production/orders">
        <Route Component={ProductionListApp} />
        <Route path=":orderId">
          <Route
            Component={OrderApp}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(`/apps/production/orders/${nodeId}`);
            }}
          />
        </Route>
      </Route>

      <Route path="production/qa-dashboard">
        <Route Component={QaDashboardApp} />
        <Route path="productionStation/:productionStationId">
          <Route
            Component={QaDashboardOrderDetail}
            render={({ match, Component }) => (
              <Component
                productionStationId={match.params.productionStationId}
              />
            )}
          />
        </Route>
      </Route>

      <Route path="production/screen-printing" Component={ScreenPrintingApp}>
        <Route Component={ScreenPrintingOrderSelection} />
        <Route path="orders/:orderId">
          <Route
            Component={ScreenPrintingOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/screen-printing/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="production/vinyl">
        <Route Component={VinylApp} />
        <Route path="orders/:orderId">
          <Route
            Component={VinylOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return <Component orderId={match.params.orderId} />;
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/vinyl/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
      <Route path="pull-from-stock">
        <Route Component={PullFromStockApp} />
      </Route>
      <Route path="receiving">
        <Route Component={ReceivingApp} />
      </Route>
      <Route path="screens-made">
        <Route Component={ScreensMadeApp} />
      </Route>
      <Route path="counting-staging">
        <Route Component={StockStagedApp} />
      </Route>
    </Route>
    <Route path="mach6/apps" Component={Root}>
      <Route path="bulk-box-location-setter">
        <Route
          Component={BulkBoxLocationSetterApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="bulk-film-location-setter">
        <Route
          Component={BulkFilmLocationSetter}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="check-return-status">
        <Route
          Component={CheckReturnStatusApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="film-check">
        <Route
          Component={FilmCheckApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route Component={Mach6AppLauncher} />
      <Route path="receiving">
        <Route
          Component={ReceivingApp}
          render={({ Component }) => {
            return (
              <Component
                filters={{ vendorId: "VmVuZG9yOjM2MA==" }}
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="counting-staging">
        <Route
          Component={StockStagedApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="production/local-inventory">
        <Route
          Component={LocalInventoryApp}
          render={({ Component }) => {
            return (
              <Component
                filters={{ vendorId: "VmVuZG9yOjM2MA==" }}
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="production/hdd-merging">
        <Route
          Component={HDDReadyApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
      </Route>
      <Route path="production/hdd-press">
        <Route
          Component={HDDPressApp}
          render={({ Component }) => {
            return (
              <Component
                backUrl="/mach6/apps"
                appBarBackgroundColor="#7fb383"
              />
            );
          }}
        />
        <Route path="orders/:orderId">
          <Route
            Component={HDDOrderDetail}
            render={({ match, Component }) => {
              if (
                new Buffer(match.params.orderId, "base64")
                  .toString("ascii")
                  .startsWith("Order:")
              ) {
                return (
                  <Component
                    orderId={match.params.orderId}
                    backUrl="/mach6/apps"
                    appBarBackgroundColor="#7fb383"
                  />
                );
              }
              const nodeId = new Buffer(
                `Order:${match.params.orderId}`
              ).toString("base64");
              throw new RedirectException(
                `/apps/production/hdd-press/orders/${nodeId}`
              );
            }}
          />
        </Route>
      </Route>
    </Route>
    <Route path="apps/production/art">
      <Route Component={ArtApp} />
      <Route path="orders/:orderId">
        <Route
          Component={ArtOrderDetail}
          render={({ match, Component }) => {
            if (
              new Buffer(match.params.orderId, "base64")
                .toString("ascii")
                .startsWith("Order:")
            ) {
              return <Component orderId={match.params.orderId} />;
            }
            const nodeId = new Buffer(`Order:${match.params.orderId}`).toString(
              "base64"
            );
            throw new RedirectException(
              `/apps/production/art/orders/${nodeId}`
            );
          }}
        />
      </Route>
    </Route>
    <Route path="apps/production/proofing/:id">
      <Route
        Component={ProofingApp}
        render={({ match, Component }) => {
          if (
            new Buffer(match.params.id, "base64")
              .toString("ascii")
              .startsWith("Order:")
          ) {
            return <Component id={match.params.id} />;
          }
          const nodeId = new Buffer(`Order:${match.params.id}`).toString(
            "base64"
          );
          throw new RedirectException(`/apps/production/proofing/${nodeId}`);
        }}
      />
    </Route>
  </Route>
);

export default routes;
