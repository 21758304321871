// @flow
import { gql } from "@apollo/client";
import { fragments as productionMethodIconSetFragments } from "../../../ProductionMethodIconSet/graph";

export default {
  order: gql`
    fragment SidebarListItem_order on Order {
      ...ProductionMethodIconSet_order
      id
      orderNumber
      primaryCustomer {
        id
        name_sidebarListItemCustomerName: name(format: FIRST_NAME_LAST_NAME)
        status
      }
      priority
      productionDate
    }
    ${productionMethodIconSetFragments.order}
  `
};
