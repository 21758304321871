// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { ThemeContextConsumer } from "../../../../../application/themeContext";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { createStockContainer } from "../../../../../graph";
import { filter } from "graphql-anywhere";
import { getLogoForBrandUrl } from "../../../../../helpers";
import { graphql } from "@apollo/client/react/hoc";
import { priorityOrder } from "../../../../../helpers/priorityOrder";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../../../components/ProductionJobLineItemBreakdownTable/graph";
import { query } from "./graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../components/StyleSubstitutionLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Checked from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import CustomerStatusChip from "../../../../apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip";
import Divider from "@mui/material/Divider";
import NoBrandTagIcon from "../../../../../assets/no-brand-tag.png";
import NotesCollectionBreakdown from "../../../../../components/NotesCollectionBreakdown";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import ProductionJobLineItemBreakdownTable from "../../../../../components/ProductionJobLineItemBreakdownTable";
import ReceivingLabelViewer from "../../../../../components/ReceivingLabelViewer";
import StyleSubstitutionLineItemBreakdown from "../../../../../components/StyleSubstitutionLineItemBreakdown";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +autoPrint: boolean,
  +classes?: {|
    +button: string,
    +descriptionList: string,
    +descriptionTag: string,
    +divider: string,
    +loadingWrapper: string,
    +orderNumberTotalBlindShippedWrapper: string,
    +primaryCustomerInfoWrapper: string,
    +primaryCustomerName: string,
    +productionDueDateWrapper: string,
    +productionJobLabel: string,
    +productionJobLineItemBreakdownTable: string,
    +wrapper: string
  |},
  +isReceivingFromStock: boolean,
  +onRequestOrderReceived: (productionJobId: string) => void,
  +productionJobId: ?string,
  +stockContainerId: ?string
|};

type State = {|
  isCreatingStockContainerId: boolean,
  stockContainerId: ?string
|};

const defaultState: State = {
  stockContainerId: null,
  isCreatingStockContainerId: false
};

const styles = theme => ({
  button: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },

  descriptionList: {
    alignItems: "center",
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  primaryCustomerInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },

  primaryCustomerName: {
    marginRight: theme.spacing(1)
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  productionJobLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },

  productionJobLineItemBreakdownTable: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  wrapper: {
    padding: theme.spacing(2)
  }
});

const isBlindShipped = "BLIND_SHIPPED";

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ReceivingDetailView"),

  createStockContainer,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(
    ({ stockContainerId }) => ({
      ...defaultState,
      stockContainerId
    }),
    {
      setCreatedStockContainerId: () => (stockContainerId: ?string) => ({
        stockContainerId
      }),

      setIsCreatingStockContainerId:
        () => (isCreatingStockContainerId: boolean) => ({
          isCreatingStockContainerId
        })
    }
  ),

  withHandlers(() => {
    let printRef;
    let printAllRef;
    let printStockContainerRef;
    return {
      handlePrintRef: () => ref => {
        printRef = ref;
      },

      handlePrintAllRef: () => ref => {
        printAllRef = ref;
      },

      handlePrintStockContainerRef: () => ref => {
        printStockContainerRef = ref;
      },

      handleReceiveButtonClick:
        ({ onRequestOrderReceived }) =>
        (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          onRequestOrderReceived(event.currentTarget.id);
        },

      handlePrintLabelButtonClick:
        () => (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          if (printRef) {
            printRef();
          }
        },

      handlePrintStockContainer:
        ({ setCreatedStockContainerId }) =>
        () => {
          setCreatedStockContainerId(null);
          if (printStockContainerRef) {
            printStockContainerRef();
          }
        },

      handlePrintAllLabelsButtonClick:
        () => (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          if (printAllRef) {
            printAllRef();
          }
        },

      handleAddStockContainerButtonClick:
        ({
          createStockContainer,
          showSuccessSnackbar,
          showErrorSnackbar,
          setCreatedStockContainerId,
          setIsCreatingStockContainerId
        }) =>
        (event: SyntheticInputEvent<HTMLInputElement>) => {
          const orderProductionJobId = event.currentTarget.id;
          setCreatedStockContainerId(null);
          setIsCreatingStockContainerId(true);
          createStockContainer({ variables: { orderProductionJobId } })
            .then(
              ({
                data: {
                  createStockContainer: {
                    succeeded,
                    errors,
                    createdStockContainer: { id }
                  }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar("Stock container has been created.");
                  if (id) {
                    setCreatedStockContainerId(id);
                  }
                } else {
                  showErrorSnackbar(errors.orderProductionJobId.join(", "));
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(e.message);
            })
            .finally(() => {
              setIsCreatingStockContainerId(false);
            });
        }
    };
  }),

  graphql(query, {
    skip: ({ productionJobId }) => !productionJobId
  })
);

const pluralize = require("pluralize");

const ReceivingDetailView = ({
  classes,
  data,
  handleAddStockContainerButtonClick,
  handlePrintLabelButtonClick,
  handlePrintRef,
  handlePrintStockContainerRef,
  handleReceiveButtonClick,
  isCreatingStockContainerId,
  isReceivingFromStock,
  stockContainerId
}) => {
  return (
    <div>
      {!data ? (
        <div />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography variant="body2" color="error">
          {data.error.message}
        </Typography>
      ) : (
        <div className={classes.wrapper}>
          <div>
            <div className={classes.primaryCustomerInfoWrapper}>
              <div
                style={{ height: "34px", maxWidth: "95px", marginRight: "8px" }}
              >
                {data.productionJob.order.primaryCustomer.businessUnit.name && (
                  <ThemeContextConsumer>
                    {context => (
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%"
                        }}
                        src={getLogoForBrandUrl(
                          data.productionJob.order.primaryCustomer.businessUnit
                            .name,
                          context.type
                        )}
                        alt={
                          data.productionJob.order.primaryCustomer.businessUnit
                            .name
                        }
                      />
                    )}
                  </ThemeContextConsumer>
                )}
              </div>
              <CustomerStatusChip
                status={data.productionJob.order.primaryCustomer.status}
              />
              {!data.productionJob.order.primaryCustomer.insideBrandTagging && (
                <img
                  style={{ width: "36px", margin: "0 8px" }}
                  src={NoBrandTagIcon}
                  alt="No Brand Tagging"
                />
              )}
            </div>
            <div className={classes.primaryCustomerInfoWrapper}>
              <Typography
                className={classes.primaryCustomerName}
                variant="body1"
              >
                {
                  data.productionJob.order.primaryCustomer
                    .name_receivingDetailView
                }
                {` (${data.productionJob.order.primaryCustomer.totalOrders}) `}
              </Typography>
            </div>
          </div>

          <div className={classes.orderNumberTotalBlindShippedWrapper}>
            <div>
              <Typography color="textSecondary" variant="subtitle2">
                {data.productionJob.order.orderNumber}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {`${pluralize(
                  "Piece",
                  data.productionJob.order.totalProductVariantQuantity,
                  true
                )}`}
              </Typography>
            </div>
            <div className={classes.descriptionList}>
              {data.productionJob.order.primaryCustomer.status ===
                isBlindShipped && (
                <React.Fragment>
                  <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Blind Shipped:
                  </Typography>
                  <Typography variant="subtitle2">
                    <Checked />
                  </Typography>
                </React.Fragment>
              )}
            </div>
          </div>
          <Divider light={true} className={classes.divider} />
          {isReceivingFromStock ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              {!isCreatingStockContainerId && (
                <ReceivingLabelViewer
                  customerName={
                    data.productionJob.order.primaryCustomer
                      .name_receivingDetailView
                  }
                  brand={
                    data.productionJob.order.primaryCustomer?.businessUnit?.name
                  }
                  isArtworkApproved={data.productionJob.order.isArtworkApproved}
                  printRef={handlePrintRef}
                  productionJobs={[data.productionJob]}
                  totalProductionJobs={
                    data.productionJob.order.productionJobs.length
                  }
                  productionDate={data.productionJob.order.productionDate}
                  orderNumber={data.productionJob.order.orderNumber}
                  isResolution={data.productionJob.order.isResolution}
                  dueBy={data.productionJob.order.dueBy.dateTime}
                  hidden={true}
                  productionStationAssignment={
                    data.productionJob.order.productionStationAssignments
                      .length > 0
                      ? data.productionJob.order.productionStationAssignments[0]
                          .productionStation.name
                      : undefined
                  }
                  priority={priorityOrder(
                    data.productionJob.order.productionDate,
                    data.productionJob.order.dueBy.dateTime
                  )}
                  express={data.productionJob.order.express}
                  includeCatalogInShipment={data.productionJob.order.includeCatalogInShipment}
                />
              )}
              {stockContainerId && !isCreatingStockContainerId && (
                <ReceivingLabelViewer
                  brand={
                    data.productionJob.order.primaryCustomer?.businessUnit?.name
                  }
                  customerName={
                    data.productionJob.order.primaryCustomer
                      .name_receivingDetailView
                  }
                  createdStockContainerId={stockContainerId}
                  isArtworkApproved={data.productionJob.order.isArtworkApproved}
                  printRef={handlePrintStockContainerRef}
                  productionJobs={[data.productionJob]}
                  totalProductionJobs={
                    data.productionJob.order.productionJobs.length
                  }
                  productionDate={data.productionJob.order.productionDate}
                  orderNumber={data.productionJob.order.orderNumber}
                  isResolution={data.productionJob.order.isResolution}
                  dueBy={data.productionJob.order.dueBy.dateTime}
                  hidden={true}
                  autoPrint={true}
                  productionStationAssignment={
                    data.productionJob.order.productionStationAssignments
                      .length > 0
                      ? data.productionJob.order.productionStationAssignments[0]
                          .productionStation.name
                      : undefined
                  }
                  priority={priorityOrder(
                    data.productionJob.order.productionDate,
                    data.productionJob.order.dueBy.dateTime
                  )}
                  express={data.productionJob.order.express}
                  includeCatalogInShipment={data.productionJob.order.includeCatalogInShipment}
                />
              )}
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handlePrintLabelButtonClick}
                disabled={data.productionJob.stockContainers.length === 0}
              >
                Print Label
              </Button>
              <Button
                className={classes.button}
                id={data.productionJob.id}
                variant="contained"
                color="primary"
                onClick={handleReceiveButtonClick}
              >
                Received
              </Button>
              {isCreatingStockContainerId ? (
                <div className={classes.loadingWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  className={classes.button}
                  id={data.productionJob.id}
                  variant="contained"
                  color="primary"
                  onClick={handleAddStockContainerButtonClick}
                >
                  Add Stock Container
                </Button>
              )}
            </React.Fragment>
          )}
          <div className={classes.productionDueDateWrapper}>
            {data.productionJob.order.productionDate && (
              <div className={classes.descriptionList}>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Production Date:
                </Typography>
                <PriorityChip
                  date={data.productionJob.order.productionDate}
                  priority={data.productionJob.order.priority}
                />
              </div>
            )}
            {data.productionJob.order.dueBy.dateTime && (
              <div className={classes.descriptionList}>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Due Date:
                </Typography>
                <Typography variant="subtitle2">
                  {DateTime.fromISO(
                    data.productionJob.order.dueBy.dateTime
                  ).toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </div>
            )}
          </div>
          {data.productionJob.order.productionStationAssignments.map(
            (assignment, index) => (
              <div className={classes.descriptionList} key={index}>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Station Assignment:
                </Typography>
                <Typography variant="subtitle2">
                  {assignment.productionStation.name}
                </Typography>
                <Divider light={true} className={classes.divider} />
              </div>
            )
          )}
          <Divider light={true} className={classes.divider} />
          <Typography className={classes.productionJobLabel} variant="body1">
            {data.productionJob.label}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {`${pluralize(
              "Piece",
              data.productionJob.totalProductVariantQuantity,
              true
            )}`}
          </Typography>
          {data.productionJob.stockContainers.length > 0 && (
            <React.Fragment>
              <Divider light={true} className={classes.divider} />
              <Typography
                className={classes.productionJobLabel}
                variant="body1"
              >
                Stock Containers ({data.productionJob.stockContainers.length})
              </Typography>
              {data.productionJob.stockContainers.map(
                ({ latestLocation, id }, index) => (
                  <Typography
                    color="textSecondary"
                    key={id}
                    variant="subtitle2"
                  >
                    {index + 1} -{" "}
                    {latestLocation
                      ? latestLocation.location
                      : `Location
               Unknown`}
                  </Typography>
                )
              )}
            </React.Fragment>
          )}
          {data.productionJob.lineItemBreakdownByProduct.map(
            productionJobLineItemBreakdown => (
              <div
                className={classes.productionJobLineItemBreakdownTable}
                key={productionJobLineItemBreakdown.product.id}
              >
                <Typography variant="body2">
                  {`${productionJobLineItemBreakdown.product.style} - `}
                  {productionJobLineItemBreakdown.product.fullDisplayName}
                </Typography>
                {productionJobLineItemBreakdown.product.isStyleSubstitution ? (
                  <StyleSubstitutionLineItemBreakdown
                    lineItems={filter(
                      styleSubstitutionLineItemBreakdownFragments.lineItems,
                      productionJobLineItemBreakdown.lineItems
                    )}
                  />
                ) : (
                  <ProductionJobLineItemBreakdownTable
                    lineItems={filter(
                      productionJobLineItemBreakdownTableFragments.lineItems,
                      productionJobLineItemBreakdown.lineItems
                    )}
                  />
                )}
              </div>
            )
          )}
          <Typography color="textSecondary" variant="subtitle2">
            Production Notes:
          </Typography>
          <NotesCollectionBreakdown
            notesCollection={data.productionJob.order.productionNotesCollection}
          />
        </div>
      )}
    </div>
  );
};

export default enhancer(ReceivingDetailView);
