// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { fragments as vinylOrderStatusFragments } from "../VinylOrderStatus/graph";
import { withRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import VinylOrderStatus from "../VinylOrderStatus";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("VinylOrderDetail"),

  withRouter,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withHandlers({
    handleAppBarRequestBack: ({ router }) => () => {
      router.push("/apps/production/vinyl");
    },
    handleOrderDetailRequestClose: ({ router }) => () => {
      router.push("/apps/production/vinyl");
    }
  })
);

const VinylOrderDetail = ({
  data,
  handleAppBarRequestBack,
  handleOrderDetailRequestClose
}) => (
  <OrderDetailWithStatusHeader
    errorMessage={data.error ? data.error.message : null}
    loading={data.loading}
    onRequestClose={handleOrderDetailRequestClose}
    order={data.order}
    orderStatusView={
      data && data.order ? (
        <VinylOrderStatus
          order={filter(vinylOrderStatusFragments.order, data.order)}
        />
      ) : (
        <CircularProgress />
      )
    }
    shouldShowCustomerArtworkWithNotes={false}
    shouldShowInkColors={false}
    title="Vinyl"
    viewer={data.viewer}
  >
    <AppBar title="Vinyl" onRequestBack={handleAppBarRequestBack} />
  </OrderDetailWithStatusHeader>
);

export default enhancer(VinylOrderDetail);
