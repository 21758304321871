// @flow

import * as React from "react";
import { compose, withHandlers, withStateHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContentWrapper from "./SnackbarContentWrapper/SnackbarContentWrapper";

import type { HOC } from "recompose";

type Props = {
  classes: {
    +snackbarRoot: string
  }
};

type SnackbarData = {|
  id: number,
  isOpen: boolean,
  message: string,
  payload?: ?{},
  secondaryAction?: ?() => {},
  secondaryActionLabel?: ?string,
  variant: string
|};

type State = {|
  snackbarQueue: Array<SnackbarData>
|};

const defaultState: State = {
  snackbarQueue: []
};

const styles = theme => ({
  snackbarRoot: {
    bottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

let snackbarId = 0;

const enhancer: HOC<*, Props> = compose(
  withStyles(styles),
  withStateHandlers(defaultState, {
    addSnackbar:
      ({ snackbarQueue }) =>
      (snackbarData: SnackbarData) => ({
        snackbarQueue: [
          ...snackbarQueue,
          { id: snackbarId++, isOpen: true, ...snackbarData }
        ]
      }),

    closeSnackbar:
      ({ snackbarQueue }) =>
      (idToClose: number) => {
        const indexToClose = snackbarQueue.findIndex(
          ({ id }) => id === idToClose
        );

        if (indexToClose < 0) {
          return { snackbarQueue };
        }

        const snackbarToHide = snackbarQueue[indexToClose];
        snackbarToHide.isOpen = false;
        snackbarQueue[indexToClose] = snackbarToHide;
        return { snackbarQueue };
      },

    clearHiddenSnackbars:
      ({ snackbarQueue }) =>
      () => {
        return { snackbarQueue: snackbarQueue.filter(({ isOpen }) => isOpen) };
      }
  }),

  withHandlers({
    handleSnackbarRequestClose:
      ({ closeSnackbar }) =>
      (id: number) => {
        closeSnackbar(id);
      },

    showErrorSnackbar:
      ({ addSnackbar }) =>
      (
        message: string,
        payload: ?{},
        secondaryAction: ?() => {},
        secondaryActionLabel: ?string
      ) => {
        addSnackbar({
          message,
          payload,
          secondaryAction,
          secondaryActionLabel,
          variant: "error"
        });
      },

    showSuccessSnackbar:
      ({ addSnackbar, clearHiddenSnackbars }) =>
      (
        message: string,
        payload?: ?{},
        secondaryAction?: ?() => {},
        secondaryActionLabel?: ?string
      ) => {
        addSnackbar({
          message,
          payload,
          secondaryAction,
          secondaryActionLabel,
          variant: "success"
        });

        // The timeout here allows for any animations to occur before the snackbar is removed from the queue.
        setTimeout(clearHiddenSnackbars, 4000);
      },

    showWarningSnackbar:
      ({ addSnackbar, clearHiddenSnackbars }) =>
      (
        message: string,
        payload?: ?{},
        secondaryAction?: ?() => {},
        secondaryActionLabel?: ?string
      ) => {
        addSnackbar({
          message,
          payload,
          secondaryAction,
          secondaryActionLabel,
          variant: "warning"
        });

        // The timeout here allows for any animations to occur before the snackbar is removed from the queue.
        setTimeout(clearHiddenSnackbars, 4000);
      }
  })
);

const withSnackbar = (BaseComponent: React.ComponentType<any>) =>
  enhancer(
    ({ handleSnackbarRequestClose, snackbarQueue, classes, ...props }) => (
      <React.Fragment>
        <BaseComponent {...props} />
        <ul style={{ listStyle: "none" }}>
          {snackbarQueue.map(
            ({
              payload,
              secondaryActionLabel,
              secondaryAction,
              message,
              isOpen,
              variant,
              id
            }) => (
              <li key={id}>
                <div>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    autoHideDuration={2000}
                    onClose={handleSnackbarRequestClose.bind(this, id)}
                    open={isOpen}
                    className={classes.snackbarRoot}
                  >
                    <div>
                      <SnackbarContentWrapper
                        action={[
                          secondaryAction && (
                            <Button
                              key={secondaryActionLabel}
                              color="inherit"
                              size="small"
                              onClick={secondaryAction.bind(this, payload)}
                            >
                              {secondaryActionLabel}
                            </Button>
                          ),
                          <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={handleSnackbarRequestClose.bind(this, id)}
                          >
                            <CloseIcon />
                          </IconButton>
                        ]}
                        message={message}
                        variant={variant}
                      />
                    </div>
                  </Snackbar>
                </div>
              </li>
            )
          )}
        </ul>
      </React.Fragment>
    )
  );

export default withSnackbar;
