// @flow

import { gql } from "@apollo/client";

export default gql`
  query CustomizationAreaClaimDetails($customizationAreaId: ID!) {
    orderProductionJobCustomizationArea: node(id: $customizationAreaId) {
      ... on OrderProductionJobCustomizationArea {
        id
        canViewerClaim
        lastClaimedBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        orderProductionJobCustomizationAreaClaim {
          id
          employee {
            id
            name(format: FIRST_NAME_LAST_NAME)
          }
        }
      }
    }
  }
`;
