// @flow

import { gql } from "@apollo/client";

import { fragments as productionJobLineItemBreakdownFragments } from "../../../../OrderApp/components/ProductQuantitySummary/components/ProductionJobLineItemBreakdown/graph";
import { fragments as styleSubstituionLineItemBreakdownFragments } from "../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";

export default gql`
  query ReceivingOrderDetail($productionJobId: ID!) {
    productionJob: node(id: $productionJobId) {
      ... on OrderProductionJob {
        id
        index
        label
        inventoryOrderCartons {
          id
          name
        }
        customizationAreas {
          id
          location
          method
        }
        order {
          id
          isArtworkApproved
          isResolution
          directToGarmentExpressEligible
          express
          includeCatalogInShipment
          highDefinitionDigitalExpressEligible
          dueBy {
            dateTime
          }
          orderNumber
          primaryCustomer {
            businessUnit {
              id
              name
            }
            id
            insideBrandTagging
            name_receivingDetailView: name(format: FIRST_NAME_LAST_NAME)
            status
            totalOrders
          }
          priority
          productionDate
          productionStationAssignments {
            id
            productionStation {
              id
              name
            }
          }
          productionJobs {
            id
            index
            label
            customizationAreas {
              id
              location
              method
            }
            lineItemBreakdownByProduct {
              product {
                id
                style
              }
              lineItems {
                id
                color {
                  id
                  name
                }
                sizes {
                  id
                  quantity
                  size {
                    id
                    code
                  }
                }
              }
            }
            stockContainers {
              id
              latestLocation {
                id
                location
              }
            }
            totalProductVariantQuantity
          }
          productionNotesCollection {
            ... on FormattedNote {
              author
              createdAt
              note
            }
            ... on UnformattedNote {
              note
            }
          }
          productionStationAssignments {
            id
            productionStation {
              id
              name
            }
          }
          totalProductVariantQuantity
        }
        lineItemBreakdownByProduct {
          ...ProductionJobLineItemBreakdown_productionJobLineItemBreakdown
          lineItems {
            ...StyleSubstitutionLineItemBreakdown_lineItems
          }
          product {
            id
            style
            isStyleSubstitution
          }
        }
        totalProductVariantQuantity
        stockContainers {
          id
          latestLocation {
            id
            location
          }
        }
      }
    }
  }
  ${productionJobLineItemBreakdownFragments.productionJobLineItemBreakdown}
  ${styleSubstituionLineItemBreakdownFragments.lineItems}
`;
