// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as inkColorDetailsFragment } from "../../components/InkColorDetails/graph";

const updateOrderProductionJobCustomizationAreaColors: any = graphql(
  gql`
    mutation(
      $colors: [InkPantoneColorInput!]!
      $orderProductionJobCustomizationAreaId: ID!
    ) {
      updateOrderProductionJobCustomizationAreaColors(
        colors: $colors
        orderProductionJobCustomizationAreaId: $orderProductionJobCustomizationAreaId
      ) {
        errors {
          orderProductionJobCustomizationAreaId
          colors
          colorsInput {
            inkColorId
            pantoneColorId
          }
        }
        updatedOrderProductionJobCustomizationArea {
          id
          inkColors {
            customizationArea {
              id
            }
            id
            inkColor {
              ...InkColorDetails_inkColor
            }
            pantoneColor {
              ...InkColorDetails_pantoneColor
            }
          }
        }
        succeeded
      }
    }
    ${inkColorDetailsFragment.inkColor}
    ${inkColorDetailsFragment.pantoneColor}
  `,
  {
    name: "updateOrderProductionJobCustomizationAreaColors"
  }
);

export default updateOrderProductionJobCustomizationAreaColors;
