// @flow

import { gql } from "@apollo/client";
import { fragments as sidebarListItemFragments } from "../../../../components/Sidebar/SidebarListItem/graph";

export default gql`
  query ordersToBeVinyled(
    $first: Int!
    $after: String
    $filters: OrdersToBeVinyledFilters
  ) {
    ordersConnection: ordersToBeVinyled(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...SidebarListItem_order
        }
      }
    }
  }
  ${sidebarListItemFragments.order}
`;
