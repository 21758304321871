// @flow

import * as React from "react";
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { playAudioForReturn } from "../../../helpers/playAudioForReturn";
import { playAudioForSetLocation } from "../../../helpers/playAudioForSetLocation";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  isLoading: boolean
|};

const styles = StyleSheet.create({
  returnText: {
    fontSize: 46,
    textAlign: "center",
    fontWeight: "bold",
    margin: 20
  },
  page: {
    backgroundColor: "#ffffff",
    padding: 8
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("CheckReturnStatusApp"),

  withApollo,

  withRouter,

  withSnackbar,

  withStateHandlers(
    ({
      isLoading: false
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading })
    }
  ),

  withHandlers(() => {
    let pdfRef;

    const printReturn = () => {
      console.log("TEST");
      console.log(pdfRef);
      if (pdfRef) {
        pdfRef.focus();
        (pdfRef.contentWindow || pdfRef.contentDocument).print();
      }
    };

    return {
      handlePdfRef: () => ref => {
        pdfRef = ref;
      },
      handleAppHeaderRequestBack:
        ({ router, backUrl }) =>
        () => {
          router.push(backUrl || "/apps");
        },

      handleBarcodeError:
        ({ showErrorSnackbar, setIsLoading }) =>
        () => {
          showErrorSnackbar("Error scanning barcode1");
          setIsLoading(false);
        },

      handleBarcodeScan:
        ({
          client,
          setIsLoading,
          showErrorSnackbar,
          showSuccessSnackbar,
          showWarningSnackbar
        }) =>
        barcode => {
          try {
            const data = JSON.parse(barcode);
            const { variant, type, id } = data;
            if (variant === "OBJECT" && type === "StockContainer" && id) {
              setIsLoading(true);
              client
                .query({
                  query,
                  variables: { id },
                  fetchPolicy: "network-only"
                })
                .then(
                  ({
                    data: {
                      node: {
                        latestLocation,
                        orderProductionJob: {
                          order: { isCanceled, shippingStatus, vendor }
                        }
                      }
                    }
                  }) => {
                    if (
                      isCanceled ||
                      (vendor && !vendor.internal) ||
                      shippingStatus === "SHIPPED"
                    ) {
                      showErrorSnackbar("RETURN");
                      playAudioForReturn();
                      printReturn();
                    } else if (!latestLocation || !latestLocation.location) {
                      showWarningSnackbar("SET LOCATION");
                      playAudioForSetLocation();
                    } else {
                      showSuccessSnackbar("OK");
                      playAudioForSuccess();
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                })
                .finally(() => setIsLoading(false));
            } else if (
              variant === "OBJECT" &&
              type === "OrderProductionJob" &&
              id
            ) {
              setIsLoading(true);
              client
                .query({
                  query,
                  variables: { id },
                  fetchPolicy: "network-only"
                })
                .then(
                  ({
                    data: {
                      node: {
                        order: { isCanceled, shippingStatus, vendor }
                      }
                    }
                  }) => {
                    if (
                      isCanceled ||
                      (vendor && !vendor.internal) ||
                      shippingStatus === "SHIPPED"
                    ) {
                      showErrorSnackbar("RETURN");
                      playAudioForReturn();
                      printReturn();
                    } else {
                      showSuccessSnackbar("OK");
                      playAudioForSuccess();
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                })
                .finally(() => setIsLoading(false));
            }
          } catch {
            setIsLoading(false);
            showErrorSnackbar("Error Scanning Barcode2");
          }
        }
    };
  })
);

const CheckReturnStatusApp = ({
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  handlePdfRef,
  isLoading,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Audit Goods Status"
      onRequestBack={handleAppHeaderRequestBack}
      appBarBackgroundColor={appBarBackgroundColor}
    />
    {isLoading ? (
      <PendingStatusView status="Loading" />
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        {false && <CenteredSpinner />}
        <Typography
          variant="h6"
          align="center"
          color="inherit"
          style={{ flexGrow: 1, marginTop: "10em" }}
        >
          Scan Carton to Check Return Status
        </Typography>
      </div>
    )}
    <PDFViewer
      innerRef={handlePdfRef}
      style={{ display: "none" }}
      width="100%"
      height="1000"
    >
      <Document>
        <Page size={[288, 166.5]} style={styles.page}>
          <View style={styles.returnText}>
            <Text style={styles.returnText}>RETURN</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  </div>
);

export default enhancer(CheckReturnStatusApp);
