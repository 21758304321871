// @flow

import { gql } from "@apollo/client";

export default gql`
  query latestInventoryOrderByPoNumber($poNumber: String!) {
    latestInventoryOrderByPoNumber(poNumber: $poNumber) {
      id
      poNumber
      items {
        gtin
        quantity
        countryOfOriginOptions
        description
      }
      productionJob {
        id
      }
    }
  }
`;
