import { Page } from "@react-pdf/renderer";
import { compose, setDisplayName } from "recompose";
import {
  createInventoryOrderCartons,
  markInventoryOrderCartonAsReceived
} from "../../graph";
import { getAbbreviationForProductionJobMethod } from "../../helpers/getAbbreviationForProductionJobMethod";
import { getAbbreviationForProductionJobMethodLocation } from "../../helpers/getAbbreviationForProductionJobMethodLocation";
import { graphql } from "@apollo/client/react/hoc";
import { priorityOrder } from "../../helpers/priorityOrder";
import { query } from "./graph";
import CustomsLabel from "../CustomsLabel";
import CustomsShipmentPalletDetailScreen from "../CustomsShipmentPalletDetailScreen";
import FindInventoryOrderModal from "./components/FindInventoryOrderModal";
import PendingStatusView from "../PendingStatusView";
import QRCode from "qrcode";
import React, { useState } from "react";
import ReceivingLabel from "../../components/ReceivingLabelViewer/components/ReceivingLabel";
import useDeferredPromise from "../../hooks/useDeferredPromise";
import withSnackbar from "../../components/withSnackbar";

const {
  REACT_APP_INVENTORY_MANAGEMENT_TOKEN,
  REACT_APP_INVENTORY_MANAGEMENT_URL
} = process.env;

const enhancer = compose(
  setDisplayName("SouthboundCustomsShipmentPalletDetailScreen"),

  graphql(query, {
    options: ({ palletId }) => ({
      variables: {
        id: palletId
      },
      fetchPolicy: "network-only"
    })
  }),

  createInventoryOrderCartons,

  markInventoryOrderCartonAsReceived,

  withSnackbar
);

const SouthboundCustomsShipmentPalletDetailScreen = props => {
  const {
    createInventoryOrderCartons,
    markInventoryOrderCartonAsReceived,
    showSuccessSnackbar,
    showWarningSnackbar,
    showErrorSnackbar,
    data
  } = props;

  const { defer, deferRef } = useDeferredPromise();
  const [findInventoryOrderModalIsOpen, setFindInventoryOrderModalIsOpen] =
    useState(false);
  const [scannedCartonNumber, setScannedCartonNumber] = useState(null);

  const handleFindInventoryOrderModalRequestClose = () => {
    setFindInventoryOrderModalIsOpen(false);
  };

  const handleFindInventoryOrderModalComplete = data => {
    setFindInventoryOrderModalIsOpen(false);

    const { cartonNumber, inventoryOrderId, lineItems } = data;

    createInventoryOrderCartons({
      variables: {
        cartonNumber: cartonNumber,
        inventoryOrderId: inventoryOrderId,
        received: true,
        trackingNumber: null
      }
    })
      .then(
        ({
          data: {
            createInventoryOrderCartons: { createdCartons, succeeded, errors }
          }
        }) => {
          if (succeeded) {
            if (createdCartons.length > 0) {
              const [createdCarton] = createdCartons;
              const { stockContainer } = createdCarton;
              deferRef.resolve({
                ...data,
                label: labelFor({
                  lineItems,
                  stockContainer: stockContainer
                }),
                stockContainerId: stockContainer.id
              });
              showSuccessSnackbar("Carton added successfully!");
            } else {
              showErrorSnackbar("No cartons created.");
            }
          } else {
            showErrorSnackbar(
              Object.values(errors).reduce((a, b) => [...a, ...b], [])
            );
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(e.message);
      })
      .finally(() => {
        setScannedCartonNumber(null);
      });
  };

  const labelFor = props => {
    const { stockContainer, lineItems } = props;
    const input = {
      lineItems: lineItems,
      orderProductionJob: {
        ...stockContainer.orderProductionJob,
        printLocationsByMethod:
          stockContainer.orderProductionJob.customizationAreas.reduce(
            (accumulator, customizationArea) => {
              const method = getAbbreviationForProductionJobMethod(
                customizationArea.method
              );
              const location = getAbbreviationForProductionJobMethodLocation(
                customizationArea.location
              );
              const a = accumulator.find(i => i.method === method);
              if (a) {
                a.locations.push(location);
              } else {
                accumulator.push({
                  method: method,
                  locations: [location]
                });
              }
              return accumulator;
            },
            []
          )
      },
      orderNumber: stockContainer.orderProductionJob.order.orderNumber,
      isArtworkApproved:
        stockContainer.orderProductionJob.order.isArtworkApproved,
      priority: priorityOrder(
        stockContainer.orderProductionJob.order.productionDate,
        stockContainer.orderProductionJob.order.dueBy.dateTime
      ),
      express: stockContainer.orderProductionJob.order.express,
      productionDate: stockContainer.orderProductionJob.order.productionDate,
      isResolution: stockContainer.orderProductionJob.order.isResolution,
      dueBy: stockContainer.orderProductionJob.order.dueBy.dateTime,
      customerName:
        stockContainer.orderProductionJob.order.primaryCustomer.name,
      productionStationAssignment:
        stockContainer.orderProductionJob.order.productionStationAssignments
          .length > 0
          ? stockContainer.orderProductionJob.order
              .productionStationAssignments[0].productionStation.name
          : undefined,
      totalProductionJobs:
        stockContainer.orderProductionJob.order.productionJobs.length
    };

    const {
      orderProductionJob,
      isArtworkApproved,
      isResolution,
      orderNumber,
      express,
      priority,
      productionDate,
      dueBy,
      customerName,
      brand,
      productionStationAssignment
    } = input;
    return (
      <Page size={[288, 432]}>
        <ReceivingLabel
          express={express}
          productionJob={orderProductionJob}
          brand={brand}
          customerName={customerName}
          isResolution={isResolution}
          priority={priority}
          isArtworkApproved={isArtworkApproved}
          orderNumber={orderNumber}
          barcode={QRCode.toDataURL(
            JSON.stringify({
              variant: "OBJECT",
              type: "StockContainer",
              id: stockContainer.id
            })
          )}
          productionDate={productionDate}
          dueBy={dueBy}
          totalProductionJobs={10}
          productionStationAssignment={productionStationAssignment}
          lineItems={lineItems}
        />
      </Page>
    );
  };

  const handleBarcodeScan = barcode => {
    if (barcode) {
      console.log("handleBarcodeScan");
      console.log(barcode);
      console.log(data);
      const duplicateCartons = data.customsShipmentPallet.cartons.filter(
        ({ inventoryOrderCartonNumber }) =>
          inventoryOrderCartonNumber === barcode
      );
      console.log(duplicateCartons);
      if (duplicateCartons.length > 0) {
        const [duplicateCarton] = duplicateCartons;
        const { lineItems, stockContainer } = duplicateCarton;
        return {
          label: labelFor({ lineItems, stockContainer })
        };
      }

      return markInventoryOrderCartonAsReceived({
        variables: { inventoryOrderCartonIdentifier: barcode }
      })
        .then(
          ({
            data: {
              markInventoryOrderCartonAsReceived: {
                succeeded,
                updatedInventoryOrderCartons,
                errorStatus
              }
            }
          }) => {
            if (succeeded && updatedInventoryOrderCartons.length > 0) {
              const [updatedInventoryOrderCarton] =
                updatedInventoryOrderCartons;
              const {
                stockContainer: { id: stockContainerId }
              } = updatedInventoryOrderCarton;
              const { stockContainer } = updatedInventoryOrderCarton;
              const lineItems = updatedInventoryOrderCartons.map(
                ({
                  inventoryOrderItem: {
                    countryOfOriginOptions,
                    gtin,
                    description,
                    quantity: inventoryOrderItemQuantity
                  },
                  quantity
                }) => ({
                  quantity:
                    quantity != null ? quantity : inventoryOrderItemQuantity,
                  countryOfOrigin: countryOfOriginOptions[0],
                  identifier: gtin,
                  description: description
                })
              );

              return {
                inventoryOrderCartonNumber: barcode,
                lineItems: lineItems,
                label: labelFor({ lineItems, stockContainer }),
                stockContainerId
              };
            } else {
              console.log("errorStatus");
              console.log(errorStatus);
              if (errorStatus === "INVENTORY_ORDER_CARTON_NOT_FOUND") {
                return fetch(
                  `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory/api/supplier-carton`,
                  {
                    method: "POST",
                    body: JSON.stringify({ trackingNumber: barcode }),
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${REACT_APP_INVENTORY_MANAGEMENT_TOKEN}`
                    }
                  }
                )
                  .then(response => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      // TODO:
                      showWarningSnackbar(
                        "No Carton found. Please lookup the order manually and add a box."
                      );
                      setFindInventoryOrderModalIsOpen(true);
                      setScannedCartonNumber(barcode);
                      return { data: null };
                    }
                  })
                  .then(({ data }) => {
                    if (data != null) {
                      console.log("data");
                      console.log(data);
                      const { inventoryOrderCartonNumber, lineItems } = data;
                      const adjustedLineItems = lineItems.map(
                        ({ gtin, ...rest }) => ({
                          ...rest,
                          identifier: gtin
                        })
                      );
                      return {
                        lineItems: adjustedLineItems,
                        inventoryOrderCartonNumber: `${inventoryOrderCartonNumber}`,
                        label: (
                          <Page size={[288, 432]}>
                            <CustomsLabel
                              title="LOCAL INVENTORY"
                              data={adjustedLineItems}
                            />
                          </Page>
                        )
                      };
                    } else {
                      return defer().promise;
                    }
                  });
              } else if (errorStatus === "INVENTORY_ORDER_CANCELED") {
                showWarningSnackbar("RETURN: Order Canceled!");
                // TODO: printReturn();
              } else if (errorStatus === "INVENTORY_ORDER_VENDOR_ASSIGNED") {
                showWarningSnackbar("RETURN: Assigned to subcontractor!");
                // TODO: printReturn();
              }
            }
          }
        )
        .catch(e => {
          alert(e.message);
        });
    } else {
      setFindInventoryOrderModalIsOpen(true);
      return defer().promise;
    }
  };

  const { loading, error, customsShipmentPallet } = data;

  if (loading) {
    return <PendingStatusView status="Loading" />;
  }

  if (error) {
    return null;
  }

  const enhancedCustomsShipmentPallet = {
    ...customsShipmentPallet,
    cartons: customsShipmentPallet.cartons.map(carton => ({
      ...carton,
      label:
        carton.stockContainer != null ? (
          labelFor(carton)
        ) : (
          <Page size={[288, 432]}>
            <CustomsLabel title="LOCAL INVENTORY" data={carton.lineItems} />
          </Page>
        )
    }))
  };

  return (
    <>
      <CustomsShipmentPalletDetailScreen
        {...props}
        data={{ ...data, customsShipmentPallet: enhancedCustomsShipmentPallet }}
        onBarcodeScan={handleBarcodeScan}
        onRequestManualAddCarton={handleBarcodeScan}
        labelFor={labelFor}
      />
      <FindInventoryOrderModal
        isOpen={findInventoryOrderModalIsOpen}
        onRequestClose={handleFindInventoryOrderModalRequestClose}
        onComplete={handleFindInventoryOrderModalComplete}
        scannedCartonNumber={scannedCartonNumber}
      />
    </>
  );
};

export default enhancer(SouthboundCustomsShipmentPalletDetailScreen);
