// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { markOrderAsStockStaged } from "../../../graph";
import { markOrderAsUnstaged } from "../../../graph";
import { playAudioForReturnStock } from "../../../helpers/playAudioForReturnStock";
import { query } from "./graph";
import { fragments as stockStagedTableFragments } from "./components/StockStagedTable/graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import MenuItem from "@mui/material/MenuItem";
import OrderDetailView from "./components/OrderDetailView";
import PendingStatusView from "../../../components/PendingStatusView";
import StockStagedTable from "./components/StockStagedTable";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withDrawer from "../../../components/withDrawer";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {||};

type State = {|
  autoPrint: boolean,
  hasNextPage: boolean,
  isLoadingMore: boolean,
  loadingOrderIds: Array<string>,
  searchQuery: ?string,
  selectedOrderId: ?string,
  selectedProductionDate: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string,
  showAllSwitchValue: boolean
|};

const ORDERS_PER_PAGE = 20;

const ORDER_PRODUCTION_JOB_QUERY = gql`
  query BarcodeOrderQuery($orderProductionJobId: ID!) {
    node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        id
        order {
          id
          vendor {
            id
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const STOCK_CONTAINER_QUERY = gql`
  query StockContainerBarcodeOrderQuery($stockContainerId: ID!) {
    node(id: $stockContainerId) {
      ... on StockContainer {
        id
        orderProductionJob {
          id
          order {
            id
            vendor {
              id
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("StockStagedApp"),

  withApollo,

  withRouter,

  withSnackbar,

  markOrderAsUnstaged,

  markOrderAsStockStaged(ORDERS_PER_PAGE),

  withStateHandlers(
    ({
      autoPrint: false,
      hasNextPage: true,
      isLoadingMore: false,
      loadingOrderIds: [],
      searchQuery: null,
      selectedOrderId: null,
      selectedProductionDate: null,
      selectedSortBy: null,
      selectedSortDirection: null,
      showAllSwitchValue: false
    }: State),
    {
      setAutoPrint: () => (autoPrint: boolean) => ({ autoPrint }),

      addLoadingOrderId:
        ({ loadingOrderIds }) =>
        (orderId: string) => ({
          loadingOrderIds: [...loadingOrderIds, orderId]
        }),

      removeLoadingOrderId:
        ({ loadingOrderIds }) =>
        (orderId: string) => {
          const indexToRemove = loadingOrderIds.indexOf(orderId);
          return {
            loadingOrderIds: [
              ...loadingOrderIds.slice(0, indexToRemove),
              ...loadingOrderIds.slice(
                indexToRemove + 1,
                loadingOrderIds.length
              )
            ]
          };
        },

      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedOrderId: () => (selectedOrderId: ?string) => ({
        selectedOrderId
      }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      setSelectedProductionDate: () => (selectedProductionDate: ?string) => ({
        selectedProductionDate
      }),

      setShowAllSwitchValue: () => (showAllSwitchValue: boolean) => ({
        showAllSwitchValue
      })
    }
  ),

  withHandlers({
    processRequestStockStaged:
      ({
        addLoadingOrderId,
        removeLoadingOrderId,
        showErrorSnackbar,
        showSuccessSnackbar,
        markOrderAsStockStaged
      }) =>
      (orderId: string) => {
        addLoadingOrderId(orderId);
        markOrderAsStockStaged({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsStockStaged: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Order status has been updated.");
              } else {
                showErrorSnackbar(errors.orderId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingOrderId(orderId));
      },

    processRequestStockUnstaged:
      ({
        addLoadingOrderId,
        removeLoadingOrderId,
        showErrorSnackbar,
        showSuccessSnackbar,
        markOrderAsUnstaged
      }) =>
      (orderId: string) => {
        addLoadingOrderId(orderId);
        markOrderAsUnstaged({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsUnstaged: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Order status has been updated.");
              } else {
                showErrorSnackbar(errors.orderId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingOrderId(orderId));
      }
  }),

  withDrawer(
    "Job Details",
    ({
      autoPrint,
      processRequestStockStaged,
      processRequestStockUnstaged,
      loadingOrderIds,
      selectedOrderId,
      setDisplayState
    }) => (
      <OrderDetailView
        autoPrint={autoPrint}
        onRequestStockStaged={(selectedOrderId: string) => {
          setDisplayState("closed");
          processRequestStockStaged(selectedOrderId);
        }}
        onRequestUnstaged={(selectedOrderId: string) => {
          setDisplayState("closed");
          processRequestStockUnstaged(selectedOrderId);
        }}
        orderId={selectedOrderId}
        isStagingFromStock={loadingOrderIds.includes(selectedOrderId)}
      />
    )
  ),

  graphql(query, {
    options: ({
      searchQuery,
      showAllSwitchValue,
      selectedSortBy,
      selectedSortDirection,
      selectedProductionDate
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          includeUnassigned: true,
          includeStaged: searchQuery ? true : showAllSwitchValue,
          productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection
        }
      }
    })
  }),

  withProps(({ data }) => {
    if (data.ordersToBeStaged) {
      let formattedProductionDates =
        data.ordersToBeStaged.productionDates.map(( productionDate ) => {
          return {
            value: productionDate,
            label: DateTime.fromISO(productionDate).toLocaleString(DateTime.DATE_SHORT)
          };
        });
      formattedProductionDates = [{value: "All", label: "All"}].concat(formattedProductionDates);

      return {
        formattedProductionDates
      };
    }
  }),

  withHandlers({
    handleBarcodeError:
      ({ showErrorSnackbar }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
      },

    handleBarcodeScan:
      ({
        client,
        markOrderAsStockStaged,
        setAutoPrint,
        setSelectedOrderId,
        setDisplayState,
        setSearchQuery,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      barcode => {
        const data = JSON.parse(barcode);

        if (
          data.variant === "OBJECT" &&
          data.type === "OrderProductionJob" &&
          data.id
        ) {
          setSearchQuery(null);
          client
            .query({
              query: ORDER_PRODUCTION_JOB_QUERY,
              variables: { orderProductionJobId: data.id }
            })
            .then(
              ({
                data: {
                  node: {
                    order: { id, vendor }
                  }
                }
              }) => {
                markOrderAsStockStaged({
                  variables: { orderId: id }
                })
                  .then(
                    ({
                      data: {
                        markOrderAsStockStaged: { succeeded, order, errors }
                      }
                    }) => {
                      if (succeeded) {
                        setAutoPrint(true);
                        setSelectedOrderId(order.id);
                        setDisplayState("open-partial");
                        showSuccessSnackbar("Order status has been staged.");
                      } else {
                        const { orderId: error } = errors;
                        if (vendor) {
                          showErrorSnackbar(
                            `Return Goods. Order Assigned to ${vendor.organization.name}`
                          );
                          playAudioForReturnStock();
                        } else {
                          showErrorSnackbar(error);
                        }
                      }
                    }
                  )
                  .catch(e => {
                    showErrorSnackbar(e.message);
                  })
                  .finally(() => setAutoPrint(false));
              }
            );
        } else if (
          data.variant === "OBJECT" &&
          data.type === "StockContainer" &&
          data.id
        ) {
          setSearchQuery(null);
          client
            .query({
              query: STOCK_CONTAINER_QUERY,
              variables: { stockContainerId: data.id }
            })
            .then(
              ({
                data: {
                  node: {
                    orderProductionJob: {
                      order: { id, vendor }
                    }
                  }
                }
              }) => {
                markOrderAsStockStaged({
                  variables: { orderId: id }
                })
                  .then(
                    ({
                      data: {
                        markOrderAsStockStaged: { succeeded, order, errors }
                      }
                    }) => {
                      if (succeeded) {
                        setAutoPrint(true);
                        setSelectedOrderId(order.id);
                        setDisplayState("open-partial");
                        showSuccessSnackbar("Order status has been staged.");
                      } else {
                        const { orderId: error } = errors;
                        showErrorSnackbar(
                          `Return Goods. Order Assigned to ${vendor.organization.name}`
                        );
                        playAudioForReturnStock();
                        showErrorSnackbar(error);
                      }
                    }
                  )
                  .catch(e => {
                    showErrorSnackbar(e.message);
                  });
              }
            );
        }
      },

    handleSearch:
      ({ setSearchQuery, setSelectedOrderId }) =>
      searchQuery => {
        setSelectedOrderId(null);
        setSearchQuery(searchQuery);
      },

    handleStockStagedTableRowClicked:
      ({ setDisplayState, setSelectedOrderId }) =>
      (orderId: string) => {
        setSelectedOrderId(orderId);
        setDisplayState("open-partial");
      },

    handleShowAllSwitchChanged:
      ({
        setShowAllSwitchValue,
        data,
        selectedProductionDate,
        setHasNextPage
      }) =>
      (event, showAll: boolean) => {
        setShowAllSwitchValue(showAll);
        setHasNextPage(true);

        data.refetch({
          first: ORDERS_PER_PAGE,
          filters: {
            productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
            includeUnassigned: true,
            includeStaged: showAll
          }
        });
      },

    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl ? backUrl : "/apps");
      },

    handleAppHeaderTabSelected:
      ({
        data,
        showAllSwitchValue,
        setSelectedProductionDate,
        setHasNextPage
      }) =>
      (selectedProductionDate: string) => {
        setHasNextPage(true);
        setSelectedProductionDate(selectedProductionDate);

        data.refetch({
          first: ORDERS_PER_PAGE,
          filters: {
            productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
            includeUnassigned: true,
            includeStaged: showAllSwitchValue
          }
        });
      },

    handleStockStagedTableScrolledToBottom:
      ({
        data,
        hasNextPage,
        isLoadingMore,
        showAllSwitchValue,
        setHasNextPage,
        setIsLoadingMore,
        selectedProductionDate
      }) =>
      () => {
        if (isLoadingMore || !hasNextPage) {
          return;
        }

        setIsLoadingMore(true);
        data
          .fetchMore({
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.ordersToBeStaged.edges;
              const pageInfo = fetchMoreResult.ordersToBeStaged.pageInfo;
              setHasNextPage(pageInfo.hasNextPage);
              return newEdges.length
                ? {
                    ordersToBeStaged: {
                      pageInfo,
                      __typename: previousResult.ordersToBeStaged.__typename,
                      edges: [
                        ...previousResult.ordersToBeStaged.edges,
                        ...newEdges
                      ],
                      productionDates:
                        fetchMoreResult.ordersToBeStaged.productionDates
                    }
                  }
                : previousResult;
            },
            variables: {
              after: data.ordersToBeStaged.pageInfo.endCursor,
              filters: {
                productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
                includeUnassigned: true,
                includeStaged: showAllSwitchValue
              }
            }
          })
          .finally(() => setIsLoadingMore(false));
      },

    handleSort:
      ({
        selectedSortBy,
        setSortDirection,
        setSortBy,
        selectedSortDirection
      }) =>
      (newSelectedSortBy: string) => {
        setSortBy(newSelectedSortBy);
        if (selectedSortBy === newSelectedSortBy) {
          selectedSortDirection === "ASCENDING"
            ? setSortDirection("DESCENDING")
            : setSortDirection("ASCENDING");
        } else {
          setSortDirection("ASCENDING");
        }
      }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { data, setSelectedOrderId, setDisplayState } = this.props;
      if (!data.loading && !data.error) {
        if (
          data.ordersToBeStaged.edges.length === 1 &&
          data.ordersToBeStaged.edges[0].node.id !== prevProps.selectedOrderId
        ) {
          setSelectedOrderId(data.ordersToBeStaged.edges[0].node.id);
          setDisplayState("open-partial");
        }
      }
    }
  })
);

const StockStagedApp = ({
  data,
  formattedProductionDates,
  handleBarcodeError,
  handleBarcodeScan,
  handleAppHeaderRequestBack,
  handleAppHeaderTabSelected,
  handleShowAllSwitchChanged,
  processRequestStockStaged,
  processRequestStockUnstaged,
  handleSearch,
  handleSort,
  handleStockStagedTableRowClicked,
  handleStockStagedTableScrolledToBottom,
  hasNextPage,
  isLoadingMore,
  loadingOrderIds,
  showAllSwitchValue,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Counting / Staging"
      tabItems={
        data && data.ordersToBeStaged
          ? formattedProductionDates
          : []
      }
      onRequestBack={handleAppHeaderRequestBack}
      onSearch={handleSearch}
      onTabSelected={handleAppHeaderTabSelected}
      appBarBackgroundColor={appBarBackgroundColor}
      menuItems={[
        <MenuItem>
          Show All
          <Switch
            checked={showAllSwitchValue}
            onChange={handleShowAllSwitchChanged}
            color="primary"
          />
        </MenuItem>
      ]}
    />
    {data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        {data.error.message}
      </Typography>
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        <StockStagedTable
          loadingOrderIds={loadingOrderIds}
          onScrolledToBottom={handleStockStagedTableScrolledToBottom}
          orders={filter(
            stockStagedTableFragments.order,
            data.ordersToBeStaged.edges.map(edge => ({ ...edge.node }))
          )}
          onRequestStockStaged={processRequestStockStaged}
          onRequestUnstaged={processRequestStockUnstaged}
          onRequestSort={handleSort}
          onRowClicked={handleStockStagedTableRowClicked}
        />
        {isLoadingMore && hasNextPage && <CenteredSpinner />}
      </div>
    )}
  </div>
);

export default enhancer(StockStagedApp);
