// @flow

import { fragments as OrderProductionJobCustomizationAreaListFragments } from "../../components/OrderProductionJobCustomizationAreaList/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const updateOrderProductionJobCustomizationArea: any = graphql(
  gql`
    mutation(
      $autobase: Boolean!
      $colorCount: Int
      $customizationAreaTemplateId: ID!
      $distressed: Boolean!
      $flatten: Boolean!
      $halftones: Boolean!
      $imageRepair: Boolean!
      $method: ProductCustomizationMethod!
      $orderProductionJobCustomizationAreaId: ID!
      $recreate: Boolean!
      $splitIntoColors: Boolean!
      $sponsorback: Boolean!
      $vinylConfiguration: VinylConfigurationInput
    ) {
      updateOrderProductionJobCustomizationArea(
        autobase: $autobase
        colorCount: $colorCount
        customizationAreaTemplateId: $customizationAreaTemplateId
        distressed: $distressed
        flatten: $flatten
        halftones: $halftones
        imageRepair: $imageRepair
        method: $method
        orderProductionJobCustomizationAreaId: $orderProductionJobCustomizationAreaId
        recreate: $recreate
        splitIntoColors: $splitIntoColors
        sponsorback: $sponsorback
        vinylConfiguration: $vinylConfiguration
      ) {
        updatedOrderProductionJobCustomizationArea {
          ...OrderProductionJobCustomizationAreaList_customizationAreas
        }
        errors {
          autobase
          colorCount
          customizationAreaTemplateId
          distressed
          flatten
          halftones
          imageRepair
          method
          orderProductionJobCustomizationAreaId
          recreate
          splitIntoColors
          sponsorback
          vinylConfiguration
          vinylConfigurationInput {
            names
            namesColorId
            namesFontId
            numbers
            numbersColorId
            numbersFontId
            size
          }
        }
        succeeded
      }
    }
    ${OrderProductionJobCustomizationAreaListFragments.customizationAreas}
  `,
  {
    name: "updateOrderProductionJobCustomizationArea"
  }
);

export default updateOrderProductionJobCustomizationArea;
