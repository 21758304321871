// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const setStockLocationParent: any = graphql(
  gql`
    mutation($location: String!, $parentLocation: String!) {
      setStockLocationParent(
        location: $location
        parentLocation: $parentLocation
      ) {
        errors {
          location
          parentLocation
        }
        succeeded
        updatedStockContainers {
          id
        }
      }
    }
  `,
  {
    name: "setStockLocationParent"
  }
);

export default setStockLocationParent;
