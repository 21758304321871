// @flow

import { fragments as customerArchiveDesignProofListFragments } from "../../CustomerArchiveDesignProofList/graph";
import { fragments as customerArchiveOrderSummaryFragments } from "../../CustomerArchiveOrderSummary/graph";
import { gql } from "@apollo/client";
import { fragments as notesWrapperFragments } from "../../../../../../components/NotesWrapper/graph";
import { fragments as orderPricingSummaryFragments } from "../../../../../../components/OrderPricingSummary/graph";
import { fragments as productionJobDetailFragments } from "../../../../../../components/ProductionJobDetail/graph";

export default {
  order: gql`
    fragment CustomerArchiveListItem_order on Order {
      productionJobs {
        ...ProductionJobDetail_productionJob
        customizationAreas {
          id
        }
        label
        lineItemBreakdownByProduct {
          lineItems {
            id
            product {
              id
              style
            }
            color {
              id
              name
            }
            totalPrice {
              formatted
            }
            totalQuantity
          }
          totalPrice {
            formatted
          }
        }
        totalPrice {
          formatted
        }
        totalProductVariantQuantity
      }
      ...NotesWrapper_order
      totalProductVariantQuantity
      ...CustomerArchiveOrderSummary_order
      ...OrderPricingSummary_order
    }
    ${notesWrapperFragments.order}
    ${customerArchiveOrderSummaryFragments.order}
    ${customerArchiveDesignProofListFragments.proofs}
    ${orderPricingSummaryFragments.order}
    ${productionJobDetailFragments.productionJob}
  `
};
