// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import type { HOC } from "recompose";

type State = {|
  selectedProductionStationId: ?string
|};

const defaultState: State = {
  selectedProductionStationId: null
};

const styles = theme => ({
  content: {
    height: "calc(100vh - 48px)"
  },

  spinnerWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("QaDashboardApp"),

  withRouter,

  withStyles(styles),

  withStateHandlers(defaultState, {
    setSelectedProductionStationId: () => (
      selectedProductionStationId: string
    ) => ({
      selectedProductionStationId
    })
  }),

  withHandlers({
    handleSelectedProductionStationId: ({
      router,
      setSelectedProductionStationId
    }) => id => {
      setSelectedProductionStationId(id);
      router.push(`/apps/production/qa-dashboard/productionStation/${id}`);
    },

    handleTabbedAppBarRequestBack: ({ router }) => () => {
      router.push("/apps");
    }
  }),

  graphql(query, {
    options: () => ({
      variables: {
        type: "BELT"
      }
    })
  })
);

const QaDashboardApp = ({
  classes,
  handleSelectedProductionStationId,
  data: { loading, productionStations },
  selectedProductionStationId
}) => (
  <React.Fragment>
    {!selectedProductionStationId && (
      <div>
        {loading ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress />
          </div>
        ) : (
          productionStations.map(station => (
            <MenuItem
              id={station.id}
              button
              onClick={() => handleSelectedProductionStationId(station.id)}
            >
              {station.name}
            </MenuItem>
          ))
        )}
      </div>
    )}
  </React.Fragment>
);

export default enhancer(QaDashboardApp);
