// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsUnstaged: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsUnstaged(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          id
          isStockStaged
        }
        succeeded
      }
    }
  `,
  {
    name: "markOrderAsUnstaged"
  }
);

export default markOrderAsUnstaged;
