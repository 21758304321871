// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { createInventoryOrderCartons } from "../../../../../graph";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type InputErrors = {|
  cartonNumber: $ReadOnlyArray<string>,
  inventoryOrderItems: $ReadOnlyArray<string>,
  received: $ReadOnlyArray<string>,
  trackingNumber: $ReadOnlyArray<string>
|};

const defaultInputErrors: InputErrors = {
  cartonNumber: [],
  inventoryOrderItems: [],
  received: [],
  trackingNumber: []
};

type Props = {|
  +cartonNumber: ?string,
  +classes?: {|
    +autocompleteBox: string,
    +formControl: string,
    +list: string,
    +resultMessage: string
  |},
  +isOpen: boolean,
  +onRequestClose: () => void
|};

type SelectedInventoryOrder = {|
  +id: string,
  +items: $ReadOnlyArray<{|
    +description: string,
    +id: string
  |}>,
  +poNumber: string
|};

type SelectedOrderProductionJob = {|
  +id: string,
  +inventoryOrders: $ReadOnlyArray<SelectedInventoryOrder>,
  +label: string,
  +order: {|
    +id: string,
    +orderNumber: string
  |}
|};

type State = {|
  cartonNumber: ?string,
  generalErrors: Array<string>,
  inputErrors: InputErrors,
  isSubmittingForm: boolean,
  selectedInventoryOrder: ?SelectedInventoryOrder,
  selectedOrderProductionJob: ?SelectedOrderProductionJob,
  succeeded: boolean
|};

const defaultState: State = {
  cartonNumber: null,
  generalErrors: [],
  inputErrors: defaultInputErrors,
  isSubmittingForm: false,
  selectedInventoryOrder: null,
  selectedOrderProductionJob: null,
  succeeded: false
};

const styles = theme => ({
  autocompleteBox: {
    minHeight: 120
  },

  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120
  },

  list: {
    listStyle: "none",
    padding: 0
  },

  resultMessage: {
    marginBottom: theme.spacing(4)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ReceiveCartonModal"),

  withStateHandlers(
    {
      orderProductionJobLabelStartsWith: ""
    },
    {
      setOrderProductionJobLabelStartsWith:
        () => orderProductionJobLabelStartsWith => ({
          orderProductionJobLabelStartsWith
        })
    }
  ),

  graphql(query, {
    skip: ({ orderProductionJobLabelStartsWith }) =>
      orderProductionJobLabelStartsWith.length <= 3,
    options: ({ orderProductionJobLabelStartsWith }) => ({
      variables: {
        labelStartsWith: orderProductionJobLabelStartsWith
      },
      fetchPolicy: "network-only"
    })
  }),

  createInventoryOrderCartons,

  withStyles(styles),

  withStateHandlers(
    ({
      selectedInventoryOrder,
      selectedOrderProductionJob,
      scannedCartonNumber: cartonNumber
    }) => ({
      ...defaultState,
      cartonNumber,
      selectedInventoryOrder,
      selectedOrderProductionJob
    }),
    {
      resetForm: () => () => defaultState,

      resetInputErrors: () => () => defaultInputErrors,

      setInputErrors: () => (inputErrors: InputErrors) => ({ inputErrors }),

      setSelectedOrderProductionJob:
        () => (selectedOrderProductionJob: ?SelectedOrderProductionJob) => ({
          selectedOrderProductionJob
        }),

      setSelectedInventoryOrder:
        () => (selectedInventoryOrder: ?SelectedInventoryOrder) => ({
          selectedInventoryOrder
        }),

      setIsSubmittingForm: () => (isSubmittingForm: boolean) => ({
        isSubmittingForm
      }),

      setCartonNumber: () => (cartonNumber: string) => ({
        cartonNumber
      }),

      setGeneralErrors: () => (generalErrors: Array<string>) => ({
        generalErrors
      }),

      setSucceeded: () => (succeeded: boolean) => ({ succeeded })
    }
  ),

  withHandlers({
    handleCloseButtonClick:
      ({ onRequestClose, resetForm, resetInputErrors }) =>
      () => {
        resetForm();
        resetInputErrors();
        onRequestClose();
      },

    handleReceiveButtonClick:
      ({
        createInventoryOrderCartons,
        resetInputErrors,
        cartonNumber,
        selectedInventoryOrder,
        setCartonNumber,
        setGeneralErrors,
        setInputErrors,
        setIsSubmittingForm,
        setSelectedOrderProductionJob,
        setSucceeded
      }) =>
      () => {
        setIsSubmittingForm(true);

        createInventoryOrderCartons({
          variables: {
            cartonNumber: cartonNumber,
            inventoryOrderId: selectedInventoryOrder.id,
            received: true,
            trackingNumber: null
          }
        })
          .then(
            ({
              data: {
                createInventoryOrderCartons: { succeeded, errors }
              }
            }) => {
              setSucceeded(succeeded);

              if (succeeded) {
                resetInputErrors();
                setCartonNumber(null);
                setGeneralErrors([]);
                setSelectedOrderProductionJob(null);
              } else {
                setInputErrors(errors);
              }
            }
          )
          .catch(e => {
            resetInputErrors();
            setCartonNumber(null);
            setGeneralErrors([e.message]);
            setSelectedOrderProductionJob(null);
            setSucceeded(false);
          })
          .finally(() => {
            setIsSubmittingForm(false);
          });
      },

    handleDialogClose:
      ({ onRequestClose, resetForm, resetInputErrors }) =>
      () => {
        onRequestClose();
        resetInputErrors();
        resetForm();
      },

    handleSelectOrderProductionJob:
      ({ setSelectedInventoryOrder, setSelectedOrderProductionJob }) =>
      value => {
        const orderProductionJob = value ? value.orderProductionJob : null;

        setSelectedOrderProductionJob(orderProductionJob);

        const inventoryOrders = orderProductionJob
          ? orderProductionJob.inventoryOrders
          : [];
        setSelectedInventoryOrder(
          inventoryOrders.length === 1 ? inventoryOrders[0] : null
        );
      },

    handleSelectInventoryOrder:
      ({ setSelectedInventoryOrder }) =>
      (event: SyntheticInputEvent<HTMLInputElement>) => {
        setSelectedInventoryOrder(event.target.value);
      },

    handleCartonNumberChange:
      ({ setCartonNumber }) =>
      (event: SyntheticInputEvent<HTMLInputElement>) => {
        setCartonNumber(event.target.value);
      }
  })
);

const ReceiveCartonModal = ({
  classes,
  cartonNumber,
  data,
  generalErrors,
  handleCartonNumberChange,
  handleCloseButtonClick,
  handleDialogClose,
  handleReceiveButtonClick,
  handleSelectInventoryOrder,
  handleSelectOrderProductionJob,
  inputErrors,
  isOpen,
  isSubmittingForm,
  selectedInventoryOrder,
  selectedOrderProductionJob,
  succeeded,
  orderProductionJobLabelStartsWith,
  setOrderProductionJobLabelStartsWith
}) => {
  const cartonNumberInputErrors = inputErrors.cartonNumber;

  return (
    <Dialog
      aria-describedby="Receive Carton"
      aria-labelledby="Receive Carton"
      open={isOpen}
      onClose={handleDialogClose}
      maxWidth="md"
    >
      <DialogTitle>Receive Carton</DialogTitle>
      <DialogContent>
        <div>
          {generalErrors.length > 0 ? (
            <Typography
              variant="body2"
              color="error"
              className={classes.resultMessage}
            >
              Something went wrong: {generalErrors.join(", ")}
            </Typography>
          ) : null}
          {succeeded ? (
            <Typography
              variant="body2"
              color="success.main"
              className={classes.resultMessage}
            >
              Inventory Order Cartons has been created
            </Typography>
          ) : null}
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              error={cartonNumberInputErrors.length > 0}
              id="receiveCartonCartonNumber"
              label="Carton Number"
              onChange={handleCartonNumberChange}
              value={cartonNumber}
              variant="outlined"
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedOrderProductionJob}
              onChange={(event, newInputValue) => {
                handleSelectOrderProductionJob(newInputValue);
              }}
              options={
                data && data.orderProductionJobsToBeManuallyReceivedList
                  ? data.orderProductionJobsToBeManuallyReceivedList.map(
                      orderProductionJob => ({
                        orderProductionJob: orderProductionJob,
                        label: `${orderProductionJob.label} - Order ${orderProductionJob.order.orderNumber}`
                      })
                    )
                  : []
              }
              loading={data && data.loading}
              fullWidth={true}
              inputValue={orderProductionJobLabelStartsWith}
              onInputChange={(event, value) => {
                setOrderProductionJobLabelStartsWith(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Order Production Job Label/PO" />
              )}
            />
          </FormControl>
          {!selectedOrderProductionJob ? (
            <div className={classes.autocompleteBox} />
          ) : (
            <div>
              <FormControl className={classes.formControl} fullWidth>
                {selectedInventoryOrder ? (
                  <TextField
                    label="Inventory Order"
                    value={selectedInventoryOrder.poNumber}
                    variant="outlined"
                  />
                ) : (
                  <div>
                    <InputLabel id="demo-simple-select-label">
                      Inventory Order
                    </InputLabel>
                    <Select
                      defaultValue=""
                      id="demo-simple-select"
                      label="Inventory Order"
                      labelId="demo-simple-select-label"
                      onChange={handleSelectInventoryOrder}
                      fullWidth={true}
                    >
                      {selectedOrderProductionJob.inventoryOrders.map(
                        inventoryOrder => (
                          <MenuItem
                            key={inventoryOrder.id}
                            value={inventoryOrder}
                          >
                            {inventoryOrder.poNumber}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                )}
              </FormControl>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={isSubmittingForm}
          onClick={handleCloseButtonClick}
          variant="contained"
        >
          Close
        </Button>
        <Button
          color="primary"
          disabled={
            isSubmittingForm || !cartonNumber || !selectedInventoryOrder
          }
          onClick={handleReceiveButtonClick}
          variant="contained"
        >
          Receive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(ReceiveCartonModal);
