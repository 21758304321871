// @flow

import { gql } from "@apollo/client";
import { fragments as productionJobListItemFragments } from "../components/ProductionJobListItem/graph";

export default {
  productionJobs: gql`
    fragment FixPieceProductionJobList_productionJobs on OrderProductionJob {
      id
      ...FixPieceProductionJobListItem_productionJob
    }
    ${productionJobListItemFragments.productionJob}
  `
};
