// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import type { HOC } from "recompose";

type LineItem = {|
  +description: string,
  +id: string,
  +quantity: number,
  +reason: {|
    +id: string,
    +name: string
  |}
|};
type Props = {|
  +classes: {|
    +cell: string,
    +tableRow: string
  |},
  +lineItems: $ReadOnlyArray<LineItem>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("FixPieceRequestItemTable")
);

const styles = theme => ({
  cell: {
    padding: theme.spacing(1)
  },

  image: {
    maxHeight: 50
  },

  tableRow: {
    height: 0
  },

  tableWrapper: {
    paddingBottom: theme.spacing(1)
  }
});

const FixPieceRequestItemTable = ({ classes, lineItems }) => {
  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.cell}>Product</TableCell>
            <TableCell className={classes.cell}>Reason</TableCell>
            <TableCell align="right" className={classes.cell}>
              Quantity
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map(lineItem => (
            <TableRow key={lineItem.id}>
              <TableCell component="th" scope="row" className={classes.cell}>
                {lineItem.inventoryItem.description}
              </TableCell>
              <TableCell component="th" scope="row" className={classes.cell}>
                {lineItem.reason.name}
              </TableCell>
              <TableCell align="right" className={classes.cell}>
                {lineItem.quantity}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(
  enhancer(FixPieceRequestItemTable)
);
