// @flow

import * as React from "react";
import { BarcodePivotContextConsumer } from "./BarcodePivot";

const withBarcodePivot = (BaseComponent: React.ComponentType<any>) => (
  props: any
) => (
  <BarcodePivotContextConsumer>
    {({
      acknowledgeOrderProductionJob,
      acknowledgeStockContainer,
      lastOrderProductionJobScanned,
      lastStockContainerScanned
    }) => (
      <BaseComponent
        acknowledgeOrderProductionJob={acknowledgeOrderProductionJob}
        acknowledgeStockContainer={acknowledgeStockContainer}
        lastOrderProductionJobScanned={lastOrderProductionJobScanned}
        lastStockContainerScanned={lastStockContainerScanned}
        {...props}
      />
    )}
  </BarcodePivotContextConsumer>
);

export default withBarcodePivot;
