import { compose, setDisplayName } from "recompose";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState } from "react";

const enhancer = compose(setDisplayName("SelectOrderProductionJobModal"));

const SelectOrderProductionJobModal = ({
  isOpen,
  onRequestClose,
  onComplete,
  orderProductionJobOptions
}) => {
  const [selectedOrderProductionJobs, setSelectedOrderProductionJobs] =
    useState(orderProductionJobOptions.map(() => true));

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setSelectedOrderProductionJobs([]);
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      onComplete({
        lineItems: orderProductionJobOptions
          .filter((_, index) => selectedOrderProductionJobs[index])
          .reduce((accumulator, orderProductionJobOption) => {
            const { order: { includeCatalogInShipment } } = orderProductionJobOption;
            return [
              ...accumulator,
              ...orderProductionJobOption.lineItemBreakdownByProduct
                .map(({ product: { style }, lineItems }) => {
                  return lineItems
                    .map(({ sizes, color: { name: color } }) => {
                      return sizes.map(
                        ({
                          quantity,
                          size: { code: size },
                          gtin,
                          countryOfOrigin
                        }) => {
                          return {
                            identifier: gtin,
                            countryOfOrigin: countryOfOrigin,
                            description: `${style}-${color}-${size}`,
                            embroidery: (orderProductionJobOption.customizationAreas.some(area => area.method == 'EMBROIDERY')),
                            quantity: quantity
                          };
                        }
                      );
                    })
                    .reduce(
                      (accumulator, value) => [...accumulator, ...value],
                      []
                    );
                })
                .reduce((accumulator, value) => [...accumulator, ...value], includeCatalogInShipment ? [{ identifier: "catalog01", countryOfOrigin: "CN", description: "Catalog", quantity: 1 }]  : [])
            ];
          }, [])
      });
      setSelectedOrderProductionJobs([]);
    }
  };

  return (
    <Dialog
      aria-describedby="Select Order Production Job"
      aria-labelledby="Select Order Production Job"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Select Order Production Job</DialogTitle>
      <DialogContent>
        {orderProductionJobOptions.map(({ label }, index) => (
          <div key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOrderProductionJobs[index]}
                  onChange={({ target: { checked } }) => {
                    selectedOrderProductionJobs.splice(index, 1, checked);
                    setSelectedOrderProductionJobs([
                      ...selectedOrderProductionJobs
                    ]);
                  }}
                />
              }
              label={label}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={
            selectedOrderProductionJobs.filter(selected => !selected).length > 1
          }
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(SelectOrderProductionJobModal);
