// @flow

import { gql } from "@apollo/client";
import { fragments as productionMethodIconSetFragments } from "../../../../../components/ProductionMethodIconSet/graph";

export default {
  productionJob: gql`
    fragment ProductionJobListItem_productionJob on OrderProductionJob {
      customizationAreas {
        id
        lastClaimedBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        method
        quantity
      }
      latestBoxLocation {
        id
        location
      }
      latestScreenLocation {
        id
        location
      }
      boxLocationHistory {
        id
        createdAt
        createdBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        location
      }
      screenLocationHistory {
        id
        createdAt
        createdBy {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        location
      }
      stockContainers {
        id
        latestLocation {
          id
          location
        }
      }
      embroideryStatus
      directToGarmentStatus
      vinylStatus
      screenPrintingStatus
      highDefinitionDigitalStatus
      id
      label
      lineItemBreakdownByProduct {
        totalProductVariantQuantity
        product {
          fullDisplayName
          id
        }
      }
      filteredProofs {
        id
        asset {
          url {
            formatted
          }
        }
      }
      totalProductVariantQuantity
    }
    ${productionMethodIconSetFragments.order}
  `
};
