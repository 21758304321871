import * as React from "react";
import StandardExpeditingLabel from "./components/StandardExpeditingLabel";
import TransfersExpeditingLabel from "./components/TransfersExpeditingLabel";

const ExpeditingLabel = (props) => {
  const { productionJob: { customizationAreas } } = props;
  const methods = customizationAreas.map(({ method }) => method);
  if(methods.length > 0 && methods.length === methods.filter(method => method.startsWith("TRANSFER_")).length) {
      return <TransfersExpeditingLabel {...props} />;
  }
  return <StandardExpeditingLabel {...props} />;
}

export default ExpeditingLabel;