// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const purchaseProductsForFixPieceRequest: any = graphql(
  gql`
    mutation($fixPieceRequestId: ID!, $source: String) {
      purchaseProductsForFixPieceRequest(
        fixPieceRequestId: $fixPieceRequestId,
        source: $source
      ) {
        errors {
          fixPieceRequestId
        }
        inventoryOrders {
          fixPieceRequest {
            hasBeenOrdered
            id
            orderedAt
          }
          id
          vendor {
            id
            organization{
              id
              name
            }
          }
        }
        partialOrder
        succeeded
      }
    }
  `,
  {
    name: "purchaseProductsForFixPieceRequest"
  }
);

export default purchaseProductsForFixPieceRequest;
