// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as transfersUVOrderStatusFragments } from "../../application/apps/TransferPrintingApp/components/TransfersUVOrderStatus/graph";

const markOrderAsTransfersUVManualPrintingStarted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsTransfersUvManualPrintingStarted(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...TransfersUVOrderStatus_order
        }
        succeeded
      }
    }
    ${transfersUVOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsTransfersUVManualPrintingStarted"
  }
);

export default markOrderAsTransfersUVManualPrintingStarted;
