// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { fragments as customizationAreaListItemFragments } from "./components/OrderProductionJobCustomizationAreaListItem/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import AddCustomizationAreaModal from "../AddCustomizationAreaModal";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import OrderProductionJobCustomizationAreaListItem from "./components/OrderProductionJobCustomizationAreaListItem";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +customizationAreas: $ReadOnlyArray<{||}>,
  +productionJobId: string,
  +shouldShowCustomerArtworkWithNotes: boolean,
  +shouldShowInkColors: boolean
|};

const styles = theme => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  list: {
    padding: 0,
    marginTop: theme.spacing(2)
  },

  listItem: {
    display: "flex",
    flexDirection: "column",
    padding: 0
  },

  listHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  root: {
    width: "100%"
  }
});

type State = {|
  addCustomizationAreaModalIsOpen: boolean
|};

const defaultState: State = {
  addCustomizationAreaModalIsOpen: false
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderProductionJobCustomizationAreaList"),

  withStyles(styles),

  withStateHandlers(() => defaultState, {
    setAddCustomizationAreaModalVisibility: () => (
      addCustomizationAreaModalIsOpen: boolean
    ) => ({
      addCustomizationAreaModalIsOpen
    })
  }),

  withHandlers({
    handleAddCustomizationAreaButtonClick: ({
      setAddCustomizationAreaModalVisibility
    }) => () => {
      setAddCustomizationAreaModalVisibility(true);
    },

    handleAddCustomizationAreaModalRequestClose: ({
      setAddCustomizationAreaModalVisibility
    }) => () => {
      setAddCustomizationAreaModalVisibility(false);
    }
  })
);

const OrderProductionJobCustomizationAreaList = ({
  addCustomizationAreaModalIsOpen,
  classes,
  customizationAreas,
  handleAddCustomizationAreaButtonClick,
  handleAddCustomizationAreaModalRequestClose,
  productionJobId,
  shouldShowCustomerArtworkWithNotes,
  shouldShowInkColors
}) => (
  <React.Fragment>
    <div className={classes.heading}>
      <Typography variant="h6">
        Customization Areas ({customizationAreas.length})
      </Typography>

      <Button
        color="primary"
        variant="contained"
        onClick={handleAddCustomizationAreaButtonClick}
      >
        Add Customization Area
      </Button>
      <AddCustomizationAreaModal
        isOpen={addCustomizationAreaModalIsOpen}
        onRequestClose={handleAddCustomizationAreaModalRequestClose}
        orderProductionJobId={productionJobId}
      />
    </div>
    <List className={classes.list}>
      {customizationAreas.map(customizationArea => (
        <React.Fragment key={customizationArea.id}>
          <ListItem
            alignItems="flex-start"
            className={classes.listItem}
            key={customizationArea.id}
          >
            <OrderProductionJobCustomizationAreaListItem
              customizationArea={filter(
                customizationAreaListItemFragments.customizationArea,
                customizationArea
              )}
              shouldShowCustomerArtworkWithNotes={
                shouldShowCustomerArtworkWithNotes
              }
              shouldShowInkColors={shouldShowInkColors}
              productionJobId={productionJobId}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  </React.Fragment>
);

export default enhancer(OrderProductionJobCustomizationAreaList);
