// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment NotesWrapper_order on Order {
      artNotesCollection {
        __typename
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
      id
      productionNotesCollection {
        __typename
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
      salesNotesCollection {
        __typename
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
    }
  `,
  orderProductionJobCustomizationArea: gql`
    fragment NotesWrapper_orderProductionJobCustomizationArea on OrderProductionJobCustomizationArea {
      id
      notesCollection {
        __typename
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
    }
  `
};
