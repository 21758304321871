// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import CenteredSpinner from "../../components/CenteredSpinner";
import SidebarList from "./components/SidebarList";
import SidebarListItem from "./SidebarListItem";

import type { HOC } from "recompose";

type Props = {|
  +children?: React.ChildrenArray<React.Element<typeof SidebarListItem>>,
  +classes?: {|
    +spinner: string,
    +toolbar: string
  |},
  +isLoadingMoreItems?: boolean,
  +isOpen: boolean,
  +onItemSelected: (index: number) => void,
  +onRequestClose: () => void,
  +onScrolledToBottom: () => void
|};

const styles = theme => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3)
  },

  toolbar: theme.mixins.toolbar
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("Sidebar"),
  withStyles(styles),
  withHandlers({
    handleItemClicked:
      ({ onItemSelected }) =>
      (index: number) => {
        onItemSelected(index);
      },

    handleSidebarListScrolledToBottom:
      ({ onScrolledToBottom }) =>
      () => {
        onScrolledToBottom();
      },

    handleDrawerScroll:
      ({ onScrolledToBottom }) =>
      event => {
        const target = event.target;
        if (target.scrollTop + target.clientHeight === target.scrollHeight) {
          onScrolledToBottom();
        }
      }
  })
);

const Sidebar = ({ children, handleItemClicked, isLoadingMoreItems }) => (
  <div style={{ display: "flex", justifyContent: "space-around" }}>
    <SidebarList children={children} onItemSelected={handleItemClicked} />
    {isLoadingMoreItems && <CenteredSpinner />}
  </div>
);

export default enhancer(Sidebar);
