import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const SetControlNumberModal = ({
  isOpen,
  onRequestClose,
  onComplete,
  showSealNumbers,
  controlNumberLabel
}) => {
  const [controlNumber, setControlNumber] = useState("");
  const [processedBy, setProcessedBy] = useState("");
  const [primarySealNumber, setPrimarySealNumber] = useState("");
  const [secondarySealNumber, setSecondarySealNumber] = useState("");
  const [checked, setChecked] = useState([false, false, false, false]);
  const [carrierName, setCarrierName] = useState("");
  const [economicNumber, setEconomicNumber] = useState("");
  const [usPlates, setUSPlates] = useState("");
  const [mexicanPlates, setMexicanPlates] = useState("");
  const [scac, setSCAC] = useState("");
  const [caat, setCAAT] = useState("");
  const [boxDimension, setBoxDimension] = useState("");
  const [numberOfDoors, setNumberOfDoors] = useState("");
  const [state, setState] = useState("");
  const [driverName, setDriverName] = useState("");

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setControlNumber("");
      setProcessedBy("");
      setPrimarySealNumber("");
      setSecondarySealNumber("");
      setChecked([false, false, false]);
      setCarrierName("");
      setEconomicNumber("");
      setUSPlates("");
      setMexicanPlates("");
      setSCAC("");
      setCAAT("");
      setBoxDimension("");
      setNumberOfDoors("");
      setState("");
      setDriverName("");
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      onComplete({
        controlNumber,
        processedBy,
        primarySealNumber: showSealNumbers ? primarySealNumber : null,
        secondarySealNumber: showSealNumbers ? secondarySealNumber : null,
        carrierName,
        economicNumber,
        usPlates,
        mexicanPlates,
        scac,
        caat,
        boxDimension,
        numberOfDoors,
        state,
        driverName
      });
      setControlNumber("");
      setProcessedBy("");
      setPrimarySealNumber("");
      setSecondarySealNumber("");
      setChecked([false, false, false]);
      setCarrierName("");
      setEconomicNumber("");
      setUSPlates("");
      setMexicanPlates("");
      setSCAC("");
      setCAAT("");
      setBoxDimension("");
      setNumberOfDoors("");
      setState("");
      setDriverName("");
    }
  };

  const handleControlNumberChange = ({ target: { value } }) => {
    setControlNumber(value);
  };

  const handleProcessedByChange = ({ target: { value } }) => {
    setProcessedBy(value);
  };

  const handlePrimarySealNumberChange = ({ target: { value } }) => {
    setPrimarySealNumber(value);
  };

  const handleSecondarySealNumberChange = ({ target: { value } }) => {
    setSecondarySealNumber(value);
  };

  const handleCarrierNameChange = ({ target: { value } }) => {
    setCarrierName(value);
  };

  const handleEconomicNumberChange = ({ target: { value } }) => {
    setEconomicNumber(value);
  };

  const handleUSPlatesChange = ({ target: { value } }) => {
    setUSPlates(value);
  };

  const handleMexicanPlatesChange = ({ target: { value } }) => {
    setMexicanPlates(value);
  };

  const handleSCACChange = ({ target: { value } }) => {
    setSCAC(value);
  };

  const handleCAATChange = ({ target: { value } }) => {
    setCAAT(value);
  };

  const handleBoxDimensionChange = ({ target: { value } }) => {
    setBoxDimension(value);
  };

  const handleNumberOfDoorsChange = ({ target: { value } }) => {
    setNumberOfDoors(value);
  };

  const handleStateChange = ({ target: { value } }) => {
    setState(value);
  };

  const handleDriverNameChange = ({ target: { value } }) => {
    setDriverName(value);
  };

  return (
    <Dialog
      aria-describedby={`Set ${controlNumberLabel || "Control Number"}`}
      aria-labelledby={`Set ${controlNumberLabel || "Control Number"} Modal`}
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Set {controlNumberLabel || "Control Number"}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {!showSealNumbers && (
            <TextField
              label={controlNumberLabel || "Control Number"}
              value={controlNumber}
              onChange={handleControlNumberChange}
              fullWidth
              required
              style={{ marginBottom: "1em" }}
            />
          )}
          {showSealNumbers && (
            <>
              <TextField
                label="Primary Seal Number"
                value={primarySealNumber}
                onChange={handlePrimarySealNumberChange}
                fullWidth
                required
                style={{ marginBottom: "1em" }}
              />
              <TextField
                label="Secondary Seal Number"
                value={secondarySealNumber}
                onChange={handleSecondarySealNumberChange}
                fullWidth
                required
                style={{ marginBottom: "1em" }}
              />
            </>
          )}
          <Typography variant="h6" component="h6" gutterBottom>
            I certify that we have:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="success"
                checked={checked[0]}
                onChange={({ target: { checked: check } }) => {
                  const newChecked = [...checked];
                  newChecked.splice(0, 1, check);
                  setChecked(newChecked);
                }}
              />
            }
            label="Put labels on all pallets"
          />
          {!showSealNumbers && (
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={checked[1]}
                  onChange={({ target: { checked: check } }) => {
                    const newChecked = [...checked];
                    newChecked.splice(1, 1, check);
                    setChecked(newChecked);
                  }}
                />
              }
              label={`Have entered a valid customs ${
                controlNumberLabel
                  ? controlNumberLabel.toLowerCase()
                  : "control number"
              }`}
            />
          )}
          {showSealNumbers && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={checked[1]}
                    onChange={({ target: { checked: check } }) => {
                      const newChecked = [...checked];
                      newChecked.splice(1, 1, check);
                      setChecked(newChecked);
                    }}
                  />
                }
                label={"Accurately entered the primary seal number"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={checked[2]}
                    onChange={({ target: { checked: check } }) => {
                      const newChecked = [...checked];
                      newChecked.splice(2, 1, check);
                      setChecked(newChecked);
                    }}
                  />
                }
                label={"Accurately entered the secondary seal number"}
              />
            </>
          )}
          <TextField
            label="Your Name"
            value={processedBy}
            onChange={handleProcessedByChange}
            fullWidth
            required
            style={{ marginTop: "1em", marginBottom: "1em" }}
          />
          {showSealNumbers && (
          <>
            <TextField
              label="Carrier Name"
              value={carrierName}
              onChange={handleCarrierNameChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="Economic Number"
              value={economicNumber}
              onChange={handleEconomicNumberChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="US Plates"
              value={usPlates}
              onChange={handleUSPlatesChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="Mexican Plates"
              value={mexicanPlates}
              onChange={handleMexicanPlatesChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="SCAC"
              value={scac}
              onChange={handleSCACChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="CAAT"
              value={caat}
              onChange={handleCAATChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="Box Dimension"
              value={boxDimension}
              onChange={handleBoxDimensionChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="Number of Doors"
              value={numberOfDoors}
              onChange={handleNumberOfDoorsChange}
              fullWidth
              required
              type="number"
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="State"
              value={state}
              onChange={handleStateChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
            <TextField
              label="Driver Name"
              value={driverName}
              onChange={handleDriverNameChange}
              fullWidth
              required
              style={{ marginTop: "1em", marginBottom: "1em" }}
            />
          </>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={
            (controlNumber.trim().length === 0 && !showSealNumbers) ||
            processedBy.trim().length === 0 ||
            checked.slice(0, showSealNumbers ? 3 : 2).filter(check => !check)
              .length > 0 ||
            (showSealNumbers && primarySealNumber.trim().length === 0)
          }
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetControlNumberModal;
