// @flow

import type { HighDefinitionDigitalStatus } from "../types/HighDefinitionDigitalStatus";

const getLabelForHighDefinitionDigitalStatus = (status: HighDefinitionDigitalStatus): string => {
  switch (status) {
    case "COMPLETE":
      return "Complete";
    case "FILM_PRINTED":
      return "Film Printed";
    case "IN_PROGRESS":
      return "In Progress";
    case "LOCATED":
      return "Located";
    case "NOT_READY":
      return "Not Ready";
    case "NOT_APPLICABLE":
      return "Not Applicable";
    case "ON_HOLD":
      return "On Hold";
    case "READY":
      return "Ready";
    case "QUEUED":
      return "Queued";
    case "STAGED":
      return "Staged";
    case "TRIMMED":
      return "Trimmed";
    case "PAUSED":
      return "Paused";
    default:
      return status;
  }
};

export { getLabelForHighDefinitionDigitalStatus };
