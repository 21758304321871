// @flow

import { DateTime } from "luxon";
import { Link } from "found";
import { Page, StyleSheet } from "@react-pdf/renderer";
import { compose, setDisplayName } from "recompose";
import {
  createCustomsShipmentPalletCarton,
  deleteCustomsShipmentPalletCarton
} from "../../graph";
import { debounce } from "debounce";
import { withStyles } from "@mui/styles";
import BarcodeReader from "react-barcode-reader";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CustomsLabel from "../CustomsLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PrintablePDFDocument from "../PrintablePDFDocument";
import React, { useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import withSnackbar from "../withSnackbar";

const pdfStyles = StyleSheet.create({
  page: {
    padding: 8
  }
});

const styles = theme => ({
  tableCellRoot: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("CustomsShipmentPalletDetailScreen"),

  withSnackbar,

  withStyles(styles),

  createCustomsShipmentPalletCarton,

  deleteCustomsShipmentPalletCarton
);

const debouncePrint = debounce(
  ({ print }) => {
    print();
  },
  2000,
  false
);

const CustomsShipmentPalletDetailScreen = ({
  data: { customsShipmentPallet, refetch },
  direction,
  createCustomsShipmentPalletCarton,
  deleteCustomsShipmentPalletCarton,
  onBarcodeScan,
  onRequestManualAddCarton,
  classes,
  suffix,
  includePrintMethodInSuffix,
  columns,
  showErrorSnackbar,
  showSuccessSnackbar,
  hidePrintCartonLabel = false
}) => {
  const [cartonLabelToPrint, setCartonLabelToPrint] = useState(null);
  const newCartonRef = useRef(null);

  const handleOnRender = () => {
    console.log("handleOnRender");
    console.log(newCartonRef.current);
    if (newCartonRef.current) {
      debouncePrint(newCartonRef.current);
    }
  };

  const handleBarcodeScan = async barcode => {
    setCartonLabelToPrint(null);
    if (onBarcodeScan) {
      const result = await onBarcodeScan(barcode);
      if (result) {
        const { label, ...data } = result;
        if (label) {
          setCartonLabelToPrint(label);
        }
        if (Object.keys(data).length > 0) {
          createCustomsShipmentPalletCarton({
            variables: {
              ...data,
              customsShipmentPalletId: customsShipmentPallet.id
            }
          });
        }
      }
    }
  };

  const handleManualAddCarton = async () => {
    setCartonLabelToPrint(null);
    if (onRequestManualAddCarton) {
      const result = await onRequestManualAddCarton();
      if (result) {
        const { label, ...data } = result;
        setCartonLabelToPrint(label);
        createCustomsShipmentPalletCarton({
          variables: {
            ...data,
            customsShipmentPalletId: customsShipmentPallet.id
          }
        });
      }
    }
  };

  const handleDeletePalletCartonClick = id => {
    if (window.confirm("Are you sure you want to delete this carton?")) {
      deleteCustomsShipmentPalletCarton({
        variables: { customsShipmentPalletCartonId: id }
      }).then(
        ({
          data: {
            deleteCustomsShipmentPalletCarton: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Carton successfully deleted!");
            refetch();
          } else {
            showErrorSnackbar(errors.customsShipmentPalletCartonId.join(","));
          }
        }
      );
    }
  };

  const handleBarcodeError = () => {
    setCartonLabelToPrint(null);
    alert("Error scanning barcode");
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/apps/customs/${direction}`}>Shipments</Link>
            <Link
              to={`/apps/customs/${direction}/shipment/${customsShipmentPallet.shipment.id}`}
            >
              Shipment{" "}
              {DateTime.fromISO(
                customsShipmentPallet.shipment.createdAt
              ).toLocaleString(DateTime.DATETIME_MED)}
            </Link>
            <Typography color="text.primary">Pallet</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4">
            Pallet{" "}
            {DateTime.fromISO(customsShipmentPallet.createdAt).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            aria-label="Create New Pallet"
            variant="contained"
            onClick={handleManualAddCarton}
            style={{ marginRight: "1em" }}
          >
            Add Carton
          </Button>
          <PrintablePDFDocument label="Print Pallet Label">
            <Page
              size={[6.5 * 72, 10 * 72]}
              orientation="landscape"
              style={pdfStyles.page}
            >
              <CustomsLabel
                data={customsShipmentPallet.lineItems}
                suffix={suffix}
                includePrintMethodInSuffix={includePrintMethodInSuffix}
              />
            </Page>
          </PrintablePDFDocument>
        </Grid>
      </Grid>
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Typography variant="h5" gutterBottom>
          Summary
        </Typography>
        <Typography variant="h6" gutterBottom>
          Total Cartons: {customsShipmentPallet.cartons.length}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Total Qty:{" "}
          {customsShipmentPallet.lineItems.reduce(
            (accumulator, { quantity }) => accumulator + quantity,
            0
          )}
        </Typography>
      </div>
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Typography variant="h6" gutterBottom>
          Cartons ({customsShipmentPallet.cartons.length})
        </Typography>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root: classes.tableCellRoot
                  }}
                  align="center"
                >
                  Carton #
                </TableCell>
                {columns &&
                  columns.map(({ label }) => (
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      align="center"
                    >
                      {label}
                    </TableCell>
                  ))}
                <TableCell
                  classes={{
                    root: classes.tableCellRoot
                  }}
                  align="center"
                >
                  Qty
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.tableCellRoot
                  }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customsShipmentPallet.cartons.map((carton, index) => (
                <>
                  <TableRow
                    key={`summary-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    {columns &&
                      columns.map(({ value }) => (
                        <TableCell
                          classes={{
                            root: classes.tableCellRoot
                          }}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {value(carton)}
                        </TableCell>
                      ))}
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      align="center"
                    >
                      {carton.lineItems.reduce(
                        (accumulator, { quantity }) => accumulator + quantity,
                        0
                      )}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      align="center"
                    >
                      <Button
                        onClick={() => handleDeletePalletCartonClick(carton.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <BarcodeReader onScan={handleBarcodeScan} onError={handleBarcodeError} />
      {!hidePrintCartonLabel && cartonLabelToPrint && (
        <div style={{ display: "none" }}>
          <PrintablePDFDocument
            ref={newCartonRef}
            onRender={handleOnRender}
            label="Print Carton Label"
          >
            {cartonLabelToPrint}
          </PrintablePDFDocument>
        </div>
      )}
    </Container>
  );
};

export default enhancer(CustomsShipmentPalletDetailScreen);
