// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForOrderMethodStatus } from "../../../../../helpers";
import {
  markOrderAsNoLongerOnHold,
  markOrderAsVinylCompleted,
  markOrderAsVinylCut
} from "../../../../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import PlaceOrderOnHoldModal from "../../../../../components/PlaceOrderOnHoldModal";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +canViewerCompleteVinyl: boolean,
    +canViewerCutVinyl: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +id: string,
    +vinylStatus: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean
|};

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("VinylOrderStatus"),

  markOrderAsNoLongerOnHold,

  markOrderAsVinylCompleted,

  markOrderAsVinylCut,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(
    ({ isMutatingOrder: false, isPlacingOrderOnHold: false }: State),
    {
      setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
        isMutatingOrder
      }),

      setIsPlacingOrderOnHold: () => (isPlacingOrderOnHold: boolean) => ({
        isPlacingOrderOnHold
      })
    }
  ),

  withHandlers({
    handleHoldButtonClick: ({ setIsPlacingOrderOnHold }) => () => {
      setIsPlacingOrderOnHold(true);
    },

    handlePlaceOrderOnHoldModalRequestClose: ({
      setIsPlacingOrderOnHold
    }) => () => {
      setIsPlacingOrderOnHold(false);
    },

    handleRemoveHoldButtonClick: ({
      markOrderAsNoLongerOnHold,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsNoLongerOnHold({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsNoLongerOnHold: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleVinylCompletedButtonClick: ({
      markOrderAsVinylCompleted,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsVinylCompleted({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsVinylCompleted: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleCutButtonClick: ({
      markOrderAsVinylCut,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsVinylCut({ variables: { orderId } })
        .then(({ data: { markOrderAsVinylCut: { succeeded, errors } } }) => {
          if (succeeded) {
            showSuccessSnackbar(`The order has been updated`);
          } else {
            showErrorSnackbar(
              `Something went wrong: ${errors.orderId.join(", ")}`
            );
          }
        })
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    }
  })
);

const VinylOrderStatus = ({
  classes,
  handleVinylCompletedButtonClick,
  handleHoldButtonClick,
  handlePlaceOrderOnHoldModalRequestClose,
  handleRemoveHoldButtonClick,
  handleCutButtonClick,
  isMutatingOrder,
  isPlacingOrderOnHold,
  order: {
    canViewerCompleteVinyl,
    canViewerCutVinyl,
    canViewerPlaceOnHold,
    canViewerRemoveHold,
    id: orderId,
    vinylStatus
  }
}) => (
  <div>
    <div className={classes.spaceBetweenContainer}>
      <JobStatusIcon status={vinylStatus} />
      <div className={classes.jobStatus}>
        <Typography variant="h6">Vinyl</Typography>
        <Typography variant="body2">
          {getLabelForOrderMethodStatus(vinylStatus)}
        </Typography>
      </div>
    </div>
    {isMutatingOrder ? (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    ) : (
      <div className={classes.buttonContainer}>
        {canViewerCutVinyl && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCutButtonClick}
          >
            Cut
          </Button>
        )}
        {canViewerCompleteVinyl && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleVinylCompletedButtonClick}
          >
            Vinyl Complete
          </Button>
        )}
        {canViewerPlaceOnHold && (
          <Button
            color="secondary"
            onClick={handleHoldButtonClick}
            variant="contained"
          >
            Hold
          </Button>
        )}
        {canViewerRemoveHold && (
          <Button
            color="secondary"
            onClick={handleRemoveHoldButtonClick}
            variant="contained"
          >
            Remove Hold
          </Button>
        )}
      </div>
    )}
    <PlaceOrderOnHoldModal
      isOpen={isPlacingOrderOnHold}
      onRequestClose={handlePlaceOrderOnHoldModalRequestClose}
      orderId={orderId}
    />
  </div>
);

export default enhancer(VinylOrderStatus);
