// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { recordOrderProductionJobBoxLocation } from "../../../graph";
import { recordStockContainerLocation } from "../../../graph";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  currentLocation: string,
  isLoading: boolean
|};

const enhancer: HOC<*, *> = compose(
  setDisplayName("BulkBoxLocationSetterApp"),

  withRouter,

  withSnackbar,

  recordStockContainerLocation,

  recordOrderProductionJobBoxLocation,

  withStateHandlers(
    ({
      isLoading: false,
      currentLocation: null
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),
      setCurrentLocation: () => (currentLocation: string) => ({
        currentLocation
      })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl || "/apps");
      },

    handleBarcodeError:
      ({ showErrorSnackbar, setIsLoading }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
        setIsLoading(false);
      },

    handleBarcodeScan:
      ({
        recordStockContainerLocation,
        recordOrderProductionJobBoxLocation,
        setIsLoading,
        showErrorSnackbar,
        showSuccessSnackbar,
        setCurrentLocation,
        currentLocation
      }) =>
      barcode => {
        try {
          const data = JSON.parse(barcode);
          const { variant, type, id, name } = data;
          if (variant === "LOCATION" && type.toLowerCase() === "box" && name) {
            setCurrentLocation(name);
          } else if (variant === "OBJECT" && type === "StockContainer" && id) {
            if (!currentLocation) {
              showErrorSnackbar("No Location Set");
              return;
            }
            setIsLoading(true);
            recordStockContainerLocation({
              variables: { location: currentLocation, stockContainerId: id }
            })
              .then(
                ({
                  data: {
                    recordStockContainerLocation: { succeeded, errors }
                  }
                }) => {
                  if (succeeded) {
                    showSuccessSnackbar("Box location has been recorded.");
                    playAudioForSuccess();
                  } else {
                    showErrorSnackbar(errors.stockContainerId.join(", "));
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else if (
            variant === "OBJECT" &&
            type === "OrderProductionJob" &&
            id
          ) {
            recordOrderProductionJobBoxLocation({
              variables: { location: currentLocation, orderProductionJobId: id }
            })
              .then(
                ({
                  data: {
                    recordOrderProductionJobBoxLocation: { succeeded, errors }
                  }
                }) => {
                  if (succeeded) {
                    showSuccessSnackbar(
                      "Order production job box location has been recorded."
                    );
                    playAudioForSuccess();
                  } else {
                    showErrorSnackbar(errors.orderProductionJobId.join(", "));
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              });
          }
        } catch {
          setIsLoading(false);
          showErrorSnackbar("Error Scanning Barcode");
        }
      }
  })
);

const BulkBoxLocationSetterApp = ({
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  isLoading,
  currentLocation,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Bulk Box Location Setter"
      onRequestBack={handleAppHeaderRequestBack}
      appBarBackgroundColor={appBarBackgroundColor}
    />
    {isLoading ? (
      <PendingStatusView status="Loading" />
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        {false && <CenteredSpinner />}
        {currentLocation && (
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ flexGrow: 1, marginTop: "10em" }}
          >
            Current Location: {currentLocation}
            <br />
            Scan Container or new location
          </Typography>
        )}
        {!currentLocation && (
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ flexGrow: 1, marginTop: "10em" }}
          >
            Scan Location
          </Typography>
        )}
      </div>
    )}
  </div>
);

export default enhancer(BulkBoxLocationSetterApp);
