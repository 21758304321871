// @flow

import { gql } from "@apollo/client";
import { fragments as orderAppProductionJobListItemFragments } from "../../../application/apps/OrderApp/components/OrderAppProductionJobList/OrderAppProductionJobListItem/graph";

export default {
  order: gql`
    fragment FulfillmentLocation_order on Order {
      id
      isInHouse
      productionStationAssignments {
        ...OrderAppProductionJobListItem_productionStationAssignments
      }
      vendor {
        id
        organization {
          id
          name
        }
      }
    }
    ${orderAppProductionJobListItemFragments.productionStationAssignments}
  `
};
