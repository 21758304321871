// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { fragments as screenPrintingOrderStatusFragments } from "../ScreenPrintingOrderStatus/graph";
import { withRouter } from "found";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import ScreenPrintingOrderStatus from "../ScreenPrintingOrderStatus";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ScreenPrintingOrderDetail"),

  withRouter,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withHandlers({
    handleOrderDetailRequestClose: ({ router }) => () => {
      router.push("/apps/production/screen-printing");
    }
  })
);

const ScreenPrintingOrderDetail = ({ data, handleOrderDetailRequestClose }) => (
  <OrderDetailWithStatusHeader
    errorMessage={data.error ? data.error.message : null}
    loading={data.loading}
    onRequestClose={handleOrderDetailRequestClose}
    order={data.order}
    orderStatusView={
      data && data.order ? (
        <ScreenPrintingOrderStatus
          order={filter(screenPrintingOrderStatusFragments.order, data.order)}
        />
      ) : (
        <CircularProgress />
      )
    }
    shouldShowClaimButton={data && data.order && !!data.order.isInHouse}
    shouldShowCustomerArtworkWithNotes={false}
    shouldShowInkColors={false}
    title="Screen Printing"
    viewer={data.viewer}
  />
);

export default enhancer(ScreenPrintingOrderDetail);
