// @flow

import { gql } from "@apollo/client";

export default gql`
  query customsShipmentPallet($id: ID!) {
    customsShipmentPallet: node(id: $id) {
      ... on CustomsShipmentPallet {
        id
        createdAt
        lineItems {
          identifier
          countryOfOrigin
          embroidery
          quantity
          description
        }
        shipment {
          id
          createdAt
        }
        cartons {
          id
          lineItems {
            id
            identifier
            countryOfOrigin
            embroidery,
            quantity
            description
          }
          shippingPackage {
            trackingNumber
          }
        }
      }
    }
  }
`;
