// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as fulfillmentLocationFragments } from "../../../components/FulfillmentLocation/graph";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as headerToolbarFragments } from "./components/HeaderToolbar/graph";
import { fragments as notesWrapperFragments } from "../../../components/NotesWrapper/graph";
import { fragments as orderAppProductionJobListFragments } from "./components/OrderAppProductionJobList/graph";
import { fragments as orderFulfillmentSummaryFragments } from "../../../components/OrderFulfillmentSummary/graph";
import { fragments as orderPricingSummaryFragments } from "../../../components/OrderPricingSummary/graph";
import { fragments as productionJobTabsFragments } from "./components/ProductionJobTabs/graph";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import FulfillmentLocation from "../../../components/FulfillmentLocation";
import HeaderToolbar from "./components/HeaderToolbar";
import NotesWrapper from "../../../components/NotesWrapper";
import OrderAppProductionJobList from "./components/OrderAppProductionJobList";
import OrderFulfillmentSummary from "../../../components/OrderFulfillmentSummary";
import OrderPricingSummary from "../../../components/OrderPricingSummary";
import OrderStatusHistorySummary from "./components/OrderStatusHistorySummary";
import PendingStatusView from "../../../components/PendingStatusView";
import ProductionJobTabs from "./components/ProductionJobTabs";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +detailContainer: string,
    +largeMargin: string,
    +moduleSpacing: string,
    +summaryContainer: string
  |},
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderApp"),
  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),
  withHandlers({
    handleHeaderToolbarBackButtonClick: () => () => {
      alert("handleHeaderToolbarBackButtonClick");
    }
  })
);

const styles = theme => ({
  detailContainer: {
    backgroundColor: theme.palette.secondary.light,
    flex: "2 2 70%",
    minWidth: "600px"
  },

  largeMargin: {
    margin: theme.spacing(2)
  },

  moduleSpacing: {
    marginBottom: theme.spacing(2)
  },

  notesTitle: {
    color: "red"
  },

  titleSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  },

  summaryContainer: {
    backgroundColor: theme.palette.secondary.light,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    display: "flex",
    flex: "1 1 30%",
    flexDirection: "column",
    minWidth: "350px",
    padding: theme.spacing(2)
  }
});

const OrderApp = ({ classes, data, handleHeaderToolbarBackButtonClick }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {!data ? (
      <div className={classes.largeMargin}>
        <PendingStatusView status="Waiting" />
      </div>
    ) : data.loading ? (
      <div className={classes.largeMargin}>
        <PendingStatusView status="Loading" />
      </div>
    ) : data.error ? (
      <Typography align="center" variant="body2" color="error">
        There was an error while attempting to load the order
      </Typography>
    ) : !data.order ? (
      <Typography align="center" variant="body2" color="error">
        Unfortunately, that order could not be located
      </Typography>
    ) : (
      <div>
        <HeaderToolbar
          onBackButtonClicked={handleHeaderToolbarBackButtonClick}
          order={filter(headerToolbarFragments.order, data.order)}
        />
        <div style={{ display: "flex" }}>
          <div className={classes.summaryContainer}>
            <div className={classes.moduleSpacing}>
              <OrderFulfillmentSummary
                order={filter(
                  orderFulfillmentSummaryFragments.order,
                  data.order
                )}
                title="Order Fulfillment Summary"
              />
              <OrderPricingSummary
                order={filter(orderPricingSummaryFragments.order, data.order)}
              />
              <React.Fragment>
                <Typography variant="h6" className={classes.titleSpacing}>
                  Order Notes Summary
                </Typography>
                <Card>
                  <NotesWrapper
                    order={filter(notesWrapperFragments.order, data.order)}
                  />
                </Card>
              </React.Fragment>
            </div>
            <div className={classes.moduleSpacing}>
              <FulfillmentLocation
                order={filter(fulfillmentLocationFragments.order, data.order)}
              />
              <OrderAppProductionJobList
                order={filter(
                  orderAppProductionJobListFragments.order,
                  data.order
                )}
              />
              <Typography variant="h6" className={classes.titleSpacing}>
                Order Status History
              </Typography>
              <OrderStatusHistorySummary orderId={data.order.id} />
            </div>
          </div>
          <div className={classes.detailContainer}>
            <ProductionJobTabs
              productionJobs={data.order.productionJobs.map(productionJob =>
                filter(productionJobTabsFragments.productionJobs, productionJob)
              )}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default withStyles(styles)(enhancer(OrderApp));
