// @flow

import { gql } from "@apollo/client";
import { fragments as localInventoryTableFragments } from "../components/LocalInventoryTable/graph";

export default gql`
  query OrderProductionJobsToBeReceivedFromLocalInventory(
    $first: Int!
    $after: String
    $filters: OrderProductionJobsToBeReceivedFromLocalInventoryFilters
  ) {
    orderProductionJobsToBeReceivedFromLocalInventory(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...LocalInventoryTable_productionJob
          order {
            id
            isResolution
            directToGarmentExpressEligible
            highDefinitionDigitalExpressEligible
            isArtworkApproved
            priority
            express
            includeCatalogInShipment
            shipMissCosts {
              date
              cost {
                formatted
              }
            }
          }
        }
      }
      productionDates(filters: $filters)
    }
    businessUnits {
      id
      name
    }
  }
  ${localInventoryTableFragments.productionJob}
`;
