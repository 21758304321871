// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import Typography from "@mui/material/Typography";

import { fragments as customerArchiveHeaderFragments } from "./components/CustomerArchiveHeader/graph";
import { fragments as customerArchiveListFragments } from "./components/CustomerArchiveList/graph";
import { query } from "./graph";
import CustomerArchiveHeader from "./components/CustomerArchiveHeader";
import CustomerArchiveList from "./components/CustomerArchiveList";
import PendingStatusView from "../../../components/PendingStatusView";

import type { HOC } from "recompose";

const ORDERS_PER_PAGE = 5;

const enhancer: HOC<*, *> = compose(
  setDisplayName("CustomerArchiveApp"),
  graphql(query, {
    options: ({ customerId }) => {
      return {
        variables: { customerId: customerId, first: ORDERS_PER_PAGE }
      };
    }
  }),
  withHandlers({
    handleCustomerArchiveListRequestLoadMore: ({ data }) => () => {
      data.fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.customer.orders.edges;
          const pageInfo = fetchMoreResult.customer.orders.pageInfo;

          return newEdges.length
            ? {
                customer: {
                  __typename: previousResult.customer.__typename,
                  ...previousResult.customer,
                  orders: {
                    __typename: previousResult.customer.orders.__typename,
                    edges: [
                      ...previousResult.customer.orders.edges,
                      ...newEdges
                    ],
                    pageInfo
                  }
                }
              }
            : previousResult;
        },
        variables: {
          cursor: data.customer.orders.pageInfo.endCursor
        }
      });
    }
  })
);
const CustomerArchiveApp = ({
  data,
  handleCustomerArchiveListRequestLoadMore
}) => (
  <div>
    {!data ? (
      <div>
        <PendingStatusView status="Waiting" />
      </div>
    ) : data.loading ? (
      <div>
        <PendingStatusView status="Loading" />
      </div>
    ) : data.error ? (
      <Typography align="center" variant="body2" color="error">
        There was an error while attempting to load the customer
      </Typography>
    ) : !data.customer ? (
      <Typography align="center" variant="body2" color="error">
        Unfortunately, that customer could not be located
      </Typography>
    ) : (
      <React.Fragment>
        <CustomerArchiveHeader
          customer={filter(
            customerArchiveHeaderFragments.customer,
            data.customer
          )}
        />
        <CustomerArchiveList
          onRequestLoadMore={handleCustomerArchiveListRequestLoadMore}
          orders={filter(
            customerArchiveListFragments.orders,
            data.customer.orders.edges.map(edge => edge.node)
          )}
        />
      </React.Fragment>
    )}
  </div>
);

export default enhancer(CustomerArchiveApp);
