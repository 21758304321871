// @flow
import * as React from "react";
import Typography from "@mui/material/Typography";

type Props = {|
  +note: string
|};

const UnformattedNote = ({ note }: Props) => (
  <React.Fragment>
    {note.split("\n").map((line, index) => (
      <Typography key={index}>{line}</Typography>
    ))}
  </React.Fragment>
);

export default UnformattedNote;
