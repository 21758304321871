// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { Link } from "found";
import { ThemeContextConsumer } from "../../application/themeContext";
import { compose, setDisplayName, withHandlers, withProps } from "recompose";
import { filter } from "graphql-anywhere";
import { getLogoForBrandUrl } from "../../helpers";
import { fragments as productionMethodIconSetFragments } from "../../components/ProductionMethodIconSet/graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CustomerStatusChip from "../../../src/application/apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import NoBrandTagIcon from "../../assets/no-brand-tag.png";
import Paper from "@mui/material/Paper";
import PriorityChip from "../../components/PriorityChip";
import ProductionMethodIconSet from "../../components/ProductionMethodIconSet";
import ProductionStatusLabel from "../../components/ProductionStatusLabel";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";

import pluralize from "pluralize";

import type { BrandUrl } from "../../types/BrandUrl";
import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +bottomContainer: string,
    +container: string,
    +customerArchiveLink: string,
    +divider: string,
    +flexContainer: string,
    +logo: string,
    +orderStatusView: string,
    +paperContainer: string,
    +resolutionSnackbar: string,
    +resolutionSnackbarIcon: string,
    +resolutionSnackbarMessage: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +topContainer: string
  |},
  +isArtApp: boolean,
  +onRequestClose: () => void,
  +order: {|
    +dueBy: {|
      +date: string
    |},
    +id: string,
    +isArtworkApproved: boolean,
    +isInHouse: boolean,
    +isResolution: boolean,
    +orderNumber: string,
    +primaryCustomer: {|
      +businessUnit: {
        +name: BrandUrl
      },
      +emailAddress: ?string,
      +id: string,
      +insideBrandTagging: boolean,
      +name_orderStatusAppBar: string,
      +primaryPhoneNumber: ?{|
        +formatted: string
      |},
      +status: string,
      +totalOrders: number
    |},
    +priority: string,
    +productionDate: string,
    +productionJobs: $ReadOnlyArray<{|
      +customizationAreas: $ReadOnlyArray<{|
        +method: string
      |}>
    |}>,
    +productionStationAssignments: $ReadOnlyArray<{|
      +employee: {|
        +employee_orderStatusAppBar: string
      |}
    |}>,
    +salesRepresentatives: $ReadOnlyArray<{|
      +salesRepresentative_orderStatusAppBar: string
    |}>,
    +stockStatusLabel: string
  |},
  +orderStatusView: React.Element<any>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderStatusHeader"),
  withProps(
    ({
      order,
      order: {
        productionStationAssignments,
        dueBy,
        primaryCustomer,
        primaryCustomer: { businessUnit },
        salesRepresentatives
      },
      ...props
    }) => {
      const assigneesList = productionStationAssignments.map(
        assignment =>
          `${assignment.employee.employee_orderStatusAppBar}${
            assignment.productionStation
              ? ` - ${assignment.productionStation.name}`
              : ""
          }`
      );

      const dueDate = DateTime.fromISO(dueBy.date).toLocaleString(
        DateTime.DATE_SHORT
      );

      const brandUrl = businessUnit.name;
      const salesRep = salesRepresentatives.map(
        salesRepresentative =>
          salesRepresentative.salesRepresentative_orderStatusAppBar
      );
      return {
        ...props,
        order: {
          ...order,
          brandUrl,
          primaryCustomer: { ...primaryCustomer },
          assigneesList,
          dueDate,
          salesRep
        }
      };
    }
  ),
  withHandlers({
    handleCloseButtonClick:
      ({ onRequestClose }) =>
      () => {
        onRequestClose();
      }
  })
);

const styles = theme => ({
  brandLogo: {
    width: 15
  },
  container: {
    display: "flex",
    flex: "1 1 100%"
  },

  closeIconContainer: {
    marginLeft: "auto"
  },

  customerArchiveLink: {
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: "underline",
    textTransform: "none"
  },

  divider: {
    width: 1,
    backgroundColor: theme.palette.grey[500],
    margin: theme.spacing(3)
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  logo: {
    width: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 20
  },

  orderStatusView: {
    marginTop: theme.spacing(1)
  },

  paperContainer: {
    color: theme.palette.mode === "dark" && "#fff",
    backgroundColor: "inherit",
    margin: 0,
    padding: theme.spacing(2)
  },

  resolutionSnackbar: {
    backgroundColor: "#B39DDB",
    maxWidth: "100%"
  },

  resolutionSnackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    color: "#000000"
  },

  resolutionSnackbarMessage: {
    display: "flex",
    alignItems: "center",
    color: "#000000"
  },

  canceledDialog: {
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    maxWidth: "100%"
  },

  canceledSnackbar: {
    backgroundColor: theme.palette.error.dark,
    maxWidth: "100%"
  },

  canceledSnackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },

  canceledSnackbarMessage: {
    display: "flex",
    alignItems: "center"
  },

  artworkNotApprovedSnackbar: {
    backgroundColor: theme.palette.error.dark,
    maxWidth: "100%"
  },

  artworkNotApprovedSnackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },

  artworkNotApprovedSnackbarMessage: {
    display: "flex",
    alignItems: "center"
  },

  notInHouseSnackbar: {
    backgroundColor: theme.palette.error.dark,
    maxWidth: "100%"
  },

  notInHouseSnackbarIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },

  notInHouseSnackbarMessage: {
    display: "flex",
    alignItems: "center"
  },

  spaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  }
});

const OrderStatusHeader = ({
  classes,
  handleCloseButtonClick,
  order,
  isArtApp,
  order: {
    id,
    assigneesList,
    isArtworkApproved,
    isCanceled,
    isInHouse,
    isResolution,
    brandUrl,
    dueDate,
    orderNumber,
    primaryCustomer: {
      insideBrandTagging,
      name_orderStatusAppBar,
      totalOrders,
      status,
      emailAddress,
      primaryPhoneNumber
    },
    priority,
    productionDate,
    salesRep,
    stockStatusLabel,
    vendor
  },
  orderStatusView
}) => {
  console.log("isCanceled");
  console.log(isCanceled);

  const [cancelDialogIsOpen, setCancelDialogIsOpen] =
    React.useState(isCanceled);

  console.log("cancelDialogIsOpen");
  console.log(cancelDialogIsOpen);

  const handleCancelDialogClose = () => {
    setCancelDialogIsOpen(false);
  };

  return (
    <React.Fragment>
      <Paper square={true} className={classes.paperContainer}>
        <Helmet>
          <title>
            {name_orderStatusAppBar} - {orderNumber}
          </title>
        </Helmet>
        {isResolution && (
          <SnackbarContent
            className={classes.resolutionSnackbar}
            message={
              <span className={classes.resolutionSnackbarMessage}>
                <WarningIcon className={classes.resolutionSnackbarIcon} />
                Resolution
              </span>
            }
          />
        )}
        {isCanceled && (
          <SnackbarContent
            className={classes.canceledSnackbar}
            message={
              <span className={classes.canceledSnackbarMessage}>
                <WarningIcon className={classes.canceledSnackbarIcon} />
                ORDER CANCELLED - DO NOT PRINT & GIVE GOODS TO MANAGER FOR
                RETURN
              </span>
            }
          />
        )}
        {!isArtworkApproved && (
          <SnackbarContent
            className={classes.artworkNotApprovedSnackbar}
            message={
              <span className={classes.artworkNotApprovedSnackbar}>
                <WarningIcon
                  className={classes.artworkNotApprovedSnackbarIcon}
                />
                Artwork is NOT approved! Do Not Print!!!
              </span>
            }
          />
        )}
        {!isCanceled && !isInHouse && !vendor.internal && (
          <SnackbarContent
            className={classes.notInHouseSnackbar}
            message={
              <span className={classes.notInHouseSnackbarMessage}>
                <WarningIcon className={classes.notInHouseSnackbarIcon} />
                Order Assigned to {vendor.organization.name}! Do Not Print!!!
              </span>
            }
          />
        )}
        {!isCanceled && !isInHouse && vendor.internal && (
          <SnackbarContent
            message={<span>Order Assigned to {vendor.organization.name}</span>}
          />
        )}
        <div>
          <div className={classes.container}>
            <div>
              <dl className={classes.flexContainer}>
                <div
                  style={{
                    height: "34px",
                    maxWidth: "95px",
                    marginRight: "8px"
                  }}
                >
                  <ThemeContextConsumer>
                    {context => (
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%"
                        }}
                        src={getLogoForBrandUrl(
                          brandUrl,
                          context && context.type
                        )}
                        alt={brandUrl}
                      />
                    )}
                  </ThemeContextConsumer>
                </div>
                <CustomerStatusChip status={status} />
                {!insideBrandTagging && (
                  <img
                    style={{ width: "36px", margin: "0 8px" }}
                    src={NoBrandTagIcon}
                    alt="No Brand Tagging"
                  />
                )}
              </dl>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="h6"
                  color="inherit"
                >
                  {`${name_orderStatusAppBar} (${totalOrders}) `}
                </Typography>
              </dl>
              {isArtApp && (
                <React.Fragment>
                  {primaryPhoneNumber && primaryPhoneNumber.formatted && (
                    <Typography
                      className={classes.spacerRight}
                      variant="subtitle2"
                      color="inherit"
                    >
                      {`Tel: `}
                      <Link
                        to={`tel:+${primaryPhoneNumber.formatted}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {primaryPhoneNumber.formatted}
                      </Link>
                    </Typography>
                  )}
                  {emailAddress && (
                    <Typography
                      className={classes.spacerRight}
                      variant="subtitle2"
                      color="inherit"
                    >
                      {`Email: `}
                      <Link
                        to={`mailto:${emailAddress}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {emailAddress}
                      </Link>
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className={classes.divider} />
            <div>
              <dl className={classes.flexContainer}>
                <Typography
                  style={{ marginRight: "8px" }}
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                  color="inherit"
                >
                  {orderNumber}
                  <Typography style={{ fontSize: "0.650rem" }}>
                    {pluralize("designs", order.productionJobs.length, true)}
                  </Typography>
                </Typography>
                <ProductionMethodIconSet
                  productionMethodStatuses={filter(
                    productionMethodIconSetFragments.order,
                    order
                  )}
                />
                <Button
                  className={classes.customerArchiveLink}
                  href={`https://crm2.legacy.printfly.com/app#/research/orders/5474309${id}`}
                  target="_blank"
                  rel="noopener"
                >
                  <Typography variant="body2">Research Center</Typography>
                </Button>
              </dl>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Production Status:
                </Typography>
                <Typography
                  component="dd"
                  variant="body2"
                  color="textSecondary"
                >
                  <ProductionStatusLabel order={order} />
                </Typography>
              </dl>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Assigned:
                </Typography>
                <Typography
                  component="dd"
                  variant="body2"
                  color="textSecondary"
                >
                  {assigneesList.join(", ")}
                </Typography>
              </dl>
            </div>
            <div className={classes.divider} />
            <div>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Production Date:
                </Typography>

                <PriorityChip date={productionDate} priority={priority} />
              </dl>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Due Date:
                </Typography>
                <Typography
                  component="dd"
                  variant="body2"
                  color="textSecondary"
                >
                  {dueDate}
                </Typography>
              </dl>
            </div>
            <div className={classes.divider} />
            <div>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Stock Status:
                </Typography>
                <Typography
                  component="dd"
                  variant="body2"
                  color="textSecondary"
                >
                  {stockStatusLabel}
                </Typography>
              </dl>
              <dl className={classes.flexContainer}>
                <Typography
                  className={classes.spacerRight}
                  component="dt"
                  variant="body2"
                >
                  Sales Rep:
                </Typography>
                <Typography
                  component="dd"
                  variant="body2"
                  color="textSecondary"
                >
                  {salesRep}
                </Typography>
              </dl>
            </div>
            <div className={classes.divider} />
            <div className={classes.orderStatusView}>{orderStatusView}</div>
            <div className={classes.divider} />
            <div className={classes.orderStatusView}>
              <Button
                component="a"
                href={`/expediting-label/order/${order.id}`}
                target="_blank"
                rel="noopener"
                color="secondary"
                variant="contained"
              >
                Print Staging Label
              </Button>
            </div>
            <div className={classes.closeIconContainer}>
              <IconButton
                onClick={handleCloseButtonClick}
                aria-label="Close"
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Paper>
      <Dialog
        maxWidth="sm"
        open={cancelDialogIsOpen}
        onClose={handleCancelDialogClose}
        PaperProps={{
          className: classes.canceledDialog
        }}
      >
        <DialogTitle>CANCELLED ORDER</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do not print this order as it has been cancelled. Give the goods to
            your manager immediately and let them know it has been cancelled.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(enhancer(OrderStatusHeader));
