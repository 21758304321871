// @flow

import { gql } from "@apollo/client";

import { fragments as productionJobLineItemBreakdownFragments } from "../../../../OrderApp/components/ProductQuantitySummary/components/ProductionJobLineItemBreakdown/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";

export default {
  productionJob: gql`
    fragment LocalInventoryTable_productionJob on OrderProductionJob {
      id
      index
      label
      hasLocalInventorySplit
      customizationAreas {
        id
        location
        method
        directToGarmentAutomatable
        artwork {
          asset {
            url {
              formatted
            }
          }
        }
      }
      proofs: filteredProofs {
        id
        asset {
          url {
            formatted
          }
        }
      }
      order {
        dueBy {
          dateTime
        }
        productionDate
        productionJobs {
          id
        }
        id
        isArtworkApproved
        orderNumber
        primaryCustomer {
          businessUnit {
            id
            name
          }
          id
          insideBrandTagging
          name(format: FIRST_NAME_LAST_NAME)
          status
        }
        priority
        productionDate
        totalShippingPrice {
          value
        }
        productionStationAssignments {
          id
          productionStation {
            id
            name
          }
        }
      }
      productCount
      totalProductVariantQuantity
      lineItemBreakdownByProduct {
        lineItems {
          id
          color {
            id
            name
          }
        }
        product {
          id
          fullDisplayName
          style
          isStyleSubstitution
        }
        ...ProductionJobLineItemBreakdown_productionJobLineItemBreakdown
        lineItems {
          ...StyleSubstitutionLineItemBreakdown_lineItems
        }
      }
      totalProductVariantQuantity
    }
    ${productionJobLineItemBreakdownFragments.productionJobLineItemBreakdown}
    ${styleSubstitutionLineItemBreakdownFragments.lineItems}
  `
};
