// @flow

import { fragments as artOrderStatusFragments } from "../../application/apps/ArtApp/components/ArtOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsArtworkChangeRequested: any = graphql(
  gql`
    mutation($description: String!, $orderId: ID!) {
      markOrderAsArtworkChangeRequested(
        description: $description
        orderId: $orderId
      ) {
        errors {
          description
          orderId
        }
        createdArtworkChange {
          changedAt
          description
          employee {
            id
            name_artworkOrderStatus: name(format: FIRST_NAME_LAST_NAME)
          }
          id
        }
        updatedOrder {
          ...ArtOrderStatus_order
        }
        succeeded
      }
    }
    ${artOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsArtworkChangeRequested"
  }
);

export default markOrderAsArtworkChangeRequested;
