// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createStockContainer: any = graphql(
  gql`
    mutation ($orderProductionJobId: ID!) {
      createStockContainer(orderProductionJobId: $orderProductionJobId) {
        errors {
          orderProductionJobId
        }
        createdStockContainer {
          id
          orderProductionJob {
            id
            index
            label
            totalProductVariantQuantity
            customizationAreas {
              id
              location
              method
            }
            inventoryOrderCartons {
              id
              name
            }
            lineItemBreakdownByProduct {
              product {
                id
                style
              }
              lineItems {
                id
                color {
                  id
                  name
                }
                sizes {
                  id
                  quantity
                  size {
                    id
                    code
                  }
                }
              }
            }
            order {
              id
              isArtworkApproved
              isResolution
              dueBy {
                dateTime
              }
              orderNumber
              primaryCustomer {
                name(format: FIRST_NAME_LAST_NAME)
              }
              productionDate
              productionStationAssignments {
                id
                productionStation {
                  id
                  name
                }
              }
              productionJobs {
                id
              }
            }
            stockContainers {
              id
              latestLocation {
                id
                location
              }
            }
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "createStockContainer"
  }
);

export default createStockContainer;
