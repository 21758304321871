// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createFixPieceRequest: any = graphql(
  gql`
    mutation (
      $description: String!
      $lineItems: [FixPieceRequestLineItemInput!]!
      $orderProductionJobId: ID!
      $requestedByEmployeeId: ID
    ) {
      createFixPieceRequest(
        description: $description
        lineItems: $lineItems
        orderProductionJobId: $orderProductionJobId
        requestedByEmployeeId: $requestedByEmployeeId
      ) {
        createdFixPieceRequest {
          id
          description
          hasBeenOrdered
          orderedAt
        }
        errors {
          description
          orderProductionJobId
          requestedByEmployeeId
          lineItems
          lineItemsInput {
            orderProductionJobLineItemSizeId
            quantity
            reasonId
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "createFixPieceRequest"
  }
);

export default createFixPieceRequest;
