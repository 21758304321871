// @flow

import * as React from "react";
import { Link } from "found";
import { compose, mapProps, setDisplayName } from "recompose";

import type { HOC } from "recompose";

type Props = {|
  +height?: number,
  +proof: {|
    +asset: {|
      +url: {|
        +formatted: string
      |}
    |}
  |},
  +width?: number
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("DesignProofPreview"),
  mapProps(({ proof: { asset: { url: { formatted } } }, ...props }) => ({
    ...props,
    alt: "Preview of a design proof",
    src: formatted
  }))
);

const DesignProofPreview = ({ alt, height, src, width }) =>
  height && width ? (
    <Link to={src} target="_blank" rel="noopener">
      <img
        alt={alt}
        src={src}
        style={{ height: height, objectFit: "contain", width: width }}
      />
    </Link>
  ) : (
    <Link to={src} target="_blank" rel="noopener">
      <img src={src} alt={alt} />
    </Link>
  );

export default enhancer(DesignProofPreview);
