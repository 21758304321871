// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { markOrderAsOnHold } from "../../graph";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../../components/PendingStatusView";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +isOpen: boolean,
  +onRequestClose: () => void,
  +orderId: string
|};

type MutationErrors = {|
  note: Array<string>,
  orderId: Array<string>
|};

type State = {|
  isPlacingOrderOnHold: boolean,
  mutationErrors: MutationErrors,
  notesInputValue: string
|};

const defaultMutationErrors = {
  note: [],
  orderId: []
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("PlaceOrderOnHoldModal"),

  markOrderAsOnHold,

  withSnackbar,

  withStateHandlers(
    ({
      isPlacingOrderOnHold: false,
      mutationErrors: defaultMutationErrors,
      notesInputValue: ""
    }: State),
    {
      setIsPlacingOrderOnHold: () => (isPlacingOrderOnHold: boolean) => ({
        isPlacingOrderOnHold
      }),

      setMutationErrors: () => (mutationErrors: MutationErrors) => ({
        mutationErrors
      }),

      setNotesInputValue: () => (notesInputValue: string) => ({
        notesInputValue
      })
    }
  ),

  withHandlers({
    handleNotesInputChange: ({ setNotesInputValue }) => (
      event: SyntheticInputEvent<HTMLInputElement>
    ) => {
      setNotesInputValue(event.target.value);
    },

    handleCancelButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleSubmitButtonClick: ({
      orderId,
      markOrderAsOnHold,
      notesInputValue: note,
      onRequestClose,
      setIsPlacingOrderOnHold,
      setMutationErrors,
      showErrorSnackbar
    }) => () => {
      setIsPlacingOrderOnHold(true);
      markOrderAsOnHold({ variables: { orderId, note: `FlynetHold: ${note}` } })
        .then(({ data: { markOrderAsOnHold: { errors, succeeded } } }) => {
          if (succeeded) {
            onRequestClose();
          } else {
            setMutationErrors(errors);
          }
        })
        .catch(e => {
          showErrorSnackbar(e.message);
        })
        .finally(() => setIsPlacingOrderOnHold(false));
    }
  })
);

const PlaceOrderOnHoldModal = ({
  handleCancelButtonClick,
  handleNotesInputChange,
  handleSubmitButtonClick,
  isOpen,
  isPlacingOrderOnHold,
  mutationErrors: { note: noteErrors, orderId: orderIdErrors },
  notesInputValue,
  onRequestClose
}) => (
  <Dialog
    aria-describedby="Place Order on Hold"
    aria-labelledby="PlaceOrderOnHoldModal"
    open={isOpen}
    onClose={onRequestClose}
    fullWidth={true}
  >
    <DialogTitle>Why are you locking this order?</DialogTitle>
    <DialogContent>
      {isPlacingOrderOnHold ? (
        <PendingStatusView status="Loading" />
      ) : (
        <React.Fragment>
          <TextField
            id="outlined-simple-start-adornment"
            variant="outlined"
            multiline={true}
            error={noteErrors.length > 0}
            helperText={noteErrors.length > 0 && noteErrors.join(", ")}
            label="Enter a production note here..."
            onChange={handleNotesInputChange}
            value={notesInputValue}
            fullWidth={true}
            rows={5}
          />
          {orderIdErrors.length > 0 && (
            <Typography color="error">{orderIdErrors.join(", ")}</Typography>
          )}
        </React.Fragment>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelButtonClick} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmitButtonClick} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
);

export default enhancer(PlaceOrderOnHoldModal);
