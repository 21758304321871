// @flow

import invariant from "invariant";

const oAuth2ClientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
const oAuth2AuthorizationTokenUrl =
  process.env.REACT_APP_OAUTH2_AUTHORIZATION_TOKEN_URL;
const oAuth2LogoutUrl = process.env.REACT_APP_OAUTH2_LOGOUT_URL;
const oAuth2RedirectUrl = process.env.REACT_APP_OAUTH2_REDIRECT_URL;
const pubNubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubNubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
const flynetCoreProductionGraphUrl =
  process.env.REACT_APP_FLYNET_CORE_PRODUCTION_GRAPH_URL;

const getOAuth2ClientId = (): string => {
  invariant(!!oAuth2ClientId, "REACT_APP_OAUTH2_CLIENT_ID must be set");
  return oAuth2ClientId;
};

const getOAuth2AuthorizationTokenUrl = (): string => {
  invariant(
    !!oAuth2AuthorizationTokenUrl,
    "REACT_APP_OAUTH2_AUTHORIZATION_TOKEN_URL must be set"
  );
  return oAuth2AuthorizationTokenUrl;
};

const getOAuth2LogoutUrl = (): string => {
  invariant(!!oAuth2LogoutUrl, "REACT_APP_OAUTH2_LOGOUT_URL must be set");
  return oAuth2LogoutUrl;
};

const getOAuth2RedirectUrl = (): string => {
  invariant(!!oAuth2RedirectUrl, "REACT_APP_OAUTH2_REDIRECT_URL must be set");
  return oAuth2RedirectUrl;
};

const getPubNubKeys = (): {| publishKey: string, subscribeKey: string |} => {
  invariant(!!pubNubPublishKey, "REACT_APP_PUBNUB_PUBLISH_KEY must be set");
  invariant(!!pubNubSubscribeKey, "REACT_APP_PUBNUB_SUBSCRIBE_KEY must be set");
  return {
    publishKey: pubNubPublishKey,
    subscribeKey: pubNubSubscribeKey
  };
};

const getFlynetCoreProductionGraphUrl = (): string => {
  return flynetCoreProductionGraphUrl ? flynetCoreProductionGraphUrl : "";
};

export default {
  getOAuth2ClientId,
  getOAuth2AuthorizationTokenUrl,
  getOAuth2LogoutUrl,
  getOAuth2RedirectUrl,
  getFlynetCoreProductionGraphUrl,
  getPubNubKeys
};
