// @flow

import { gql } from "@apollo/client";

import { fragments as inkColorDetailsFragments } from "../../../../InkColorDetails/graph";

export default {
  customizationArea: gql`
    fragment OrderProductionJobCustomizationAreaListItem_customizationArea on OrderProductionJobCustomizationArea {
      id
      customerArtwork {
        id
        file {
          id
          asset {
            url {
              formatted
            }
          }
        }
      }
      inkColors {
        id
        inkColor {
          ...InkColorDetails_inkColor
        }
        pantoneColor {
          ...InkColorDetails_pantoneColor
        }
      }
      template {
        description
        id
      }
      autobase
      distressed
      flatten
      halftones
      imageRepair
      recreate
      sponsorback
      splitIntoColors
      colorCount
      location
      method
      artwork {
        id
        asset {
          url {
            formatted
          }
        }
        originalFilename
      }
      notesCollection {
        __typename
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
      vinylConfiguration {
        id
        includeNames
        includeNumbers
        namesColor {
          id
          inkColor {
            id
            name
            color {
              formatted(format: HEX_WITH_POUND)
            }
          }
        }
        namesFont {
          alias
          id
        }
        numbersColor {
          id
          inkColor {
            id
            name
            color {
              formatted(format: HEX_WITH_POUND)
            }
          }
        }
        numbersFont {
          alias
          id
        }
        sizeInteger
      }
    }
    ${inkColorDetailsFragments.inkColor}
    ${inkColorDetailsFragments.pantoneColor}
  `
};
