// @flow

import { gql } from "@apollo/client";

export default {
  orderProductionJob: gql`
    fragment NamesAndNumbers_orderProductionJob on OrderProductionJob {
      id
      lineItemBreakdownByProduct {
        product {
          id
          style
        }
        lineItems {
          id
          color {
            id
            name
          }
          sizesInclusive {
            __typename
            ... on OrderProductionJobLineItemSize {
              id
              vinylNamesAndNumbers {
                id
                name
                number
              }
              size {
                id
                code
              }
            }
          }
        }
      }
    }
  `
};
