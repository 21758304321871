export default ({ top, left, pixelsPerInch, height=14, width=16 }) => (
  <div
    style={{
      position: "absolute",
      zIndex: 999,
      pointerEvents: "none",
      top: top,
      left: left,
      width: pixelsPerInch * width + 1,
      height: pixelsPerInch * height + 1,
      backgroundSize: `${pixelsPerInch}px ${pixelsPerInch}px`,
      backgroundImage:
        "linear-gradient(to right, rgba(0,0,0,0.2) 1px, transparent 1px),linear-gradient(to bottom, rgba(0,0,0,0.2) 1px, transparent 1px)"
    }}
  />
);
