// @flow

import { gql } from "@apollo/client";

import { fragments as automatedNotesCollectionFragments } from "../../../../../../components/AutomatedNotesWrapper/graph";
import { fragments as notesWrapperFragments } from "../../../../../../components/NotesWrapper/graph";
import { fragments as productQuantitySummaryFragments } from "../../../../OrderApp/components/ProductQuantitySummary/graph";
import { fragments as productionJobLineItemBreakdownFragments } from "../../../../OrderApp/components/ProductQuantitySummary/components/ProductionJobLineItemBreakdown/graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";

export default gql`
  query StockStagedOrderDetail($orderId: ID!) {
    order: node(id: $orderId) {
      ... on Order {
        ...AutomatedNotesWrapper_order
        ...NotesWrapper_order
        externalStoreOrderName
        express
        includeCatalogInShipment
        id
        isArtworkApproved
        isResolution
        isStockStaged
        orderNumber
        directToGarmentExpressEligible
        highDefinitionDigitalExpressEligible
        directToGarmentAutomatable
        dropDeadDueBy
        totalShippingPrice {
          value
        }
        dropDeadDueBy
        dueBy {
          dateTime
        }
        primaryCustomer {
          id
          businessUnit {
            id
            name
          }
          insideBrandTagging
          name(format: FIRST_NAME_LAST_NAME)
          totalOrders
          totalOrderCount
          status
        }
        priority
        productionDate
        productionNotesCollection {
          ... on FormattedNote {
            author
            createdAt
            note
          }
          ... on UnformattedNote {
            note
          }
        }
        productionStationAssignments {
          id
          productionStation {
            id
            name
          }
        }
        productionJobs {
          ...ProductQuantitySummary_productionJob
          id
          index
          label
          hasLocalInventorySplit
          customizationAreas {
            id
            location
            method
            directToGarmentAutomatable
            artwork {
              asset {
                url {
                  formatted
                }
              }
            }
            customerArtwork {
              id
              file {
                id
                asset {
                  url {
                    formatted
                  }
                }
              }
            }
            vinylConfiguration {
              id
              includeNames
              includeNumbers
            }
            quantity
            width
            height
            precut
            gangSheet
          }
          proofs: filteredProofs {
            id
            asset {
              url {
                formatted
              }
            }
          }
          order {
            id
            externalStoreOrderName
            dueBy {
              dateTime
            }
            dueByLatest {
              dateTime
            }
            dropDeadDueBy
            orderNumber
            primaryCustomer {
              id
              businessUnit {
                id
                name
              }
              insideBrandTagging
              name(format: FIRST_NAME_LAST_NAME)
              totalOrders
              totalOrderCount
              status
            }
            productionDate
            shipMissCosts {
              cost {
                formatted
              }
              date
            }
            totalShippingPrice {
              value
            }
            totalProductVariantQuantity
          }
          productCount
          stockContainers {
            id
            latestLocation {
              id
              location
            }
          }
          totalProductVariantQuantity
          lineItemBreakdownByProduct {
            lineItems {
              id
              color {
                id
                name
              }
            }
            product {
              id
              fullDisplayName
              style
              isStyleSubstitution
            }
            ...ProductionJobLineItemBreakdown_productionJobLineItemBreakdown
            lineItems {
              ...StyleSubstitutionLineItemBreakdown_lineItems
            }
          }
        }
        productionStationAssignments {
          id
          productionStation {
            id
            name
          }
        }
        shipMissCosts {
          cost {
            formatted
          }
          date
        }
        shippingTransactions {
          shippingMethod {
            name
          }
        }
        totalProductVariantQuantity
      }
    }
  }
  ${automatedNotesCollectionFragments.order}
  ${notesWrapperFragments.order}
  ${productQuantitySummaryFragments.productionJob}
  ${productionJobLineItemBreakdownFragments.productionJobLineItemBreakdown}
  ${styleSubstitutionLineItemBreakdownFragments.lineItems}
`;
