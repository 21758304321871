// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ProductionStatusLabel_order on Order {
      artworkStatus
      directToGarmentStatus
      embroideryStatus
      screenPrintingStatus
      vinylStatus
      artworkStatus
      shippingStatus
    }
  `
};
