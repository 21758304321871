// @flow

import * as React from "react";
import { addArtNoteToOrder } from "../../graph";
import { addNoteToOrderProductionJobCustomizationArea } from "../../graph";
import { addProductionNoteToOrder } from "../../graph";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../components/CenteredSpinner";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +addNoteContainer: string,
    +formControl: string
  |},
  +customizationAreaId?: string,
  +isAddingArtNote?: boolean,
  +isAddingCustomizationAreaNote?: boolean,
  +order?: {
    +id: string
  },
  +placeholder: string,
  +setNoteErrors?: () => void,
  +setTextAreaValue?: () => void
|};

type InputErrors = {|
  note: $ReadOnlyArray<string>,
  orderId: $ReadOnlyArray<string>,
  orderProductionJobCustomizationAreaId: $ReadOnlyArray<string>
|};

type State = {|
  generalErrors: $ReadOnlyArray<string>,
  inputErrors: InputErrors,
  isAddingNote: boolean,
  noteErrors: boolean,
  noteTextAreaValue: string
|};

const defaultInputErrors = {
  note: [],
  orderId: [],
  orderProductionJobCustomizationAreaId: []
};

const styles = theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120
  },

  formAction: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: theme.spacing(1)
  },

  addNoteContainer: {
    padding: theme.spacing(2)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("AddNoteForm"),

  addArtNoteToOrder,

  addNoteToOrderProductionJobCustomizationArea,

  addProductionNoteToOrder,

  withStateHandlers(
    () =>
      ({
        generalErrors: [],
        inputErrors: defaultInputErrors,
        isAddingNote: false,
        noteErrors: false,
        noteTextAreaValue: ""
      }: State),
    {
      setIsAddingNote: () => (isAddingNote: boolean) => ({
        isAddingNote
      }),
      setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
        generalErrors
      }),
      setInputErrors: () => (inputErrors: InputErrors) => ({ inputErrors }),
      setNoteErrors: () => (noteErrors: boolean) => ({
        noteErrors
      }),
      setNoteTextAreaValue: () => (noteTextAreaValue: string) => ({
        noteTextAreaValue
      })
    }
  ),

  withHandlers({
    handleArtFormSubmit: ({
      addArtNoteToOrder,
      noteTextAreaValue,
      order: { id },
      setIsAddingNote,
      setGeneralErrors,
      setInputErrors,
      setNoteErrors,
      setNoteTextAreaValue
    }) => (event: SyntheticInputEvent<HTMLFormElement>) => {
      event.preventDefault();
      const requiredInputErrors = {};
      if (!noteTextAreaValue) {
        setNoteErrors(true);
        requiredInputErrors.note = ["is required"];
      }

      if (Object.keys(requiredInputErrors).length > 0) {
        setNoteErrors(true);
        setInputErrors({
          ...defaultInputErrors,
          ...requiredInputErrors
        });
        return;
      }
      setIsAddingNote(true);
      addArtNoteToOrder({
        variables: { orderId: id, note: noteTextAreaValue }
      })
        .then(({ data: { addArtNoteToOrder: { succeeded, errors } } }) => {
          if (!succeeded) {
            setNoteErrors(true);
            setInputErrors(...errors);
          } else {
            setNoteTextAreaValue("");
            setNoteErrors(false);
            setInputErrors("");
          }
        })
        .catch(e => {
          setGeneralErrors(e.message);
        })
        .finally(() => {
          setIsAddingNote(false);
        });
    },

    handleFormSubmit: ({
      addProductionNoteToOrder,
      noteTextAreaValue,
      order: { id },
      setIsAddingNote,
      setGeneralErrors,
      setInputErrors,
      setNoteErrors,
      setNoteTextAreaValue
    }) => (event: SyntheticInputEvent<HTMLFormElement>) => {
      event.preventDefault();
      const requiredInputErrors = {};
      if (!noteTextAreaValue) {
        setNoteErrors(true);
        requiredInputErrors.note = ["is required"];
      }

      if (Object.keys(requiredInputErrors).length > 0) {
        setNoteErrors(true);
        setInputErrors({
          ...defaultInputErrors,
          ...requiredInputErrors
        });
        return;
      }
      setIsAddingNote(true);
      addProductionNoteToOrder({
        variables: { orderId: id, note: noteTextAreaValue }
      })
        .then(
          ({
            data: {
              addProductionNoteToOrder: { succeeded, errors }
            }
          }) => {
            if (!succeeded) {
              setNoteErrors(true);
              setInputErrors(...errors);
            } else {
              setNoteTextAreaValue("");
              setNoteErrors(false);
              setInputErrors("");
            }
          }
        )
        .catch(e => {
          setGeneralErrors(e.message);
        })
        .finally(() => {
          setIsAddingNote(false);
        });
    },

    handleCustomizationAreaNoteFormSubmit: ({
      addNoteToOrderProductionJobCustomizationArea,
      noteTextAreaValue,
      customizationAreaId,
      setIsAddingNote,
      setGeneralErrors,
      setInputErrors,
      setNoteErrors,
      setNoteTextAreaValue
    }) => (event: SyntheticInputEvent<HTMLFormElement>) => {
      event.preventDefault();
      const requiredInputErrors = {};
      if (!noteTextAreaValue) {
        setNoteErrors(true);
        requiredInputErrors.note = ["is required"];
      }

      if (Object.keys(requiredInputErrors).length > 0) {
        setNoteErrors(true);
        setInputErrors({
          ...defaultInputErrors,
          ...requiredInputErrors
        });
        return;
      }
      setIsAddingNote(true);

      addNoteToOrderProductionJobCustomizationArea({
        variables: {
          orderProductionJobCustomizationAreaId: customizationAreaId,
          note: noteTextAreaValue
        }
      })
        .then(
          ({
            data: {
              addNoteToOrderProductionJobCustomizationArea: {
                succeeded,
                errors
              }
            }
          }) => {
            if (!succeeded) {
              setNoteErrors(true);
              setInputErrors(...errors);
            } else {
              setNoteTextAreaValue("");
              setNoteErrors(false);
              setInputErrors("");
            }
          }
        )
        .catch(e => {
          setGeneralErrors(e.message);
        })
        .finally(() => {
          setIsAddingNote(false);
        });
    },

    handleNoteTextAreaChange: ({ setNoteTextAreaValue }) => (
      event: SyntheticInputEvent<HTMLInputElement>
    ) => {
      setNoteTextAreaValue(event.target.value);
    }
  })
);

const AddNoteForm = ({
  isAddingCustomizationAreaNote,
  isAddingNote,
  isAddingArtNote,
  handleFormSubmit,
  handleArtFormSubmit,
  handleCustomizationAreaNoteFormSubmit,
  noteTextAreaValue,
  handleNoteTextAreaChange,
  noteErrors,
  inputErrors,
  placeholder,
  classes
}) => (
  <div className={classes.addNoteContainer}>
    <form>
      <FormControl className={classes.formControl} fullWidth={true}>
        <TextField
          required
          helperText={noteErrors && inputErrors.note}
          error={noteErrors}
          fullWidth={true}
          placeholder={placeholder}
          onChange={handleNoteTextAreaChange}
          multiline={true}
          variant="outlined"
          value={noteTextAreaValue}
        />
      </FormControl>
      <div>
        {isAddingNote ? (
          <CenteredSpinner />
        ) : (
          <FormControl className={classes.formAction}>
            <Button
              color="primary"
              onClick={
                isAddingCustomizationAreaNote
                  ? handleCustomizationAreaNoteFormSubmit
                  : isAddingArtNote
                  ? handleArtFormSubmit
                  : handleFormSubmit
              }
              variant="contained"
            >
              Add Note
            </Button>
          </FormControl>
        )}
      </div>
    </form>
  </div>
);

export default withStyles(styles)(enhancer(AddNoteForm));
