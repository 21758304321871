// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment DTGOrderStatus_order on Order {
      id
      canViewerCompleteDtg
      canViewerPlaceOnHold
      canViewerRemoveHold
      canViewerStartDtg
      directToGarmentStatus
    }
  `
};
