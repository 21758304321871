import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default ({ alignment, onChange }) => (
  <>
    <h3>Alignment Form</h3>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <TextField
          label="X"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          value={alignment?.position?.x}
          onChange={({ target: { value } }) => {
            onChange({
              ...(alignment || {}),
              position: {
                ...(alignment?.position || {}),
                x: parseInt(value),
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Y"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          value={alignment?.position?.y}
          onChange={({ target: { value } }) => {
            onChange({
              ...(alignment || {}),
              position: {
                ...(alignment?.position || {}),
                y: parseInt(value),
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Width"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          value={alignment?.size?.width}
          onChange={({ target: { value } }) => {
            onChange({
              ...(alignment || {}),
              size: {
                ...(alignment?.size || {}),
                width: parseInt(value),
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Height"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          value={alignment?.size?.height}
          onChange={({ target: { value } }) => {
            onChange({
              ...(alignment || {}),
              size: {
                ...(alignment?.size || {}),
                height: parseInt(value),
              },
            });
          }}
        />
      </Grid>
    </Grid>
  </>
);
