// @flow

import * as React from "react";
import Chip from "@mui/material/Chip";

import PersonAvatar from "../PersonAvatar/PersonAvatar";

type Props = {|
  +employee: {|
    +name_employeeChipModule: string
  |}
|};

const EmployeeChip = ({ employee: { name_employeeChipModule } }: Props) => (
  <Chip
    avatar={<PersonAvatar name={name_employeeChipModule} size="chip" />}
    label={`${
      name_employeeChipModule === "" ? "N/A" : name_employeeChipModule
    }`}
  />
);

export default EmployeeChip;
