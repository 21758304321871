// @flow

import * as React from "react";
import FormattedNote from "./components/FormattedNote";
import UnformattedNote from "./components/UnformattedNote";

import Typography from "@mui/material/Typography";

type UnformattedNoteType = {|
  +__typename: "UnformattedNote",
  +note: string
|};

type FormattedNoteType = {|
  +__typename: "FormattedNote",
  +author: string,
  +createdAt: string,
  +note: string
|};

type NoteType = FormattedNoteType | UnformattedNoteType;

type Props = {|
  +notesCollection: $ReadOnlyArray<NoteType>
|};

const NotesCollectionBreakdown = ({ notesCollection }: Props) => (
  <React.Fragment>
    {notesCollection.length > 0 ? (
      notesCollection.map(({ __typename, ...rest }, index) => (
        <div key={`note-${index}`}>
          {__typename === "FormattedNote" && <FormattedNote {...rest} />}
          {__typename === "UnformattedNote" && (
            <div style={{ marginBottom: "24px" }}>
              <UnformattedNote note={rest.note} />
            </div>
          )}
        </div>
      ))
    ) : (
      <Typography> N/A </Typography>
    )}
  </React.Fragment>
);

export default NotesCollectionBreakdown;
