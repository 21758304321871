// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const unclaimOrderProductionJobCustomizationArea: any = graphql(
  gql`
    mutation($orderProductionJobCustomizationAreaId: ID!) {
      unclaimOrderProductionJobCustomizationArea(
        orderProductionJobCustomizationAreaId: $orderProductionJobCustomizationAreaId
      ) {
        errors {
          orderProductionJobCustomizationAreaId
        }
        succeeded
        updatedOrderProductionJobCustomizationArea {
          id
        }
      }
    }
  `,
  {
    name: "unclaimOrderProductionJobCustomizationArea"
  }
);

export default unclaimOrderProductionJobCustomizationArea;
