// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment FixPieceRequestTable_fixPieceRequest on FixPieceRequest {
      canViewerPurchase
      id
      hasBeenOrdered
      orderedAt
      orderProductionJob {
        id
        label
        order {
          id
          orderNumber
          priority
          productionDate
        }
      }
    }
  `
};
