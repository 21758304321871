// @flow

import * as React from "react";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { fragments as orderAppProductionJobListItemFragments } from "./OrderAppProductionJobListItem/graph";
import OrderAppProductionJobListItem from "./OrderAppProductionJobListItem";

type Props = {|
  +classes: {|
    +listItemWrapper: string,
    +title: string
  |},
  +order: {|
    +productionJobs: $ReadOnlyArray<{|
      +id: string
    |}>,
    +productionStationAssignments: $ReadOnlyArray<{|
      +employee: {|
        +name_employeeChipModule: string
      |},
      +id: string,
      +productionStation: {|
        +name: string
      |}
    |}>
  |}
|};

const styles = theme => ({
  listItemWrapper: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(1),
    "&:last-child": {
      borderBottomStyle: "none"
    }
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const OrderAppProductionJobList = ({ classes, order }: Props) => (
  <div>
    {order.productionJobs.length > 0 && (
      <React.Fragment>
        <Typography className={classes.title} variant="h6">
          Production Jobs
        </Typography>
        <Card>
          <CardContent>
            {order.productionJobs.map((productionJob, index) => (
              <div className={classes.listItemWrapper} key={productionJob.id}>
                <OrderAppProductionJobListItem
                  index={`${index + 1}`}
                  productionJob={filter(
                    orderAppProductionJobListItemFragments.productionJob,
                    productionJob
                  )}
                  productionStationAssignments={filter(
                    orderAppProductionJobListItemFragments.productionStationAssignments,
                    order.productionStationAssignments
                  )}
                />
              </div>
            ))}
          </CardContent>
        </Card>
      </React.Fragment>
    )}
  </div>
);

export default withStyles(styles)(OrderAppProductionJobList);
