// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment TransfersUVOrderStatus_order on Order {
      id
      transfersUvStatus
      canViewerStartTransfersUvManualRipping
      canViewerStartTransfersUvManualPrinting
      canViewerCompleteTransfersUvManualRipping
      canViewerCompleteTransfersUvManualPrinting
    }
  `
};
