// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { markOrderAsScreensMade } from "../../../graph";
import { query } from "./graph";
import { fragments as screensMadeTableFragments } from "./components/ScreensMadeTable/graph";
import { withRouter } from "found";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import ScreensMadeTable from "./components/ScreensMadeTable";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";
type Props = {|
  +router: {|
    +push: (location: string) => void
  |}
|};

type State = {|
  hasNextPage: boolean,
  isLoadingMore: boolean,
  loadingOrderIds: Array<string>,
  searchQuery: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string
|};

const ORDERS_PER_PAGE = 1000;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ScreensMadeApp"),

  withRouter,

  withSnackbar,

  markOrderAsScreensMade(ORDERS_PER_PAGE),

  withStateHandlers(
    ({
      hasNextPage: true,
      isLoadingMore: false,
      loadingOrderIds: [],
      searchQuery: null,
      selectedSortDirection: null,
      selectedSortBy: null
    }: State),
    {
      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      addLoadingOrderId: ({ loadingOrderIds }) => (orderId: string) => ({
        loadingOrderIds: [...loadingOrderIds, orderId]
      }),

      removeLoadingOrderId: ({ loadingOrderIds }) => (orderId: string) => {
        const indexToRemove = loadingOrderIds.indexOf(orderId);
        return {
          loadingOrderIds: [
            ...loadingOrderIds.slice(0, indexToRemove),
            ...loadingOrderIds.slice(indexToRemove + 1, loadingOrderIds.length)
          ]
        };
      }
    }
  ),

  graphql(query, {
    options: ({ searchQuery, selectedSortBy, selectedSortDirection }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection
        }
      }
    })
  }),

  withHandlers({
    handleAppHeaderRequestBack: ({ router }) => () => {
      router.push("/apps");
    },

    handleSearch: ({ setSearchQuery }) => searchQuery => {
      setSearchQuery(searchQuery);
    },

    handleScreensMadeTableScrolledToBottom: ({
      data,
      hasNextPage,
      isLoadingMore,
      setHasNextPage,
      setIsLoadingMore
    }) => () => {
      if (isLoadingMore || !hasNextPage) {
        return;
      }

      setIsLoadingMore(true);
      data
        .fetchMore({
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.ordersToBeScreened.edges;
            const pageInfo = fetchMoreResult.ordersToBeScreened.pageInfo;
            setHasNextPage(pageInfo.hasNextPage);
            return newEdges.length
              ? {
                  ordersToBeScreened: {
                    pageInfo,
                    __typename: previousResult.ordersToBeScreened.__typename,
                    edges: [
                      ...previousResult.ordersToBeScreened.edges,
                      ...newEdges
                    ]
                  }
                }
              : previousResult;
          },
          variables: {
            after: data.ordersToBeScreened.pageInfo.endCursor
          }
        })
        .finally(() => setIsLoadingMore(false));
    },

    handleScreensMadeTableRequestMakeScreen: ({
      addLoadingOrderId,
      removeLoadingOrderId,
      showErrorSnackbar,
      showSuccessSnackbar,
      markOrderAsScreensMade
    }) => (orderId: string) => {
      addLoadingOrderId(orderId);
      markOrderAsScreensMade({ variables: { orderId } })
        .then(({ data: { markOrderAsScreensMade: { succeeded, errors } } }) => {
          if (succeeded) {
            showSuccessSnackbar("Order status has been updated.");
          } else {
            showErrorSnackbar(errors.orderId.join(", "));
          }
        })
        .catch(e => {
          showErrorSnackbar(e.message);
        })
        .finally(() => removeLoadingOrderId(orderId));
    },

    handleSort: ({
      selectedSortBy,
      setSortDirection,
      setSortBy,
      selectedSortDirection
    }) => (newSelectedSortBy: string) => {
      setSortBy(newSelectedSortBy);
      if (selectedSortBy === newSelectedSortBy) {
        selectedSortDirection === "ASCENDING"
          ? setSortDirection("DESCENDING")
          : setSortDirection("ASCENDING");
      } else {
        setSortDirection("ASCENDING");
      }
    }
  })
);

const ScreensMadeApp = ({
  data,
  handleSearch,
  handleScreensMadeTableRequestMakeScreen,
  handleScreensMadeTableScrolledToBottom,
  handleSort,
  handleAppHeaderRequestBack,
  isLoadingMore,
  hasNextPage,
  loadingOrderIds
}) => (
  <div>
    <TabbedAppBar
      title="Screens Made"
      onRequestBack={handleAppHeaderRequestBack}
      onSearch={handleSearch}
    />
    {data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        {data.error.message}
      </Typography>
    ) : (
      <div>
        <ScreensMadeTable
          onScrolledToBottom={handleScreensMadeTableScrolledToBottom}
          loadingOrderIds={loadingOrderIds}
          onRequestMakeScreen={handleScreensMadeTableRequestMakeScreen}
          onRequestSort={handleSort}
          orders={filter(
            screensMadeTableFragments.order,
            data.ordersToBeScreened.edges.map(edge => ({ ...edge.node }))
          )}
        />
        {isLoadingMore && hasNextPage && <CenteredSpinner />}
      </div>
    )}
  </div>
);

export default enhancer(ScreensMadeApp);
