// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createInventoryOrderCartons: any = graphql(
  gql`
    mutation (
      $cartonNumber: String
      $inventoryOrderId: ID!
      $received: Boolean
      $trackingNumber: String
    ) {
      createInventoryOrderCartons(
        cartonNumber: $cartonNumber
        inventoryOrderId: $inventoryOrderId
        received: $received
        trackingNumber: $trackingNumber
      ) {
        createdCartons {
          id
          stockContainer {
            id
            orderProductionJob {
              id
              totalProductVariantQuantity
              customizationAreas {
                id
                location
                method
              }
              index
              order {
                dueBy {
                  dateTime
                }
                id
                isArtworkApproved
                isResolution
                orderNumber
                primaryCustomer {
                  id
                  name(format: FIRST_NAME_LAST_NAME)
                }
                productionDate
                productionJobs {
                  id
                  totalProductVariantQuantity
                }
                productionStationAssignments {
                  productionStation {
                    name
                  }
                }
              }
            }
          }
        }
        errors {
          cartonNumber
          inventoryOrderId
          received
          trackingNumber
        }
        succeeded
      }
    }
  `,
  {
    name: "createInventoryOrderCartons"
  }
);

export default createInventoryOrderCartons;
