// @flow

import { gql } from "@apollo/client";

export default {
  productionJob: gql`
    fragment FixPieceProductionJobListItem_productionJob on OrderProductionJob {
      id
      label
      filteredProofs {
        id
        asset {
          url {
            formatted
          }
        }
      }
      totalProductVariantQuantity
    }
  `
};
