// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ScreenPrintingOrderStatus_order on Order {
      canViewerCompleteScreenPrinting
      canViewerPlaceOnHold
      canViewerRemoveHold
      canViewerStartScreenPrinting
      id
      screenPrintingStatus
    }
  `
};
