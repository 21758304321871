// @flow

import { DateTime } from "luxon";
import { Link } from "found";
import { Page, StyleSheet } from "@react-pdf/renderer";
import { compose, setDisplayName } from "recompose";
import {
  createCustomsShipmentPallet,
  deleteCustomsShipmentPallet
} from "../../graph";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CustomsLabel from "../CustomsLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PendingStatusView from "../PendingStatusView";
import PrintablePDFDocument from "../PrintablePDFDocument";
import React, { useState } from "react";
import SelectShippingCarrierModal from "./components/SelectShippingCarrierModal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import withSnackbar from "../withSnackbar";

const pdfStyles = StyleSheet.create({
  page: {
    padding: 8
  }
});

const styles = theme => ({
  tableCellRoot: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("CustomsShipmentDetailScreen"),

  withSnackbar,

  withRouter,

  withStyles(styles),

  createCustomsShipmentPallet,

  deleteCustomsShipmentPallet,

  graphql(query, {
    options: ({ shipmentId }) => ({
      variables: {
        id: shipmentId
      },
      fetchPolicy: "network-only"
    })
  })
);

const CustomsShipmentDetailScreen = ({
  data,
  direction,
  createCustomsShipmentPallet,
  deleteCustomsShipmentPallet,
  router,
  classes,
  showSuccessSnackbar,
  showErrorSnackbar
}) => {
  const [
    selectShippingCarrierModalIsOpen,
    setSelectShippingCarrierModalIsOpen
  ] = useState(false);

  const { loading, error, customsShipment, refetch } = data;

  if (loading) {
    return <PendingStatusView status="Loading" />;
  }

  if (error) {
    return null;
  }

  const handleCreatePalletClick = () => {
    if (direction.toUpperCase() === "NORTHBOUND") {
      createCustomsShipmentPallet({
        variables: { customsShipmentId: customsShipment.id }
      });
      //setSelectShippingCarrierModalIsOpen(true);
    } else if (direction.toUpperCase() === "SOUTHBOUND") {
      createCustomsShipmentPallet({
        variables: { customsShipmentId: customsShipment.id }
      });
    }
  };

  const handleTableRowClicked = id => {
    router.push(`/apps/customs/${direction}/pallet/${id}`);
  };

  const handleDeletePalletClick = id => {
    if (window.confirm("Are you sure you want to delete this pallet?")) {
      deleteCustomsShipmentPallet({
        variables: { customsShipmentPalletId: id }
      }).then(
        ({
          data: {
            deleteCustomsShipmentPallet: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Pallet successfully deleted!");
            refetch();
          } else {
            showErrorSnackbar(errors.customsShipmentPalletId.join(","));
          }
        }
      );
    }
  };

  const handleSelectShippingCarrierModalRequestClose = () => {
    setSelectShippingCarrierModalIsOpen(false);
  };

  const handleSelectShippingCarrierModalComplete = data => {
    setSelectShippingCarrierModalIsOpen(false);
    createCustomsShipmentPallet({
      variables: { customsShipmentId: customsShipment.id, ...data }
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/apps/customs/${direction}`}>Shipments</Link>
            <Typography color="text.primary">Shipment</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4">
            #{customsShipment.canonicalId} Pallets for Shipment{" "}
            {DateTime.fromISO(customsShipment.createdAt).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            aria-label="Create New Pallet"
            variant="contained"
            onClick={handleCreatePalletClick}
            style={{ marginRight: "1em" }}
          >
            Create New Pallet
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Pallets ({customsShipment.pallets.length})
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Pallet
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Carton Count
              </TableCell>
              {direction.toUpperCase() === "NORTHBOUND" && (
                <TableCell
                  classes={{
                    root: classes.tableCellRoot
                  }}
                  align="center"
                >
                  Carrier
                </TableCell>
              )}
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customsShipment.pallets.map(
              (
                { id, cartons, createdAt, lineItems, shippingCarrier },
                index
              ) => (
                <TableRow
                  key={id}
                  onClick={handleTableRowClicked.bind(null, id)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    #{index + 1}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {DateTime.fromISO(createdAt).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {cartons.length}
                  </TableCell>
                  {direction.toUpperCase() === "NORTHBOUND" && (
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      align="center"
                    >
                      {shippingCarrier && shippingCarrier.name}
                    </TableCell>
                  )}
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    <PrintablePDFDocument label="Print Pallet Label">
                      <Page
                        size={[6.5 * 72, 10 * 72]}
                        orientation="landscape"
                        style={pdfStyles.page}
                      >
                        <CustomsLabel
                          data={lineItems}
                          suffix={
                            direction.toUpperCase() === "NORTHBOUND"
                              ? "-FG"
                              : ""
                          }
                          includePrintMethodInSuffix={
                            direction.toUpperCase() === "NORTHBOUND"
                              ? true
                              : false
                          }
                        />
                      </Page>
                    </PrintablePDFDocument>
                    <Button
                      onClick={event => {
                        event.stopPropagation();
                        handleDeletePalletClick(id);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SelectShippingCarrierModal
        isOpen={selectShippingCarrierModalIsOpen}
        onRequestClose={handleSelectShippingCarrierModalRequestClose}
        onComplete={handleSelectShippingCarrierModalComplete}
      />
    </Container>
  );
};

export default enhancer(CustomsShipmentDetailScreen);
