// @flow

import { compose, setDisplayName, withStateHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import ProductionJobDetail from "../ProductionJobDetail";
import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +productionJobDetailContainer: string,
    +tabsContainer: string
  |},
  +productionJobs: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +totalPrice: {|
      +formatted: string
    |},
    +totalProductVariantQuantity: number
  |}>
|};

type State = {|
  selectedProductionJobIndex: number
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobTabs"),
  withStateHandlers(({ selectedProductionJobIndex: 0 }: State), {
    handleSelectedProductionJobTabChange: () => (event, value) => ({
      selectedProductionJobIndex: value
    })
  })
);

const styles = theme => ({
  productionJobDetailContainer: {
    padding: theme.spacing(2)
  },

  tabsContainer: {
    backgroundColor: "#FFF",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(2)
  }
});

const ProductionJobTabs = ({
  classes,
  handleSelectedProductionJobTabChange,
  productionJobs,
  selectedProductionJobIndex
}) => (
  <div>
    {!productionJobs ? (
      <Typography variant="body2" color="error">
        Unfortunately that order could not be located.
      </Typography>
    ) : productionJobs.length === 0 ? (
      <Typography variant="body2" color="error">
        There are no production jobs on this order.
      </Typography>
    ) : (
      <div>
        <div className={classes.tabsContainer}>
          <Tabs
            onChange={handleSelectedProductionJobTabChange}
            value={selectedProductionJobIndex}
            variant="scrollable"
            scrollButtons="auto"
          >
            {productionJobs.map(
              ({
                id,
                label,
                totalProductVariantQuantity,
                totalPrice: { formatted }
              }) => {
                return (
                  <Tab
                    key={id}
                    label={`${label} (${totalProductVariantQuantity} PCS | ${formatted})`}
                  />
                );
              }
            )}
          </Tabs>
        </div>
        <div className={classes.productionJobDetailContainer}>
          <ProductionJobDetail
            orderProductionJobId={productionJobs[selectedProductionJobIndex].id}
          />
        </div>
      </div>
    )}
  </div>
);

export default withStyles(styles)(enhancer(ProductionJobTabs));
