// @flow

import * as React from "react";
import { fragments as designProofPreviewFragments } from "../../../../../components/DesignProofPreview/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DesignProofPreview from "../../../../../components/DesignProofPreview";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type Props = {|
  +classes: {|
    +breakdownSummary: string,
    +divider: string,
    +list: string,
    +proof: string,
    +proofsWrapper: string,
    +root: string,
    +title: string
  |},
  +order: {
    +productionJobs: $ReadOnlyArray<{|
      +customizationAreas: $ReadOnlyArray<{|
        +id: string,
        +location: string,
        +method: string
      |}>,
      +filteredProofs: $ReadOnlyArray<{|
        +id: string
      |}>,
      +id: string,
      +label: string,
      +lineItemBreakdownByProduct: $ReadOnlyArray<{|
        +lineItems: $ReadOnlyArray<{|
          +color: {
            +name: string
          },
          +id: string,
          +product: {
            +style: string
          },
          +totalPrice: {
            +formatted: string
          },
          +totalQuantity: string
        |}>,
        +totalPrice: {
          +formatted: string
        },
        +totalProductVariantQuantity: string
      |}>,
      +totalPrice: {
        formatted: string
      },
      +totalProductVariantQuantity: string
    |}>
  }
|};

const styles = theme => ({
  breakdownSummary: {
    minWidth: "200px",
    marginRight: theme.spacing(2)
  },

  proofsWrapper: {
    [theme.breakpoints.down("md")]: {
      direction: "column",
      wrap: "wrap"
    }
  },

  divider: {
    marginBottom: theme.spacing(1)
  },

  proof: {
    justifyContent: "space-around",
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      direction: "column"
    }
  },

  list: {
    listStyle: "none",
    marginBottom: theme.spacing(4)
  },

  root: {
    direction: "column",
    display: "flex",
    flexGrow: 1
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const customizationMethod = {
  DIRECT_TO_GARMENT: "Direct-to-garment",
  CUT_VINYL: "Cut Vinyl",
  EMBROIDERY: "Embroidery",
  HEAT_PRESS: "Heat Press",
  PRINTED_VINYL: "Printed Vinyl",
  SCREEN_PRINTING: "Screen Printing",
  SUBLIMATION: "Sublimation",
  TACKLE_TWILL: "Tackle Twill"
};

const CustomerArchiveDesignProofList = ({ classes, order }: Props) => (
  <React.Fragment>
    <Card>
      <CardContent>
        {order.productionJobs.map(productionJob => (
          <ul key={productionJob.id} className={classes.list}>
            <div className={classes.root}>
              <div className={classes.breakdownSummary}>
                <Typography className={classes.title}>
                  PO: {productionJob.label} <br />
                  {productionJob.totalProductVariantQuantity}pc -
                  {productionJob.totalPrice.formatted}
                </Typography>
                <Divider className={classes.divider} light />
                {productionJob.lineItemBreakdownByProduct.map(lineBreakdown =>
                  lineBreakdown.lineItems.map(lineItem => (
                    <Typography key={lineItem.id}>
                      {`${lineItem.product.style} ${lineItem.color.name} - ${
                        lineItem.totalQuantity
                      }pc- ${lineItem.totalPrice.formatted} `}
                    </Typography>
                  ))
                )}
                <Divider className={classes.divider} light />
                {productionJob.customizationAreas.map(printArea => (
                  <Typography key={printArea.id.concat("", printArea.location)}>
                    {`${printArea.location.toUpperCase()} - ${
                      customizationMethod[printArea.method]
                    }`}
                  </Typography>
                ))}
              </div>

              <Grid className={classes.proofsWrapper} container spacing={0}>
                {productionJob.filteredProofs.length > 0 ? (
                  productionJob.filteredProofs.map(proof => (
                    <Grid className={classes.proof} key={proof.id} item md>
                      <li>
                        <DesignProofPreview
                          height={125}
                          proof={filter(
                            designProofPreviewFragments.proof,
                            proof
                          )}
                          width={175}
                        />
                      </li>
                    </Grid>
                  ))
                ) : (
                  <div>
                    <Typography component="p">No artwork</Typography>
                  </div>
                )}
              </Grid>
            </div>
          </ul>
        ))}
      </CardContent>
    </Card>
  </React.Fragment>
);

export default withStyles(styles)(CustomerArchiveDesignProofList);
