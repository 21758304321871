// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import AppBar from "../../../components/AppBar";
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";

import type { HOC } from "recompose";

type State = {|
  searchQuery: ?string
|};

const ORDERS_PER_PAGE = 25;

const enhancer: HOC<*, *> = compose(
  setDisplayName("VinylApp"),

  withStateHandlers(({ searchQuery: null }: State), {
    setSearchQuery: () => (searchQuery: string) => ({
      searchQuery
    })
  }),

  graphql(query, {
    options: ({ searchQuery }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          searchQuery: searchQuery
        }
      },
      fetchPolicy: "network-only"
    })
  }),

  withHandlers({
    handleSearchQuerySubmit: ({ setSearchQuery }) => searchQuery => {
      setSearchQuery(searchQuery);
    },

    handleAppBarRequestBack: ({ router }) => () => {
      router.push("/apps");
    }
  })
);

const VinylApp = ({
  data,
  handleAppBarRequestBack,
  handleSearchQuerySubmit
}) => (
  <ProductionAppWithSidebar
    data={data}
    destinationRoute="/apps/production/vinyl/orders"
    errorMessage={data.error ? data.error.message : null}
    fetchMore={data.fetchMore}
    loading={data.loading}
    ordersConnection={data.ordersConnection}
  >
    <AppBar
      title="Vinyl"
      onSearch={handleSearchQuerySubmit}
      onRequestBack={handleAppBarRequestBack}
    />
  </ProductionAppWithSidebar>
);

export default enhancer(VinylApp);
