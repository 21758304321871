// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment VinylOrderStatus_order on Order {
      id
      canViewerCompleteVinyl
      canViewerCutVinyl
      canViewerPlaceOnHold
      canViewerRemoveHold
      vinylStatus
    }
  `
};
