// @flow

import { gql } from "@apollo/client";

export default {
  customizationAreaTemplates: gql`
    fragment CustomizationAreaRadioList_customizationAreaTemplates on CustomizationAreaTemplate {
      description
      id
    }
  `
};
