// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../components/AppBar";
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";

import type { HOC } from "recompose";

type State = {|
  searchQuery: ?string
|};

const ORDERS_PER_PAGE = 25;

const enhancer: HOC<*, *> = compose(
  setDisplayName("HDDPressApp"),

  withRouter,

  withStateHandlers(({ searchQuery: null }: State), {
    setSearchQuery: () => (searchQuery: string) => ({
      searchQuery
    })
  }),

  graphql(query, {
    options: ({ searchQuery }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          searchQuery: searchQuery
        },
        runQuery: searchQuery && searchQuery.trim().length > 0 ? true : false
      },
      fetchPolicy: "network-only"
    })
  }),

  withHandlers({
    handleSearchQuerySubmit:
      ({ setSearchQuery }) =>
      searchQuery => {
        setSearchQuery(searchQuery);
      },

    handleAppBarRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl ? backUrl : "/apps");
      }
  })
);

const HDDPressApp = ({
  data,
  handleAppBarRequestBack,
  handleSearchQuerySubmit,
  appBarBackgroundColor
}) => (
  <ProductionAppWithSidebar
    data={data}
    destinationRoute="/apps/production/hdd-press/orders"
    errorMessage={data.error ? data.error.message : null}
    fetchMore={data.fetchMore}
    loading={data.loading}
    ordersConnection={data.ordersConnection}
  >
    <AppBar
      title="HDD Pressing"
      onSearch={handleSearchQuerySubmit}
      onRequestBack={handleAppBarRequestBack}
      backgroundColor={appBarBackgroundColor}
    />
  </ProductionAppWithSidebar>
);

export default enhancer(HDDPressApp);
