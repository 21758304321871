// @flow

import * as React from "react";
import { compose, mapProps, setDisplayName } from "recompose";
import { getIconForProductionJobMethod } from "../../helpers/getIconForProductionJobMethod";
import { withStyles } from "@mui/styles";
import Badge from "@mui/material/Badge";

import type { HOC } from "recompose";
import type { HighDefinitionDigitalStatus } from "../../types/HighDefinitionDigitalStatus";
import type { OrderMethodStatus } from "../../types/OrderMethodStatus";

type OrderMethods = Array<{|
  +method: string,
  +status: string
|}>;
type Props = {|
  +classes?: {|
    +icon: string
  |},
  +productionMethodStatuses: {|
    +directToGarmentStatus: OrderMethodStatus,
    +embroideryStatus: OrderMethodStatus,
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +screenPrintingStatus: OrderMethodStatus,
    +vinylStatus: OrderMethodStatus
  |}
|};

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionMethodIconSet"),
  mapProps(
    ({
      productionMethodStatuses: {
        directToGarmentStatus,
        highDefinitionDigitalStatus,
        embroideryStatus,
        screenPrintingStatus,
        vinylStatus
      },
      ...props
    }) => {
      let orderMethods: OrderMethods = [];
      const notApplicable = "NOT_APPLICABLE";

      if (directToGarmentStatus !== notApplicable) {
        orderMethods.push({
          method: "DIRECT_TO_GARMENT",
          status: directToGarmentStatus
        });
      }

      if (highDefinitionDigitalStatus !== notApplicable) {
        orderMethods.push({
          method: "HIGH_DEFINITION_DIGITAL",
          status: highDefinitionDigitalStatus
        });
      }

      if (embroideryStatus !== notApplicable) {
        orderMethods.push({ method: "EMBROIDERY", status: embroideryStatus });
      }

      if (screenPrintingStatus !== notApplicable) {
        orderMethods.push({
          method: "SCREEN_PRINTING",
          status: screenPrintingStatus
        });
      }

      if (vinylStatus !== notApplicable) {
        orderMethods.push({
          method: "VINYL",
          status: vinylStatus
        });
      }

      return { ...props, orderMethods };
    }
  )
);

const getColorForStatus = status => {
  switch (status) {
    case "COMPLETE": {
      return "primary";
    }
    case "IN_PROGRESS": {
      return "secondary";
    }
    case "ON_HOLD": {
      return "error";
    }
    default: {
      return "default";
    }
  }
};

const ProductionMethodIconSet = ({ classes, orderMethods }) => (
  <div>
    {orderMethods.map(({ method, status }, index) => {
      const hasReadyStatus = status === "READY";
      const methodIcon = getIconForProductionJobMethod(method, index);
      return (
        status &&
        methodIcon && (
          <Badge
            badgeContent={null}
            className={classes.icon}
            key={index}
            variant="dot"
            invisible={hasReadyStatus}
            color={getColorForStatus(status)}
          >
            {methodIcon}
          </Badge>
        )
      );
    })}
  </div>
);

export default withStyles(styles)(enhancer(ProductionMethodIconSet));
