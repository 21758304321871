// @flow

import { fragments as fulfillmentLocationFragments } from "../../../../components/FulfillmentLocation/graph";
import { gql } from "@apollo/client";
import { fragments as headerToolbarFragments } from "../components/HeaderToolbar/graph";
import { fragments as notesWrapperFragments } from "../../../../components/NotesWrapper/graph";
import { fragments as orderAppProductionJobListFragments } from "../components/OrderAppProductionJobList/graph";
import { fragments as orderFulfillmentSummaryFragments } from "../../../../components/OrderFulfillmentSummary/graph";
import { fragments as orderPricingSummaryFragments } from "../../../../components/OrderPricingSummary/graph";
import { fragments as productionJobTabsFragments } from "../components/ProductionJobTabs/graph";

export default gql`
  query OrderApp($orderId: ID!) {
    order: node(id: $orderId) {
      ... on Order {
        id
        ...NotesWrapper_order
        productionJobs {
          ...ProductionJobTabs_productionJobs
        }
        ...FulfillmentLocation_order
        ...HeaderToolbar_order
        ...OrderFulfillmentSummary_order
        ...OrderPricingSummary_order
        ...OrderAppProductionJobList_order
      }
    }
  }
  ${headerToolbarFragments.order}
  ${notesWrapperFragments.order}
  ${orderFulfillmentSummaryFragments.order}
  ${orderPricingSummaryFragments.order}
  ${productionJobTabsFragments.productionJobs}
  ${orderAppProductionJobListFragments.order}
  ${fulfillmentLocationFragments.order}
`;
