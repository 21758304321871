// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { withRouter } from "found";
import CircularProgress from "@mui/material/CircularProgress";
import QaDashboardOrderDetailsCard from "../QaDashboardOrderDetailsCard";
import Ticker from "react-ticker";

import type { HOC } from "recompose";

type Props = {|
  +productionStationId: string
|};

const ORDERS_PER_PAGE = 1000;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("QaDashboardOrderDetail"),

  withRouter,

  graphql(query, {
    options: ({ productionStationId }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          productionStationId: productionStationId
        }
      },
      fetchPolicy: "network-only",
      pollInterval: 10000
    })
  })
);

const QaDashboardOrderDetail = ({ data }) =>
  data && data.loading ? (
    <CircularProgress />
  ) : (
    <React.Fragment>
      {data.ordersConnection && (
        <Ticker speed={2}>
          {({ index }) => (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {data.ordersConnection.edges
                .filter(
                  ({ node }) => node.screenPrintingStatus === "IN_PROGRESS"
                )
                .slice(
                  index * 4 >=
                    data.ordersConnection.edges.filter(
                      ({ node }) => node.screenPrintingStatus === "IN_PROGRESS"
                    ).length
                    ? 0
                    : index * 4,
                  index * 4 + 4 >
                    data.ordersConnection.edges.filter(
                      ({ node }) => node.screenPrintingStatus === "IN_PROGRESS"
                    ).length
                    ? data.ordersConnection.edges.filter(
                        ({ node }) =>
                          node.screenPrintingStatus === "IN_PROGRESS"
                      ).length
                    : index * 4 + 4
                )
                .map(edge => (
                  <QaDashboardOrderDetailsCard order={edge.node} />
                ))}
            </div>
          )}
        </Ticker>
      )}
    </React.Fragment>
  );

export default enhancer(QaDashboardOrderDetail);
