// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EmployeeChip from "../../components/EmployeeChip";
import Typography from "@mui/material/Typography";

type Props = {|
  +classes: {|
    +employeeChipListItem: string,
    +list: string,
    +orderHistoryLink: string,
    +productionAssignments: string,
    +productionJob: string,
    +productionStation: string,
    +title: string
  |},
  +order: {|
    +id: string,
    +isInHouse: boolean,
    +productionStationAssignments: $ReadOnlyArray<{|
      +employee: {|
        +name_employeeChipModule: string
      |},
      +id: string,
      +productionStation: {|
        +name: string
      |}
    |}>,
    +vendor: {|
      +organization: {|
        +name: string
      |}
    |}
  |}
|};

const styles = theme => ({
  employeeChipListItem: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0
    }
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  orderHistoryLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "none"
  },

  productionAssignments: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  productionJob: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(1),
    "&:last-child": {
      borderBottomStyle: "none"
    }
  },

  productionStation: {
    display: "inline",
    marginLeft: theme.spacing(1)
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const FulfillmentLocation = ({ classes, order }: Props) => (
  <div>
    {order.productionStationAssignments.length > 0 && (
      <div className={classes.productionAssignments}>
        <Typography className={classes.title} variant="h6">
          Fulfillment Location
        </Typography>
        <Card>
          <CardContent>
            <ul className={classes.list}>
              {order.productionStationAssignments.map(
                ({ id, employee, productionStation: { name } }) => (
                  <li key={id} className={classes.employeeChipListItem}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      variant="h6"
                    >
                      {order.isInHouse
                        ? "In House"
                        : order.vendor.organization.name}
                    </Typography>
                    <EmployeeChip employee={employee} />
                    <Typography
                      className={classes.productionStation}
                      color="textSecondary"
                      variant="body2"
                    >
                      {name}
                    </Typography>
                  </li>
                )
              )}
            </ul>
          </CardContent>
        </Card>
      </div>
    )}
  </div>
);

export default withStyles(styles)(FulfillmentLocation);
