import { compose, setDisplayName } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PendingStatusView from "../../../PendingStatusView";
import React, { useState } from "react";
import Select from "@mui/material/Select";

const enhancer = compose(
  setDisplayName("SelectShippingCarrierModal"),

  graphql(query)
);

const SelectShippingCarrierModal = ({
  isOpen,
  onRequestClose,
  onComplete,
  data
}) => {
  const [selectedShippingCarrier, setSelectedShippingCarrier] = useState(null);

  console.log("data");
  console.log(data);

  const handleCancelButtonClick = () => {
    setSelectedShippingCarrier(null);
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      onComplete({
        shippingCarrierId: selectedShippingCarrier
      });
      setSelectedShippingCarrier(null);
    }
  };

  const handleShippingCarrierChange = ({ target: { value } }) => {
    console.log("handleShippingCarrierChange");
    console.log(value);
    setSelectedShippingCarrier(value);
  };

  const shippingCarrierOptions =
    data.loading || data.error ? [] : data.shippingCarriers;

  return (
    <Dialog
      aria-describedby="Select Shipping Carrier"
      aria-labelledby="Select Shipping Carrier"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Select Shipping Carrier</DialogTitle>
      <DialogContent>
        {data.isLoading && <PendingStatusView status="Loading..." />}
        {!data.isLoading && (
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel>Shipping Carrier</InputLabel>
              <Select
                value={selectedShippingCarrier}
                label="Shipping Carrier"
                onChange={handleShippingCarrierChange}
              >
                {shippingCarrierOptions.map(shippingCarrierOption => (
                  <MenuItem
                    key={shippingCarrierOption.id}
                    value={shippingCarrierOption.id}
                  >
                    {shippingCarrierOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={selectedShippingCarrier == null}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(SelectShippingCarrierModal);
