// @flow

import { gql } from "@apollo/client";
import { fragments as productionJobListFragments } from "../../ProductionJobList/graph";

export default gql`
  query OrderDetail($orderId: ID!) {
    node(id: $orderId) {
      ... on Order {
        id
        orderNumber
        productionJobs {
          id
          ...FixPieceProductionJobList_productionJobs
        }
      }
    }
  }
  ${productionJobListFragments.productionJobs}
`;
