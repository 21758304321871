// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as localInventoryTableFragments } from "../../application/apps/LocalInventoryApp/components/LocalInventoryTable/graph";

const markOrderProductionJobAsLocalInventoryReceived: any = graphql(
  gql`
    mutation ($orderProductionJobId: ID!, $location: String!) {
      markOrderProductionJobAsLocalInventoryReceived(
        orderProductionJobId: $orderProductionJobId
        location: $location
      ) {
        errors {
          orderProductionJobId
          location
        }
        updatedOrderProductionJob {
          ...LocalInventoryTable_productionJob
          order {
            id
            isResolution
            express
            includeCatalogInShipment
            directToGarmentExpressEligible
            highDefinitionDigitalExpressEligible
            shipMissCosts {
              date
              cost {
                formatted
              }
            }
          }
        }
        succeeded
      }
    }
    ${localInventoryTableFragments.productionJob}
  `,
  {
    name: "markOrderProductionJobAsLocalInventoryReceived"
  }
);

export default markOrderProductionJobAsLocalInventoryReceived;
