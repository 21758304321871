// @flow

import * as React from "react";
import { filter } from "graphql-anywhere";
import { fragments as productionJobLineItemBreakdownFragments } from "./components/ProductionJobLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import ProductionJobLineItemBreakdown from "./components/ProductionJobLineItemBreakdown";
import Typography from "@mui/material/Typography";

const pluralize = require("pluralize");

type Props = {|
  +classes: {|
    +item: string,
    +list: string,
    +titleWrapper: string
  |},
  +productionJob: {|
    +lineItemBreakdownByProduct: $ReadOnlyArray<{|
      +product: {|
        +id: string
      |}
    |}>,
    +productCount: number,
    +totalProductVariantQuantity: number
  |}
|};

const styles = theme => ({
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  titleWrapper: {
    marginBottom: theme.spacing(0.5)
  }
});

const ProductQuantitySummary = ({
  classes,
  productionJob: {
    lineItemBreakdownByProduct,
    productCount,
    totalProductVariantQuantity
  }
}: Props) => (
  <div>
    <div className={classes.titleWrapper}>
      <Typography variant="h6" color="inherit">
        {`Associated products (${pluralize(
          "piece",
          totalProductVariantQuantity,
          true
        )} | ${pluralize("style", productCount, true)})`}
      </Typography>
    </div>
    <Card>
      <ul className={classes.list}>
        {lineItemBreakdownByProduct.map(productionJobLineItemBreakdown => (
          <li
            key={productionJobLineItemBreakdown.product.id}
            className={classes.item}
          >
            <ProductionJobLineItemBreakdown
              productionJobLineItemBreakdown={filter(
                productionJobLineItemBreakdownFragments.productionJobLineItemBreakdown,
                productionJobLineItemBreakdown
              )}
            />
          </li>
        ))}
      </ul>
    </Card>
  </div>
);

export default withStyles(styles)(ProductQuantitySummary);
