import { compose, setDisplayName, withStateHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const enhancer = compose(
  setDisplayName("FindOrderProductionJobModal"),

  withStateHandlers(
    {
      orderProductionJobLabelStartsWith: ""
    },
    {
      setOrderProductionJobLabelStartsWith:
        () => orderProductionJobLabelStartsWith => ({
          orderProductionJobLabelStartsWith
        })
    }
  ),

  graphql(query, {
    skip: ({ orderProductionJobLabelStartsWith }) =>
      orderProductionJobLabelStartsWith.length <= 3,
    options: ({ orderProductionJobLabelStartsWith }) => ({
      variables: {
        labelStartsWith: orderProductionJobLabelStartsWith
      },
      fetchPolicy: "network-only"
    })
  })
);

const FindOrderProductionJobModal = ({
  isOpen,
  onRequestClose,
  onComplete,
  setOrderProductionJobLabelStartsWith,
  orderProductionJobLabelStartsWith,
  data
}) => {
  const [selectedOrderProductionJob, setSelectedOrderProductionJob] =
    useState(null);

  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      setSelectedOrderProductionJob(null);
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      const { order: { includeCatalogInShipment } } = selectedOrderProductionJob;

      onComplete({
        lineItems: selectedOrderProductionJob.lineItemBreakdownByProduct
          .map(({ product: { style }, lineItems }) => {
            return lineItems
              .map(({ sizes, color: { name: color } }) => {
                return sizes.map(
                  ({
                    quantity,
                    size: { code: size },
                    gtin,
                    countryOfOrigin
                  }) => {
                    return {
                      identifier: gtin,
                      countryOfOrigin: countryOfOrigin,
                      description: `${style}-${color}-${size}`,
                      embroidery: (selectedOrderProductionJob.customizationAreas.some(area => area.method == 'EMBROIDERY')),
                      quantity: quantity
                    };
                  }
                );
              })
              .reduce((accumulator, value) => [...accumulator, ...value], []);
          })
          .reduce((accumulator, value) => [...accumulator, ...value], includeCatalogInShipment ? [{ identifier: "catalog01", countryOfOrigin: "CN", description: "Catalog", quantity: 1 }]  : [])
      });
      setSelectedOrderProductionJob(null);
    }
  };

  const handleOrderProductionJobChange = (e, value) => {
    setSelectedOrderProductionJob(value);
  };

  const { orderProductionJobsToBeManuallyReceivedList } = data || {
    orderProductionJobsToBeManuallyReceivedList: []
  };
  const orderProductionJobOptions =
    orderProductionJobsToBeManuallyReceivedList || [];

  return (
    <Dialog
      aria-describedby="Find Order Production Job"
      aria-labelledby="Find Order Production Job Modal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Find Order Production Job</DialogTitle>
      <DialogContent>
        <Autocomplete
          disablePortal
          value={selectedOrderProductionJob}
          onChange={handleOrderProductionJobChange}
          options={orderProductionJobOptions}
          sx={{ width: 300 }}
          inputValue={orderProductionJobLabelStartsWith}
          onInputChange={(event, value) => {
            setOrderProductionJobLabelStartsWith(value);
          }}
          renderInput={params => (
            <TextField {...params} label="Production Job" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          disabled={!selectedOrderProductionJob}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(FindOrderProductionJobModal);
