// @flow

import { fragments as customizationAreaTemplateRadioListFragments } from "../../CustomizationAreaForm/components/CustomizationAreaRadioList/graph";

import { gql } from "@apollo/client";

export default gql`
  query UpdateCustomizationAreaModal($orderProductionJobId: ID!) {
    orderProductionJob: node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        id
        availableCustomizationAreaTemplates {
          ...CustomizationAreaRadioList_customizationAreaTemplates
        }
      }
    }
    vinylFonts {
      alias
      id
    }
    vinylColors {
      id
      inkColor {
        color {
          formatted(format: HEX_WITH_POUND)
        }
        pantoneColor {
          id
          code
        }
        id
        name
      }
    }
  }
  ${customizationAreaTemplateRadioListFragments.customizationAreaTemplates}
`;
