// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment StockStagedTable_order on Order {
      isStockStaged
      productionDate
      id
      express
      includeCatalogInShipment
      orderNumber
      primaryCustomer {
        id
        name(format: FIRST_NAME_LAST_NAME)
      }
      priority
      productionJobs {
        id
        label
      }
      productionStationAssignments {
        id
        productionStation {
          id
          name
        }
      }
      totalProductVariantQuantity
    }
  `
};
