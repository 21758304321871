// @flow

const getAttributesList = (
  autobase: boolean,
  distressed: boolean,
  flatten: boolean,
  halftones: boolean,
  imageRepair: boolean,
  recreate: boolean,
  sponsorback: boolean
) => {
  let list = [];

  if (autobase) {
    list.push("Autobase");
  }
  if (distressed) {
    list.push("Distressed");
  }
  if (flatten) {
    list.push("Flatten");
  }
  if (halftones) {
    list.push("Halftones");
  }
  if (imageRepair) {
    list.push("Image Repair");
  }
  if (recreate) {
    list.push("Recreate");
  }
  if (sponsorback) {
    list.push("Sponsorback");
  }

  return list.join(", ");
};

export { getAttributesList };
