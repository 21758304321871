// @flow

import * as React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme =>
  createStyles({
    titleText: {
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  })
);

const NamesAndNumbers = ({ productionJob: { lineItemBreakdownByProduct } }) => {
  const classes = useStyles();
  const hasNamesAndNumbers = lineItemBreakdownByProduct.reduce(
    (accumlator, { lineItems }) => {
      if (accumlator) {
        return accumlator;
      }
      return lineItems.reduce((accumlator, { sizesInclusive }) => {
        if (accumlator) {
          return accumlator;
        }
        return (
          sizesInclusive.filter(
            ({ __typename, vinylNamesAndNumbers }) =>
              __typename === "OrderProductionJobLineItemSize" &&
              vinylNamesAndNumbers.length > 0
          ).length > 0
        );
      }, false);
    },
    false
  );
  if (!hasNamesAndNumbers) {
    return null;
  }
  return (
    <React.Fragment>
      <Typography className={classes.titleText} variant="h6">
        Names & Numbers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Style</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItemBreakdownByProduct.map(
            ({ product: { style }, lineItems }) => (
              <React.Fragment>
                {lineItems.map(({ color: { name: color }, sizesInclusive }) => (
                  <React.Fragment>
                    {sizesInclusive
                      .filter(
                        ({ __typename, vinylNamesAndNumbers }) =>
                          __typename === "OrderProductionJobLineItemSize" &&
                          vinylNamesAndNumbers.length > 0
                      )
                      .map(({ size: { code: size }, vinylNamesAndNumbers }) => (
                        <React.Fragment>
                          {vinylNamesAndNumbers.map(({ name, number }) => (
                            <TableRow>
                              <TableCell>{style}</TableCell>
                              <TableCell>{color}</TableCell>
                              <TableCell>{size}</TableCell>
                              <TableCell>{name}</TableCell>
                              <TableCell>{number}</TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default NamesAndNumbers;
