// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as transfersGlitterOrderStatusFragments } from "../../application/apps/TransferPrintingApp/components/TransfersGlitterOrderStatus/graph";

const markOrderAsTransfersGlitterManualPrintingStarted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsTransfersGlitterManualPrintingStarted(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...TransfersGlitterOrderStatus_order
        }
        succeeded
      }
    }
    ${transfersGlitterOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsTransfersGlitterManualPrintingStarted"
  }
);

export default markOrderAsTransfersGlitterManualPrintingStarted;
