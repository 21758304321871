// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { fragments as transfersGlitterOrderStatusFragments } from "../TransfersGlitterOrderStatus/graph";
import { fragments as transfersStandardOrderStatusFragments } from "../TransfersStandardOrderStatus/graph";
import { fragments as transfersUVOrderStatusFragments } from "../TransfersUVOrderStatus/graph";
import { withRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import TransfersGlitterOrderStatus from "../TransfersGlitterOrderStatus";
import TransfersStandardOrderStatus from "../TransfersStandardOrderStatus";
import TransfersUVOrderStatus from "../TransfersUVOrderStatus";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TransferPrintingOrderDetail"),

  withRouter,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withHandlers({
    handleAppBarRequestBack:
      ({ router, film }) =>
      () => {
        router.push(`/apps/production/transfer-printing/${film}`);
      },
    handleOrderDetailRequestClose:
      ({ router, film }) =>
      () => {
        router.push(`/apps/production/transfer-printing/${film}`);
      }
  })
);

const TransfersPrintingOrderDetail = ({
  data,
  handleAppBarRequestBack,
  handleOrderDetailRequestClose,
  appBarBackgroundColor,
  film
}) => { 
  console.log("data", data);
  console.log("film", film);
  let statusComponent = null;
  if(data && data.order) {
    switch(film.toUpperCase()) {
      case "GLITTER":
        statusComponent = (
          <TransfersGlitterOrderStatus
            order={filter(transfersGlitterOrderStatusFragments.order, data.order)}
          />
        );
        break;
      case "UV":
        statusComponent = (
          <TransfersUVOrderStatus
            order={filter(transfersUVOrderStatusFragments.order, data.order)}
          />
        );
        break;
      default:
        statusComponent = (
          <TransfersStandardOrderStatus
            order={filter(transfersStandardOrderStatusFragments.order, data.order)}
          />
        );
        break;
    }
  }
  
  return (
    <OrderDetailWithStatusHeader
      destinationRoute="/apps/production/transfer-printing/orders"
      errorMessage={data.error ? data.error.message : null}
      loading={data.loading}
      onRequestClose={handleOrderDetailRequestClose}
      order={data.order}
      orderStatusView={
        data && data.order ? statusComponent : (
          <CircularProgress />
        )
      }
      shouldShowCustomerArtworkWithNotes={false}
      shouldShowInkColors={false}
      title="Transfer - Printing"
      viewer={data.viewer}
    >
      <AppBar
        title="Transfer - Printing"
        onRequestBack={handleAppBarRequestBack}
        backgroundColor={appBarBackgroundColor}
      />
    </OrderDetailWithStatusHeader>
  )
};

export default enhancer(TransfersPrintingOrderDetail);
