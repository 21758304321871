// @flow

import * as React from "react";
import { compose, lifecycle, setDisplayName, withState } from "recompose";
import { withStyles } from "@mui/styles";
import QRCode from "qrcode";

import type { HOC } from "recompose";

type Props = {|
  +productionJobId: string
|};

const styles = () => ({
  barcode: {
    marginTop: 20,
    width: 100,
    height: 100
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobBarcode"),
  withStyles(styles),
  withState("barcode", "setBarcode", null),
  lifecycle({
    componentDidMount() {
      const { productionJobId, setBarcode } = this.props;
      QRCode.toDataURL(
        JSON.stringify({
          variant: "OBJECT",
          type: "OrderProductionJob",
          id: productionJobId
        })
      ).then(barcode => {
        setBarcode(barcode);
      });
    }
  })
);

const ProductionJobBarcode = ({ productionJobId, classes, barcode }) => (
  <React.Fragment>
    {barcode && (
      <img
        alt={`ProductionJobBarcode: ${productionJobId}`}
        src={barcode}
        className={classes.barcode}
      />
    )}
  </React.Fragment>
);

export default enhancer(ProductionJobBarcode);
