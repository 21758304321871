// @flow

import { gql } from "@apollo/client";

export default gql`
  query beltProductionStations($type: ProductionStationType) {
    productionStations: productionStations(type: $type) {
      id
      name
      type
    }
  }
`;
