// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const cancelFixPieceRequest: any = graphql(
  gql`
    mutation($fixPieceRequestId: ID!) {
      cancelFixPieceRequest(fixPieceRequestId: $fixPieceRequestId) {
        errors {
          fixPieceRequestId
        }
        canceledFixPieceRequest {
          id
        }
        succeeded
      }
    }
  `,
  {
    name: "cancelFixPieceRequest"
  }
);

export default cancelFixPieceRequest;
