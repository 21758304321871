// @flow

import { gql } from "@apollo/client";
import { fragments as orderArtworkChangesTableFragments } from "../components/OrderArtworkChangesTable/graph";
import { fragments as orderStatusHistoryTableFragments } from "../components/OrderStatusHistoryTable/graph";

export default gql`
  query OrderStatusHistorySummary($orderId: ID!) {
    order: node(id: $orderId) {
      ... on Order {
        id
        artworkChanges {
          ...OrderArtworkChangesTable_artworkChange
        }
        statusHistory {
          ...OrderStatusHistoryTable_statusHistory
        }
      }
    }
  }
  ${orderArtworkChangesTableFragments.artworkChanges}
  ${orderStatusHistoryTableFragments.statusHistory}
`;
