// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const markInventoryOrderCartonAsReceived: any = graphql(
  gql`
    mutation ($inventoryOrderCartonIdentifier: String!) {
      markInventoryOrderCartonAsReceived(
        inventoryOrderCartonIdentifier: $inventoryOrderCartonIdentifier
      ) {
        errors {
          inventoryOrderCartonIdentifier
        }
        errorStatus
        updatedInventoryOrderCartons {
          id
          name
          quantity
          inventoryOrderItem {
            id
            gtin
            quantity
            countryOfOriginOptions
            description
          }
          orderProductionJob {
            id
            totalProductVariantQuantity
            label
            inventoryOrderCartons {
              id
              name
            }
            index
            order {
              id
              productionDate
              productionJobs {
                id
                totalProductVariantQuantity
                inventoryOrderCartons {
                  id
                  received
                  name
                }
              }
            }
            stockContainers {
              id
              latestLocation {
                id
                location
              }
            }
          }
          received
          stockContainer {
            id
            orderProductionJob {
              id
              totalProductVariantQuantity
              customizationAreas {
                id
                location
                method
              }
              index
              order {
                dueBy {
                  dateTime
                }
                id
                isArtworkApproved
                isResolution
                orderNumber
                primaryCustomer {
                  id
                  name(format: FIRST_NAME_LAST_NAME)
                }
                productionDate
                productionJobs {
                  id
                  totalProductVariantQuantity
                }
                productionStationAssignments {
                  productionStation {
                    name
                  }
                }
              }
            }
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "markInventoryOrderCartonAsReceived"
  }
);

export default markInventoryOrderCartonAsReceived;
