// @flow

import * as React from "react";
import { Link } from "found";
import { compose, setDisplayName } from "recompose";
import { withStyles } from "@mui/styles";
import CustomerStatusChip, { statuses } from "./components/CustomerStatusChip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../../../../../assets/logo.png";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +base: string,
    +customerName: string,
    +customerNameStatusWrapper: string,
    +historyResearchCenterContainer: string,
    +list: string,
    +logo: string,
    +orderHistoryButton: string,
    +researchCenterLink: string,
    +researchCenterLinkContainer: string,
    +subtitle: string,
    +toolbar: string
  |},
  +onBackButtonClicked: () => void,
  +order: {|
    +id: string,
    +orderNumber: string,
    +primaryCustomer: {|
      +id: string,
      +name_headerToolbar: string,
      +status: $Keys<typeof statuses>
    |},
    +productionJobCustomizationAreaSummaries: $ReadOnlyArray<string>
  |}
|};

const enhancer: HOC<*, Props> = compose(setDisplayName("HeaderToolbar"));

const styles = theme => ({
  base: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(2)
  },

  customerName: {
    marginRight: theme.spacing(2)
  },

  customerNameStatusWrapper: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  historyResearchCenterContainer: {
    alignSelf: "flex-start"
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  logo: {
    width: 40,
    marginRight: theme.spacing(2),
    height: 40
  },

  orderContainer: {
    margin: theme.spacing(2)
  },

  orderHistoryButton: {
    marginBottom: theme.spacing(1)
  },

  subtitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },

  toolbar: {
    justifyContent: "space-between"
  },

  researchCenterLink: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    fontWeight: theme.typography.fontWeightMedium
  }
});

const HeaderToolbar = ({
  classes,
  order: {
    orderNumber,
    primaryCustomer: { name_headerToolbar, status, id, totalOrders },
    productionJobCustomizationAreaSummaries
  }
}) => (
  <div className={classes.base}>
    <Toolbar className={classes.toolbar}>
      <div>
        <div className={classes.customerNameStatusWrapper}>
          <img className={classes.logo} src={logo} alt="logo" />
          <Typography
            className={classes.customerName}
            variant="h4"
            color="inherit"
          >
            {`${name_headerToolbar ? name_headerToolbar : "N/A"} `}
            {`(${totalOrders ? totalOrders : ""})`}
          </Typography>
          <CustomerStatusChip borderColor={"#FFFFFF"} status={status} />
        </div>
        <Typography variant="h4" color="inherit">
          {`Order #${orderNumber}`}
        </Typography>
        {productionJobCustomizationAreaSummaries.length > 0 && (
          <ul className={classes.list}>
            {productionJobCustomizationAreaSummaries.map(
              (customizationAreaSummary, i) => (
                <li key={i}>
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className={classes.subtitle}
                  >
                    {customizationAreaSummary}
                  </Typography>
                </li>
              )
            )}
          </ul>
        )}
      </div>
      <div className={classes.historyResearchCenterContainer}>
        <Typography variant="body2">
          <Link
            className={classes.researchCenterLink}
            to={`/apps/production/customers/${id}`}
          >
            Visit Research Center
          </Link>
        </Typography>
      </div>
    </Toolbar>
  </div>
);

export default withStyles(styles)(enhancer(HeaderToolbar));
