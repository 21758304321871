// @flow

import * as React from "react";
import { fragments as customizationAreaArtworkListFragments } from "../../../../../../../components/CustomizationAreaArtworkList/graph";
import { fragments as designProofListFragments } from "../../../../../../../components/DesignProofList/graph";
import { filter } from "graphql-anywhere";
import CardContent from "@mui/material/CardContent";
import CustomizationAreaArtworkList from "../CustomizationAreaArtworkList";
import DesignProofList from "../../../../../../../components/DesignProofList";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  artworkContainer: {
    padding: theme.spacing(1)
  },

  artworkDetailsContainer: {
    display: "flex",
    marginBottom: theme.spacing(1)
  },

  proofsProductContainer: {
    display: "flex",
    padding: theme.spacing(1)
  },

  artworkFilesContainer: {
    width: "100%",
    marginRight: theme.spacing(1)
  },

  image: {
    maxHeight: 100
  },

  leftContainer: {
    width: "40%",
    marginRight: theme.spacing(2)
  },

  list: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  rightContainer: {
    width: "60%"
  },

  productionJobInformationText: {
    "@media (min-width: 4320px)": {
      fontSize: "36px"
    }
  },

  producStyleText: {
    "@media (min-width: 4320px)": {
      fontSize: "24px"
    }
  },

  tableCell: {
    fontWeight: "bold",
    fontSize: "14px",
    "@media (min-width: 4320px)": {
      fontSize: "28px"
    },
    padding: "4px"
  },

  tableRow: {
    height: theme.spacing(1)
  },

  tableWrapper: {
    paddingBottom: theme.spacing(1)
  },

  titleText: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

const QaDashboardProductionJobCard = ({
  classes,
  productionJob,
  productionJob: { lineItemBreakdownByProduct }
}) => (
  <CardContent>
    <div className={classes.proofsProductContainer}>
      <div className={classes.leftContainer}>
        <Typography
          variant="h6"
          className={classes.productionJobInformationText}
        >
          Proof
        </Typography>
        <div style={{ maxWidth: "782px", maxHeight: "1060px" }}>
          <DesignProofList
            proofs={filter(
              designProofListFragments.proofs,
              productionJob.filteredProofs
            )}
          />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <ul className={classes.list} style={{ flexFlow: "wrap" }}>
          {productionJob.customizationAreas.map(
            ({ artwork, location }, index) => (
              <li style={{ width: "50%" }}>
                <div className={classes.artworkDetailsContainer}>
                  <div className={classes.artworkFilesContainer}>
                    <Typography
                      variant="h6"
                      className={classes.productionJobInformationText}
                    >
                      {`${location} (${index + 1}/${
                        productionJob.customizationAreas.length
                      }) `}
                    </Typography>
                    <CustomizationAreaArtworkList
                      proofs={filter(
                        customizationAreaArtworkListFragments.proofs,
                        artwork
                      )}
                    />
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
        <div className={classes.artworkContainer}>
          <Typography
            variant="h6"
            className={classes.productionJobInformationText}
          >
            {`Products (${lineItemBreakdownByProduct.length})`}
          </Typography>
          <div className={classes.list}>
            {lineItemBreakdownByProduct.map((breakdown, index) => (
              <React.Fragment key={`lineItem${index}`}>
                <div style={{ display: "flex" }}>
                  <div>
                    {breakdown.lineItems.map((item, index) => (
                      <React.Fragment key={`breakdown${index}`}>
                        {item.product.primaryImage && (
                          <div
                            style={{
                              float: "left",
                              marginRight: "8px",
                              marginLeft: "8px"
                            }}
                          >
                            <img
                              alt={item.product.name}
                              className
                              src={item.primaryImage.asset.url.formatted}
                            />
                          </div>
                        )}
                        <div style={{ float: "right" }}>
                          <Typography
                            variant="h6"
                            className={classes.producStyleText}
                          >
                            {`${item.product.style}/${item.color.name}`}
                          </Typography>
                          <Table>
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                {item.sizes.map((size, index) => (
                                  <TableCell className={classes.tableCell} key={`size${index}`}>
                                    {size.size.code}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className={classes.tableRow}>
                                {item.sizes.map((size, index) => (
                                  <TableCell className={classes.tableCell} key={`size${index}`}>
                                    {size.quantity}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  </CardContent>
);

export default withStyles(styles)(QaDashboardProductionJobCard);
