// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +root: string,
    +table: string
  |},
  +router: {|
    +push: (location: string) => void
  |}
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionListApp"),
  withHandlers({
    handleTableRowListItemClick: ({ router }) => (orderNumber: string) => {
      router.push(`/apps/production/orders/${orderNumber}`);
    }
  })
);

const styles = theme => ({
  root: {
    margin: theme.spacing(2)
  },
  table: {
    minWidth: 700
  }
});

let id = 0;
function createOrderData(
  orderNumber,
  printMethod,
  fulfillmentDate,
  contact,
  balanceDue
) {
  id += 1;
  return { id, orderNumber, printMethod, fulfillmentDate, contact, balanceDue };
}

const rows = [
  createOrderData(
    "T3JkZXI6NDU4NTcx",
    "DTG",
    "06/06/2006",
    "m.matranga@printfly.com",
    "0.01"
  )
];

const ProductionListApp = ({ classes, handleTableRowListItemClick }) => {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Production Lists
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell>Print Method</TableCell>
              <TableCell>Fulfillment Date</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.id}
                onClick={handleTableRowListItemClick.bind(
                  null,
                  row.orderNumber
                )}
              >
                <TableCell component="th" scope="row">
                  {row.orderNumber}
                </TableCell>
                <TableCell>{row.printMethod}</TableCell>
                <TableCell>{row.fulfillmentDate}</TableCell>
                <TableCell>{row.contact}</TableCell>
                <TableCell>{row.balanceDue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(withRouter(enhancer(ProductionListApp)));
