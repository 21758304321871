// @flow

import { fragments as designProofPreviewFragments } from "../../../../../../components/DesignProofPreview/graph";
import { gql } from "@apollo/client";

export default {
  proofs: gql`
    fragment CustomerArchiveDesignProofList_proofs on Proof {
      id
      ...DesignProofPreview_proof
    }
    ${designProofPreviewFragments.proof}
  `
};
