// @flow

import { fragments as employeeChipFragments } from "../../../../../../components/EmployeeChip/graph";
import { gql } from "@apollo/client";

export default {
  customer: gql`
    fragment CustomerArchiveHeader_customer on Customer {
      lifetimeValue {
        formatted
      }
      name_customerArchiveHeader: name(format: FIRST_NAME_LAST_NAME)
      primaryPhoneNumber {
        formatted
      }
      totalOrders
      relationshipOwner {
        ...EmployeeChip_order
      }
      status
    }
    ${employeeChipFragments.employee}
  `
};
