// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const addProofToOrderProductionJob: any = graphql(
  gql`
    mutation ($orderProductionJobId: ID!, $fileIdentifier: String!, $force: Boolean) {
      addProofToOrderProductionJob(
        orderProductionJobId: $orderProductionJobId
        fileIdentifier: $fileIdentifier
        force: $force
      ) {
        errors {
          orderProductionJobId
          fileIdentifier
        }
        updatedOrderProductionJob {
          id
        }
        succeeded
      }
    }
  `,
  {
    name: "addProofToOrderProductionJob"
  }
);

export default addProofToOrderProductionJob;
