// @flow

import { gql } from "@apollo/client";

export default gql`
query customsShipment($id: ID!) {
    node(id: $id) {
      __typename
      ... on CustomsShipment {
        id
        pallets {
          id
          cartons {
            id
            lineItems {
              carrierName
            }
          }
        }
      }
    }
  }
`;
