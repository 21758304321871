// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { fragments as dtgOrderStatusFragments } from "../HDDOrderStatus/graph";
import { filter } from "graphql-anywhere";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import HDDOrderStatus from "../HDDOrderStatus";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("HDDOrderDetail"),

  withRouter,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withHandlers({
    handleAppBarRequestBack:
      ({ router }) =>
      () => {
        router.push("/apps/production/hdd-press");
      },
    handleOrderDetailRequestClose:
      ({ router }) =>
      () => {
        router.push("/apps/production/hdd-press");
      }
  })
);

const HDDOrderDetail = ({
  data,
  handleAppBarRequestBack,
  handleOrderDetailRequestClose,
  appBarBackgroundColor
}) => (
  <OrderDetailWithStatusHeader
    destinationRoute="/apps/production/hdd-press/orders"
    errorMessage={data.error ? data.error.message : null}
    loading={data.loading}
    onRequestClose={handleOrderDetailRequestClose}
    order={data.order}
    orderStatusView={
      data && data.order ? (
        <HDDOrderStatus
          order={filter(dtgOrderStatusFragments.order, data.order)}
        />
      ) : (
        <CircularProgress />
      )
    }
    shouldShowCustomerArtworkWithNotes={false}
    shouldShowInkColors={false}
    title="DTG"
    viewer={data.viewer}
  >
    <AppBar
      title="HDD"
      onRequestBack={handleAppBarRequestBack}
      backgroundColor={appBarBackgroundColor}
    />
  </OrderDetailWithStatusHeader>
);

export default enhancer(HDDOrderDetail);
