// @flow

import { fragments as dtgOrderStatusFragments } from "../../application/apps/DTGApp/components/DTGOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsDtgStarted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsDtgStarted(orderId: $orderId) {
        errors {
          orderId
        }
        order {
          ...DTGOrderStatus_order
        }
        succeeded
      }
    }
    ${dtgOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsDtgStarted"
  }
);

export default markOrderAsDtgStarted;
