// @flow

import { fragments as embroideryOrderStatusFragments } from "../../application/apps/EmbroideryApp/components/EmbroideryOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsEmbroideryStarted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsEmbroideryStarted(orderId: $orderId) {
        errors {
          orderId
        }
        order {
          ...EmbroideryOrderStatus_order
        }
        succeeded
      }
    }
    ${embroideryOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsEmbroideryStarted"
  }
);

export default markOrderAsEmbroideryStarted;
