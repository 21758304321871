// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  isLoading: boolean
|};

const enhancer: HOC<*, *> = compose(
  setDisplayName("FilmCheckApp"),

  withApollo,

  withRouter,

  withSnackbar,

  withStateHandlers(
    ({
      isLoading: false,
      screenLocation: null,
      orderNumber: null,
      highDefinitionDigitalStatus: null
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),
      setScreenLocation: () => (screenLocation: string) => ({ screenLocation }),
      setOrderNumber: () => (orderNumber: string) => ({ orderNumber }),
      setHighDefinitionDigitalStatus:
        () => (highDefinitionDigitalStatus: string) => ({
          highDefinitionDigitalStatus
        })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl || "/apps");
      },

    handleBarcodeError:
      ({ showErrorSnackbar, setIsLoading }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
        setIsLoading(false);
      },

    handleBarcodeScan:
      ({
        client,
        setIsLoading,
        setScreenLocation,
        setOrderNumber,
        setHighDefinitionDigitalStatus,
        showErrorSnackbar
      }) =>
      barcode => {
        try {
          const data = JSON.parse(barcode);
          const { variant, type, id } = data;
          if (variant === "OBJECT" && type === "OrderProductionJob" && id) {
            setIsLoading(true);
            client
              .query({
                query,
                variables: { id },
                fetchPolicy: "network-only"
              })
              .then(({ data: { node } }) => {
                if (node) {
                  const {
                    order: { orderNumber, highDefinitionDigitalStatus },
                    latestScreenLocation
                  } = node;
                  setScreenLocation(latestScreenLocation?.location);
                  setOrderNumber(orderNumber);
                  setHighDefinitionDigitalStatus(highDefinitionDigitalStatus);
                } else {
                  setScreenLocation(null);
                  setOrderNumber(null);
                  setHighDefinitionDigitalStatus(null);
                  showErrorSnackbar("Order not found.");
                }
              })
              .catch(e => {
                showErrorSnackbar(e.message);
              })
              .finally(() => setIsLoading(false));
          }
        } catch {
          setIsLoading(false);
          showErrorSnackbar("Error Scanning Barcode");
        }
      }
  })
);

const FilmCheckApp = ({
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  highDefinitionDigitalStatus,
  isLoading,
  orderNumber,
  screenLocation,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Audit Film Status"
      appBarBackgroundColor={appBarBackgroundColor}
      onRequestBack={handleAppHeaderRequestBack}
    />
    {isLoading ? (
      <PendingStatusView status="Loading" />
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        {orderNumber ? (
          <React.Fragment>
            <Typography
              variant="h6"
              align="center"
              color="inherit"
              style={{ flexGrow: 1, marginTop: "10em" }}
            >
              Order #: {orderNumber}
            </Typography>
            <Typography variant="h6" align="center" color="inherit">
              HDD Status: {highDefinitionDigitalStatus}
            </Typography>
            <Typography variant="h6" align="center" color="inherit">
              Film Location: {screenLocation}
            </Typography>
          </React.Fragment>
        ) : (
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ flexGrow: 1, marginTop: "10em" }}
          >
            Scan Staging Label to check Film Location
          </Typography>
        )}
      </div>
    )}
  </div>
);

export default enhancer(FilmCheckApp);
