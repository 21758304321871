// @flow

import * as React from "react";
import { branch, compose, setDisplayName } from "recompose";
import { fragments as designProofListItemFragments } from "./components/DesignProofListItem/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import DesignProofListItem from "./components/DesignProofListItem";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +item: string,
    +list: string
  |},
  +proofs: $ReadOnlyArray<{|
    +id: string
  |}>
|};

const noArtworkMessage = () => () => {
  return <Typography variant="body2">No artwork.</Typography>;
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("DesignProofList"),
  branch(({ proofs }) => proofs.length === 0, noArtworkMessage)
);

const styles = () => ({
  list: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    listStyleType: "none",
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0
  }
});

const DesignProofList = ({ classes, proofs, flipArtwork = false }) => (
  <ul className={classes.list}>
    {proofs.map(proof => (
      <li className={classes.item} key={proof.id}>
        <DesignProofListItem
          flipArtwork={flipArtwork}
          proof={filter(designProofListItemFragments.proof, proof)}
        />
      </li>
    ))}
  </ul>
);

export default withStyles(styles)(enhancer(DesignProofList));
