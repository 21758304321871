// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +contactColumn: string,
    +fulfillmentDateColumn: string,
    +statusColumn: string
  |},
  +fixPieceRequests: $ReadOnlyArray<{|
    +hasBeenOrdered: boolean,
    +id: string,
    +orderedAt: ?string,
    +orderProductionJob: {|
      +label: string,
      +order: {|
        +id: string,
        +orderNumber: string,
        +priority: ?string,
        +productionDate: ?string
      |}
    |}
  |}>,
  +loadingFixPieceRequestIds: $ReadOnlyArray<string>,
  +onRequestOrderGoods: (fixPieceRequestId: string) => void,
  +onRequestSort: (fixPieceRequestSortFilter: string) => void,
  +onRowClicked: (fixPieceRequestId: string) => void,
  +onScrolledToBottom: () => void
|};

const styles = theme => ({
  fulfillmentDateColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  contactColumn: {
    width: 225,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  statusColumn: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("FixPieceRequestTable"),

  withStyles(styles),

  withHandlers({
    handleOrderGoodsButtonClick: ({ onRequestOrderGoods }) => event => {
      event.stopPropagation();
      onRequestOrderGoods(event.currentTarget.id);
    },

    handleTableRowClicked: ({ onRowClicked }) => (id: string) => {
      onRowClicked(id);
    },

    handleSortRequest: ({ onRequestSort }) => event => {
      onRequestSort(event.currentTarget.id);
    }
  })
);

const FixPieceRequestTable = ({
  classes,
  fixPieceRequests,
  handleOrderGoodsButtonClick,
  handleTableRowClicked,
  handleSortRequest,
  loadingFixPieceRequestIds,
  onScrolledToBottom
}) => {
  const isScrolledToBottom = useScrollPosition().isScrolledToBottom;
  React.useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell className={classes.contactColumn}>
            <div className={classes.contactColumn}>
              <Button id="LABEL" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  PO/Order #
                </Typography>
              </Button>
            </div>
          </CustomTableCell>
          <CustomTableCell
            align="center"
            className={classes.fulfillmentDateColumn}
          >
            <Button id="ORDER_PRODUCTION_DATE" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Production Date
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.statusColumn}>
            <Typography color="textSecondary" variant="overline">
              Stock Ordered
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fixPieceRequests.map(
          ({
            canViewerPurchase,
            id,
            hasBeenOrdered,
            orderedAt,
            orderProductionJob
          }) => (
            <TableRow key={id} onClick={handleTableRowClicked.bind(null, id)}>
              <TableCell className={classes.contactColumn} scope="row">
                <div className={classes.contactColumn}>
                  <Typography variant="h6">
                    {orderProductionJob.label}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {orderProductionJob.order.orderNumber}
                  </Typography>
                </div>
              </TableCell>
              <TableCell
                align="center"
                className={classes.fulfillmentDateColumn}
              >
                {orderProductionJob.order.productionDate && (
                  <PriorityChip
                    date={orderProductionJob.order.productionDate}
                    priority={orderProductionJob.order.priority}
                  />
                )}
              </TableCell>
              <TableCell align="center" className={classes.statusColumn}>
                {hasBeenOrdered ? (
                  <Button
                    disabled={true}
                    id={id}
                    variant="contained"
                    color="primary"
                  >
                    {`Ordered: ${DateTime.fromISO(
                      orderedAt
                    ).toLocaleString(DateTime.DATETIME_SHORT)}`}
                  </Button>
                ) : loadingFixPieceRequestIds.includes(id) ? (
                  <CircularProgress />
                ) : (
                  <Button
                    id={id}
                    variant="contained"
                    color="primary"
                    disabled={!canViewerPurchase}
                    onClick={handleOrderGoodsButtonClick}
                  >
                    Order Goods
                  </Button>
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default enhancer(FixPieceRequestTable);
