// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const recordStockContainerLocation: any = graphql(
  gql`
    mutation ($location: String!, $stockContainerId: ID!) {
      recordStockContainerLocation(
        location: $location
        stockContainerId: $stockContainerId
      ) {
        errors {
          stockContainerId
        }
        updatedStockContainer {
          id
          latestLocation {
            id
            location
          }
          locationHistory {
            id
            createdAt
            employee {
              id
              name(format: FIRST_NAME_LAST_NAME)
            }
            location
          }
          orderProductionJob {
            id
            order {
              id
              highDefinitionDigitalStatus
            }
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "recordStockContainerLocation"
  }
);

export default recordStockContainerLocation;
