// @flow

import { fragments as fixPieceRequestTableFragments } from "../components/FixPieceRequestTable/graph";
import { gql } from "@apollo/client";

export default gql`
  query fixPieceRequestsToBeOrdered(
    $first: Int
    $after: String
    $filters: FixPieceRequestsToBeOrderedFilters
  ) {
    fixPieceRequestsToBeOrdered(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...FixPieceRequestTable_fixPieceRequest
        }
      }
    }
  }
  ${fixPieceRequestTableFragments.order}
`;
