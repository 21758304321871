// @flow

import { compose, setDisplayName, withStateHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import Typography from "@mui/material/Typography";

import { fragments as customizationAreaListFragments } from "../CustomizationAreaList/graph";
import { fragments as designProofListFragments } from "../../../../../components/DesignProofList/graph";
import { fragments as productQuantitySummaryFragments } from "../../components/ProductQuantitySummary/graph";
import { query } from "./graph";
import CustomerArtworkModal from "../CustomerArtworkModal";
import CustomizationAreaList from "../CustomizationAreaList";
import DesignProofList from "../../../../../components/DesignProofList";
import PendingStatusView from "../../../../../components/PendingStatusView";
import ProductQuantitySummary from "../../components/ProductQuantitySummary";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +columnContainer: string,
    +columnLeft: string,
    +columnLeftContainer: string,
    +columnRightContainer: string,
    +container: string,
    +customerOriginalsButton: string,
    +header: string,
    +moduleSpacing: string
  |},
  +orderProductionJobId: string
|};

type State = {|
  showCustomerArtworkModal: boolean
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobDetail"),
  graphql(query),
  withStateHandlers(({ showCustomerArtworkModal: false }: State), {
    handleViewCustomerOriginalsButtonClick: () => () => ({
      showCustomerArtworkModal: true
    }),
    handleCustomerArtworkModalRequestClose: () => () => ({
      showCustomerArtworkModal: false
    })
  })
);

const styles = theme => ({
  columnContainer: {
    display: "flex",
    flexWrap: "nowrap"
  },

  columnLeft: {
    display: "flex",
    flexDirection: "column"
  },

  columnLeftContainer: {
    flex: "1 1 40%",
    minWidth: "250px",
    paddingRight: theme.spacing(2)
  },

  columnRightContainer: {
    flex: "1 1 60%"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  },

  customerOriginalsButton: {
    marginBottom: theme.spacing(1)
  },

  header: {
    marginBottom: theme.spacing(1),
    minWidth: "460px"
  },

  moduleSpacing: {
    marginBottom: theme.spacing(2)
  }
});

const ProductionJobDetail = ({
  classes,
  data,
  handleCustomerArtworkModalRequestClose,
  handleViewCustomerOriginalsButtonClick,
  showCustomerArtworkModal
}) => (
  <div>
    {!data ? (
      <PendingStatusView status="Waiting" />
    ) : data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        There was an error while attempting to load this order.
      </Typography>
    ) : !data.orderProductionJob ? (
      <Typography variant="body2" color="error">
        Unfortunately that order could not be located.
      </Typography>
    ) : (
      <div className={classes.container}>
        <div className={classes.columnContainer}>
          <div className={classes.columnLeftContainer}>
            <div className={classes.header}>
              <Typography variant="h6">Print Area(s) & Artwork</Typography>
            </div>
            <Card>
              <CardContent>
                <Button
                  className={classes.customerOriginalsButton}
                  color="primary"
                  onClick={handleViewCustomerOriginalsButtonClick}
                  variant="outlined"
                >
                  View Customer Originals
                </Button>
                <div className={classes.columnLeft}>
                  <DesignProofList
                    proofs={filter(
                      designProofListFragments.proofs,
                      data.orderProductionJob.filteredProofs
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className={classes.columnRightContainer}>
            <div>
              <div className={classes.moduleSpacing}>
                <CustomizationAreaList
                  customizationAreas={filter(
                    customizationAreaListFragments.customizationAreas,
                    data.orderProductionJob.customizationAreas
                  )}
                />
              </div>
              <div className={classes.moduleSpacing}>
                <ProductQuantitySummary
                  productionJob={filter(
                    productQuantitySummaryFragments.productionJob,
                    data.orderProductionJob
                  )}
                />
              </div>
              <CustomerArtworkModal
                isOpen={!!showCustomerArtworkModal}
                orderProductionJobId={data.orderProductionJob.id}
                onRequestClose={handleCustomerArtworkModalRequestClose}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default withStyles(styles)(enhancer(ProductionJobDetail));
