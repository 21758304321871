// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsStockStaged: (
  ordersPerPage?: number
) => any = _ordersPerPage => { // eslint-disable-line no-unused-vars
  return graphql(
    gql`
      mutation($orderId: ID!) {
        markOrderAsStockStaged(orderId: $orderId) {
          errors {
            orderId
          }
          order {
            id
            isStockStaged
          }
          succeeded
        }
      }
    `,
    {
      name: "markOrderAsStockStaged"
    }
  );
}

export default markOrderAsStockStaged;
