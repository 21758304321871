// @flow

import { gql } from "@apollo/client";

export default gql`
  query orderProofing($id: ID!) {
    productsWithMask(first: 100) {
      edges {
        node {
          id
          style
        }
      }
    }
    node(id: $id) {
      ... on Order {
        id
        orderNumber
        primaryCustomer {
          id
          name(format: FIRST_NAME_LAST_NAME)
        }
        productionJobs {
          id
          label
          customizationAreas {
            id
            template {
              id
              code
            }
            productionArtwork {
              id
              remoteAsset {
                url {
                  formatted
                }
              }
            }
            vinylConfiguration {
              id
              includeNames
              includeNumbers
              sizeInteger
              namesColor {
                id
                inkColor {
                  id
                  color {
                    formatted(format: HEX_WITH_POUND)
                  }
                }
              }
              namesFont {
                id
                alias
              }
              numbersColor {
                id
                inkColor {
                  id
                  color {
                    formatted(format: HEX_WITH_POUND)
                  }
                }
              }
              numbersFont {
                id
                alias
              }
            }
          }
          lineItemBreakdownByProduct {
            product {
              id
              style
              colorAssociations {
                id
                color {
                  id
                  color {
                    formatted(format: HEX_WITH_POUND)
                  }
                }
              }
            }
            lineItems {
              id
              color {
                id
                name
                code
                color {
                  formatted(format: HEX_WITH_POUND)
                }
              }
            }
          }
        }
      }
    }
  }
`;
