// @flow

import * as React from "react";
import { compose, mapProps, setDisplayName } from "recompose";
import { fragments as customerArchiveOrderSummaryFragments } from "../CustomerArchiveOrderSummary/graph";
import { filter } from "graphql-anywhere";
import { fragments as notesWrapperFragments } from "../../../../../components/NotesWrapper/graph";
import { withStyles } from "@mui/styles";
import CustomerArchiveDesignProofList from "../CustomerArchiveDesignProofList";
import CustomerArchiveOrderSummary from "../CustomerArchiveOrderSummary";
import NotesWrapper from "../../../../../components/NotesWrapper";
import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +flexContainer: string,
    +left: string,
    +right: string,
    +root: string
  |},
  +order: {|
    +productionJobs: $ReadOnlyArray<{|
      +filteredProofs: $ReadOnlyArray<{||}>,
      +id: string,
      +label: string
    |}>,
    +totalPrice: {|
      +formatted: string
    |},
    +totalProductVariantQuantity: number
  |}
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaListItem"),
  mapProps(({ classes, order }) => ({
    classes,
    order
  }))
);

const styles = theme => ({
  flexContainer: {
    display: "flex",
    width: "100%"
  },

  left: {
    minWidth: 350,
    padding: theme.spacing(2)
  },

  right: {
    flex: "1 1 60%",
    overflowY: "scroll",
    minWidth: 350,
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  notesWrapperSpacing: {
    flex: "1 1 25%",
    overflowY: "scroll"
  },

  root: {
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column"
  }
});

const CustomizationAreaListItem = ({ classes, order }) => (
  <div className={classes.root}>
    <div className={classes.flexContainer}>
      <div className={classes.left}>
        <CustomerArchiveOrderSummary
          order={filter(customerArchiveOrderSummaryFragments.order, order)}
        />
      </div>
      <div className={classes.right}>
        <CustomerArchiveDesignProofList order={order} />
      </div>
      <div className={classes.notesWrapperSpacing}>
        <NotesWrapper order={filter(notesWrapperFragments.order, order)} />
      </div>
    </div>
  </div>
);

export default withStyles(styles)(enhancer(CustomizationAreaListItem));
