// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as vinylOrderStatusFragments } from "../../application/apps/VinylApp/components/VinylOrderStatus/graph";

const markOrderAsVinylCompleted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsVinylCompleted(orderId: $orderId) {
        errors {
          orderId
        }
        order {
          ...VinylOrderStatus_order
        }
        succeeded
      }
    }
    ${vinylOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsVinylCompleted"
  }
);

export default markOrderAsVinylCompleted;
