// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderProductionJobAsStockReceived: (
  ordersPerPage: number
) => any = _ordersPerPage => { // eslint-disable-line no-unused-vars
  return graphql(
    gql`
      mutation($orderProductionJobId: ID!) {
        markOrderProductionJobAsStockReceived(
          orderProductionJobId: $orderProductionJobId
        ) {
          errors {
            orderProductionJobId
          }
          order {
            id
          }
          succeeded
        }
      }
    `,
    {
      name: "markOrderProductionJobAsStockReceived"
    }
  );
};

export default markOrderProductionJobAsStockReceived;
