// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as productionMethodIconSetFragments } from "../../ProductionMethodIconSet/graph";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ProductionMethodIconSet from "../../ProductionMethodIconSet";

import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +divider: string,
    +listItem: string,
    +listItemContainer: string,
    +textWrapper: string
  |},
  +order: {|
    +orderNumber: string,
    +primaryCustomer: {|
      +name_sidebarListItemCustomerName: string
    |},
    +productionDate: string
  |}
|};

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(1)
  },

  listItemContainer: {
    width: "100%"
  },

  listItem: {
    paddingBottom: 0
  },

  spaceBetweenWrapper: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("SidebarListItem"),
  withStyles(styles),
  withHandlers({
    handleItemClicked: ({ onListItemSelected, index }) => () => {
      onListItemSelected(index);
    }
  })
);

const SidebarListItem = ({
  classes,
  handleItemClicked,
  order,
  order: {
    orderNumber,
    primaryCustomer: { name_sidebarListItemCustomerName },
    productionDate
  }
}) => (
  <ListItem className={classes.listItem} onClick={handleItemClicked} button>
    <div className={classes.listItemContainer}>
      <div className={classes.spaceBetweenWrapper}>
        <Typography variant="body1">
          {name_sidebarListItemCustomerName}
        </Typography>
        {productionDate && (
          <Typography color="textSecondary" variant="caption">
            {DateTime.fromISO(productionDate).toLocaleString(
              DateTime.DATE_SHORT
            )}
          </Typography>
        )}
      </div>
      <div className={classes.spaceBetweenWrapper}>
        <Typography variant="body1">{orderNumber}</Typography>
        <div>
          <ProductionMethodIconSet
            productionMethodStatuses={filter(
              productionMethodIconSetFragments.order,
              order
            )}
          />
        </div>
      </div>
      <Divider light={true} className={classes.divider} />
    </div>
  </ListItem>
);

export default enhancer(SidebarListItem);
