// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  isLoading: boolean
|};

const enhancer: HOC<*, *> = compose(
  setDisplayName("ArtReadyApp"),

  withApollo,

  withRouter,

  withSnackbar,

  withStateHandlers(
    ({
      isLoading: false
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack: ({ router }) => () => {
      router.push("/apps");
    },

    handleBarcodeError: ({ showErrorSnackbar, setIsLoading }) => () => {
      showErrorSnackbar("Error scanning barcode");
      setIsLoading(false);
    },

    handleBarcodeScan: ({
      client,
      setIsLoading,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => barcode => {
      try {
        const data = JSON.parse(barcode);
        const { variant, type, id } = data;
        if (
          variant === "OBJECT" &&
          (type === "StockContainer" || type === "OrderProductionJob") &&
          id
        ) {
          setIsLoading(true);
          client
            .query({
              query,
              variables: { id },
              fetchPolicy: "network-only"
            })
            .then(({ data: { node, node: { __typename } } }) => {
              console.log(node);
              const orderProductionJob =
                __typename === "OrderProductionJob"
                  ? node
                  : node.orderProductionJob;
              const {
                order: { artworkStatus }
              } = orderProductionJob;

              console.log(`ARTWORK STATUS: ${artworkStatus}`);
              if (
                artworkStatus === "COMPLETE" ||
                artworkStatus === "REVIEWED" ||
                artworkStatus === "APPROVED"
              ) {
                showSuccessSnackbar("Artwork Ready");
                playAudioForSuccess();
              } else {
                showErrorSnackbar(`Artwork NOT Ready: ${artworkStatus}`);
              }
            })
            .catch(e => {
              showErrorSnackbar(e.message);
            })
            .finally(() => setIsLoading(false));
        }
      } catch {
        setIsLoading(false);
        showErrorSnackbar("Error Scanning Barcode");
      }
    }
  })
);

const ArtReadyApp = ({
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  isLoading
}) => (
  <div>
    <TabbedAppBar
      title="Art Ready?"
      onRequestBack={handleAppHeaderRequestBack}
    />
    {isLoading ? (
      <PendingStatusView status="Loading" />
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        {false && <CenteredSpinner />}
        <Typography
          variant="h6"
          align="center"
          color="inherit"
          style={{ flexGrow: 1, marginTop: "10em" }}
        >
          Scan Carton to Check If Art is Ready
        </Typography>
      </div>
    )}
  </div>
);

export default enhancer(ArtReadyApp);
