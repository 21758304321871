// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { compose, setDisplayName, withProps } from "recompose";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import ProductionJobBarcode from "../ProductionJobBarcode";
import ProductionMethodIconSet from "../../../../../ProductionMethodIconSet";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import type { HOC } from "recompose";

const pluralize = require("pluralize");

type Props = {|
  +classes?: {|
    +imageContainer: string,
    +labelIconContainer: string,
    +latestLocation: string,
    +lineItemTotalProductVariantQuantity: string,
    +locationHistory: string,
    +popper: string,
    +proofsContainer: string,
    +tooltip: string,
    +topContainer: string
  |},
  +index: number,
  +productionJob: {|
    +customizationAreas: $ReadOnlyArray<{|
      +id: string
    |}>,
    +directToGarmentStatus: OrderMethodStatus,
    +embroideryStatus: OrderMethodStatus,
    +filteredProofs: $ReadOnlyArray<{|
      +asset: ?{|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string
    |}>,
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +id: string,
    +label: string,
    +latestBoxLocation: {
      +location: string
    },
    +latestScreenLocation: {
      +location: string
    },
    +screenPrintingStatus: OrderMethodStatus,
    +totalProductVariantQuantity: number,
    +vinylStatus: OrderMethodStatus
  |},
  +shouldShowClaimButton?: boolean
|};

const styles = theme => ({
  topContainer: {
    display: "flex",
    flexBasis: "100%"
  },

  designProofsContainer: {
    flexWrap: "wrap"
  },

  imageContainer: {
    alignSelf: "flex-end",
    borderRadius: 5,
    height: 60,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    objectFit: "cover",
    width: 80
  },

  labelIconContainer: {
    display: "flex",
    alignItems: "center"
  },

  latestLocation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "&:hover": {
      cursor: "pointer"
    }
  },

  lineItemTotalProductVariantQuantity: {
    color: theme.palette.grey[700]
  },

  locationHistory: {
    display: "flex",
    flexDirection: "column"
  },

  popper: {
    opacity: 1
  },

  proofsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2
  },

  tooltip: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobListItem"),

  withStyles(styles),

  withProps(({ productionJob }) => ({
    productionJob: {
      ...productionJob,
      proofs: productionJob.filteredProofs
    }
  }))
);

const ProductionJobListItem = ({
  classes,
  index,
  productionJob,
  productionJob: {
    id,
    boxLocationHistory,
    label,
    latestScreenLocation,
    screenLocationHistory,
    stockContainers,
    highDefinitionDigitalStatus,
    customizationAreas
  }
}) => {
  const quantity = customizationAreas.reduce((total, { quantity }) => total + quantity, 0);
  return (
    <React.Fragment>
      <div className={classes.topContainer}>
        <Typography style={{ paddingRight: "5px" }} variant="subtitle2">
          {index + 1}
        </Typography>
        <div className={classes.labelIconContainer}>
          <Typography variant="h6">
            {label}
            <ProductionMethodIconSet productionMethodStatuses={productionJob} />
            <Typography
              className={classes.lineItemTotalProductVariantQuantity}
              variant="body1"
            >
              {pluralize("transfer", quantity, true)}
            </Typography>
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={2}>
        {highDefinitionDigitalStatus === "NOT_APPLICABLE" && (
          <div>
            <ProductionJobBarcode productionJobId={id} />
          </div>
        )}
        <div>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              popper: classes.popper
            }}
            placement="right"
            disableFocusListener
            disableTouchListener
            title={
              <React.Fragment>
                {boxLocationHistory && boxLocationHistory.length > 0 && (
                  <React.Fragment>
                    <Typography variant="subtitle2">Goods:</Typography>
                    {boxLocationHistory.map(
                      ({ id, createdAt, createdBy, location }) => (
                        <div key={id} className={classes.locationHistory}>
                          <Typography variant="caption">
                            {`${location} - ${
                              createdBy.name
                            } (${DateTime.fromISO(createdAt).toLocaleString(
                              DateTime.DATETIME_SHORT
                            )})`}
                          </Typography>
                        </div>
                      )
                    )}
                  </React.Fragment>
                )}
                {screenLocationHistory && screenLocationHistory.length > 0 && (
                  <React.Fragment>
                    <Typography variant="subtitle2">Screens:</Typography>
                    {screenLocationHistory.map(
                      ({ id, createdAt, createdBy, location }) => (
                        <div key={id} className={classes.locationHistory}>
                          <Typography variant="caption">
                            {`${location} - ${
                              createdBy.name
                            } (${DateTime.fromISO(createdAt).toLocaleString(
                              DateTime.DATETIME_SHORT
                            )})`}
                          </Typography>
                        </div>
                      )
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            }
          >
            <div className={classes.latestLocation}>
              {latestScreenLocation && (
                <Typography variant="caption">{`S: ${latestScreenLocation.location}`}</Typography>
              )}
              {undefined !== stockContainers && stockContainers.length > 0 && (
                <React.Fragment>
                  <Divider light={true} className={classes.divider} />
                  <Typography
                    className={classes.productionJobLabel}
                    variant="body1"
                  >
                    Stock Containers ({stockContainers.length})
                  </Typography>
                  {stockContainers.map(({ latestLocation, id }, index) => (
                    <Typography
                      color="textSecondary"
                      key={id}
                      variant="subtitle2"
                    >
                      {index + 1} -{" "}
                      {latestLocation
                        ? latestLocation.location
                        : `Location
               Unknown`}
                    </Typography>
                  ))}
                  <Divider light={true} className={classes.divider} />
                </React.Fragment>
              )}
            </div>
          </Tooltip>
        </div>
      </Stack>
    </React.Fragment>
  );
};

export default enhancer(ProductionJobListItem);
