// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { createFixPieceRequest } from "../../../../../graph";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withStyles } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +cell: string,
    +colorLabelWrapper: string,
    +colorWrapper: string,
    +descriptionList: string,
    +descriptionTag: string,
    +divider: string,
    +labelWrapper: string,
    +orderNumberTotalBlindShippedWrapper: string,
    +productionDueDateWrapper: string,
    +quantityCell: string,
    +reasonCell: string,
    +removeCell: string,
    +styleCell: string
  |},
  +isOpen: boolean,
  +onComplete: () => void,
  +onRequestClose: () => void,
  +orderProductionJobId: ?string
|};

const styles = theme => ({
  cell: {
    padding: 5,
    "vertical-align": "top"
  },

  colorLabelWrapper: {
    padding: 5
  },

  colorWrapper: {
    padding: 5,
    border: `1px solid ${theme.palette.secondary.main}`
  },

  descriptionList: {
    alignItems: "center",
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  labelWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  quantityCell: {
    padding: 5,
    width: 150,
    "vertical-align": "top"
  },

  reasonCell: {
    padding: 5,
    width: 175,
    "vertical-align": "top"
  },

  removeCell: {
    padding: 5,
    width: 70,
    "vertical-align": "top"
  },

  styleCell: {
    "font-weight": 500,
    padding: 10,
    "vertical-align": "top"
  }
});

const EMPLOYEE_QUERY = gql`
  query RequestingEmployees($filters: ActiveEmployeeFilters) {
    activeEmployees(filters: $filters, first: 50) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

type LineItemInputErrors = {|
  orderProductionJobLineItemSizeId: $ReadOnlyArray<string>,
  orderProductionJobLineItemSizeIdValue: string,
  quantity: $ReadOnlyArray<string>,
  reasonId: $ReadOnlyArray<string>,
  reasonIndex: number
|};

type InputErrors = {|
  description: $ReadOnlyArray<string>,
  lineItems: $ReadOnlyArray<string>,
  lineItemsInput: $ReadOnlyArray<LineItemInputErrors>,
  orderProductionJobId: $ReadOnlyArray<string>,
  requestedByEmployeeId: $ReadOnlyArray<string>
|};

const defaultInputErrors: InputErrors = {
  description: [],
  lineItems: [],
  lineItemsInput: [],
  orderProductionJobId: [],
  requestedByEmployeeId: []
};

type State = {|
  descriptionInputValue: ?string,
  employees: $ReadOnlyArray<object>,
  generalErrors: $ReadOnlyArray<string>,
  inputErrors: InputErrors,
  isCreatingFixPieceRequest: boolean,
  lineItemInputValues: ?object,
  requestedByEmployeeInputValue: ?string,
  requestedByEmployeeValue: ?object
|};

const defaultState: State = {
  descriptionInputValue: "",
  employees: [],
  generalErrors: [],
  inputErrors: {
    ...defaultInputErrors
  },
  isCreatingFixPieceRequest: false,
  lineItemInputValues: {},
  requestedByEmployeeInputValue: "",
  requestedByEmployeeValue: null
};

const buildDefaultState = () => {
  return  {
    ...defaultState,
    inputErrors: {
      ...defaultInputErrors
    },
    lineItemInputValues: {}
  };
}

const enhancer: HOC<*, Props> = compose(
  setDisplayName("RequestFixPieceModal"),

  withApollo,

  graphql(query, {
    skip: ({ isOpen, orderProductionJobId }) => !isOpen || !orderProductionJobId
  }),

  createFixPieceRequest,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(buildDefaultState(), {
    resetForm: () => () => {
      return buildDefaultState();
    },

    resetGeneralErrors: () => () => ({ generalErrors: [] }),

    resetInputErrors: () => () => ({
      inputErrors: {
        ...defaultInputErrors
      }
    }),

    removeLineItemSizeReason: ({
      lineItemInputValues
    }) => (lineItemSizeId: string, index: number) => {
      let sizeReasonQuantities = lineItemInputValues[lineItemSizeId];
      if(sizeReasonQuantities) {
        sizeReasonQuantities.splice(index, 1);
      }

      lineItemInputValues[lineItemSizeId] = sizeReasonQuantities;
      return {
        lineItemInputValues: lineItemInputValues
      };
    },

    setDescriptionError: ({
      inputErrors
    }) => (error: string) => ({
      inputErrors: {
        ...inputErrors,
        description: [error]
      }
    }),

    setDescriptionInputValue: () => (descriptionInputValue: string) => ({
      descriptionInputValue
    }),

    setEmployees: () => (employees: $ReadOnlyArray<object>) => ({
      employees
    }),

    setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
      generalErrors
    }),

    setIsCreatingFixPieceRequest: () => (
      isCreatingFixPieceRequest: boolean
    ) => ({ isCreatingFixPieceRequest }),

    setInputErrors: () => (inputErrors: InputErrors) => ({ inputErrors }),

    setLineItemSizeReasonCount: ({
      lineItemInputValues
    }) => (lineItemSizeId: string, count: number) => {
      let sizeReasonQuantities = lineItemInputValues[lineItemSizeId] || Array(count).fill(null);
      sizeReasonQuantities[count] = {quantity: null, reasonId: null};

      lineItemInputValues[lineItemSizeId] = sizeReasonQuantities;
      return {
        lineItemInputValues: lineItemInputValues
      };
    },

    setLineItemSizeQuantityValue: ({
      lineItemInputValues
    }) => (lineItemSizeId: string, count: number, index: number, selected_quantity: number) => {
      let sizeReasonQuantities = lineItemInputValues[lineItemSizeId] || Array(count).fill(null);

      if(sizeReasonQuantities[index] == null) {
        sizeReasonQuantities[index] = {quantity: null, reasonId: null};
      }

      sizeReasonQuantities[index].quantity = selected_quantity;
      lineItemInputValues[lineItemSizeId] = sizeReasonQuantities;
      return {
        lineItemInputValues: lineItemInputValues
      };
    },

    setLineItemSizeReasonValue: ({
      lineItemInputValues
    }) => (lineItemSizeId: string, count: number, index: number, reasonId: ?string) => {
      let sizeReasonQuantities = lineItemInputValues[lineItemSizeId] || Array(count).fill(null);

      if(sizeReasonQuantities[index] == null) {
        sizeReasonQuantities[index] = {quantity: null, reasonId: null};
      }

      sizeReasonQuantities[index].reasonId = reasonId;
      lineItemInputValues[lineItemSizeId] = sizeReasonQuantities;
      return {
        lineItemInputValues: lineItemInputValues
      };
    },

    setRequestedByEmployeeValue: () => (requestedByEmployeeValue: ?object) => ({
      requestedByEmployeeValue
    }),

    setRequestedByEmployeeInputValue: () => (requestedByEmployeeInputValue: ?string) => ({
      requestedByEmployeeInputValue
    })
  }),

  withHandlers({
    handleAddReasonClick: ({ setLineItemSizeReasonCount }) => (event: SyntheticEvent<HTMLInputElement>) => {
      const value = event.target.attributes['value']['value'];
      const values = value.split('_');
      setLineItemSizeReasonCount(values[1], parseInt(values[2]));
    },

    handleRemoveReasonClick: ({ removeLineItemSizeReason }) => (event: SyntheticEvent<HTMLInputElement>) => {
      const value = event.target.attributes['value']['value'];
      const values = value.split('_');
      removeLineItemSizeReason(values[1], parseInt(values[2]));
    },

    handleRequestClose: ({ onRequestClose, resetForm }) => () => {
      resetForm();
      if (onRequestClose) {
        onRequestClose();
      }
    },

    handleDescriptionInputValueChange: ({ setDescriptionInputValue }) => (
      event: SyntheticEvent<HTMLInputElement>
    ) => {
      setDescriptionInputValue(event.target.value);
    },

    handleLineItemSizeQuantityChange: ({ setLineItemSizeQuantityValue }) => (
      event: SyntheticEvent<HTMLInputElement>
    ) => {
      const idValues = event.target.id.split('_');
      setLineItemSizeQuantityValue(idValues[1], parseInt(idValues[2]), parseInt(idValues[3]), (event.target.value && event.target.value.length > 0) ? parseInt(event.target.value) : null);
    },

    handleLineItemSizeReasonChange: ({ setLineItemSizeReasonValue }) => (
      event: SelectChangeEvent
    ) => {
      //TODO: how handle empty selection?
      const values = event.target.value.split('_');
      setLineItemSizeReasonValue(values[0], parseInt(values[1]), parseInt(values[2]), values[3]);
    },

    handleRequestedByEmployeeInputValueChange: ({ client, setEmployees, requestedByEmployeeValue, setRequestedByEmployeeInputValue }) => (
      event: SyntheticEvent<HTMLInputElement>
    ) => {
      if(!event || !event.target || !event.target.value) {
        setEmployees([]);
        return;
      }

      setRequestedByEmployeeInputValue(event.target.value);

      if (event.target.value === '') {
        setEmployees(requestedByEmployeeValue ? [requestedByEmployeeValue] : []);
        return;
      }

      client
      .query({
        query: EMPLOYEE_QUERY,
        variables: { filters: {searchQuery: event.target.value} }
      })
      .then(
        ({
          data: {
            activeEmployees: {
              edges
            }
          }
        }) => {
          if (edges === null || edges.length === 0) {
            setEmployees([]);
          } else {
            let options = edges.map(edge => edge.node).filter(node => node.firstName != null && node.lastName != null);
            setEmployees(options);
          }
        }
      );
    },

    handleRequestedByEmployeeValueChange: ({
      employees,
      setEmployees,
      setRequestedByEmployeeValue
    }) => (event: SyntheticEvent<HtmlInputElement>, selectedEmployee: object) => {
      setEmployees(selectedEmployee ? [selectedEmployee, ...employees] : employees);
      setRequestedByEmployeeValue(selectedEmployee);
    },

    handleSubmitButtonClick: ({
      createFixPieceRequest,
      descriptionInputValue,
      inputErrors,
      lineItemInputValues,
      onComplete,
      onRequestClose,
      orderProductionJobId,
      requestedByEmployeeValue,
      resetForm,
      resetGeneralErrors,
      resetInputErrors,
      setGeneralErrors,
      setInputErrors,
      setIsCreatingFixPieceRequest
    }) => (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      resetGeneralErrors();
      resetInputErrors();

      let failedRequiredFieldValidation = false;

      let descriptionErrors = [];
      if(descriptionInputValue == null || descriptionInputValue === "") {
        descriptionErrors = ["is required"];
        failedRequiredFieldValidation = true;
      }

      let requiredLineItemInputValues = [];
      for (const [orderProductionJobLineItemSizeId, inputValuesList] of Object.entries(lineItemInputValues)) {
        if(Array.isArray(inputValuesList)) {
          for(var j = 0; j < inputValuesList.length; j++) {
            let inputValue = inputValuesList[j];
            if(inputValue && inputValue.quantity != null && inputValue.reasonId == null) {
              requiredLineItemInputValues.push({
                orderProductionJobLineItemSizeId: [],
                orderProductionJobLineItemSizeIdValue: orderProductionJobLineItemSizeId,
                quantity: [],
                reasonId: ['is required'],
                reasonIndex: j
              });
              failedRequiredFieldValidation = true;
            }
          }
        }
      }

      if (failedRequiredFieldValidation) {
        setInputErrors({
          ...inputErrors,
          description: descriptionErrors,
          lineItemsInput: requiredLineItemInputValues
        });
        return;
      }

      setIsCreatingFixPieceRequest(true);

      let lineItemInputIndexMap = [];
      let lineItemInputs = [];
      for (const [orderProductionJobLineItemSizeId, inputValuesList] of Object.entries(lineItemInputValues)) {
        if(Array.isArray(inputValuesList)) {
          for(var i = 0; i < inputValuesList.length; i++) {
            let inputValue = inputValuesList[i];

            if(inputValue && inputValue.quantity != null && inputValue.reasonId != null) {
              lineItemInputs.push({
                orderProductionJobLineItemSizeId: orderProductionJobLineItemSizeId,
                quantity: inputValue.quantity,
                reasonId: inputValue.reasonId
              });
              lineItemInputIndexMap.push({
                orderProductionJobLineItemSizeId: orderProductionJobLineItemSizeId,
                reasonIndex: i
              });
            }
          }
        }
      }

      const variables = {
        description: descriptionInputValue,
        lineItems: lineItemInputs,
        orderProductionJobId: orderProductionJobId,
        requestedByEmployeeId: requestedByEmployeeValue ? requestedByEmployeeValue.id : null
      };

      createFixPieceRequest({
        variables
      })
        .then(
          ({
            data: {
              createFixPieceRequest: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              resetForm();

              if (onComplete) {
                onComplete();
              } else if (onRequestClose) {
                onRequestClose();
              }
            } else {
              let adjustedLineItemsInputErrors = errors.lineItemsInput;
              for(var i = 0; i < errors.lineItemsInput.length; i++) {
                adjustedLineItemsInputErrors[i].reasonIndex = lineItemInputIndexMap[i].reasonIndex;
                adjustedLineItemsInputErrors[i].orderProductionJobLineItemSizeIdValue = lineItemInputIndexMap[i].orderProductionJobLineItemSizeId;
              }

              setInputErrors({
                ...errors,
                lineItemsInput: adjustedLineItemsInputErrors
              });
            }
          }
        )
        .catch(e => {
          console.log(e);
          setGeneralErrors([e.message])
        })
        .finally(() => setIsCreatingFixPieceRequest(false));
    }
  })
);

const RequestFixPieceModal = ({
  classes,
  descriptionInputValue,
  generalErrors,
  inputErrors: {
    description,
    lineItems,
    lineItemsInput,
    requestedByEmployeeId
  },
  isOpen,
  data,
  employees,
  handleAddReasonClick,
  handleRemoveReasonClick,
  handleRequestClose,
  handleDescriptionInputValueChange,
  handleLineItemSizeQuantityChange,
  handleLineItemSizeReasonChange,
  handleRequestedByEmployeeInputValueChange,
  handleRequestedByEmployeeValueChange,
  handleSubmitButtonClick,
  isCreatingFixPieceRequest,
  lineItemInputValues,
  requestedByEmployeeValue
}) => {
  return (
    <Dialog
      aria-describedby="Request Fix Piece"
      aria-labelledby="Request Fix Piece Modal"
      open={isOpen}
      onClose={handleRequestClose}
      fullWidth={true}
      maxWidth='md'
    >
      <DialogTitle>Request Fix Pieces</DialogTitle>
      <DialogContent style={{ minHeight: 250 }}>
        {generalErrors && generalErrors.length > 0 ? (
          <Typography color="error">{generalErrors.join(", ")}</Typography>
        ) : !data ? (
          <PendingStatusView status="Waiting" />
        ) : data.loading ? (
          <PendingStatusView status="Loading" />
        ) : data.error ? (
          <div>
            <Typography variant="body2">
              There was an error while attempting to load the order production job
            </Typography>
          </div>
        ) : !data.orderProductionJob ? (
          <Typography variant="body2">
            Unfortunately, that order production job could not be located
          </Typography>
        ) : (
          <div>
            <div className={classes.labelWrapper}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                {data.orderProductionJob.label}
              </Typography>
            </div>

            <div className={classes.labelWrapper}>
              <Typography color="textSecondary" variant="subtitle2">
                {data.orderProductionJob.order.orderNumber}
              </Typography>
            </div>

            <div className={classes.productionDueDateWrapper}>
              {data.orderProductionJob.order.productionDate && (
                <div className={classes.descriptionList}>
                  <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Production Date:
                  </Typography>
                  <PriorityChip
                    date={data.orderProductionJob.order.productionDate}
                    priority={data.orderProductionJob.order.priority}
                  />
                </div>
              )}

              {data.orderProductionJob.order.dueBy.dateTime && (
                <div className={classes.descriptionList}>
                  <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Due Date:
                  </Typography>
                  <Typography variant="subtitle2">
                    {DateTime.fromISO(
                      data.orderProductionJob.order.dueBy.dateTime
                    ).toLocaleString(DateTime.DATE_SHORT)}
                  </Typography>
                </div>
              )}
            </div>

            <Divider light={true} className={classes.divider} />

            <FormControl
              error={(description.length > 0)}
              fullWidth
              variant="outlined"
            >
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                type="text"
                value={descriptionInputValue}
                onChange={handleDescriptionInputValueChange}
                label="Description"
                fullWidth
              />
              <FormHelperText>{description.length ? description.join(', ') : " "}</FormHelperText>
            </FormControl>

            <Autocomplete
              id="requested-by-employee"
              //sx={{ width: 300 }}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : (option.firstName + ' ' + option.lastName)
              }
              filterOptions={(x) => x}
              options={employees}
              autoComplete
              includeInputInList
              isOptionEqualToValue={(a, b) => {return (a && b && a['ID'] === b['ID']);}}
              filterSelectedOptions
              value={requestedByEmployeeValue}
              noOptionsText="No Employees"
              onChange={handleRequestedByEmployeeValueChange}
              onInputChange={handleRequestedByEmployeeInputValueChange}
              renderInput={(params) => (
                <TextField {...params} label="Requesting Employee" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                          {option.firstName} {option.lastName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
            <FormHelperText error={true}>{requestedByEmployeeId.length ? requestedByEmployeeId.join(', ') : " "}</FormHelperText>

            <div className={classes.tableWrapper}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.cell}>Product</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.orderProductionJob.lineItemBreakdownByProduct.map((productLineItemBreakdown, product_breakdown_index) => (
                    <TableRow key={product_breakdown_index}>
                      <TableCell scope="row" className={classes.styleCell}>
                        {productLineItemBreakdown.product.style}
                      </TableCell>
                      <TableCell>
                        {productLineItemBreakdown.lineItems.map((productLineItemBreakdownLineItem, lineItemIndex) => {
                          const filteredSizes = productLineItemBreakdownLineItem.sizesInclusive.filter(productLineItemBreakdownLineItemSize => productLineItemBreakdownLineItemSize.quantity > 0);
                          return (
                            <div key={productLineItemBreakdownLineItem.id + '_' + lineItemIndex} className={classes.colorWrapper}>
                              <div className={classes.colorLabelWrapper}>
                                <div className={classes.descriptionList}>
                                  <Typography
                                    className={classes.descriptionTag}
                                    color="textSecondary"
                                    variant="subtitle2"
                                  >
                                    Color:
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {productLineItemBreakdownLineItem.color.name}
                                  </Typography>
                                </div>
                              </div>

                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={classes.cell}>Size</TableCell>
                                    <TableCell className={classes.cell}>Total Quantity</TableCell>
                                    <TableCell className={classes.reasonCell}>Reason</TableCell>
                                    <TableCell className={classes.quantityCell}>Quantity Requested</TableCell>
                                    <TableCell className={classes.removeCell}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredSizes.map(productLineItemBreakdownLineItemSize => {
                                    let haslineItemSizeState = productLineItemBreakdownLineItemSize.id in lineItemInputValues;
                                    let lineItemSizeState = haslineItemSizeState ? lineItemInputValues[productLineItemBreakdownLineItemSize.id] : [];
                                    let lineItemReasonCount = haslineItemSizeState ? lineItemSizeState.length : 1;

                                    return (
                                      <TableRow key={productLineItemBreakdownLineItemSize.id}>
                                        <TableCell className={classes.cell}>{productLineItemBreakdownLineItemSize.size.code}</TableCell>
                                        <TableCell className={classes.cell}>{productLineItemBreakdownLineItemSize.quantity}</TableCell>
                                        <TableCell className={classes.cell} colSpan="3">
                                          <Table size="small">
                                            <TableBody>
                                              {Array(lineItemReasonCount).fill(null).map((x, reasonIndex) => {
                                                let lineItemInputError = lineItemsInput ? lineItemsInput.find(x => x.orderProductionJobLineItemSizeIdValue === productLineItemBreakdownLineItemSize.id && x.reasonIndex === reasonIndex) : null;
                                                return (
                                                <TableRow key={productLineItemBreakdownLineItemSize.id + '_' + reasonIndex}>
                                                  <TableCell className={classes.reasonCell}>
                                                    <FormControl
                                                      error={(lineItemInputError && lineItemInputError.reasonId.length > 0)}
                                                      fullWidth
                                                    >
                                                      <InputLabel>Reason</InputLabel>
                                                      <Select
                                                        size="small"
                                                        //labelId="vendor-label"
                                                        id={'lineItemSizeReason_' + productLineItemBreakdownLineItemSize.id + '_' + reasonIndex}
                                                        label="Reason"
                                                        value={(haslineItemSizeState && lineItemSizeState[reasonIndex] != null && lineItemSizeState[reasonIndex].reasonId != null) ? productLineItemBreakdownLineItemSize.id + '_' + lineItemSizeState.length + '_' + reasonIndex + '_' + lineItemSizeState[reasonIndex].reasonId : ''}
                                                        onChange={handleLineItemSizeReasonChange}
                                                      >
                                                        {data.fixPieceReasons && data.fixPieceReasons.map((reason, reason_index) => (
                                                            <MenuItem
                                                              key={'lineItemSizeReasonSelectItem_' + productLineItemBreakdownLineItemSize.id + '_' + lineItemReasonCount + '_' + reasonIndex + '_' + reason_index}
                                                              value={productLineItemBreakdownLineItemSize.id + '_' + lineItemReasonCount + '_' + reasonIndex + '_' + reason.id}
                                                            >
                                                              {reason.name}
                                                            </MenuItem>
                                                        ))}
                                                      </Select>
                                                      <FormHelperText>{lineItemInputError ? lineItemInputError.reasonId.join(', ') : ""}</FormHelperText>
                                                    </FormControl>
                                                  </TableCell>
                                                  <TableCell className={classes.quantityCell}>
                                                    <FormControl
                                                      variant="outlined"
                                                      fullWidth
                                                      error={(lineItemInputError && lineItemInputError.quantity.length > 0)}
                                                    >
                                                      <InputLabel>Quantity</InputLabel>
                                                      <OutlinedInput
                                                        size="small"
                                                        type="number"
                                                        label="QTY"
                                                        id={'lineItemSizeQuantity_' + productLineItemBreakdownLineItemSize.id + '_' + lineItemReasonCount + '_' + reasonIndex}
                                                        inputProps={{lineitemsizeid: productLineItemBreakdownLineItemSize.id, index: reasonIndex}}
                                                        onChange={handleLineItemSizeQuantityChange}
                                                        value={(haslineItemSizeState && lineItemSizeState[reasonIndex] != null ? lineItemSizeState[reasonIndex].quantity : '') || ''}
                                                      />
                                                      <FormHelperText>{lineItemInputError ? lineItemInputError.quantity.join(', ') : ""}</FormHelperText>
                                                    </FormControl>
                                                  </TableCell>
                                                  <TableCell className={classes.removeCell}>
                                                    {(reasonIndex > 0) && (
                                                      <Button
                                                        variant="text"
                                                        size="small"
                                                        onClick={handleRemoveReasonClick}
                                                        value={'lineItemSizeRemoveReason_' + productLineItemBreakdownLineItemSize.id + '_' + reasonIndex}
                                                      >
                                                        Remove
                                                      </Button>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              )})}
                                              <TableRow sx={{ "&:last-child td": { border: 0 } }}>
                                                <TableCell colSpan="3">
                                                  <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={handleAddReasonClick}
                                                    value={'lineItemSizeAddReason_' + productLineItemBreakdownLineItemSize.id + '_' + lineItemReasonCount}
                                                  >
                                                    Add Reason
                                                  </Button>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })}

                                </TableBody>
                              </Table>
                            </div>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <FormHelperText error>{lineItems.length ? lineItems.join(', ') : " "}</FormHelperText>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleRequestClose}
          color="primary"
          disabled={isCreatingFixPieceRequest}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          color="primary"
          disabled={(isCreatingFixPieceRequest || (data && data.orderProductionJob && !data.orderProductionJob.canViewerRequestFixPieces))}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(RequestFixPieceModal);
