import StandardProductionJobListItem from "./components/StandardProductionJobListItem";
import TransfersProductionJobListItem from "./components/TransfersProductionJobListItem";

const ProductionJobListItem = (props) => {
  const { productionJob: { customizationAreas } } = props;
  const methods = customizationAreas.map(({ method }) => method);
  console.log("customizationAreas", customizationAreas);
  if(methods.length > 0 && methods.length === methods.filter(method => method.startsWith("TRANSFER_")).length) { 
      return <TransfersProductionJobListItem {...props} />;
  }
  return <StandardProductionJobListItem {...props}/>;
}

export default ProductionJobListItem;