// @flow

import { gql } from "@apollo/client";
import { fragments as inkColorDetailsFragments } from "../../../../../../../../components/InkColorDetails/graph";

export default {
  customizationArea: gql`
    fragment CustomizationAreaListItem_customizationArea on OrderProductionJobCustomizationArea {
      id
      inkColors {
        id
        inkColor {
          ...InkColorDetails_inkColor
        }
        pantoneColor {
          ...InkColorDetails_pantoneColor
        }
      }
      location
      method
      notesCollection {
        ... on FormattedNote {
          author
          createdAt
          note
        }
        ... on UnformattedNote {
          note
        }
      }
      shape {
        height {
          unit
          value
        }
        width {
          unit
          value
        }
      }
    }
    ${inkColorDetailsFragments.inkColor}
    ${inkColorDetailsFragments.pantoneColor}
  `
};
