// @flow

import { gql } from "@apollo/client";

export default gql`
  query stockContainerStatusCheck($id: ID!) {
    node(id: $id) {
      ... on OrderProductionJob {
        id
        order {
          id
          isCanceled
          isInHouse
          shippingStatus
          vendor {
            id
            internal
          }
        }
      }
      ... on StockContainer {
        id
        latestLocation {
          id
          location
        }
        orderProductionJob {
          id
          order {
            id
            isCanceled
            isInHouse
            shippingStatus
            vendor {
              id
              internal
            }
          }
        }
      }
    }
  }
`;
