// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as transfersStandardOrderStatusFragments } from "../../application/apps/TransferPrintingApp/components/TransfersStandardOrderStatus/graph";

const markOrderAsTransfersStandardManualRippingCompleted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsTransfersStandardManualRippingCompleted(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...TransfersStandardOrderStatus_order
        }
        succeeded
      }
    }
    ${transfersStandardOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsTransfersStandardManualRippingCompleted"
  }
);

export default markOrderAsTransfersStandardManualRippingCompleted;
