// @flow

import { gql } from "@apollo/client";

export default gql`
  query filmCheck($id: ID!) {
    node(id: $id) {
      ... on OrderProductionJob {
        id
        order {
          id
          orderNumber
          highDefinitionDigitalStatus
        }
        latestScreenLocation {
          id
          location
        }
      }
    }
  }
`;
