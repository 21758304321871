// @flow

import { gql } from "@apollo/client";

export default {
  employee: gql`
    fragment EmployeeChip_order on Employee {
      name_employeeChipModule: name(format: FIRST_NAME_LAST_NAME)
    }
  `
};
