// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as productionJobListItemFragments } from "./components/ProductionJobListItem/graph";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import ProductionJobListItem from "./components/ProductionJobListItem";

import type { HOC } from "recompose";
type Props = {|
  +classes?: {|
    +divider: string,
    +list: string,
    +productionJob: string,
    +selectedProductionJob: string
  |},
  +onProductionJobSelected: (index: number) => void,
  +productionJobs: $ReadOnlyArray<{|
    +id: string
  |}>,
  +selectedIndex: number,
  +shouldShowClaimButton: boolean
|};

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2)
  },

  list: {
    listStyle: "none",
    margin: 0,
    paddingLeft: 0
  },

  productionJob: {
    padding: theme.spacing(1)
  },

  selectedProductionJob: {
    backgroundColor: theme.palette.mode === "dark" ?  "#424242" : theme.palette.grey[200],
    padding: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobList"),
  withStyles(styles),
  withHandlers({
    handleListItemClick: ({ onProductionJobSelected }) => (index: number) => {
      onProductionJobSelected(index);
    }
  })
);

const ProductionJobList = ({
  classes,
  handleListItemClick,
  productionJobs,
  selectedIndex,
  shouldShowClaimButton
}) => (
  <ul className={classes.list}>
    {productionJobs.map((productionJob, index) => (
      <React.Fragment key={productionJob.id}>
        <li
          className={
            index === selectedIndex
              ? classes.selectedProductionJob
              : classes.productionJob
          }
          onClick={handleListItemClick.bind(this, index)}
        >
          <ProductionJobListItem
            index={index}
            productionJob={filter(
              productionJobListItemFragments.productionJob,
              productionJob
            )}
            shouldShowClaimButton={shouldShowClaimButton}
          />
        </li>
        <Divider className={classes.divider} light />
      </React.Fragment>
    ))}
  </ul>
);

export default enhancer(ProductionJobList);
