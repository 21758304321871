// @flow

import { gql } from "@apollo/client";

export default gql`
  query shippingCarriers {
    shippingCarriers {
      id
      name
    }
  }
`;
