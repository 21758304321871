// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as orderArtworkChangesTableFragments } from "./components/OrderArtworkChangesTable/graph";
import { fragments as orderStatusHistoryTableFragments } from "./components/OrderStatusHistoryTable/graph";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import OrderArtworkChangesTable from "./components/OrderArtworkChangesTable";
import OrderStatusHistoryTable from "./components/OrderStatusHistoryTable";
import PendingStatusView from "../../../../../components/PendingStatusView";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +orderId: string
|};

const styles = theme => ({
  artChangesContainer: {
    marginBottom: theme.spacing(2)
  },

  titleSpacing: {
    margin: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderStatusHistorySummary"),

  withStyles(styles),

  graphql(query)
);

const OrderStatusHistorySummary = ({ classes, data }) => (
  <Card aria-labelledby="order-status-history-summary">
    <CardContent>
      {!data ? (
        <PendingStatusView status="Waiting" />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography variant="body2" color="error">
          There was an error while attempting to load the order
        </Typography>
      ) : !data.order ? (
        <Typography variant="body2" color="error">
          Unfortunately, that order could not be located
        </Typography>
      ) : (
        <React.Fragment>
          <div className={classes.artChangesContainer}>
            <Typography variant="h6" className={classes.titleSpacing}>
              Art Changes
            </Typography>
            <OrderArtworkChangesTable
              artworkChanges={filter(
                orderArtworkChangesTableFragments.artworkChanges,
                data.order.artworkChanges
              )}
            />
          </div>
          <Typography variant="h6" className={classes.titleSpacing}>
            Order Status History
          </Typography>
          <OrderStatusHistoryTable
            statusHistory={filter(
              orderStatusHistoryTableFragments.statusHistory,
              data.order.statusHistory
            )}
          />
        </React.Fragment>
      )}
    </CardContent>
  </Card>
);

export default enhancer(OrderStatusHistorySummary);
