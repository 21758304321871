// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment HDDOrderStatus_order on Order {
      id
      canViewerPauseHighDefinitionDigital
      canViewerQueueHighDefinitionDigital
      canViewerLocateHighDefinitionDigital
      canViewerStartHighDefinitionDigital
      canViewerStageHighDefinitionDigital
      canViewerCompleteHighDefinitionDigital
      canViewerPauseHighDefinitionDigital
      highDefinitionDigitalStatus
    }
  `
};
