// @flow

import * as React from "react";
import { ThemeContextConsumer } from "../../../../../application/themeContext";
import { compose, setDisplayName, withHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import config from "../../../../config";

import type { HOC } from "recompose";

const styles = theme => ({
  colorPrimary: {
    backgroundColor: "#7fb383"
  },
  currentViewer: {
    marginLeft: theme.spacing(1)
  },
  menuTextSpacing: {
    marginLeft: theme.spacing(1.5)
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("AppLauncherAppBar"),
  withRouter,
  graphql(query),
  withHandlers({
    handleSignOutButtonClick: () => () => {
      window.location =
        config.getOAuth2LogoutUrl() +
        "?client_id=" +
        config.getOAuth2ClientId() +
        "&return_to=" +
        window.location.origin;
    }
  }),
  withStyles(styles)
);

const AppLauncherAppBar = ({
  classes,
  handleSignOutButtonClick,
  router,
  data
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  return (
    <AppBar className={classes.colorPrimary} position="static">
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          onClick={() => {
            router.push("/mach6/apps");
          }}
          style={{ flexGrow: 1 }}
        >
          Mach6 Flynet
        </Typography>

        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <AccountCircleIcon />
            <Typography className={classes.currentViewer}>
              {data && data.viewer && data.viewer.me.name}
            </Typography>
          </MenuItem>
          <MenuItem>
            <ThemeContextConsumer>
              {context =>
                context &&
                context.componentMounted && (
                  <React.Fragment>
                    <IconButton onClick={context.toggleTheme}>
                      <BrightnessMediumIcon color="action" />
                      {context.type === "light" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Dark Mode
                        </Typography>
                      )}
                      {context.type === "dark" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Light Mode
                        </Typography>
                      )}
                    </IconButton>
                  </React.Fragment>
                )
              }
            </ThemeContextConsumer>
          </MenuItem>
          <MenuItem>
            <Button fullWidth onClick={handleSignOutButtonClick}>
              <Typography align="center">Sign out</Typography>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default enhancer(AppLauncherAppBar);
