// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ArtOrderStatus_order on Order {
      id
      artworkChanges {
        id
        changedAt
        description
        employee {
          id
          name_artworkOrderStatus: name(format: FIRST_NAME_LAST_NAME)
        }
      }
      artworkStatus
      autoApproved
      canViewerApproveArtwork
      canViewerCompleteProof
      canViewerPlaceOnArtworkHold
      canViewerPlaceOnHold
      canViewerRemoveArtworkHold
      canViewerRemoveHold
      canViewerRequestArtworkChange
      canViewerReviewArtwork
      canViewerStartArtwork
    }
  `
};
