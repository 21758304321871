// @flow

import * as React from "react";
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import GradientIcon from "@mui/icons-material/Gradient";
import TextureIcon from "@mui/icons-material/Texture";
import WavesIcon from "@mui/icons-material/Waves";

const getIconForProductionJobMethod = (method: string, index?: number) => {
  switch (method) {
    case "CUT_VINYL":
    case "PRINTED_VINYL":
    case "HEAT_PRESS":
    case "VINYL":
      return <FilterBAndWIcon key={index} style={{ color: "#f2684c" }} />;
    case "DIRECT_TO_GARMENT":
      return <FormatColorFillIcon key={index} style={{ color: "#7e51a1" }} />;
    case "HIGH_DEFINITION_DIGITAL":
      return <FormatColorFillIcon key={index} style={{ color: "#ff5686" }} />;
    case "EMBROIDERY":
    case "TACKLE_TWILL":
      return <TextureIcon key={index} style={{ color: "#69bd45" }} />;
    case "SCREEN_PRINTING":
      return <GradientIcon key={index} style={{ color: "#5bbad3" }} />;
    case "SUBLIMATION":
      return <WavesIcon key={index} />;
    default:
      return null;
  }
};

export { getIconForProductionJobMethod };
