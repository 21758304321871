// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

type Props = {|
  +statusHistory: $ReadOnlyArray<{|
    +changedAt: string,
    +classes?: {|
      +tableCell: string
    |},
    +employee: {
      +name_orderStatusHistoryTable: string
    },
    +id: string,
    +status: {
      +name: string
    }
  |}>
|};

const styles = theme => ({
  tableCell: {
    padding: theme.spacing(2)
  }
});

const OrderStatusHistoryTable = ({ classes, statusHistory }: Props) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>Status Change</TableCell>
        <TableCell className={classes.tableCell}>User</TableCell>
        <TableCell className={classes.tableCell}>Date</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {statusHistory.length === 0 ? (
        <TableRow>
          <TableCell align="center" className={classes.tableCell} colSpan={3}>
            There are no status changes for this order
          </TableCell>
        </TableRow>
      ) : (
        statusHistory.map(({ changedAt, employee, id, status }) => (
          <TableRow key={id}>
            <TableCell className={classes.tableCell}>{status.name}</TableCell>
            <TableCell className={classes.tableCell}>
              {employee && employee.name_orderStatusHistoryTable}
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              {DateTime.fromISO(changedAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            </TableCell>
          </TableRow>
        ))
      )}
    </TableBody>
  </Table>
);

export default withStyles(styles)(OrderStatusHistoryTable);
