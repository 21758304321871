// @flow

import { gql } from "@apollo/client";

export default gql`
  query CustomerArtworkModal($orderProductionJobId: ID!) {
    orderProductionJob: node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        customizationAreas {
          customerArtwork {
            file {
              id
              asset {
                url {
                  formatted
                }
              }
            }
            id
          }
          id
          location
        }
      }
      id
    }
  }
`;
