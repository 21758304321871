// @flow

import { gql } from "@apollo/client";

export default {
  artworkChanges: gql`
    fragment OrderArtworkChangesTable_artworkChange on ArtworkChange {
      changedAt
      description
      employee {
        id
        name_orderArtworkChangesTable: name(format: FIRST_NAME_LAST_NAME)
      }
      id
    }
  `
};
