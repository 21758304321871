// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../components/AppBar";
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";

import type { HOC } from "recompose";

type State = {|
  searchQuery: ?string,
  status: string
|};

const ORDERS_PER_PAGE = 25;

const enhancer: HOC<*, *> = compose(
  setDisplayName("ArtApp"),

  withRouter,

  withStateHandlers(
    ({ searchQuery: null, status: "ARTBOT_DESIGN_REVIEW" }: State),
    {
      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      })
    }
  ),

  graphql(query, {
    options: ({ searchQuery, status }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          searchQuery: searchQuery,
          status: status
        }
      },
      fetchPolicy: "network-only"
    })
  }),

  withHandlers({
    handleSearchQuerySubmit: ({ setSearchQuery }) => searchQuery => {
      setSearchQuery(searchQuery);
    },

    handleAppBarRequestBack: ({ router }) => () => {
      router.push("/apps");
    }
  })
);

const ArtApp = ({ data, handleAppBarRequestBack, handleSearchQuerySubmit }) => (
  <ProductionAppWithSidebar
    data={data}
    destinationRoute="/apps/production/art/orders"
    errorMessage={data.error ? data.error.message : null}
    fetchMore={data.fetchMore}
    loading={data.loading}
    ordersConnection={data.ordersConnection}
  >
    <AppBar
      title="Art"
      onSearch={handleSearchQuerySubmit}
      onRequestBack={handleAppBarRequestBack}
    />
  </ProductionAppWithSidebar>
);

export default enhancer(ArtApp);
