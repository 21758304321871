// @flow

import { ApolloProvider } from "@apollo/client";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ThemeContextConsumer, ThemeContextProvider } from "./themeContext";
import { createBrowserRouter } from "found";
import ArtworkProjectionContext from "./ArtworkProjectionContext";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState } from "react";
import client from "./client";
import routeConfig from "./routes";

const Router = createBrowserRouter({ routeConfig });

const App = () => {
  const [artworkProjectionWindow, setArtworkProdjectionWindow] = useState(null);

  return (
    <ArtworkProjectionContext.Provider
      value={{
        artworkProjectionEnabled: !!artworkProjectionWindow,
        updateArtworkProjection: artworkUrl => {
          artworkProjectionWindow.postMessage(artworkUrl);
        },
        launchArtworkProjection: () => {
          setArtworkProdjectionWindow(window.open("/apps/artwork-projection"));
        }
      }}
    >
      <ThemeContextProvider>
        <ThemeContextConsumer>
          {context =>
            context.componentMounted && (
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={context.theme}>
                  <CssBaseline />
                  <ApolloProvider client={client}>
                    <React.Fragment>
                      <Router />
                    </React.Fragment>
                  </ApolloProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            )
          }
        </ThemeContextConsumer>
      </ThemeContextProvider>
    </ArtworkProjectionContext.Provider>
  );
};

export default App;
