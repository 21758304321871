// @flow

import { DateTime } from "luxon";
import { compose, setDisplayName, withStateHandlers } from "recompose";
import {
  createCustomsShipment,
  markCustomsShipmentAsArchived,
  markCustomsShipmentAsShipped,
  setNorthboundCustomsShipmentFormalEntryCode,
  setSouthboundCustomsShipmentControlNumber
} from "../../graph";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CarrierPalletCountModal from "./components/CarrierPalletCountModal";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PendingStatusView from "../PendingStatusView";
import React, { useState } from "react";
import SetControlNumberModal from "./components/SetControlNumberModal";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import withSnackbar from "../withSnackbar";

import type { HOC } from "recompose";

const CUSTOMS_SHIPMENTS_PER_PAGE = 25;

function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

const styles = theme => ({
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main
  },
  tabRoot: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  tableCellRoot: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("CustomsShipmentListScreen"),

  withRouter,

  withSnackbar,

  withStyles(styles),

  createCustomsShipment,

  markCustomsShipmentAsArchived,

  markCustomsShipmentAsShipped,

  setNorthboundCustomsShipmentFormalEntryCode,

  setSouthboundCustomsShipmentControlNumber,

  withStateHandlers(
    {
      tabValue: "active"
    },
    {
      setTabValue: () => tabValue => ({
        tabValue
      })
    }
  ),

  graphql(query, {
    options: ({ direction, tabValue }) => ({
      variables: {
        first: CUSTOMS_SHIPMENTS_PER_PAGE,
        activeFilters: {
          direction: direction.toUpperCase()
        },
        shippedFilters: {
          direction: direction.toUpperCase()
        },
        archivedFilters: {
          direction: direction.toUpperCase()
        },
        active: tabValue === "active",
        shipped: tabValue === "shipped",
        archived: tabValue === "archived"
      },
      fetchPolicy: "network-only"
    })
  })
);

const CustomsShipmentListScreen = ({
  createCustomsShipment,
  data,
  direction,
  router,
  showSuccessSnackbar,
  showErrorSnackbar,
  setTabValue,
  tabValue,
  markCustomsShipmentAsArchived,
  markCustomsShipmentAsShipped,
  setSouthboundCustomsShipmentControlNumber,
  setNorthboundCustomsShipmentFormalEntryCode,
  classes
}) => {
  const [carrierPalletCountModalIsOpen, setCarrierPalletCountModalIsOpen] =
    useState(false);
  const [setControlNumberModalIsOpen, setSetControlNumberModalIsOpen] =
    useState(false);
  const [selectedCustomsShipmentId, setSelectedCustomsShipmentId] =
    useState(null);

  const {
    loading,
    error,
    refetch,
    customsShipmentsToBeShipped,
    customsShipmentsShipped,
    customsShipmentsArchived
  } = data;

  const customsShipmentConnection =
    customsShipmentsToBeShipped ||
    customsShipmentsShipped ||
    customsShipmentsArchived;

  if (loading) {
    return <PendingStatusView status="Loading" />;
  }

  if (error) {
    return null;
  }

  const handleCreateShipmentClick = () => {
    createCustomsShipment({
      variables: { direction: direction.toUpperCase() }
    }).then(refetch);
  };

  const handleMarkShipmentShipped = event => {
    event.stopPropagation();

    const {
      currentTarget: { id }
    } = event;

    setSelectedCustomsShipmentId(id);

    if(direction.toUpperCase() === "NORTHBOUND") {
      setCarrierPalletCountModalIsOpen(true);
    } else {
      markCustomsShipmentAsShipped({ variables: { customsShipmentId: id } })
        .then(
          ({
            data: {
              markCustomsShipmentAsShipped: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar("Customs shipment successfully shipped!");
            } else {
              showErrorSnackbar(errors.id.join(","));
            }
          }
        )
    }
  };

  const handleMarkShipmentArchived = event => {
    event.stopPropagation();
    const {
      currentTarget: { id }
    } = event;
    if (window.confirm("Are you sure you want to archive this shipment?")) {
      markCustomsShipmentAsArchived({
        variables: { customsShipmentId: id }
      }).then(
        ({
          data: {
            markCustomsShipmentAsArchived: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Customs shipment successfully archived!");
          } else {
            showErrorSnackbar(errors.id.join(","));
          }
        }
      );
    }
  };

  const handleSetShipmentControlNumber = (
    event,
    { id, canViewerSetControlNumber, canViewerSetFormalEntryCode }
  ) => {
    event.stopPropagation();
      
    if (canViewerSetControlNumber || canViewerSetFormalEntryCode) {
      setSelectedCustomsShipmentId(id);
      setSetControlNumberModalIsOpen(true);
    } else {
      fetch(
        `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-${direction}-shipment-manifest/${id}`
      )
        .then(response => response.text())
        .then(response => {
          if (response === "ok") {
            showSuccessSnackbar("Shipment manifest successfully sent!");
          } else {
            showErrorSnackbar(response);
          }
        });
    }
  };

  const handleTableRowClicked = id => {
    router.push(`/apps/customs/${direction}/shipment/${id}`);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSetControlNumberModalRequestClose = () => {
    setSelectedCustomsShipmentId(null);
    setSetControlNumberModalIsOpen(false);
  };

  const handleSetControlNumberModalComplete = ({
    controlNumber,
    processedBy,
    primarySealNumber,
    secondarySealNumber,
    carrierName,
    economicNumber,
    usPlates,
    mexicanPlates,
    scac,
    caat,
    boxDimension,
    numberOfDoors,
    state,
    driverName,
    carrierPalletCount
  }) => {
    console.log("carrierPalletCount", carrierPalletCount);
    if (direction.toUpperCase() === "SOUTHBOUND") {
      setSouthboundCustomsShipmentControlNumber({
        variables: {
          customsShipmentId: selectedCustomsShipmentId,
          controlNumber,
          processedBy
        }
      })
        .then(
          ({
            data: {
              setSouthboundCustomsShipmentControlNumber: { succeeded, errors }
            }
          }) => {
            setSetControlNumberModalIsOpen(false);
            if (succeeded) {
              return fetch(
                `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-${direction}-shipment-manifest/${selectedCustomsShipmentId}`
              )
                .then(response => response.text())
                .then(response => {
                  if (response === "ok") {
                    showSuccessSnackbar("Shipment manifest successfully sent!");
                  } else {
                    showErrorSnackbar(response);
                  }
                });
            } else {
              console.log("errors");
              console.log(errors);
              showErrorSnackbar(
                [...errors.customsShipmentId, ...errors.controlNumber].join(",")
              );
            }
          }
        )
        .finally(() => {
          setSetControlNumberModalIsOpen(false);
          setSelectedCustomsShipmentId(null);
        });
    } else if (direction.toUpperCase() === "NORTHBOUND") {
      setNorthboundCustomsShipmentFormalEntryCode({
        variables: {
          customsShipmentId: selectedCustomsShipmentId,
          primarySealNumber,
          secondarySealNumber,
          processedBy,
          carrierName,
          economicNumber,
          usPlates,
          mexicanPlates,
          scac,
          caat,
          boxDimension,
          numberOfDoors: numberOfDoors ? parseInt(numberOfDoors, 10) : null,
          state,
          driverName
        }
      })
        .then(
          ({
            data: {
              setNorthboundCustomsShipmentFormalEntryCode: { succeeded, errors }
            }
          }) => {
            setSetControlNumberModalIsOpen(false);
            if (succeeded) {
              return fetch(
                `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-${direction}-shipment-manifest/${selectedCustomsShipmentId}`
              )
                .then(response => response.text())
                .then(response => {
                  if (response === "ok") {
                    showSuccessSnackbar("Shipment manifest successfully sent!");
                  } else {
                    showErrorSnackbar(response);
                  }
                });
            } else {
              console.log("errors");
              console.log(errors);
              showErrorSnackbar(
                [
                  ...errors.customsShipmentId,
                  ...errors.primarySealNumber,
                  ...errors.secondarySealNumber
                ].join(",")
              );
            }
          }
        )
        .finally(() => {
          setSetControlNumberModalIsOpen(false);
          setSelectedCustomsShipmentId(null);
        });
    }
  };

  const handleCarrierPalletCountModalRequestClose = () => {
    setCarrierPalletCountModalIsOpen(false);
  };

  const handleCarrierPalletCountModalComplete = ({
    id,
    carrierPalletCount
  }) => {
    setCarrierPalletCountModalIsOpen(false);
    markCustomsShipmentAsShipped({ variables: { customsShipmentId: id } })
      .then(
        ({
          data: {
            markCustomsShipmentAsShipped: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Customs shipment successfully shipped!");
          } else {
            showErrorSnackbar(errors.id.join(","));
          }
        }
      ).then(() => {
        fetch(
          `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-northbound-shipment-ups-prealert/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ carrierPalletCount }),
          }
        )
          .then(response => response.text())
          .then(response => {
            if (response === "ok") {
              showSuccessSnackbar("UPS prealert successfully sent!");
            } else {
              showErrorSnackbar(response);
            }
          });
      });
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Grid item xs={8}>
          <Typography variant="h4">{titleCase(direction)} Shipments</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            aria-label="Create New Shipment"
            variant="contained"
            onClick={handleCreateShipmentClick}
          >
            Create New Shipment
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      <Tabs
        classes={{
          indictator: classes.tabsIndicator
        }}
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="active"
          label="Active"
        />
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="shipped"
          label="Picked up"
        />
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="archived"
          label="Archived"
        />
      </Tabs>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
              >
                Shipment
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Pallet Count
              </TableCell>
              {direction.toUpperCase() === "SOUTHBOUND" && (
                <TableCell
                  classes={{
                    root: classes.tableCellRoot
                  }}
                  align="center"
                >
                  Control #
                </TableCell>
              )}
              {direction.toUpperCase() === "NORTHBOUND" && (
                <>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    Formal Entry Code
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    Seal Number
                  </TableCell>
                </>
              )}
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Processed
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customsShipmentConnection.edges.map(
              ({
                node: {
                  id,
                  pallets,
                  createdAt,
                  archivedOn,
                  shippedOn,
                  canonicalId,
                  controlNumber,
                  canViewerSetControlNumber,
                  canViewerSetFormalEntryCode,
                  canViewerMarkAsShipped,
                  canViewerMarkAsArchived,
                  primarySealNumber,
                  processedOn,
                  processedBy,
                  secondarySealNumber
                }
              }) => (
                <TableRow
                  key={id}
                  onClick={handleTableRowClicked.bind(null, id)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    component="th"
                    scope="row"
                  >
                    #{canonicalId}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {DateTime.fromISO(createdAt).toLocaleString(
                      DateTime.DATETIME_FULL
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {archivedOn ? "ARCHIVED" : shippedOn ? "SHIPPED" : "ACTIVE"}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {pallets.length}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {controlNumber}
                  </TableCell>
                  {direction.toUpperCase() === "NORTHBOUND" && (
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot
                      }}
                      align="center"
                    >
                      {primarySealNumber &&
                        `(${primarySealNumber}, ${secondarySealNumber})`}
                    </TableCell>
                  )}
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {processedOn && (
                      <>
                        {DateTime.fromISO(processedOn).toLocaleString(
                          DateTime.DATETIME_FULL
                        )}{" "}
                        by {processedBy}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {(canViewerSetControlNumber ||
                      canViewerSetFormalEntryCode ||
                      canViewerMarkAsShipped) && (
                      <Button
                        variant="contained"
                        onClick={event =>
                          handleSetShipmentControlNumber(event, {
                            id,
                            canViewerSetControlNumber,
                            canViewerSetFormalEntryCode
                          })
                        }
                      >
                        Process to NAPS
                      </Button>
                    )}
                    {canViewerMarkAsShipped && (
                      <Button
                        variant="contained"
                        id={id}
                        onClick={handleMarkShipmentShipped}
                      >
                        Picked up
                      </Button>
                    )}
                    {canViewerMarkAsArchived && (
                      <Button
                        variant="contained"
                        id={id}
                        onClick={handleMarkShipmentArchived}
                      >
                        Archive
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SetControlNumberModal
        isOpen={setControlNumberModalIsOpen}
        onRequestClose={handleSetControlNumberModalRequestClose}
        onComplete={handleSetControlNumberModalComplete}
        controlNumberLabel={
          direction.toUpperCase() === "NORTHBOUND" ? "Formal Entry Code" : null
        }
        showSealNumbers={direction.toUpperCase() === "NORTHBOUND"}
      />
      <CarrierPalletCountModal
        isOpen={carrierPalletCountModalIsOpen}
        onRequestClose={handleCarrierPalletCountModalRequestClose}
        onComplete={handleCarrierPalletCountModalComplete}
        id={selectedCustomsShipmentId}
      />
    </Container>
  );
};

export default enhancer(CustomsShipmentListScreen);
