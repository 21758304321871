// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment HeaderToolbar_order on Order {
      id
      orderNumber
      primaryCustomer {
        id
        name_headerToolbar: name(format: FIRST_NAME_LAST_NAME)
        status
        totalOrders
      }
      productionJobCustomizationAreaSummaries
    }
  `
};
