// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const setNorthboundCustomsShipmentFormalEntryCode: any = graphql(
  gql`
    mutation (
      $customsShipmentId: ID!
      $processedBy: String!
      $primarySealNumber: String
      $secondarySealNumber: String,
      $carrierName: String,
      $economicNumber: String,
      $usPlates: String,
      $mexicanPlates: String,
      $scac: String,
      $caat: String,
      $boxDimension: String,
      $numberOfDoors: Int,
      $state: String,
      $driverName: String
    ) {
      setNorthboundCustomsShipmentFormalEntryCode(
        customsShipmentId: $customsShipmentId
        processedBy: $processedBy
        primarySealNumber: $primarySealNumber
        secondarySealNumber: $secondarySealNumber
        carrierName: $carrierName,
        economicNumber: $economicNumber,
        usPlates: $usPlates,
        mexicanPlates: $mexicanPlates,
        scac: $scac,
        caat: $caat,
        boxDimension: $boxDimension,
        numberOfDoors: $numberOfDoors,
        state: $state,
        driverName: $driverName
      ) {
        errors {
          customsShipmentId
          processedBy
          primarySealNumber
          secondarySealNumber
          carrierName
          economicNumber
          usPlates
          mexicanPlates
          scac
          caat
          boxDimension
          numberOfDoors
          state
          driverName
        }
        updatedCustomsShipment {
          id
          canonicalId
          createdAt
          shippedOn
          archivedOn
          controlNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
          processedBy
          primarySealNumber
          secondarySealNumber
          carrierName
          economicNumber
          usPlates
          mexicanPlates
          scac
          caat
          boxDimension
          numberOfDoors
          state
          driverName
        }
        succeeded
      }
    }
  `,
  {
    name: "setNorthboundCustomsShipmentFormalEntryCode"
  }
);

export default setNorthboundCustomsShipmentFormalEntryCode;
