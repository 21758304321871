// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ProductionMethodIconSet_order on Order {
      directToGarmentStatus
      embroideryStatus
      highDefinitionDigitalStatus
      screenPrintingStatus
      vinylStatus
      artworkStatus
    }
  `
};
