// @flow

import * as React from "react";
import { branch, compose, setDisplayName } from "recompose";
import { fragments as customizationAreaArtworkListItemFragments } from "./components/CustomizationAreaArtworkListItem/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import CustomizationAreaArtworkListItem from "./components/CustomizationAreaArtworkListItem";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +item: string,
    +list: string
  |},
  +proofs: $ReadOnlyArray<{|
    +id: string
  |}>
|};

const noArtworkMessage = () => () => {
  return <Typography variant="body2">No artwork.</Typography>;
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaArtworkList"),
  branch(({ proofs }) => proofs.length === 0, noArtworkMessage)
);

const styles = () => ({
  list: {
    display: "flex",
    flexFlow: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    listStyleType: "none",
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0
  }
});

const CustomizationAreaArtworkList = ({ classes, proofs }) => (
  <ul className={classes.list}>
    {proofs.map(proof => (
      <li className={classes.item} key={proof.id}>
        <CustomizationAreaArtworkListItem
          proof={filter(customizationAreaArtworkListItemFragments.proof, proof)}
        />
      </li>
    ))}
  </ul>
);

export default withStyles(styles)(enhancer(CustomizationAreaArtworkList));
