// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../components/AppBar";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";

import type { HOC } from "recompose";

type State = {|
  searchQuery: ?string
|};

const ORDERS_PER_PAGE = 25;

const enhancer: HOC<*, *> = compose(
  setDisplayName("TranfersPrintingApp"),

  withRouter,

  withStateHandlers(({ searchQuery: null }: State), {
    setSearchQuery: () => (searchQuery: string) => ({
      searchQuery
    })
  }),

  graphql(query, {
    options: ({ searchQuery }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          searchQuery: searchQuery
        },
        runQuery: searchQuery && searchQuery.trim().length > 0 ? true : false
      },
      fetchPolicy: "network-only"
    })
  }),

  withHandlers({
    handleSearchQuerySubmit:
      ({ setSearchQuery }) =>
      searchQuery => {
        setSearchQuery(searchQuery);
      },

    handleAppBarRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl ? backUrl : "/apps");
      },
    
    handleFilmChange: 
      ({ router }) => 
      (value) => {
        router.push(`/apps/production/transfer-printing/${value.toLowerCase()}`);
      },
  })
);

const TransfersPrintingApp = ({
  data,
  handleAppBarRequestBack,
  handleFilmChange,
  handleSearchQuerySubmit,
  appBarBackgroundColor,
  film
}) => { 
  console.log("film", film);
  return (
    <ProductionAppWithSidebar
      data={data}
      destinationRoute={`/apps/production/transfer-printing/${film.toLowerCase()}/orders`}
      errorMessage={data.error ? data.error.message : null}
      fetchMore={data.fetchMore}
      loading={data.loading}
      ordersConnection={data.ordersConnection}
    >
      <AppBar
        title={(
          <span>
            Transfers Printing -&nbsp;
            <ButtonGroup disableElevation variant="contained" size="small" color="primary" aria-label="outlined primary button group">
              <Button color={film.toUpperCase() === "STANDARD" ? "secondary" : "primary"} onClick={() => handleFilmChange("standard")}>Standard</Button>
              <Button color={film.toUpperCase() === "GLITTER" ? "secondary" : "primary"} onClick={() => handleFilmChange("glitter")}>Glitter</Button>
              <Button color={film.toUpperCase() === "UV" ? "secondary" : "primary"} onClick={() => handleFilmChange("uv")}>UV</Button>
            </ButtonGroup>
          </span>
        )}
        onSearch={handleSearchQuerySubmit}
        onRequestBack={handleAppBarRequestBack}
        backgroundColor={appBarBackgroundColor}
      />
    </ProductionAppWithSidebar>
  )
};

export default enhancer(TransfersPrintingApp);
