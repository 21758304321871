import { gql } from "@apollo/client";

export default gql`
  query InkColorModal {
    inkColors {
      color {
        formatted(format: HEX_WITH_POUND)
      }
      name
      id
    }
    pantoneColors {
      code
      color {
        formatted(format: HEX_WITH_POUND)
      }
      id
    }
  }
`;
