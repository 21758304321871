// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import AddNoteForm from "../AddNoteForm";
import Divider from "@mui/material/Divider";
import NotesCollectionBreakdown from "../../components/NotesCollectionBreakdown";
import Typography from "@mui/material/Typography";

type UnformattedNoteType = {|
  +__typename: "UnformattedNote",
  +note: string
|};

type FormattedNoteType = {|
  +__typename: "FormattedNote",
  +author: string,
  +createdAt: string,
  +note: string
|};

type NoteType = FormattedNoteType | UnformattedNoteType;

type Props = {|
  +classes: {|
    +divider: string,
    +noteContainer: string,
    +noteTitle: string
  |},
  +order: {|
    +artNotesCollection: $ReadOnlyArray<NoteType>,
    +id: string,
    +productionNotesCollection: $ReadOnlyArray<NoteType>,
    +salesNotesCollection: $ReadOnlyArray<NoteType>
  |},
  +shouldShowArtNotes?: boolean,
  +shouldShowSalesNotes?: boolean
|};

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2)
  },

  noteContainer: {
    padding: theme.spacing(2)
  },

  noteTitle: {
    marginBottom: theme.spacing(2)
  }
});

const NotesWrapper = ({
  classes,
  order,
  order: { artNotesCollection, productionNotesCollection, salesNotesCollection }
}: Props) => (
  <React.Fragment>
    <div className={classes.noteContainer}>
      {productionNotesCollection && productionNotesCollection.length > 0 && (
        <React.Fragment>
          <Typography className={classes.noteTitle} variant="h6">
            Production Notes
          </Typography>
          <NotesCollectionBreakdown
            notesCollection={productionNotesCollection}
          />
        </React.Fragment>
      )}
      <AddNoteForm
        placeholder="Enter a production note here..."
        order={order}
      />
      <Divider className={classes.divider} light />
    </div>
    {artNotesCollection && artNotesCollection.length > 0 && (
      <div className={classes.noteContainer}>
        <Typography className={classes.noteTitle} variant="h6">
          Art Notes
        </Typography>
        <NotesCollectionBreakdown notesCollection={artNotesCollection} />
        <AddNoteForm
          isAddingArtNote={true}
          placeholder="Enter an art note here..."
          order={order}
        />
        <Divider className={classes.divider} light />
      </div>
    )}
    <div className={classes.noteContainer}>
      {salesNotesCollection && salesNotesCollection.length > 0 && (
        <React.Fragment>
          <Typography className={classes.noteTitle} variant="h6">
            Sales Notes
          </Typography>
          <NotesCollectionBreakdown notesCollection={salesNotesCollection} />
        </React.Fragment>
      )}
    </div>
  </React.Fragment>
);

export default withStyles(styles)(NotesWrapper);
