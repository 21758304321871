// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TabbedAppBar from "../../../components/TabbedAppBar";

import { Typography } from "@mui/material";
import type { HOC } from "recompose";

const ORDERS_PER_PAGE = 1000;
const MANUAL_SCREEN_PRINTER = "MANUAL_SCREEN_PRINTER";
const AUTOMATIC_SCREEN_PRINTER = "AUTOMATIC_SCREEN_PRINTER";

type State = {|
  activeMenuItem: ?string,
  searchQuery: ?string,
  selectedProductionStation: ?string,
  selectedProductionStationId: ?string
|};

const defaultState: State = {
  activeMenuItem: null,
  selectedProductionStation: null,
  selectedProductionStationId: null,
  searchQuery: null
};

const styles = theme => ({
  content: {
    height: "calc(100vh - 48px)"
  },

  spinnerWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("ScreenPrintingApp"),

  withRouter,

  withStyles(styles),

  withStateHandlers(defaultState, {
    setActiveMenuItem: () => (activeMenuItem: ?string) => ({
      activeMenuItem
    }),

    setSelectedProductionStation:
      () => (selectedProductionStation: string) => ({
        selectedProductionStation
      }),

    setSelectedProductionStationId:
      () => (selectedProductionStationId: string) => ({
        selectedProductionStationId
      }),

    setSearchQuery: () => (searchQuery: string) => ({
      searchQuery
    })
  }),

  withHandlers({
    handleAutomaticMenuItemClick:
      ({ activeMenuItem, setActiveMenuItem, setSelectedProductionStation }) =>
      () => {
        if (activeMenuItem === AUTOMATIC_SCREEN_PRINTER) {
          setActiveMenuItem(null);
          return;
        }
        setActiveMenuItem(AUTOMATIC_SCREEN_PRINTER);
        setSelectedProductionStation(AUTOMATIC_SCREEN_PRINTER);
      },

    handleManualMenuItemClick:
      ({ activeMenuItem, setActiveMenuItem, setSelectedProductionStation }) =>
      () => {
        if (activeMenuItem === MANUAL_SCREEN_PRINTER) {
          setActiveMenuItem(null);
          return;
        }
        setActiveMenuItem(MANUAL_SCREEN_PRINTER);
        setSelectedProductionStation(MANUAL_SCREEN_PRINTER);
      },

    handleSelectedProductionStation:
      ({ setSelectedProductionStation }) =>
      (selectedProductionStation: string) => {
        setSelectedProductionStation(selectedProductionStation);
      },

    handleSelectedProductionStationId:
      ({ setSelectedProductionStationId }) =>
      event => {
        setSelectedProductionStationId(event.currentTarget.id);
      },

    handleTabbedAppBarRequestBack:
      ({ router }) =>
      () => {
        router.push("/apps");
      },

    handleSearchQuerySubmit:
      ({ setSearchQuery }) =>
      searchQuery => {
        setSearchQuery(searchQuery);
      }
  }),

  graphql(query, {
    options: ({
      searchQuery,
      selectedProductionStation,
      selectedProductionStationId
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          productionStationId: selectedProductionStationId,
          searchQuery: searchQuery
        },
        type: selectedProductionStation,
        runQuery: searchQuery && searchQuery.trim().length > 0 ? true : false
      },
      fetchPolicy: "network-only"
    })
  })
);

const ScreenPrintingApp = ({
  activeMenuItem,
  children,
  classes,
  handleAutomaticMenuItemClick,
  handleManualMenuItemClick,
  handleSelectedProductionStationId,
  handleTabbedAppBarRequestBack,
  data: { loading, productionStations },
  selectedProductionStationId,
  handleSearchQuerySubmit
}) => (
  <React.Fragment>
    <TabbedAppBar
      onSearch={handleSearchQuerySubmit}
      onRequestBack={handleTabbedAppBarRequestBack}
      title="Screen Printing"
      menuItems={[
        <MenuItem button onClick={handleManualMenuItemClick}>
          <ListItemText primary="Manual" />
          {activeMenuItem === MANUAL_SCREEN_PRINTER ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </MenuItem>,
        <Collapse in={activeMenuItem === MANUAL_SCREEN_PRINTER}>
          {loading ? (
            <div className={classes.spinnerWrapper}>
              <CircularProgress />
            </div>
          ) : productionStations !== undefined ? (
            productionStations.map(productionStation => (
              <MenuItem
                id={productionStation.id}
                key={productionStation.id}
                onClick={handleSelectedProductionStationId}
              >
                {productionStation.name}
              </MenuItem>
            ))
          ) : (
            <Typography>No Items</Typography>
          )}
        </Collapse>,
        <MenuItem button onClick={handleAutomaticMenuItemClick}>
          <ListItemText primary="Automatic" />
          {activeMenuItem === AUTOMATIC_SCREEN_PRINTER ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </MenuItem>,
        <Collapse in={activeMenuItem === AUTOMATIC_SCREEN_PRINTER}>
          {loading ? (
            <div className={classes.spinnerWrapper}>
              <CircularProgress />
            </div>
          ) : productionStations !== undefined ? (
            productionStations.map(productionStation => (
              <MenuItem
                id={productionStation.id}
                key={productionStation.id}
                onClick={handleSelectedProductionStationId}
              >
                {productionStation.name}
              </MenuItem>
            ))
          ) : (
            <Typography>No Items</Typography>
          )}
        </Collapse>
      ]}
    />
    {React.cloneElement(children, {
      selectedProductionStationId: selectedProductionStationId
    })}
  </React.Fragment>
);

export default enhancer(ScreenPrintingApp);
