// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsTagPrintCompleted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsTagPrintCompleted(orderId: $orderId) {
        errors {
          orderId
        }
        succeeded
        updatedOrder {
          id
        }
      }
    }
  `,
  {
    name: "markOrderAsTagPrintCompleted"
  }
);

export default markOrderAsTagPrintCompleted;
