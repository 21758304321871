// @flow

import { DateTime } from "luxon";
import { Page, StyleSheet } from "@react-pdf/renderer";
import { compose, setDisplayName, withStateHandlers } from "recompose";
import {
  createCustomsShipment,
  createCustomsShipmentPallet,
  createCustomsShipmentPalletCarton,
  markCustomsShipmentAsArchived,
  markCustomsShipmentAsShipped,
  setSouthboundCustomsShipmentControlNumber
} from "../../../graph";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CustomsLabel from "../../../components/CustomsLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PendingStatusView from "../../../components/PendingStatusView";
import PrintablePDFDocument from "../../../components/PrintablePDFDocument";
import React, { useState } from "react";
import SetControlNumberModal from "../../../components/CustomsShipmentListScreen/components/SetControlNumberModal";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

const chunkIntoN = (arr, n) => {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

const {
  REACT_APP_INVENTORY_MANAGEMENT_TOKEN,
  REACT_APP_INVENTORY_MANAGEMENT_URL
} = process.env;

const CARTONS_TO_SHIP_QUERY = gql`
  query inventoryOrderCartonsDeliveredButNotReceived($vendorId: ID) {
    inventoryOrderCartonsDeliveredButNotReceived(vendorId: $vendorId) {
      id
      inventoryOrderItem {
        id
        countryOfOriginOptions
        gtin
        description
        quantity
      }
    }
  }
`;

const CUSTOMS_SHIPMENTS_PER_PAGE = 25;

const pdfStyles = StyleSheet.create({
  page: {
    padding: 8
  }
});

const styles = theme => ({
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main
  },
  tabRoot: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  tableCellRoot: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  buttonRoot: {
    margin: 4
  }
});

const enhancer: HOC<*, *> = compose(
  setDisplayName("CustomsShipmentListScreen"),

  withRouter,

  withSnackbar,

  withApollo,

  withStyles(styles),

  createCustomsShipment,

  createCustomsShipmentPallet,

  createCustomsShipmentPalletCarton,

  markCustomsShipmentAsArchived,

  markCustomsShipmentAsShipped,

  setSouthboundCustomsShipmentControlNumber,

  withStateHandlers(
    {
      tabValue: "active"
    },
    {
      setTabValue: () => tabValue => ({
        tabValue
      })
    }
  ),

  graphql(query, {
    options: ({ tabValue }) => ({
      variables: {
        first: CUSTOMS_SHIPMENTS_PER_PAGE,
        activeFilters: {
          direction: "SOUTHBOUND"
        },
        shippedFilters: {
          direction: "SOUTHBOUND"
        },
        archivedFilters: {
          direction: "SOUTHBOUND"
        },
        active: tabValue === "active",
        shipped: tabValue === "shipped",
        archived: tabValue === "archived"
      },
      fetchPolicy: "network-only"
    })
  })
);

const CustomsSouthboundV2App = ({
  createCustomsShipment,
  createCustomsShipmentPallet,
  createCustomsShipmentPalletCarton,
  data,
  showSuccessSnackbar,
  showErrorSnackbar,
  setTabValue,
  tabValue,
  markCustomsShipmentAsArchived,
  markCustomsShipmentAsShipped,
  setSouthboundCustomsShipmentControlNumber,
  classes,
  client
}) => {
  const [setControlNumberModalIsOpen, setSetControlNumberModalIsOpen] =
    useState(false);
  const [selectedCustomsShipmentId, setSelectedCustomsShipmentId] =
    useState(null);

  const {
    loading,
    error,
    refetch,
    customsShipmentsToBeShipped,
    customsShipmentsShipped,
    customsShipmentsArchived
  } = data;

  const customsShipmentConnection =
    customsShipmentsToBeShipped ||
    customsShipmentsShipped ||
    customsShipmentsArchived;

  if (loading) {
    return <PendingStatusView status="Loading" />;
  }

  if (error) {
    return null;
  }

  const handleCreateShipmentClick = () => {
    const palletCount = parseInt(prompt("Please enter the pallet count", "1"));
    console.log("palletCount");
    console.log(palletCount);

    client
      .query({
        query: CARTONS_TO_SHIP_QUERY,
        variables: { vendorId: "VmVuZG9yOjM2MA==" },
        fetchPolicy: "network-only"
      })
      .then(({ data: { inventoryOrderCartonsDeliveredButNotReceived } }) => {
        let lineItems = inventoryOrderCartonsDeliveredButNotReceived
          .filter(
            ({ inventoryOrderItem: { countryOfOriginOptions } }) =>
              countryOfOriginOptions.length > 0
          )
          .map(
            ({
              inventoryOrderItem: {
                countryOfOriginOptions,
                gtin,
                description,
                quantity: inventoryOrderItemQuantity
              },
              quantity
            }) => ({
              quantity:
                quantity != null ? quantity : inventoryOrderItemQuantity,
              countryOfOrigin: countryOfOriginOptions[0],
              identifier: gtin,
              description: description
            })
          );

        console.log("lineItems");
        console.log(lineItems);

        return createCustomsShipment({
          variables: { direction: "SOUTHBOUND" }
        })
          .then(
            ({
              data: {
                createCustomsShipment: { succeeded, createdCustomsShipment }
              }
            }) => {
              console.log("createCustomsShipment");
              console.log(createdCustomsShipment);
              if (succeeded) {
                const { id: customsShipmentId } = createdCustomsShipment;
                return fetch(
                  `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory/api/mach6-shipment-supplier-order-details`,
                  {
                    method: "POST",
                    body: JSON.stringify({
                      id: customsShipmentId
                    }),
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${REACT_APP_INVENTORY_MANAGEMENT_TOKEN}`
                    }
                  }
                )
                  .then(response => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      return { data: null };
                    }
                  })
                  .then(({ data }) => {
                    if (data != null) {
                      const { lineItems: localInventoryLineItems } = data;
                      console.log("mach6-shipment-supplier-order-details");
                      console.log(localInventoryLineItems);
                      lineItems = [
                        ...lineItems,
                        ...localInventoryLineItems.map(
                          ({ identifier, quantity }) => ({
                            quantity,
                            countryOfOrigin: identifier.split("-")[1],
                            identifier: identifier.split("-")[0],
                            description: identifier
                          })
                        )
                      ];
                    }
                    return Promise.all(
                      [...new Array(palletCount)].map(() =>
                        createCustomsShipmentPallet({
                          variables: { customsShipmentId }
                        }).then(
                          ({ data: { createCustomsShipmentPallet } }) =>
                            createCustomsShipmentPallet
                        )
                      )
                    ).then(pallets => {
                      const succeeded =
                        pallets.filter(({ succeeded }) => succeeded === false)
                          .length === 0;
                      console.log("pallets");
                      console.log(pallets);
                      if (succeeded) {
                        return Promise.all(
                          pallets.map(
                            ({
                              createdCustomsShipmentPallet: {
                                id: customsShipmentPalletId
                              }
                            },
                            palletIndex) =>
                              createCustomsShipmentPalletCarton({
                                variables: {
                                  lineItems: chunkIntoN(lineItems, palletCount)[palletIndex],
                                  customsShipmentPalletId
                                }
                              })
                          )
                        ).then(cartons => {
                          console.log("cartons");
                          console.log(cartons);
                        });
                      } else {
                        // TODO:
                      }
                    });
                  });
              }
            }
          )
          .then(refetch);
      });
  };

  const handleMarkShipmentShipped = event => {
    event.stopPropagation();
    const {
      currentTarget: { id }
    } = event;
    markCustomsShipmentAsShipped({ variables: { customsShipmentId: id } }).then(
      ({
        data: {
          markCustomsShipmentAsShipped: { succeeded, errors }
        }
      }) => {
        if (succeeded) {
          showSuccessSnackbar("Custsoms shipment successfully shipped!");
        } else {
          showErrorSnackbar(errors.id.join(","));
        }
      }
    );
  };

  const handleMarkShipmentArchived = event => {
    event.stopPropagation();
    const {
      currentTarget: { id }
    } = event;
    if (window.confirm("Are you sure you want to archive this shipment?")) {
      markCustomsShipmentAsArchived({
        variables: { customsShipmentId: id }
      }).then(
        ({
          data: {
            markCustomsShipmentAsArchived: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Custsoms shipment successfully archived!");
          } else {
            showErrorSnackbar(errors.id.join(","));
          }
        }
      );
    }
  };

  const handleSetShipmentControlNumber = (
    event,
    { id, canViewerSetControlNumber, canViewerSetFormalEntryCode }
  ) => {
    event.stopPropagation();
    if (canViewerSetControlNumber || canViewerSetFormalEntryCode) {
      setSelectedCustomsShipmentId(id);
      setSetControlNumberModalIsOpen(true);
    } else {
      fetch(
        `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-southbound-shipment-manifest/${id}`
      )
        .then(response => response.text())
        .then(response => {
          if (response === "ok") {
            showSuccessSnackbar("Shipment manifest successfully sent!");
          } else {
            showErrorSnackbar(response);
          }
        });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSetControlNumberModalRequestClose = () => {
    setSelectedCustomsShipmentId(null);
    setSetControlNumberModalIsOpen(false);
  };

  const handleSetControlNumberModalComplete = ({
    controlNumber,
    processedBy
  }) => {
    setSouthboundCustomsShipmentControlNumber({
      variables: {
        customsShipmentId: selectedCustomsShipmentId,
        controlNumber,
        processedBy
      }
    })
      .then(
        ({
          data: {
            setSouthboundCustomsShipmentControlNumber: { succeeded, errors }
          }
        }) => {
          setSetControlNumberModalIsOpen(false);
          if (succeeded) {
            return fetch(
              `${process.env.REACT_APP_LAMBDA_BASE_URL}/send-southbound-shipment-manifest/${selectedCustomsShipmentId}`
            )
              .then(response => response.text())
              .then(response => {
                if (response === "ok") {
                  showSuccessSnackbar("Shipment manifest successfully sent!");
                } else {
                  showErrorSnackbar(response);
                }
              });
          } else {
            console.log("errors");
            console.log(errors);
            showErrorSnackbar(
              [...errors.customsShipmentId, ...errors.controlNumber].join(",")
            );
          }
        }
      )
      .finally(() => {
        setSetControlNumberModalIsOpen(false);
        setSelectedCustomsShipmentId(null);
      });
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Grid item xs={8}>
          <Typography variant="h4">Southbound Shipments</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            aria-label="Create New Shipment"
            variant="contained"
            onClick={handleCreateShipmentClick}
          >
            Create New Shipment
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      <Tabs
        classes={{
          indictator: classes.tabsIndicator
        }}
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="active"
          label="Active"
        />
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="shipped"
          label="Picked up"
        />
        <Tab
          classes={{
            root: classes.tabRoot
          }}
          value="archived"
          label="Archived"
        />
      </Tabs>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
              >
                Shipment
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Pallet Count
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Control #
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Processed
              </TableCell>
              <TableCell
                classes={{
                  root: classes.tableCellRoot
                }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customsShipmentConnection.edges.map(
              ({
                node: {
                  id,
                  pallets,
                  createdAt,
                  archivedOn,
                  shippedOn,
                  canonicalId,
                  controlNumber,
                  canViewerSetControlNumber,
                  canViewerSetFormalEntryCode,
                  canViewerMarkAsShipped,
                  canViewerMarkAsArchived,
                  processedOn,
                  processedBy
                }
              }) => (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    component="th"
                    scope="row"
                  >
                    #{canonicalId}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {DateTime.fromISO(createdAt).toLocaleString(
                      DateTime.DATETIME_FULL
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {archivedOn ? "ARCHIVED" : shippedOn ? "SHIPPED" : "ACTIVE"}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {pallets.length}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {controlNumber}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {processedOn && (
                      <>
                        {DateTime.fromISO(processedOn).toLocaleString(
                          DateTime.DATETIME_FULL
                        )}{" "}
                        by {processedBy}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot
                    }}
                    align="center"
                  >
                    {(canViewerSetControlNumber ||
                      canViewerSetFormalEntryCode ||
                      canViewerMarkAsShipped) && (
                      <Button
                        classes={{
                          root: classes.buttonRoot
                        }}
                        variant="contained"
                        onClick={event =>
                          handleSetShipmentControlNumber(event, {
                            id,
                            canViewerSetControlNumber,
                            canViewerSetFormalEntryCode
                          })
                        }
                      >
                        Process to NAPS
                      </Button>
                    )}
                    {canViewerMarkAsShipped && (
                      <Button
                        classes={{
                          root: classes.buttonRoot
                        }}
                        variant="contained"
                        id={id}
                        onClick={handleMarkShipmentShipped}
                      >
                        Picked up
                      </Button>
                    )}
                    {canViewerMarkAsArchived && (
                      <Button
                        classes={{
                          root: classes.buttonRoot
                        }}
                        variant="contained"
                        id={id}
                        onClick={handleMarkShipmentArchived}
                      >
                        Archive
                      </Button>
                    )}
                    {DateTime.fromISO(createdAt) >
                      DateTime.now().plus({ days: -4 }) &&
                      pallets.length > 0 && (
                        <PrintablePDFDocument
                          label="Print Pallet Labels"
                          classes={{
                            root: classes.buttonRoot
                          }}
                        >
                          {pallets
                            .filter(({ lineItems }) => !!lineItems)
                            .map(pallet => (
                              <Page
                                size={[6.5 * 72, 10 * 72]}
                                orientation="landscape"
                                style={pdfStyles.page}
                              >
                                <CustomsLabel data={pallet.lineItems} />
                              </Page>
                            ))}
                        </PrintablePDFDocument>
                      )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SetControlNumberModal
        isOpen={setControlNumberModalIsOpen}
        onRequestClose={handleSetControlNumberModalRequestClose}
        onComplete={handleSetControlNumberModalComplete}
        showSealNumbers={false}
      />
    </Container>
  );
};

export default enhancer(CustomsSouthboundV2App);
