// @flow

import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles({
  background: {
    background: "black",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  artwork: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80%"
  }
});

const ArtworkProjectionApp = () => {
  const classes = useStyles();
  const [artworkUrl, setArtworkUrl] = useState(null);

  window.addEventListener(
    "message",
    ({ data }) => {
      setArtworkUrl(data);
    },
    false
  );

  return (
    <div>
      <div className={classes.background} />
      <img src={artworkUrl} className={classes.artwork} alt="Projection" />
    </div>
  );
};

export default ArtworkProjectionApp;
