// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createManualInventoryOrderForFixPieceRequest: any = graphql(
  gql`
    mutation (
      $purchaseOrderNumber: String!
      $fixPieceRequestId: ID!
      $vendorId: ID!
      $lineItems: [FixPieceRequestManualOrderLineItemInput!]!
    ) {
      createManualInventoryOrderForFixPieceRequest(
        purchaseOrderNumber: $purchaseOrderNumber
        fixPieceRequestId: $fixPieceRequestId
        vendorId: $vendorId
        lineItems: $lineItems
      ) {
        createdInventoryOrder {
          id
          poNumber
          fixPieceRequest {
            id
            hasBeenOrdered
            orderedAt
            itemBreakdown {
              inventoryItem {
                id
              }
              quantityNeeded
              quantityOrdered
              quantityRequested
            }
          }
        }
        errors {
          purchaseOrderNumber
          fixPieceRequestId
          vendorId
          lineItems
          lineItemsInput {
            inventoryItemId
            quantity
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "createManualInventoryOrderForFixPieceRequest"
  }
);

export default createManualInventoryOrderForFixPieceRequest;
