// @flow

import { gql } from "@apollo/client";
import { fragments as productionJobLineItemBreakdownFragments } from "./../components/ProductionJobLineItemBreakdown/graph";

export default {
  productionJob: gql`
    fragment ProductQuantitySummary_productionJob on OrderProductionJob {
      productCount
      lineItemBreakdownByProduct {
        product {
          id
        }
        ...ProductionJobLineItemBreakdown_productionJobLineItemBreakdown
      }
      totalProductVariantQuantity
    }
    ${productionJobLineItemBreakdownFragments.productionJobLineItemBreakdown}
  `
};
