// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as screenPrintingOrderStatusFragments } from "../../application/apps/ScreenPrintingApp/components/ScreenPrintingOrderStatus/graph";

const markOrderAsScreenPrintingStarted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsScreenPrintingStarted(orderId: $orderId) {
        errors {
          orderId
        }
        order {
          ...ScreenPrintingOrderStatus_order
        }
        succeeded
      }
    }
    ${screenPrintingOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsScreenPrintingStarted"
  }
);

export default markOrderAsScreenPrintingStarted;
