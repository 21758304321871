// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { vinylSizeOptions } from "../../../../types/VinylSizeOptions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import VinylCombobox from "../VinylCombobox";

import type { HOC } from "recompose";
import type { VinylConfigurationInputErrors } from "../../../../types/VinylConfigurationInputErrors";

type Props = {|
  +onVinylConfigurationUpdated: ({}) => void,
  +vinylColors: $ReadOnlyArray<{
    +color: {
      +formatted: string
    },
    +id: string,
    +inkColor: {|
      +color: {|
        +formatted: string
      |},
      +name: string
    |},
    +label: string,
    +value: number
  }>,
  +vinylConfiguration: {|
    +names: boolean,
    +namesColorId: ?string,
    +namesFontId: ?string,
    +numbers: boolean,
    +numbersColorId: ?string,
    +numbersFontId: ?string,
    +size: number
  |},
  +vinylConfigurationInputErrors: VinylConfigurationInputErrors,
  +vinylFonts: $ReadOnlyArray<{
    +alias: string,
    +color: {
      +formatted: string
    },
    +id: string,
    +label: string,
    +value: number
  }>
|};

type State = {|
  vinylColorSelection: ?string,
  vinylFontSelection: ?string,
  vinylNamesCheckboxChecked: boolean,
  vinylNumbersCheckboxChecked: boolean,
  vinylSizeComboboxSelection: number
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("VinylConfigurationSelector"),

  withStateHandlers(
    ({ vinylConfiguration }): State => {
      return {
        vinylNamesCheckboxChecked: vinylConfiguration.names,
        vinylSizeComboboxSelection: vinylConfiguration.size,
        vinylColorSelection: vinylConfiguration.namesColorId,
        vinylFontSelection: vinylConfiguration.namesFontId,
        vinylNumbersCheckboxChecked: vinylConfiguration.numbers
      };
    },
    {
      setVinylNamesCheckboxChecked: () => (
        vinylNamesCheckboxChecked: boolean
      ) => ({ vinylNamesCheckboxChecked }),

      setVinylColorSelection: () => (vinylColorSelection: ?string) => ({
        vinylColorSelection
      }),

      setVinylFontSelection: () => (vinylFontSelection: ?string) => ({
        vinylFontSelection
      }),

      setVinylNumbersCheckboxChecked: () => (
        vinylNumbersCheckboxChecked: boolean
      ) => ({ vinylNumbersCheckboxChecked }),

      setVinylSizeComboboxSelection: () => (
        vinylSizeComboboxSelection: number
      ) => ({ vinylSizeComboboxSelection })
    }
  ),

  withHandlers({
    handleVinylSizeComboboxSelect: ({
      onVinylConfigurationUpdated,
      setVinylSizeComboboxSelection
    }) => (size: number) => {
      setVinylSizeComboboxSelection(size);
      onVinylConfigurationUpdated({ size });
    },

    handleVinylColorSelectorSelect: ({
      onVinylConfigurationUpdated,
      setVinylColorSelection,
      vinylNamesCheckboxChecked,
      vinylNumbersCheckboxChecked
    }) => (colorId: ?string) => {
      setVinylColorSelection(colorId);
      onVinylConfigurationUpdated({
        namesColorId: vinylNamesCheckboxChecked ? colorId : null,
        numbersColorId: vinylNumbersCheckboxChecked ? colorId : null
      });
    },

    handleVinylFontSelectorSelect: ({
      setVinylFontSelection,
      onVinylConfigurationUpdated,
      vinylNamesCheckboxChecked,
      vinylNumbersCheckboxChecked
    }) => (fontId: ?string) => {
      setVinylFontSelection(fontId);
      onVinylConfigurationUpdated({
        namesFontId: vinylNamesCheckboxChecked ? fontId : null,
        numbersFontId: vinylNumbersCheckboxChecked ? fontId : null
      });
    },

    handleVinylNamesCheckboxChange: ({
      onVinylConfigurationUpdated,
      setVinylNamesCheckboxChecked,
      vinylColorSelection,
      vinylFontSelection,
      vinylNumbersCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setVinylNamesCheckboxChecked(isChecked);
      onVinylConfigurationUpdated({
        names: isChecked,
        namesColorId: isChecked ? vinylColorSelection : null,
        namesFontId: isChecked ? vinylFontSelection : null,
        numbersColorId: vinylNumbersCheckboxChecked
          ? vinylColorSelection
          : null,
        numbersFontId: vinylNumbersCheckboxChecked ? vinylFontSelection : null
      });
    },

    handleVinylNumbersCheckboxChange: ({
      onVinylConfigurationUpdated,
      setVinylNumbersCheckboxChecked,
      vinylColorSelection,
      vinylFontSelection,
      vinylNamesCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setVinylNumbersCheckboxChecked(isChecked);
      onVinylConfigurationUpdated({
        numbers: isChecked,
        namesColorId: vinylNamesCheckboxChecked ? vinylColorSelection : null,
        namesFontId: vinylNamesCheckboxChecked ? vinylFontSelection : null,
        numbersColorId: isChecked ? vinylColorSelection : null,
        numbersFontId: isChecked ? vinylFontSelection : null
      });
    }
  })
);

const VinylConfigurationSelector = ({
  handleVinylColorSelectorSelect,
  handleVinylFontSelectorSelect,
  handleVinylNamesCheckboxChange,
  handleVinylNumbersCheckboxChange,
  handleVinylSizeComboboxSelect,
  vinylColors,
  vinylFonts,
  vinylNamesCheckboxChecked,
  vinylNumbersCheckboxChecked,
  vinylConfigurationInputErrors: {
    names: namesErrors,
    namesColorId: namesColorIdErrors,
    namesFontId: namesFontIdErrors,
    numbers: numbersErrors,
    numbersColorId: numbersColorIdErrors,
    numbersFontId: numbersFontIdErrors,
    size: sizeErrors
  }
}) => {
  return (
    <React.Fragment>
      <div>
        <Typography variant="subtitle2">Vinyl Configuration</Typography>
        <div>
          <FormControlLabel
            error={namesErrors.join(", ")}
            control={
              <Checkbox
                checked={vinylNamesCheckboxChecked}
                onChange={handleVinylNamesCheckboxChange}
                color="primary"
              />
            }
            label="Names"
          />
          <FormControlLabel
            error={numbersErrors.join(", ")}
            control={
              <Checkbox
                checked={vinylNumbersCheckboxChecked}
                onChange={handleVinylNumbersCheckboxChange}
                color="primary"
              />
            }
            label="Numbers"
          />
          {(vinylNamesCheckboxChecked || vinylNumbersCheckboxChecked) && (
            <React.Fragment>
              <VinylCombobox
                label="Vinyl Size"
                options={vinylSizeOptions}
                errorText={[...sizeErrors]}
                onSelect={handleVinylSizeComboboxSelect}
              />
              <VinylCombobox
                label="Vinyl Color"
                options={vinylColors}
                errorText={[...namesColorIdErrors, ...numbersColorIdErrors]}
                onSelect={handleVinylColorSelectorSelect}
              />
              <VinylCombobox
                label="Vinyl Font"
                options={vinylFonts}
                errorText={[...namesFontIdErrors, ...numbersFontIdErrors]}
                onSelect={handleVinylFontSelectorSelect}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default enhancer(VinylConfigurationSelector);
