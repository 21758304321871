// @flow

import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2)
  }
});

const CenteredSpinner = ({ classes }) => (
  <div className={classes.spinner}>
    <CircularProgress size={30} />
  </div>
);

export default withStyles(styles)(CenteredSpinner);
