// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { withRouter } from "found";
import ProductionAppWithSidebar from "../../../../../components/ProductionAppWithSidebar";

import type { HOC } from "recompose";

const ORDERS_PER_PAGE = 1000;

type Props = {||};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ScreenPrintingOrderSelection"),

  withRouter,

  graphql(query, {
    options: ({ selectedProductionStation, selectedProductionStationId }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          productionStationId: selectedProductionStationId
        },
        type: selectedProductionStation
      },
      fetchPolicy: "network-only"
    })
  })
);

const ScreenPrintingOrderSelection = ({ data }) => (
  <ProductionAppWithSidebar
    destinationRoute="/apps/production/screen-printing/orders"
    errorMessage={data.error ? data.error.message : null}
    fetchMore={data.fetchMore}
    loading={data.loading}
    ordersConnection={data.ordersConnection}
  />
);

export default enhancer(ScreenPrintingOrderSelection);
