// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment ScreensMadeTable_order on Order {
      id
      orderNumber
      primaryCustomer {
        id
        name(format: FIRST_NAME_LAST_NAME)
      }
      priority
      productionDate
    }
  `
};
