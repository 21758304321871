// @flow

import * as React from "react";
import { ThemeContextConsumer } from "../../application/themeContext";
import { alpha } from "@mui/material/styles";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import Button from "@mui/material/Button";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import config from "../../application/config";

import type { HOC } from "recompose";

type State = {|
  searchQuery: ?string
|};

type Props = {|
  +classes?: {|
    +appBar: string,
    +backButton: string,
    +title: string
  |},
  +onRequestBack: () => void,
  +onSearch?: (query: string) => void,
  +title: string
|};

const styles = theme => {
  const contrastText = theme.palette.getContrastText(
    theme.components.MuiAppBar.styleOverrides.colorPrimary.backgroundColor
  );
  return {
    appBar: {
      zIndex: theme.zIndex.modal + 1
    },
    backButton: {
      color: contrastText,
      marginRight: 20,
      marginLeft: -12
    },
    currentViewer: {
      marginLeft: theme.spacing(1)
    },
    menuTextSpacing: {
      marginLeft: theme.spacing(1.5)
    },
    menu: {
      zIndex: theme.zIndex.modal + 1
    },
    title: {
      color: contrastText
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      width: theme.spacing(5),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 240,
        "&:focus": {
          width: 260
        }
      }
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    }
  };
};

const ITEM_HEIGHT = 48;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("DesktopAppBar"),

  withStyles(styles),

  graphql(query),

  withStateHandlers(
    ({
      searchQuery: null
    }: State),
    {
      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      })
    }
  ),

  withHandlers({
    handleBackButtonClick: props => () => {
      console.log(props);
      const { onRequestBack } = props;
      onRequestBack();
    },

    handleSearchQueryChange:
      ({ setSearchQuery }) =>
      event => {
        setSearchQuery(event.target.value);
      },

    handleSearchQuerySubmit:
      ({ onSearch, searchQuery }) =>
      event => {
        event.preventDefault();
        if (onSearch) {
          onSearch(searchQuery);
        }
      },

    handleSignOutButtonClick: () => () => {
      window.location =
        config.getOAuth2LogoutUrl() +
        "?client_id=" +
        config.getOAuth2ClientId() +
        "&return_to=" +
        window.location.origin;
    }
  })
);

const DesktopAppBar = ({
  classes,
  data,
  handleBackButtonClick,
  handleSearchQueryChange,
  handleSearchQuerySubmit,
  handleSignOutButtonClick,
  title,
  onSearch,
  backgroundColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  return (
    <AppBar
      className={classes.appBar}
      style={backgroundColor ? { backgroundColor } : {}}
      position="sticky"
    >
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            className={classes.backButton}
            aria-label="Back"
            onClick={handleBackButtonClick}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </div>
        {onSearch && (
          <div className={classes.search}>
            <form onSubmit={handleSearchQuerySubmit}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search for Order Id / PO / Customer Name…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                onChange={handleSearchQueryChange}
              />
            </form>
          </div>
        )}
        <IconButton
          aria-haspopup="true"
          aria-owns={"app-bar-menu"}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          className={classes.menu}
          id="app-bar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          <MenuItem>
            <AccountCircleIcon />
            <Typography className={classes.currentViewer}>
              {data && data.viewer && data.viewer.me.name}
            </Typography>
          </MenuItem>
          <MenuItem>
            <ThemeContextConsumer>
              {context =>
                context &&
                context.componentMounted && (
                  <React.Fragment>
                    <IconButton onClick={context.toggleTheme}>
                      <BrightnessMediumIcon color="action" />
                      {context.type === "light" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Dark Mode
                        </Typography>
                      )}
                      {context.type === "dark" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Light Mode
                        </Typography>
                      )}
                    </IconButton>
                  </React.Fragment>
                )
              }
            </ThemeContextConsumer>
          </MenuItem>
          <MenuItem>
            <Button fullWidth onClick={handleSignOutButtonClick}>
              <Typography align="center">Sign out</Typography>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
export default enhancer(DesktopAppBar);
