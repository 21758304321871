// @flow

import * as React from "react";
import { cancelFixPieceRequest } from "../../../graph";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as fixPieceRequestTableFragments } from "./components/FixPieceRequestTable/graph";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { purchaseProductsForFixPieceRequest } from "../../../graph";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../../components/CenteredSpinner";
import FindOrderByOrderNumberModal from "./components/FindOrderByOrderNumberModal";
import FixPieceRequestDetailView from "./components/FixPieceRequestDetailView";
import FixPieceRequestTable from "./components/FixPieceRequestTable";
import MenuItem from "@mui/material/MenuItem";
import PendingStatusView from "../../../components/PendingStatusView";
import RegisterManualOrderModal from "./components/RegisterManualOrderModal";
import RequestFixPieceModal from "./components/RequestFixPieceModal";
import SelectOrderProductionJobModal from "./components/SelectOrderProductionJobModal";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withDrawer from "../../../components/withDrawer";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  hasNextPage: boolean,
  isLoadingMore: boolean,
  loadingFixPieceRequestIds: Array<string>,
  scannedCartonNumber: ?string,
  searchQuery: ?string,
  selectedFixPieceRequestId: ?string,
  selectedOrderId: ?string,
  selectedOrderProductionJobId: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string,
  showAllSwitchValue: boolean,
  showFindOrderByOrderNumberModal: boolean,
  showRegisterManualOrderModal: boolean,
  showRequestFixPieceModal: boolean,
  showSelectOrderProductionJobModal: boolean
|};

const ORDERS_PER_PAGE = 50;

const ORDER_PRODUCTION_JOB_QUERY = gql`
  query BarcodeOrderProductionJobQuery($orderProductionJobId: ID!) {
    node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        id
        order {
          id
        }
      }
    }
  }
`;

const STOCK_CONTAINER_QUERY = gql`
  query BarcodeStockContainerQuery($stockContainerId: ID!) {
    node(id: $stockContainerId) {
      ... on StockContainer {
        id
        orderProductionJob {
          id
          order {
            id
          }
        }
      }
    }
  }
`;

const enhancer: HOC<*, *> = compose(
  setDisplayName("FixPieceApp"),

  withApollo,

  withRouter,

  withSnackbar,

  cancelFixPieceRequest,

  purchaseProductsForFixPieceRequest,

  withStateHandlers(
    ({
      hasNextPage: true,
      isLoadingMore: false,
      loadingFixPieceRequestIds: [],
      scannedCartonNumber: null,
      searchQuery: null,
      selectedFixPieceRequestId: null,
      selectedOrderId: null,
      selectedSortBy: "LABEL",
      selectedSortDirection: "ASCENDING",
      showAllSwitchValue: false,
      showFindOrderByOrderNumberModal: false,
      showSelectOrderProductionJobModal: false,
      showRegisterManualOrderModal: false,
      showRequestFixPieceModal: false
    }: State),
    {
      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedFixPieceRequestId:
        () =>
        (
          selectedFixPieceRequestId: ?string
        ) => ({
          selectedFixPieceRequestId
        }),

      setSelectedOrderProductionJobId:
        () =>
        (
          selectedOrderProductionJobId: ?string
        ) => ({
          selectedOrderProductionJobId
        }),

      setSelectedOrderId:
        () =>
        (
          selectedOrderId: ?string
        ) => ({
          selectedOrderId
        }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setShowAllSwitchValue: () => (showAllSwitchValue: boolean) => ({
        showAllSwitchValue
      }),

      setShowFindOrderByOrderNumberModal: () => (showFindOrderByOrderNumberModal: boolean) => ({
        showFindOrderByOrderNumberModal
      }),

      setShowRegisterManualOrderModal: () => (showRegisterManualOrderModal: boolean) => ({
        showRegisterManualOrderModal
      }),

      setShowRequestFixPieceModal: () => (showRequestFixPieceModal: boolean) => ({
        showRequestFixPieceModal
      }),

      setShowSelectOrderProductionJobModal: () => (showSelectOrderProductionJobModal: boolean) => ({
        showSelectOrderProductionJobModal
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      addLoadingFixPieceRequestId:
        ({ loadingFixPieceRequestIds }) =>
        (fixPieceRequestId: string) => ({
          loadingFixPieceRequestIds: [...loadingFixPieceRequestIds, fixPieceRequestId]
        }),

      removeLoadingFixPieceRequestId:
        ({ loadingFixPieceRequestIds }) =>
        (fixPieceRequestId: string) => {
          const indexToRemove =
            loadingFixPieceRequestIds.indexOf(fixPieceRequestId);
          return {
            loadingFixPieceRequestIds: [
              ...loadingFixPieceRequestIds.slice(0, indexToRemove),
              ...loadingFixPieceRequestIds.slice(
                indexToRemove + 1,
                loadingFixPieceRequestIds.length
              )
            ]
          };
        }
    }
  ),

  withHandlers({
    processCancelation:
      ({
        addLoadingFixPieceRequestId,
        cancelFixPieceRequest,
        removeLoadingFixPieceRequestId,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (fixPieceRequestId: string) => {
        addLoadingFixPieceRequestId(fixPieceRequestId);
        cancelFixPieceRequest({
          variables: { fixPieceRequestId: fixPieceRequestId }
        })
          .then(
            ({
              data: {
                cancelFixPieceRequest: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Fix Piece order canceled.");
              } else {
                showErrorSnackbar(errors.fixPieceRequestId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingFixPieceRequestId(fixPieceRequestId));
      },

    processOrderGoods:
      ({
        addLoadingFixPieceRequestId,
        purchaseProductsForFixPieceRequest,
        removeLoadingFixPieceRequestId,
        showErrorSnackbar,
        showSuccessSnackbar,
        showWarningSnackbar
      }) =>
      (fixPieceRequestId: string) => {
        addLoadingFixPieceRequestId(fixPieceRequestId);
        purchaseProductsForFixPieceRequest({
          variables: { fixPieceRequestId: fixPieceRequestId, source: "FIX_PIECE_APP" }
        })
          .then(
            ({
              data: {
                purchaseProductsForFixPieceRequest: { partialOrder, succeeded, errors }
              }
            }) => {
              if (succeeded) {
                if (partialOrder) {
                  showWarningSnackbar("Fix Piece partially ordered. Confirm and order remaining product.");
                } else {
                  showSuccessSnackbar("Fix Piece order succeeded.");
                }
              } else {
                showErrorSnackbar(errors.fixPieceRequestId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingFixPieceRequestId(fixPieceRequestId));
      }
  }),

  withDrawer(
    "Request Details",
    ({
      processCancelation,
      selectedFixPieceRequestId,
      processOrderGoods,
      loadingFixPieceRequestIds,
      setDisplayState,
      setSelectedFixPieceRequestId,
      setShowRegisterManualOrderModal
    }) =>
      selectedFixPieceRequestId ? (
        <FixPieceRequestDetailView
          fixPieceRequestId={selectedFixPieceRequestId}
          isOrderingGoods={loadingFixPieceRequestIds.includes(
            selectedFixPieceRequestId
          )}
          onGoodsOrdered={(selectedFixPieceRequestId: string) => {
            setDisplayState("closed");
            processOrderGoods(selectedFixPieceRequestId);
          }}
          onCanceled={(selectedFixPieceRequestId: string) => {
            setDisplayState("closed");
            processCancelation(selectedFixPieceRequestId);
          }}
          onRegisterManualOrder={(selectedFixPieceRequestId: string) => {
            setDisplayState("closed");
            setSelectedFixPieceRequestId(selectedFixPieceRequestId);
            setShowRegisterManualOrderModal(true);
          }}
        />
      ) : null
  ),

  graphql(query, {
    options: ({
      searchQuery,
      selectedSortBy,
      selectedSortDirection,
      showAllSwitchValue,
      filters = {}
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          ...filters,
          includeOrdered: searchQuery ? true : showAllSwitchValue,
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection
        }
      }
    })
  }),

  withHandlers(() => {
    return {
      handleAppHeaderRequestBack:
        ({ router, backUrl }) =>
        () => {
          router.push(backUrl ? backUrl : "/apps");
        },

      handleSearch:
        ({ setSearchQuery, setSelectedFixPieceRequestId }) =>
        searchQuery => {
          setSelectedFixPieceRequestId(null);
          setSearchQuery(searchQuery);
        },

      handleBarcodeError:
        ({ showErrorSnackbar }) =>
        () => {
          showErrorSnackbar("Error scanning barcode");
        },

      handleBarcodeScan:
        ({
          client,
          setSelectedOrderId,
          setSelectedOrderProductionJobId,
          setShowFindOrderByOrderNumberModal,
          setShowSelectOrderProductionJobModal,
          showErrorSnackbar
        }) =>
        scannedBarcode => {
          console.log(scannedBarcode);
          setSelectedOrderId(null);
          setSelectedOrderProductionJobId(null);

          if(scannedBarcode) {
            try {
              let barcodeObject = JSON.parse(scannedBarcode);
              if ('type' in barcodeObject
                && barcodeObject.type === "OrderProductionJob"
                && 'id' in barcodeObject
              ) {
                client
                .query({
                  query: ORDER_PRODUCTION_JOB_QUERY,
                  variables: { orderProductionJobId: barcodeObject.id }
                })
                .then(
                  ({
                    data: {
                      node: orderProductionJob
                    }
                  }) => {
                    if (orderProductionJob == null) {
                      showErrorSnackbar(`Order Production Job not found`);
                      setShowFindOrderByOrderNumberModal(true);
                    } else {
                      setSelectedOrderId(orderProductionJob.order.id)
                      setSelectedOrderProductionJobId(orderProductionJob.id);
                      setShowSelectOrderProductionJobModal(true);
                    }
                  }
                );
              } else if ('type' in barcodeObject
                && barcodeObject.type === "StockContainer"
                && 'id' in barcodeObject
              ) {
                client
                .query({
                  query: STOCK_CONTAINER_QUERY,
                  variables: { stockContainerId: barcodeObject.id }
                })
                .then(
                  ({
                    data: {
                      node: stockContainer
                    }
                  }) => {
                    if (stockContainer == null) {
                      showErrorSnackbar(`Order Production Job not found`);
                      setShowFindOrderByOrderNumberModal(true);
                    } else {
                      setSelectedOrderId(stockContainer.orderProductionJob.order.id)
                      setSelectedOrderProductionJobId(stockContainer.orderProductionJob.id);
                      setShowSelectOrderProductionJobModal(true);
                    }
                  }
                );
              } else {
                setShowFindOrderByOrderNumberModal(true);
              }
            } catch(e) {
              showErrorSnackbar(
                `Invalid barcode`
              );
            }
          }
        },

      handleShowAllSwitchChanged:
        ({ setShowAllSwitchValue, setHasNextPage }) =>
        (event, showAll: boolean) => {
          setShowAllSwitchValue(showAll);
          setHasNextPage(true);
        },

      handleFixPieceRequestTableRowClicked:
        ({ setSelectedFixPieceRequestId, setDisplayState }) =>
        (fixPieceRequestId: string) => {
          setSelectedFixPieceRequestId(fixPieceRequestId);
          setDisplayState("open-partial");
        },

      handleSort:
        ({
          selectedSortBy,
          setSortDirection,
          setSortBy,
          selectedSortDirection
        }) =>
        (newSelectedSortBy: string) => {
          setSortBy(newSelectedSortBy);
          if (selectedSortBy === newSelectedSortBy) {
            selectedSortDirection === "ASCENDING"
              ? setSortDirection("DESCENDING")
              : setSortDirection("ASCENDING");
          } else {
            setSortDirection("ASCENDING");
          }
        },

      handleFixPieceRequestTableScrolledToBottom:
        ({
          data,
          hasNextPage,
          isLoadingMore,
          setHasNextPage,
          setIsLoadingMore,
          showAllSwitchValue,
          filters = {}
        }) =>
        () => {
          if (isLoadingMore || !hasNextPage) {
            return;
          }

          setIsLoadingMore(true);
          data
            .fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges =
                  fetchMoreResult.fixPieceRequestsToBeOrdered.edges;
                const pageInfo =
                  fetchMoreResult.fixPieceRequestsToBeOrdered.pageInfo;
                setHasNextPage(pageInfo.hasNextPage);
                return newEdges.length
                  ? {
                      fixPieceRequestsToBeOrdered: {
                        pageInfo,
                        __typename:
                          previousResult.fixPieceRequestsToBeOrdered
                            .__typename,
                        edges: [
                          ...previousResult.fixPieceRequestsToBeOrdered
                            .edges,
                          ...newEdges
                        ]
                      }
                    }
                  : previousResult;
              },
              variables: {
                after: data.fixPieceRequestsToBeOrdered.pageInfo.endCursor,
                filters: {
                  ...filters,
                  includeOrdered: showAllSwitchValue
                }
              }
            })
            .finally(() => setIsLoadingMore(false));
        },

      handleCreateFixPieceRequestClick:
        ({
          setSelectedOrderId,
          setSelectedOrderProductionJobId,
          setShowFindOrderByOrderNumberModal
        }) => () => {
          setSelectedOrderId(null);
          setSelectedOrderProductionJobId(null);
          setShowFindOrderByOrderNumberModal(true);
        },

      handleSelectOrderProductionJobModalComplete:
        ({
          setSelectedOrderProductionJobId,
          setShowSelectOrderProductionJobModal,
          setShowRequestFixPieceModal
        }) => ({selectedOrderProductionJobId}) => {
          setSelectedOrderProductionJobId(selectedOrderProductionJobId);
          setShowSelectOrderProductionJobModal(false);
          setShowRequestFixPieceModal(true);
        },

      handleFindOrderByOrderNumberModalComplete:
        ({
          setSelectedOrderId,
          setSelectedOrderProductionJobId,
          setShowFindOrderByOrderNumberModal,
          setShowRequestFixPieceModal,
          setShowSelectOrderProductionJobModal
        }) => ({selectedOrderId, selectedOrderProductionJobId}) => {
          setSelectedOrderId(selectedOrderId);
          setShowFindOrderByOrderNumberModal(false);

          if(selectedOrderProductionJobId) {
            setSelectedOrderProductionJobId(selectedOrderProductionJobId);
            setShowRequestFixPieceModal(true);
          } else {
            setSelectedOrderProductionJobId(null);
            setShowSelectOrderProductionJobModal(true);
          }
        },

      handleFindOrderByOrderNumberModalRequestClose:
        ({ setShowFindOrderByOrderNumberModal }) =>
        () => {
          setShowFindOrderByOrderNumberModal(false);
        },

      handleRegisterManualOrderModalRequestClose:
        ({ setShowRegisterManualOrderModal, setSelectedFixPieceRequestId }) =>
        () => {
          setShowRegisterManualOrderModal(false);
          setSelectedFixPieceRequestId(null);
        },

      handleRegisterManualOrderModalComplete:
        ({ setSelectedFixPieceRequestId, setShowRegisterManualOrderModal, showSuccessSnackbar }) => ({ hasBeenOrdered }) => {
          showSuccessSnackbar("Manual Order created succesfully");
          if(hasBeenOrdered) {
            setShowRegisterManualOrderModal(false);
            setSelectedFixPieceRequestId(null);
          }
        },

      handleRequestFixPieceModalComplete:
        ({ data, setShowRequestFixPieceModal, showSuccessSnackbar }) =>
        () => {
          showSuccessSnackbar("Request created succesfully");
          setShowRequestFixPieceModal(false);
          data.refetch();
        },

      handleRequestFixPieceModalRequestClose:
        ({ setShowRequestFixPieceModal }) =>
        () => {
          setShowRequestFixPieceModal(false);
        },

      handleSelectOrderProductionJobModalClose:
        ({ setShowSelectOrderProductionJobModal }) =>
        () => {
          setShowSelectOrderProductionJobModal(false);
        }
    };
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        data,
        setSelectedFixPieceRequestId,
        setDisplayState
      } = this.props;
      if (!data.loading && !data.error) {
        if (
          data.fixPieceRequestsToBeOrdered.edges.length === 1 &&
          data.fixPieceRequestsToBeOrdered.edges[0].node.id !==
            prevProps.selectedFixPieceRequestId
        ) {
          setSelectedFixPieceRequestId(
            data.fixPieceRequestsToBeOrdered.edges[0].node.id
          );
          setDisplayState("open-partial");
        }
      }
    }
  })
);

const FixPieceApp = ({
  data,
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  handleCreateFixPieceRequestClick,
  handleFindOrderByOrderNumberModalComplete,
  handleFindOrderByOrderNumberModalRequestClose,
  handleFixPieceRequestTableRowClicked,
  handleFixPieceRequestTableScrolledToBottom,
  handleRegisterManualOrderModalComplete,
  handleRegisterManualOrderModalRequestClose,
  handleRequestFixPieceModalComplete,
  handleRequestFixPieceModalRequestClose,
  handleSearch,
  handleSelectOrderProductionJobModalClose,
  handleSelectOrderProductionJobModalComplete,
  handleShowAllSwitchChanged,
  handleSort,
  hasNextPage,
  isLoadingMore,
  loadingFixPieceRequestIds,
  processCancelation,
  processOrderGoods,
  selectedFixPieceRequestId,
  selectedOrderId,
  selectedOrderProductionJobId,
  showAllSwitchValue,
  showFindOrderByOrderNumberModal,
  showRegisterManualOrderModal,
  showRequestFixPieceModal,
  showSelectOrderProductionJobModal,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Fix Piece Requests"
      onSearch={handleSearch}
      onRequestBack={handleAppHeaderRequestBack}
      menuItems={[
        <MenuItem>
          Show All
          <Switch
            checked={showAllSwitchValue}
            onChange={handleShowAllSwitchChanged}
            color="primary"
          />
        </MenuItem>,
        <MenuItem>
          <Button onClick={handleCreateFixPieceRequestClick}>
            <Typography variant="body2">Request Fix Pieces</Typography>
          </Button>
        </MenuItem>
      ]}
      appBarBackgroundColor={appBarBackgroundColor}
    />
    {data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        {data.error.message}
      </Typography>
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        <FixPieceRequestTable
          loadingFixPieceRequestIds={loadingFixPieceRequestIds}
          onCanceled={processCancelation}
          onScrolledToBottom={handleFixPieceRequestTableScrolledToBottom}
          fixPieceRequests={filter(
            fixPieceRequestTableFragments.order,
            data.fixPieceRequestsToBeOrdered.edges.map(edge => ({
              ...edge.node
            }))
          )}
          onRequestSort={handleSort}
          onRequestOrderGoods={processOrderGoods}
          onRowClicked={handleFixPieceRequestTableRowClicked}
        />
        {isLoadingMore && hasNextPage && <CenteredSpinner />}
      </div>
    )}
    {showFindOrderByOrderNumberModal && (
      <FindOrderByOrderNumberModal
        isOpen={showFindOrderByOrderNumberModal}
        onComplete={handleFindOrderByOrderNumberModalComplete}
        onRequestClose={handleFindOrderByOrderNumberModalRequestClose}
      />
    )}
    {showSelectOrderProductionJobModal && (
      <SelectOrderProductionJobModal
        orderId={selectedOrderId}
        orderProductionJobId={selectedOrderProductionJobId}
        isOpen={showSelectOrderProductionJobModal}
        onComplete={handleSelectOrderProductionJobModalComplete}
        onRequestClose={handleSelectOrderProductionJobModalClose}
      />
    )}
    <RequestFixPieceModal
      isOpen={showRequestFixPieceModal}
      onRequestClose={handleRequestFixPieceModalRequestClose}
      onComplete={handleRequestFixPieceModalComplete}
      orderProductionJobId={selectedOrderProductionJobId}
    />
    <RegisterManualOrderModal
      fixPieceRequestId={selectedFixPieceRequestId}
      isOpen={showRegisterManualOrderModal}
      onComplete={handleRegisterManualOrderModalComplete}
      onRequestClose={handleRegisterManualOrderModalRequestClose}
    />
  </div>
);

export default enhancer(FixPieceApp);
