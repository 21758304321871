// @flow

import * as React from "react";
import { compose, setDisplayName, withStateHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../../../../../components/PendingStatusView";
import ProductionJobList from "../ProductionJobList";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +divider: string,
    +productionJobListContainer: string
  |},
  +isOpen: boolean,
  +onComplete: (orderProductionJobId: string) => void,
  +onRequestClose: () => void,
  +orderId: ?string,
  +orderProductionJobId: ?string
|};

type State = {|
  selectedOrderProductionJobId: ?string
|};

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  productionJobListContainer: {
    overflowY: "scroll",
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "100%",
    flex: "1 1 15%",
    minWidth: 300
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("SelectOrderProductionJobModal"),

  graphql(query, {
    skip: ({ isOpen, orderId }) => !isOpen || !orderId
  }),

  withStyles(styles),

  withStateHandlers(
    ({
      selectedOrderProductionJobId: null
    }: State),
    {
      setSelectedOrderProductionJobId:
      () =>
      (
        selectedOrderProductionJobId: ?string
      ) => ({
        selectedOrderProductionJobId
      })
    }
  )
);

const SelectOrderProductionJobModal = ({
  classes,
  isOpen,
  onComplete,
  onRequestClose,
  orderProductionJobId,
  setSelectedOrderProductionJobId,
  selectedOrderProductionJobId,
  data
}) => {
  const handleCancelButtonClick = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (onComplete) {
      let selectedId = selectedOrderProductionJobId || orderProductionJobId;
      onComplete({
        selectedOrderProductionJobId: selectedId
      });
    }
    onRequestClose();
  };

  const handleProductionJobListSelection = (productionJobId) => {
    setSelectedOrderProductionJobId(productionJobId);
  };

  return (
    <Dialog
      aria-describedby="Select Production Job"
      aria-labelledby="Select Production Job Modal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Select Production Job</DialogTitle>
      <DialogContent style={{ minHeight: 250 }}>
        {!data ? (
          <PendingStatusView status="Waiting" />
        ) : data.loading ? (
          <PendingStatusView status="Loading" />
        ) : data.error ? (
          <div>
            <Typography variant="body2">
              There was an error while attempting to load the production jobs
            </Typography>
          </div>
        ) : !data.node ? (
          <Typography variant="body2">
            Unfortunately, that order could not be located
          </Typography>
        ) : (
          <div className={classes.productionJobListContainer}>
            <ProductionJobList
              onProductionJobSelected={handleProductionJobListSelection}
              productionJobs={data.node.productionJobs}
              orderProductionJobId={orderProductionJobId}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!selectedOrderProductionJobId && !orderProductionJobId}
          onClick={handleSubmitButtonClick}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(SelectOrderProductionJobModal);
