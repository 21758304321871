// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "../../application/apps/PullFromStockApp/graph";

const markOrderProductionJobAsStockPulled: (
  ordersPerPage?: number
) => any = ordersPerPage => {
  return graphql(
    gql`
      mutation($orderProductionJobId: ID!) {
        markOrderProductionJobAsStockPulled(
          orderProductionJobId: $orderProductionJobId
        ) {
          errors {
            orderProductionJobId
          }
          order {
            id
          }
          succeeded
        }
      }
    `,
    {
      name: "markOrderProductionJobAsStockPulled",
      options: {
        update: (
          proxy,
          {
            data: {
              markOrderProductionJobAsStockPulled: { order }
            }
          }: any
        ) => {
          const cachedQuery = proxy.readQuery({
            query,
            variables: { first: ordersPerPage }
          });
          if (!cachedQuery) {
            return;
          }
          const edges = cachedQuery.orderProductionJobsToBePulled.edges;
          const indexToRemove = edges.findIndex(
            edge => edge.node.order.id === order.id
          );
          cachedQuery.orderProductionJobsToBePulled.edges = [
            ...edges.slice(0, indexToRemove),
            ...edges.slice(indexToRemove + 1, edges.length)
          ];

          proxy.writeQuery({ query, data: cachedQuery });
        }
      }
    }
  );
};

export default markOrderProductionJobAsStockPulled;
