// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "../../application/apps/ScreensMadeApp/graph";

const markOrderAsScreensMade: (
  ordersPerPage: number
) => any = ordersPerPage => {
  return graphql(
    gql`
      mutation($orderId: ID!) {
        markOrderAsScreensMade(orderId: $orderId) {
          errors {
            orderId
          }
          order {
            id
          }
          succeeded
        }
      }
    `,
    {
      name: "markOrderAsScreensMade",
      options: {
        update: (
          proxy,
          {
            data: {
              markOrderAsScreensMade: { order }
            }
          }: any
        ) => {
          const cachedQuery = proxy.readQuery({
            query,
            variables: { first: ordersPerPage }
          });
          if (!cachedQuery) {
            return;
          }
          const edges = cachedQuery.ordersToBeScreened.edges;
          const indexToRemove = edges.findIndex(
            edge => edge.node.id === order.id
          );
          cachedQuery.ordersToBeScreened.edges = [
            ...edges.slice(0, indexToRemove),
            ...edges.slice(indexToRemove + 1, edges.length)
          ];

          proxy.writeQuery({ query, data: cachedQuery });
        }
      }
    }
  );
};

export default markOrderAsScreensMade;
