// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { withStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import amber from "@mui/material/colors/amber";
import classNames from "classnames";
import green from "@mui/material/colors/green";

import type { HOC } from "recompose";

type Props = {|
  +action: Array<{}>,
  +classes: {|
    +close: string,
    +error: string,
    +icon: string,
    +iconVariant: string,
    +info: string,
    +message: string,
    +success: string,
    +warning: string
  |},
  +message: string,
  +onClose: () => void,
  +variant: "success" | "warning" | "error" | "info"
|};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
    borderRadius: theme.shape.borderRadius
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    borderRadius: theme.shape.borderRadius
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.shape.borderRadius
  },
  warning: {
    backgroundColor: amber[700],
    borderRadius: theme.shape.borderRadius
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("SnackbarContentWrapper")
);

const SnackbarContentWrapper = ({
  classes,
  message,
  onClose,
  variant,
  ...other
}) => {
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

export default withStyles(styles)(enhancer(SnackbarContentWrapper));
