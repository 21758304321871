// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as hddOrderStatusFragments } from "../../application/apps/HDDPressApp/components/HDDOrderStatus/graph";

const markOrderAsHighDefinitionDigitalPaused: any = graphql(
  gql`
    mutation ($orderId: ID!) {
      markOrderAsHighDefinitionDigitalPaused(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...HDDOrderStatus_order
        }
        succeeded
      }
    }
    ${hddOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsHighDefinitionDigitalPaused"
  }
);

export default markOrderAsHighDefinitionDigitalPaused;
