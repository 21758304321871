// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment OrderPricingSummary_order on Order {
      appliedFees {
        amount {
          formatted
        }
        fee {
          id
          name
        }
        id
      }
      pricingBreakdownByProduct {
        colorCount
        product {
          id
          style
        }
        totalPrice {
          formatted
        }
        totalProductVariantQuantity
      }
      totalFeesPrice {
        formatted
      }
      totalPrice {
        formatted
      }
      totalProductsPrice {
        formatted
      }
      totalProductVariantQuantity
      totalShippingPrice {
        formatted
      }
    }
  `
};
