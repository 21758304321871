// @flow

import { TwitterPicker } from "react-color";
import { addProofToOrderProductionJob } from "../../../graph";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { requestFileUploadAllocation } from "../../../graph";
import { withRouter } from "found";
import AlignmentForm from "./components/AlignmentForm";
import ArtLayer from "./components/ArtLayer";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Drawer from "@mui/material/Drawer";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FoundationLayer from "./components/FoundationLayer";
import Grid from "./components/Grid";
import GridOnIcon from "@mui/icons-material/GridOn";
import IconButton from "@mui/material/IconButton";
import ImageFoundation from "./components/foundations/ImageFoundation";
import MaskFoundation from "./components/foundations/MaskFoundation";
import MenuItem from "@mui/material/MenuItem";
import NamesCollegeLargeImage from "../../../assets/proof/names-college-large.png";
import NamesCollegeSmallImage from "../../../assets/proof/names-college-small.png";
import NamesInterstateLargeImage from "../../../assets/proof/names-interstate-large.png";
import NamesInterstateSmallImage from "../../../assets/proof/names-interstate-small.png";
import PendingStatusView from "../../../components/PendingStatusView";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import TabbedAppBar from "../../../components/TabbedAppBar";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  currentLocation: string,
  isLoading: boolean
|};

const TOP = 220,
  LEFT = 225,
  PIXEL_PER_INCH = 11;

const dataURIToBlob = dataURI => {
  var byteString = atob(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var dw = new DataView(ab);
  for (var i = 0; i < byteString.length; i++) {
    dw.setUint8(i, byteString.charCodeAt(i));
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

const enhancer: HOC<*, *> = compose(
  setDisplayName("ProofingApp"),

  withRouter,

  withSnackbar,

  addProofToOrderProductionJob,

  requestFileUploadAllocation,

  //T3JkZXI6MTQ1NjY5NA==
  //"T3JkZXI6MTQ1Mjc2Nw=="

  graphql(query, {
    skip: ({ id }) => !id
  }),

  withStateHandlers(
    ({
      isLoading: false,
      currentLocation: null
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),
      setCurrentLocation: () => (currentLocation: string) => ({
        currentLocation
      })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl || "/apps");
      }
  })
);

const artForLocations = (productionJob, locations) =>
  productionJob.customizationAreas
    .map(
      ({
        vinylConfiguration,
        template: { code: location },
        productionArtwork
      }) => ({
        location,
        ...productionArtwork[0],
        vinylConfiguration
      })
    )
    .filter(
      ({ vinylConfiguration, location, remoteAsset }) =>
        (vinylConfiguration || remoteAsset) &&
        locations.indexOf(location) !== -1
    )
    .map(({ location, vinylConfiguration, remoteAsset }) => {
      console.log(vinylConfiguration);
      if (vinylConfiguration) {
        if (vinylConfiguration.namesFont.alias.toLowerCase() === "collegiate") {
          if (vinylConfiguration.sizeInteger === 4) {
            return {
              location,
              src: NamesCollegeSmallImage,
              color: vinylConfiguration.namesColor.inkColor.color.formatted
            };
          } else if (vinylConfiguration.sizeInteger === 8) {
            console.log(NamesCollegeLargeImage);
            return {
              location,
              src: NamesCollegeLargeImage,
              color: vinylConfiguration.namesColor.inkColor.color.formatted
            };
          }
        } else if (
          vinylConfiguration.namesFont.alias.toLowerCase() === "interstate"
        ) {
          if (vinylConfiguration.sizeInteger === 4) {
            return {
              location,
              src: NamesInterstateSmallImage,
              color: vinylConfiguration.namesColor.inkColor.color.formatted
            };
          } else if (vinylConfiguration.sizeInteger === 8) {
            return {
              location,
              src: NamesInterstateLargeImage,
              color: vinylConfiguration.namesColor.inkColor.color.formatted
            };
          }
        }
      }
      console.log("GOT HERE");
      return { location, src: remoteAsset.url.formatted.split("?")[0] };
    });

const artWithDimensions = art => {
  return Promise.all(
    art.map(({ src, color, location }) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      }).then(({ src, naturalWidth, naturalHeight }) => {
        const modifiedNaturalWidth = color ? 10.0 * 300 : naturalWidth;
        const modifiedNaturalHeight = color ? 10.0 * 300 : naturalHeight;
        console.log("modifiedNaturalWidth");
        console.log(modifiedNaturalWidth);
        const width = Math.ceil((modifiedNaturalWidth / 300) * PIXEL_PER_INCH);
        const height = Math.ceil((modifiedNaturalHeight / 300) * PIXEL_PER_INCH);
        console.log("width");
        console.log(width);

        const canvasWidth = 14.0;
        const canvasHeight = 16.0;

        const locationOffsetLookup = {
          f: {
            areaWidth: 14.0,
            areaHeight: 16.0,
            offsetX: 0.0,
            offsetY: 0.0,
            anchorX: "center",
            anchorY: "top"
          },
          b: {
            areaWidth: 14.0,
            areaHeight: 20.0,
            offsetX: 0.0,
            offsetY: 0.0,
            anchorX: "center",
            anchorY: "top"
          },
          flc: {
            areaWidth: 4.0,
            areaHeight: 4.0,
            offsetX: 0.0,
            offsetY: 1.0,
            anchorX: "left",
            anchorY: "top"
          },
          frc: {
            areaWidth: 4.0,
            areaHeight: 4.0,
            offsetX: 0.0,
            offsetY: 0.0,
            anchorX: "right",
            anchorY: "top"
          },
        };

        const { 
          anchorX, 
          areaWidth, 
          offsetX,
          anchorY,
          areaHeight,
          offsetY
        } = locationOffsetLookup[location] || locationOffsetLookup["f"];

        let x = LEFT;
        if(anchorX === "center") {
          x += Math.ceil((canvasWidth * PIXEL_PER_INCH - width) / 2);
        } else if(anchorX === "left") {
          x += canvasWidth * PIXEL_PER_INCH;
          x -= areaWidth * PIXEL_PER_INCH;
          x += Math.ceil((areaWidth * PIXEL_PER_INCH - width) / 2);
          x -= offsetX * PIXEL_PER_INCH;
        } else if(anchorX === "right") {
          x += Math.ceil((areaWidth * PIXEL_PER_INCH - width) / 2);
          x += offsetX * PIXEL_PER_INCH;
        }

        let y = TOP;
        if(anchorY === "center") {
          y += Math.ceil((canvasHeight * PIXEL_PER_INCH - height) / 2);
        } else if(anchorY === "top") {
          y += offsetY * PIXEL_PER_INCH;
        } else if(anchorY === "bottom") {
          y += canvasHeight * PIXEL_PER_INCH;
          y -= areaHeight * PIXEL_PER_INCH;
          y -= offsetY * PIXEL_PER_INCH;
        }

        // TODO: Sort out print areas
        //const x = LEFT + Math.ceil((14 * PIXEL_PER_INCH - width) / 2); // TODO: Assume front print area, should adjust
        //const y = TOP;
        console.log("x, y");
        console.log(x, y);
        return {
          src,
          x,
          y,
          width,
          printWidth: modifiedNaturalWidth / 300,
          printHeight: naturalHeight / 300,
          color,
          location
        };
      })
    )
  );
};

const ProofingApp = ({
  handleAppHeaderRequestBack,
  appBarBackgroundColor,
  addProofToOrderProductionJob,
  requestFileUploadAllocation,
  showErrorSnackbar,
  showSuccessSnackbar,
  data: { loading, node: order, productsWithMask }
}) => {
  const [selectedProductionJob, setSelectedProductionJob] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [gridVisible, setGridVisible] = useState(true);
  const [foundationGhostVisible, setFoundationGhostVisible] = useState(false);
  const [foundationSide, setFoundationSide] = useState("primary");
  const [alignmentMode, setAlignmentMode] = useState("artwork");

  // Primary
  const [primaryProductMode, setPrimaryProductMode] = useState("default");
  const [primaryFoundationLayerAlignment, setPrimaryFoundationLayerAlignment] =
    useState({
      position: { x: 0, y: 0 },
      size: { width: 600 }
    });
  const [primaryArtLayerAlignment, setPrimaryArtLayerAlignment] = useState({
    position: { x: (600 - (3006 / 300) * 16) / 2, y: 230 },
    size: { width: (3006 / 300) * 16 }
  });
  const [primaryMaskFoundationColor, setPrimaryMaskFoundationColor] =
    useState("#000000");
  const [primaryMaskFoundationProduct, setPrimaryMaskFoundationProduct] =
    useState(null);
  const [primaryCustomImageFoundationSrc, setPrimaryCustomImageFoundationSrc] =
    useState(null);
  const [primaryArt, setPrimaryArt] = useState([]);
  const [forceUploadCheckboxChecked, setForceUploadCheckboxChecked] = useState(false);

  // Secondary
  const [secondaryProductMode, setSecondaryProductMode] = useState("default");
  const [
    secondaryFoundationLayerAlignment,
    setSecondaryFoundationLayerAlignment
  ] = useState({
    position: { x: 0, y: 0 },
    size: { width: "600" }
  });
  const [secondaryArtLayerAlignment, setSecondaryArtLayerAlignment] = useState({
    position: { x: (600 - (3006 / 300) * 16) / 2, y: 230 },
    size: { width: (3006 / 300) * 16 }
  });
  const [secondaryMaskFoundationColor, setSecondaryMaskFoundationColor] =
    useState("#000000");
  const [secondaryMaskFoundationProduct, setSecondaryMaskFoundationProduct] =
    useState(null);
  const [
    secondaryCustomImageFoundationSrc,
    setSecondaryCustomImageFoundationSrc
  ] = useState(null);
  const [secondaryArt, setSecondaryArt] = useState([]);

  const [note, setNote] = useState("");
  const [isProofPreviewModalOpen, setIsProofPreviewModalOpen] = useState(false);
  const [isProofPreviewLoading, setIsProofPreviewLoading] = useState(true);
  const [isProofUploading, setIsProofUploading] = useState(false);
  const [proofPreviewUrl, setProofPreviewUrl] = useState(null);

  useEffect(() => {
    if(order) {
      const [firstProductionJob] = order.productionJobs;
      if(firstProductionJob) {
        setSelectedProductionJob(firstProductionJob);
      }
    }
  }, [order]);

  useEffect(() => {
    if (selectedProductionJob) {
      const [{ product: firstProduct, lineItems }] = selectedProductionJob.lineItemBreakdownByProduct;
      const [{ color: firstColor }] = lineItems;
      if(firstProduct && firstColor) {
        setSelectedProduct({ product: firstProduct, color: firstColor });
      }
      artWithDimensions(
        artForLocations(selectedProductionJob, [
          "f",
          "hf",
          "s",
          "frc",
          "flc",
          "sl",
          "sr"
        ])
      ).then(artData => {
        console.log("artData");
        console.log(artData);
        /*setPrimaryArt([
          {
            src: NamesCollegeLargeImage,
            x: 0,
            y: 0,
            width: 0,
            printWidth: 0,
            printHeight: 0
          }
        ]);*/
        setPrimaryArt(artData);
        // TODO: Handle multiple art layers
        setPrimaryArtLayerAlignment({
          ...primaryArtLayerAlignment,
          position: { x: artData[0].x, y: artData[0].y },
          size: { ...primaryArtLayerAlignment.size, width: artData[0].width }
        });
      });

      artWithDimensions(
        artForLocations(selectedProductionJob, ["b", "bn", "hb"])
      ).then(artData => {
        setSecondaryArt(artData);
        setSecondaryArtLayerAlignment({
          ...secondaryArtLayerAlignment,
          position: { x: artData[0].x, y: artData[0].y },
          size: { ...secondaryArtLayerAlignment.size, width: artData[0].width }
        });
      });
    }
  }, [
    selectedProductionJob,
    setPrimaryArtLayerAlignment,
    setSecondaryArtLayerAlignment
  ]);

  const productMode =
    foundationSide === "primary" ? primaryProductMode : secondaryProductMode;
  const setProductMode =
    foundationSide === "primary"
      ? setPrimaryProductMode
      : setSecondaryProductMode;
  const foundationLayerAlignment =
    foundationSide === "primary"
      ? primaryFoundationLayerAlignment
      : secondaryFoundationLayerAlignment;
  const setFoundationLayerAlignment =
    foundationSide === "primary"
      ? setPrimaryFoundationLayerAlignment
      : setSecondaryFoundationLayerAlignment;
  const artLayerAlignment =
    foundationSide === "primary"
      ? primaryArtLayerAlignment
      : secondaryArtLayerAlignment;
  const setArtLayerAlignment =
    foundationSide === "primary"
      ? setPrimaryArtLayerAlignment
      : setSecondaryArtLayerAlignment;
  const maskFoundationColor =
    foundationSide === "primary"
      ? primaryMaskFoundationColor
      : secondaryMaskFoundationColor;
  const setMaskFoundationColor =
    foundationSide === "primary"
      ? setPrimaryMaskFoundationColor
      : setSecondaryMaskFoundationColor;
  const maskFoundationProduct =
    foundationSide === "primary"
      ? primaryMaskFoundationProduct
      : secondaryMaskFoundationProduct;
  const setMaskFoundationProduct =
    foundationSide === "primary"
      ? setPrimaryMaskFoundationProduct
      : setSecondaryMaskFoundationProduct;
  const customImageFoundationSrc =
    foundationSide === "primary"
      ? primaryCustomImageFoundationSrc
      : secondaryCustomImageFoundationSrc;
  const setCustomImageFoundationSrc =
    foundationSide === "primary"
      ? setPrimaryCustomImageFoundationSrc
      : setSecondaryCustomImageFoundationSrc;

  const handleKeyDown = ({ code }) => {
    const dimension = code === "ArrowRight" || code === "ArrowLeft" ? "x" : "y";
    const direction = code === "ArrowDown" || code === "ArrowRight" ? 1 : -1;

    const alignmentFunction =
      alignmentMode === "artwork"
        ? setArtLayerAlignment
        : setFoundationLayerAlignment;

    const alignment =
      alignmentMode === "artwork"
        ? artLayerAlignment
        : foundationLayerAlignment;

    const newAlignment = {
      ...(alignment || {}),
      position: {
        ...(alignment?.position || {}),
        [dimension]: (alignment?.position[dimension] || 0) + direction
      }
    };

    alignmentFunction(newAlignment);
  };

  const handleAlignmentModeChange = ({ target: { value } }) => {
    setAlignmentMode(value);
  };

  const handleProductModeChange = ({ target: { value } }) => {
    setProductMode(value);
  };

  const handleMaskFoundationProductChange = (event, value) => {
    setMaskFoundationProduct(value);
  };

  const handleMaskFoundationColorChange = ({ hex }) => {
    setMaskFoundationColor(hex);
  };

  const handleCustomImageFoundationChange = ({ target: { files } }) => {
    const [file] = files;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setCustomImageFoundationSrc(reader.result);
    };
  };

  const handleArtLayerChange = alignment => {
    setArtLayerAlignment({ ...artLayerAlignment, ...alignment });
  };

  const handleFoundationLayerChange = alignment => {
    console.log(foundationLayerAlignment);
    console.log(alignment);
    setFoundationLayerAlignment({ ...foundationLayerAlignment, ...alignment });
  };

  const handleToggleFoundationSide = () => {
    setFoundationSide(foundationSide === "primary" ? "secondary" : "primary");
  };

  const handleToggleGrid = () => {
    setGridVisible(!gridVisible);
  };

  const handleToggleFoundationGhost = () => {
    setFoundationGhostVisible(!foundationGhostVisible);
  };

  const handleSelectProductionJob = ({ target: { value } }) => {
    setSelectedProductionJob(value);
    setSelectedProduct(null);
    setFoundationSide("primary");
  };

  const handleSelectProduct = ({ target: { value } }) => {
    setSelectedProduct(value);
  };

  const handleNoteChange = ({ target: { value } }) => {
    setNote(value);
  };

  const handleProofPreviewModalRequestClose = () => {
    setProofPreviewUrl(null);
    setIsProofPreviewModalOpen(false);
    setIsProofPreviewLoading(true);
  };

  const handleOnProofPreviewLoad = () => {
    setIsProofPreviewLoading(false);
  };

  const defaultFoundationImage = selectedProduct
    ? `https://s3.amazonaws.com/eztees-catalog/productjpg/${selectedProduct.product.style.toLowerCase()}_${
        foundationSide === "primary" ? "f" : "b"
      }_${selectedProduct.color.code}.jpg`
    : null;

  const inverseDefaultFoundationImage = selectedProduct
    ? `https://s3.amazonaws.com/eztees-catalog/productjpg/${selectedProduct.product.style.toLowerCase()}_${
        foundationSide === "primary" ? "b" : "f"
      }_${selectedProduct.color.code}.jpg`
    : null;

  const art = foundationSide === "primary" ? primaryArt : secondaryArt;

  console.log("art");
  console.log(art);

  const handleSaveClick = () => {
    setIsProofPreviewModalOpen(true);
    /*const newProofPreviewUrl = new URL(
      "https://kwtj9zd1nl.execute-api.us-east-1.amazonaws.com/staging/proof"
    );*/

    const calculateArtDimensions = a => {
      if (a.length === 0) return null;
      const [{ printWidth: width, printHeight: height }] = a;
      return {
        width,
        height
      };
    };

    const primaryArtDimensions = calculateArtDimensions(primaryArt);
    const secondaryArtDimensions = calculateArtDimensions(secondaryArt);

    /*
    console.log("secondaryArt");
    console.log(secondaryArt);
    console.log("secondaryArtLayerAlignment");
    console.log(secondaryArtLayerAlignment);

    */

    console.log("primaryFoundationLayerAlignment");
    console.log(primaryFoundationLayerAlignment);

    const content = {
      customerName: order.primaryCustomer.name,
      orderNumber: order.orderNumber,
      note,
      previews: [
        {
          foundation: {
            mode: primaryProductMode === "mask" ? "MASK" : "IMAGE",
            ...(primaryProductMode === "mask"
              ? {
                  maskUrl: `https://s3.amazonaws.com/eztees-catalog/productmask/${primaryMaskFoundationProduct.style}_f_mask.png`,
                  textureUrl: `https://s3.amazonaws.com/eztees-catalog/productmask/${primaryMaskFoundationProduct.style}_f_texture.png`,
                  color: primaryMaskFoundationColor
                }
              : {}),
            ...(primaryProductMode === "default"
              ? {
                  url: `https://s3.amazonaws.com/eztees-catalog/productjpg/${selectedProduct.product.style.toLowerCase()}_f_${
                    selectedProduct.color.code
                  }.jpg`,
                  width: primaryFoundationLayerAlignment.size.width,
                  x: primaryFoundationLayerAlignment.position.x,
                  y: primaryFoundationLayerAlignment.position.y
                }
              : {}),
            ...(primaryProductMode === "custom"
              ? {
                  url: primaryCustomImageFoundationSrc,
                  width: primaryFoundationLayerAlignment.size.width,
                  x: primaryFoundationLayerAlignment.position.x,
                  y: primaryFoundationLayerAlignment.position.y
                }
              : {})
          },
          art: primaryArt.map(({ src, color }) => ({
            url: src,
            x: (primaryArtLayerAlignment.position.x - LEFT) * PIXEL_PER_INCH,
            y: (primaryArtLayerAlignment.position.y - TOP) * PIXEL_PER_INCH,
            width: primaryArtLayerAlignment.size.width * PIXEL_PER_INCH,
            color
          })),
          dimensions: primaryArtDimensions
        },
        {
          foundation: {
            mode: secondaryProductMode === "mask" ? "MASK" : "IMAGE",
            ...(secondaryProductMode === "mask"
              ? {
                  maskUrl: `https://s3.amazonaws.com/eztees-catalog/productmask/${secondaryMaskFoundationProduct.style}_b_mask.png`,
                  textureUrl: `https://s3.amazonaws.com/eztees-catalog/productmask/${secondaryMaskFoundationProduct.style}_b_texture.png`,
                  color: secondaryMaskFoundationColor
                }
              : {}),
            ...(secondaryProductMode === "default"
              ? {
                  url: `https://s3.amazonaws.com/eztees-catalog/productjpg/${selectedProduct.product.style.toLowerCase()}_b_${
                    selectedProduct.color.code
                  }.jpg`,
                  width: secondaryFoundationLayerAlignment.size.width,
                  x: secondaryFoundationLayerAlignment.position.x,
                  y: secondaryFoundationLayerAlignment.position.y
                }
              : {}),
            ...(secondaryProductMode === "custom"
              ? {
                  url: secondaryCustomImageFoundationSrc,
                  width: secondaryFoundationLayerAlignment.size.width,
                  x: secondaryFoundationLayerAlignment.position.x,
                  y: secondaryFoundationLayerAlignment.position.y
                }
              : {})
          },
          art: secondaryArt.map(({ src, color }) => ({
            url: src,
            x: (secondaryArtLayerAlignment.position.x - LEFT) * PIXEL_PER_INCH,
            y: (secondaryArtLayerAlignment.position.y - TOP) * PIXEL_PER_INCH,
            width: secondaryArtLayerAlignment.size.width * PIXEL_PER_INCH,
            color
          })),
          dimensions: secondaryArtDimensions
        }
      ],
      customizationAreas: [
        {
          name: "Front",
          method: primaryArt.length > 0 ? "HDD" : null,
          colors: []
        },
        {
          name: "Back",
          method: secondaryArt.length > 0 ? "HDD" : null,
          colors: []
        }
      ]
    };

    console.log("content");
    console.log(content);

    fetch(`${process.env.REACT_APP_LAMBDA_BASE_URL}/proof`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(content)
    })
      .then(response => response.json())
      .then(({ proof }) => {
        setProofPreviewUrl(proof);
      });
  };

  const handleProofUpload = () => {
    setIsProofUploading(true);
    requestFileUploadAllocation()
      .then(
        ({
          data: {
            requestFileUploadAllocation: {
              fileIdentifier,
              url: { formatted }
            }
          }
        }) => {
          const blob = dataURIToBlob(proofPreviewUrl);
          fetch(formatted, {
            method: "PUT",
            body: blob,
            headers: { "Content-Type": "application/octet-stream" },
            mode: "cors"
          }).then(r => {
            console.log(r);

            addProofToOrderProductionJob({
              variables: {
                fileIdentifier,
                orderProductionJobId: selectedProductionJob.id,
                force: forceUploadCheckboxChecked
              }
            })
              .then(
                ({
                  data: {
                    addProofToOrderProductionJob: {
                      succeeded,
                      errors: errorsArray
                    }
                  }
                }) => {
                  if (succeeded) {
                    showSuccessSnackbar("Upload complete!");
                  } else {
                    const { __typename, ...errors } = errorsArray; // eslint-disable-line no-unused-vars
                    showErrorSnackbar(
                      Object.values(errors)
                        .reduce(
                          (accumlator, value) => [...accumlator, ...value],
                          []
                        )
                        .join(",")
                    );
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              });
          });
        }
      )
      .catch(e => {
        showErrorSnackbar(e.message);
      })
      .finally(() => {
        setIsProofUploading(false);
      });
  };

  const handleForceUploadCheckboxChange = () => {
    setForceUploadCheckboxChecked(!forceUploadCheckboxChecked);
  }

  return (
    <>
      <div>
        <TabbedAppBar
          title="Proofing"
          onRequestBack={handleAppHeaderRequestBack}
          appBarBackgroundColor={appBarBackgroundColor}
        />
        {loading ? (
          <PendingStatusView status="Loading" />
        ) : (
          <div>
            <Select
              label="Production Job"
              value={selectedProductionJob}
              onChange={handleSelectProductionJob}
              renderValue={({ label }) => label}
            >
              {order.productionJobs.map(productionJob => (
                <MenuItem value={productionJob}>{productionJob.label}</MenuItem>
              ))}
            </Select>
            {selectedProductionJob && (
              <Select
                label="Product"
                value={selectedProduct}
                onChange={handleSelectProduct}
                renderValue={({ product: { style }, color: { name } }) =>
                  `${style} - ${name}`
                }
              >
                {selectedProductionJob.lineItemBreakdownByProduct.map(
                  ({ product, product: { style }, lineItems }) => {
                    return lineItems.map(({ color, color: { name } }) => (
                      <MenuItem value={{ product, color }}>
                        {style} - {name}
                      </MenuItem>
                    ));
                  }
                )}
              </Select>
            )}

            {selectedProduct && (
              <div className="App">
                <IconButton
                  onClick={handleToggleFoundationSide}
                  aria-label="Flip"
                >
                  <FlipCameraAndroidIcon />
                </IconButton>
                <IconButton onClick={handleToggleGrid} aria-label="Toggle Grid">
                  <GridOnIcon />
                </IconButton>
                <IconButton
                  onClick={handleToggleFoundationGhost}
                  aria-label="Toggle Foundation Ghost"
                >
                  <WallpaperIcon />
                </IconButton>
                <Drawer
                  variant="permanent"
                  anchor="right"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: 400,
                      padding: "80px 20px 20px 20px"
                    }
                  }}
                  style={{ position: "relative" }}
                  open
                >
                  <ToggleButtonGroup
                    value={alignmentMode}
                    exclusive
                    onChange={handleAlignmentModeChange}
                    aria-label="Alignment Mode"
                    style={{ margin: "0 auto" }}
                  >
                    <ToggleButton value="artwork" aria-label="artwork">
                      Artwork
                    </ToggleButton>
                    <ToggleButton value="product" aria-label="product">
                      Product
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {alignmentMode === "artwork" && (
                    <>
                      <h2>Artwork</h2>
                      <AlignmentForm
                        alignment={artLayerAlignment}
                        onChange={handleArtLayerChange}
                      />
                    </>
                  )}
                  {alignmentMode === "product" && (
                    <div>
                      <h2>Product</h2>
                      <AlignmentForm
                        alignment={foundationLayerAlignment}
                        onChange={handleFoundationLayerChange}
                      />
                      <ToggleButtonGroup
                        value={productMode}
                        exclusive
                        onChange={handleProductModeChange}
                        aria-label="Product Mode"
                        style={{ margin: "0 auto" }}
                      >
                        <ToggleButton value="default" aria-label="default">
                          Default
                        </ToggleButton>
                        <ToggleButton value="mask" aria-label="mask">
                          Mask
                        </ToggleButton>
                        <ToggleButton value="custom" aria-label="custom">
                          Custom
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {productMode === "mask" && (
                        <>
                          <Autocomplete
                            disablePortal
                            options={productsWithMask.edges.map(
                              ({ node: { style } }) => ({
                                label: style,
                                style: style.toLowerCase()
                              })
                            )}
                            value={maskFoundationProduct}
                            onChange={handleMaskFoundationProductChange}
                            sx={{ width: 300 }}
                            renderInput={params => (
                              <TextField {...params} label="Product" />
                            )}
                          />
                          <TwitterPicker
                            triangle="hide"
                            style={{ border: "none", boxShadow: "none" }}
                            colors={selectedProduct.product.colorAssociations.map(
                              ({
                                color: {
                                  color: { formatted }
                                }
                              }) => formatted
                            )}
                            onChange={handleMaskFoundationColorChange}
                          />
                        </>
                      )}
                      {productMode === "custom" && (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleCustomImageFoundationChange}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      position: "absolute",
                      bottom: 20,
                      margin: "0 -20px"
                    }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </Drawer>
                <div style={{ marginRight: 400 }}>
                  <div
                    style={{
                      margin: 50,
                      width: 600,
                      height: 600,
                      position: "relative"
                    }}
                    tabIndex="0"
                    onKeyDown={handleKeyDown}
                  >
                    <FoundationLayer
                      alignment={foundationLayerAlignment}
                      onChange={handleFoundationLayerChange}
                      enabled={alignmentMode === "product"}
                    >
                      {productMode === "default" && (
                        <ImageFoundation src={defaultFoundationImage} />
                      )}
                      {productMode === "mask" && maskFoundationProduct && (
                        <MaskFoundation
                          side={foundationSide}
                          color={maskFoundationColor}
                          {...(maskFoundationProduct || {})}
                        />
                      )}
                      {productMode === "custom" && customImageFoundationSrc && (
                        <ImageFoundation src={customImageFoundationSrc} />
                      )}
                    </FoundationLayer>
                    {foundationGhostVisible && (
                      <div style={{ opacity: 0.3, pointerEvents: "none" }}>
                        {productMode === "default" && (
                          <ImageFoundation
                            src={inverseDefaultFoundationImage}
                            enabled={false}
                          />
                        )}
                        {productMode === "mask" && (
                          <MaskFoundation
                            side={
                              foundationSide === "primary"
                                ? "secondary"
                                : "primary"
                            }
                            color={maskFoundationColor}
                            {...(maskFoundationProduct || {})}
                            enabled={false}
                          />
                        )}
                        {productMode === "custom" &&
                          customImageFoundationSrc && (
                            <ImageFoundation
                              src={customImageFoundationSrc}
                              enabled={false}
                            />
                          )}
                      </div>
                    )}
                    {art
                      //.map(({ src }) => src.split("?")[0])
                      .map(({ src, color }) => (
                        <ArtLayer
                          alignment={artLayerAlignment}
                          color={color}
                          src={src}
                          onChange={handleArtLayerChange}
                          enabled={alignmentMode === "artwork"}
                        />
                      ))}
                    {gridVisible && (
                      <Grid
                        top={foundationSide === "primary" ? TOP : TOP - PIXEL_PER_INCH*2}
                        left={LEFT}
                        pixelsPerInch={PIXEL_PER_INCH}
                        width={14}
                        height={foundationSide === "primary" ? 16 : 20}
                      />
                    )}
                  </div>
                  <div style={{ padding: 10 }}>
                    <TextField
                      label="Notes"
                      multiline
                      rows={4}
                      margin="normal"
                      fullWidth
                      value={note}
                      onChange={handleNoteChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        aria-describedby="Proof Preview Modal"
        aria-labelledby="ProofPreviewModal"
        open={isProofPreviewModalOpen}
        onClose={handleProofPreviewModalRequestClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>Proof Preview</DialogTitle>
        <DialogContent>
          {isProofPreviewLoading && <PendingStatusView status="Loading" />}
          {isProofUploading && <PendingStatusView status="Uploading" />}
          {isProofPreviewModalOpen && proofPreviewUrl && (
            <img
              width="100%"
              src={proofPreviewUrl}
              onLoad={handleOnProofPreviewLoad}
              style={{ display: isProofPreviewLoading ? "none" : "block" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={forceUploadCheckboxChecked}
                onChange={() =>
                  handleForceUploadCheckboxChange(
                    !forceUploadCheckboxChecked
                  )
                }
                color="primary"
              />
            }
            label="Force Upload"
          />
          <Button
            color="secondary"
            onClick={handleProofPreviewModalRequestClose}
            disabled={isProofUploading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleProofUpload}
            disabled={isProofUploading}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default enhancer(ProofingApp);
