// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const claimOrderProductionJobCustomizationArea: any = graphql(
  gql`
    mutation($orderProductionJobCustomizationAreaId: ID!) {
      claimOrderProductionJobCustomizationArea(
        orderProductionJobCustomizationAreaId: $orderProductionJobCustomizationAreaId
      ) {
        createdOrderProductionJobCustomizationAreaClaim {
          id
        }
        errors {
          orderProductionJobCustomizationAreaId
        }
        succeeded
        updatedOrderProductionJobCustomizationArea {
          id
        }
      }
    }
  `,
  {
    name: "claimOrderProductionJobCustomizationArea"
  }
);

export default claimOrderProductionJobCustomizationArea;
