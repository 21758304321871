// @flow

import { gql } from "@apollo/client";

export default {
  productionJobs: gql`
    fragment ProductionJobTabs_productionJobs on OrderProductionJob {
      id
      label
      totalPrice {
        formatted
      }
      totalProductVariantQuantity
    }
  `
};
