import * as React from "react";
import { theme } from ".";
import darkTheme from "./darkTheme";
const { Provider, Consumer } = React.createContext();

const ThemeContextProvider = props => {
  const [currentTheme, setCurrentTheme] = React.useState("light");
  const [componentMounted, setComponentMounted] = React.useState(false);

  const setMode = mode => {
    window.localStorage.setItem("theme", mode);
    setCurrentTheme(mode);
  };

  const toggleTheme = () => {
    if (currentTheme === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  React.useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    if (localTheme) {
      setCurrentTheme(localTheme);
    } else {
      setMode("light");
    }
    setComponentMounted(true);
  }, []);

  return (
    [currentTheme, toggleTheme, componentMounted],
    (
      <Provider
        value={{
          type: currentTheme,
          theme: currentTheme === "light" ? theme : darkTheme,
          toggleTheme: toggleTheme,
          componentMounted: componentMounted
        }}
      >
        {props.children}
      </Provider>
    )
  );
};

export { ThemeContextProvider, Consumer as ThemeContextConsumer };
