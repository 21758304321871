// @flow

import { fragments as customizationAreaListItemFragments } from "../components/CustomizationAreaArtworkListItem/graph";
import { gql } from "@apollo/client";

export default {
  proofs: gql`
    fragment CustomizationAreaArtworkList_proofs on Proof {
      id
      ...CustomizationAreaArtworkListItem_proof
    }
    ${customizationAreaListItemFragments.proof}
  `
};
