// @flow

import { gql } from "@apollo/client";
import { fragments as stockStagedTableFragments } from "../components/StockStagedTable/graph";

export default gql`
  query ordersToBeStaged(
    $first: Int!
    $after: String
    $filters: OrdersToBeStagedFilters
  ) {
    ordersToBeStaged(first: $first, after: $after, filters: $filters) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...StockStagedTable_order
        }
      }
      productionDates(filters: $filters)
    }
  }
  ${stockStagedTableFragments.order}
`;
