// @flow

import { DateTime } from "luxon";

const priorityOrder = (productionDate, dueDate) => {
  console.log("dueDate"); // eslint-disable-line no-console
  console.log(dueDate); // eslint-disable-line no-console
  console.log("productionDate"); // eslint-disable-line no-console
  console.log(productionDate); // eslint-disable-line no-console
  if (!dueDate) {
    return false;
  }
  const productionDay = DateTime.fromISO(productionDate);
  const dueDay = DateTime.fromISO(dueDate).startOf("day");
  let lastShippingDate = dueDay.minus({ days: 1 });
  let count = 0;
  while (lastShippingDate.weekday >= 6) {
    console.log(lastShippingDate.toString()); // eslint-disable-line no-console
    lastShippingDate = lastShippingDate.minus({ days: 1 });
    count++;
    if (count > 7) {
      break;
    }
  }
  console.log("lastShippingDate"); // eslint-disable-line no-console
  console.log(lastShippingDate); // eslint-disable-line no-console
  return productionDay.valueOf() >= lastShippingDate.valueOf();
};

export { priorityOrder };
