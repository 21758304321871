// @flow

//import { DateTime } from "luxon";
import { compose, setDisplayName } from "recompose";
import { fragments as customizationAreaArtworkListFragments } from "../../../CustomizationAreaArtworkList/graph";
//import { fragments as designProofListFragments } from "../../../DesignProofList/graph";
import { filter } from "graphql-anywhere";
//import { getIconForProductionJobMethod } from "../../../../helpers/getIconForProductionJobMethod";
//import { fragments as inkColorDetailsFragments } from "../../../InkColorDetails/graph";
//import { fragments as productionJobCustomizationAreaListFragments } from "../../../OrderProductionJobCustomizationAreaList/graph";
//import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../ProductionJobLineItemBreakdownTable/graph";
//import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../StyleSubstitutionLineItemBreakdown/graph";
import { withStyles } from "@mui/styles";
//import Button from "@mui/material/Button";
//import CustomerArtworkPreview from "../CustomerArtworkPreview";
import CustomizationAreaArtworkList from "../../../CustomizationAreaArtworkList";
//import CustomizationAreaBarcode from "../CustomizationAreaBarcode";
//import CustomizationAreaNotes from "../CustomizationAreaNotes";
//import DesignProofList from "../../../DesignProofList";
//import Divider from "@mui/material/Divider";
//import InkColorDetails from "../../../InkColorDetails";
//import NamesAndNumbers from "../NamesAndNumbers";
//import OrderProductionJobCustomizationAreaList from "../../../OrderProductionJobCustomizationAreaList";
//import ProductionJobLineItemBreakdownTable from "../../../ProductionJobLineItemBreakdownTable";
import React from "react";
//import StyleSubstitutionLineItemBreakdown from "../../../StyleSubstitutionLineItemBreakdown";
//import Tooltip from "@mui/material/Tooltip";
//import Typography from "@mui/material/Typography";

//import ArtworkProjectionContext from "../../../../application/ArtworkProjectionContext";
//import CustomizationAreaClaimDetails from "../../../CustomizationAreaClaimDetails";
import type { HOC } from "recompose";
import type { HighDefinitionDigitalStatus } from "../../types/HighDefinitionDigitalStatus";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


type Props = {|
  +classes?: {|
    +artworkContainer: string,
    +artworkDetailsContainer: string,
    +artworkFilesContainer: string,
    +buttonHeaderContainer: string,
    +detailContainer: string,
    +image: string,
    +inkColorListItem: string,
    +leftContainer: string,
    +list: string,
    +methodIconContainer: string,
    +productList: string,
    +productListItem: string,
    +productsContainer: string,
    +productStyleWrapper: string,
    +proofsProductContainer: string,
    +rightContainer: string,
    +tableContainer: string,
    +titleText: string
  |},
  +isArtApp: boolean,
  +productionJob: {|
    +customizationAreas: $ReadOnlyArray<{|
      +artwork: $ReadOnlyArray<{|
        +asset: {|
          +url: {|
            +formatted: string
          |}
        |},
        +id: string,
        +originalFilename: string
      |}>,
      +id: string,
      +inkColors: $ReadOnlyArray<{|
        +id: string,
        +inkColor: ?{|
          +color: {|
            +formatted: string
          |},
          +id: string,
          +name: string
        |},
        +pantoneColor: ?{|
          +code: string,
          +color: {|
            +formatted: string
          |},
          +id: string
        |}
      |}>,
      +location: string,
      +method: string
    |}>,
    +design: $ReadOnlyArray<{|
      +backPreviewImageUrl: {
        +formatted: string
      },
      +frontPreviewImageUrl: {
        +formatted: string
      },
      +rushOrderTeesUrl: {
        +formatted: string
      }
    |}>,
    +filteredProofs: $ReadOnlyArray<{|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string,
      +originalFilename: string
    |}>,
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +id: string,
    +lineItemBreakdownByProduct: $ReadOnlyArray<{|
      +id: string,
      +product: {|
        +style: string
      |}
    |}>,
    +unclassifiedArtwork: $ReadOnlyArray<{|
      +asset: {|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string,
      +originalFilename: string
    |}>
  |},
  +shouldShowCustomerArtworkWithNotes: boolean,
  +shouldShowInkColors: boolean
|};

const styles = theme => ({
  artworkContainer: {
    padding: theme.spacing(1)
  },

  artworkDetailsContainer: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  buttonHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  proofsProductContainer: {
    display: "flex",
    padding: theme.spacing(1)
  },

  detailContainer: {
    padding: theme.spacing(1)
  },

  image: {
    height: 100
  },

  inkColorListItem: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },

  artworkFilesContainer: {
    width: "100%",
    marginRight: theme.spacing(2)
  },

  leftContainer: {
    width: "40%",
    marginRight: theme.spacing(2)
  },

  list: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  locationContainer: {
    padding: theme.spacing(1)
  },

  methodIconContainer: {
    display: "flex",
    alignItems: "center"
  },

  productStyleWrapper: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    minWidth: 100
  },

  productsContainer: {
    display: "flex"
  },

  productList: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  productListItem: {
    marginBottom: theme.spacing(2)
  },

  rightContainer: {
    width: "60%"
  },

  tableContainer: {
    marginTop: -23,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

  tooltip: {
    backgroundColor: "#ffffff",
    padding: 0,
    display: "flex",
    flexWrap: "nowrap",
    width: "700px"
  },

  popper: { opacity: 1 },

  zoomedImage: {
    width: "300px",
    height: "auto"
  },

  proofsTitleButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },

  titleText: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  flipArtwork: {
    transform: "rotate(180deg)"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobDetail"),
  withStyles(styles)
);

/*
const customizationMethod = {
  DIRECT_TO_GARMENT: "Direct-to-garment",
  CUT_VINYL: "Cut Vinyl",
  EMBROIDERY: "Embroidery",
  HEAT_PRESS: "Heat Press",
  PRINTED_VINYL: "Printed Vinyl",
  SCREEN_PRINTING: "Screen Printing",
  SUBLIMATION: "Sublimation",
  TACKLE_TWILL: "Tackle Twill"
};
*/

// TODO: Method
const CustomizationArea = ({ customizationArea: { customerArtwork, quantity, width, height, precut, gangSheet } }) => (
  <Paper>
    <CustomizationAreaArtworkList
      flipArtwork={false}
      proofs={filter(
        customizationAreaArtworkListFragments.proofs,
        customerArtwork.map(({ file }) => ({ ...file, originalFilename: "" }))
      )}
    />
    <Grid container columnSpacing={2} rowSpacing={1} style={{padding: 5}}>
      <Grid item xs={6}>
        Quantity: {quantity}
      </Grid>
      <Grid item xs={6}>
        Precut: {precut ? "True" : "False"}
      </Grid>
      <Grid item xs={6}>
        Gangsheet: {gangSheet ? "True" : "False"}
      </Grid>
      <Grid item xs={6}>
        Width: {width}"
      </Grid>
      <Grid item xs={6}>
        Height: {height}"
      </Grid>
    </Grid>
  </Paper>
);

const ProductionJobDetail = ({
  //classes,
  //isArtApp,
  productionJob,
  //shouldShowCustomerArtworkWithNotes,
  //shouldShowInkColors
}) => {
  //const [flipArtworkEnabled, setFlipArtworkEnabled] = useState(false);
/*
  const { artworkProjectionEnabled, updateArtworkProjection } = useContext(
    ArtworkProjectionContext
  );
  */
  /*
  const handleFlipArtworkEnable = () => {
    setFlipArtworkEnabled(true);
  };
  const handleFlipArtworkDisable = () => {
    setFlipArtworkEnabled(false);
  };

  const handleArtworkProjection = (e, artworkUrl) => {
    console.log(artworkUrl);
    if (!artworkProjectionEnabled) return;
    e.preventDefault();
    updateArtworkProjection(artworkUrl);
  };
  */

  console.log("productionJob", productionJob);

  return (
    <div style={{ padding: 10 }}>
      <Grid container spacing={2}>
        {productionJob.customizationAreas.map((customizationArea) => (
          <Grid item xs={3} key={customizationArea.id}>
            <CustomizationArea customizationArea={customizationArea} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default enhancer(ProductionJobDetail);
