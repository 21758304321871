// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

type Props = {|
  +appImage: string,
  +classes: {
    +card: string
  },
  +handleAppCardClick: string,
  +location: string,
  +title: string
|};

const styles = {
  card: {
    width: "auto"
  }
};

const AppLauncherCard = ({
  classes,
  title,
  appImage,
  handleAppCardClick
}: Props) => {
  return (
    <Card className={classes.card} onClick={handleAppCardClick}>
      <CardActionArea>
        <CardMedia component="img" src={appImage} />
        <CardContent>
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withStyles(styles)(AppLauncherCard);
