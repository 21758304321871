// @flow

import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CustomerStatusChip from "../../../../../application/apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip/CustomerStatusChip";
import NoBrandTagIcon from "../../../../../assets/no-brand-tag.png";
import QaDashboardProductionJobCard from "./components/QaDashboardProductionJobCard";
import Typography from "@mui/material/Typography";
import useInterval from "@use-it/interval";
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  productionTabFontSize: {
    "@media (min-width:4320px)": {
      fontSize: "44px"
    }
  },
  orderNumberFontSize: {
    "@media (min-width: 4320px)": {
      fontSize: "54px"
    }
  }
});

const QaDashboardOrderDetailsCard = ({
  order,
  classes,
  order: {
    primaryCustomer: { status, insideBrandTagging }
  }
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const handleNextButtonClick = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= order.productionJobs.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(nextIndex);
    }
  };
  const handlePreviousButtonClick = () => {
    if (currentIndex === 0) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  useInterval(handleNextButtonClick, 5000);

  return (
    <Card style={{ height: "49vh", width: "49vw" }}>
      <CardHeader
        style={{ display: "flex", aligntems: "center" }}
        title={
          <div>
            <div style={{ float: "left" }}>
              <Typography variant="h6" className={classes.orderNumberFontSize}>
                {`Order: ${order.orderNumber}`}
              </Typography>
              <Typography
                variant="h6"
                className={classes.productionTabFontSize}
              >
                {`Production Job: ${currentIndex + 1}/${
                  order.productionJobs.length
                }`}
              </Typography>
            </div>
            <div
              style={{
                maxHeight: "70px",
                float: "left",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <CustomerStatusChip status={status} />
            </div>
            <div style={{ float: "left", alignSelf: "center" }}>
              {!insideBrandTagging && (
                <img
                  style={{ maxHeight: "70px" }}
                  src={NoBrandTagIcon}
                  alt="No Brand Tagging"
                />
              )}
            </div>
          </div>
        }
        action={
          <div>
            <Button onClick={() => handlePreviousButtonClick()}>
              Previous
            </Button>
            <Button onClick={() => handleNextButtonClick()}>Next</Button>
          </div>
        }
      />
      <CardContent style={{ padding: 0 }}>
        <QaDashboardProductionJobCard
          productionJob={order.productionJobs[currentIndex]}
        />
      </CardContent>
    </Card>
  );
};
export default withStyles(styles)(QaDashboardOrderDetailsCard);
