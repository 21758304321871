import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 0
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center"
  },
  table: {},
  tableRow: {
    flexDirection: "row"
  },
  tableHeader: {
    flex: 1,
    color: "#FFFFFF",
    backgroundColor: "#000000",
    padding: 2,
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "8pt"
  },
  tableHeaderWide: {
    flex: 2,
    color: "#FFFFFF",
    backgroundColor: "#000000",
    padding: 2,
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "8pt"
  },
  tableCell: {
    flex: 1,
    padding: 2,
    textAlign: "center",
    border: "1pt solid #000000",
    fontSize: "8pt"
  },
  tableCellWide: {
    flex: 2,
    padding: 2,
    textAlign: "center",
    border: "1pt solid #000000",
    fontSize: "8pt"
  }
});

const Table = ({ children }) => <View style={styles.table}>{children}</View>;
const TableRow = ({ children }) => (
  <View style={styles.tableRow}>{children}</View>
);
const TableHeader = ({ children, style }) => (
  <View style={style || styles.tableHeader}>
    <Text>{children}</Text>
  </View>
);
const TableCell = ({ children, style }) => (
  <View style={style || styles.tableCell}>
    <Text>{children}</Text>
  </View>
);

const CustomsLabel = ({ data, suffix, includePrintMethodInSuffix, title }) => {
  return (
    <View style={styles.page}>
      {title && <Text style={styles.title}>{title}</Text>}
      <Table>
        <TableRow>
          <TableHeader style={styles.tableHeaderWide}>Identifier</TableHeader>
          <TableHeader style={styles.tableHeaderWide}>Description</TableHeader>
          <TableHeader>Qty</TableHeader>
          <TableHeader>Origin</TableHeader>
        </TableRow>
        {data.map(({ identifier, quantity, countryOfOrigin, description, embroidery }) => (
          <TableRow key={`${identifier}-${countryOfOrigin}${((includePrintMethodInSuffix && embroidery) ? "-E" : "")}${suffix}`}>
            <TableCell style={styles.tableCellWide}>
              {identifier}-{countryOfOrigin}
              {(includePrintMethodInSuffix && embroidery) ? "-E" : ""}
              {suffix}
            </TableCell>
            <TableCell style={styles.tableCellWide}>{description}</TableCell>
            <TableCell>{quantity}</TableCell>
            <TableCell>{countryOfOrigin}</TableCell>
          </TableRow>
        ))}
      </Table>
    </View>
  );
};

export default CustomsLabel;
