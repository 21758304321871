// @flow

import * as React from "react";
import { Link } from "found";
import { filter } from "graphql-anywhere";
import { fragments as orderFulfillmentSummaryFragments } from "../../../../../components/OrderFulfillmentSummary/graph";
import { fragments as orderPricingSummaryFragments } from "../../../../../components/OrderPricingSummary/graph";
import { withStyles } from "@mui/styles";
import OrderFulfillmentSummary from "../../../../../components/OrderFulfillmentSummary";
import OrderPricingSummary from "../../../../../components/OrderPricingSummary";

type Props = {|
  +classes: {|
    +orderNumberLink: string
  |},
  +order: {|
    +id: string,
    +orderNumber: string
  |}
|};

const styles = theme => ({
  orderNumberLink: {
    color: theme.palette.getContrastText(theme.palette.primary.light)
  }
});

const CustomerArchiveOrderSummary = ({ classes, order }: Props) => (
  <div>
    <OrderFulfillmentSummary
      order={filter(orderFulfillmentSummaryFragments.order, order)}
      title={
        <Link
          className={classes.orderNumberLink}
          to={`/apps/production/orders/${order.id}`}
          rel="noopener"
          target="_blank"
        >
          Order #{order.orderNumber}
        </Link>
      }
    />
    <OrderPricingSummary
      order={filter(orderPricingSummaryFragments.order, order)}
    />
  </div>
);

export default withStyles(styles)(CustomerArchiveOrderSummary);
