// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment EmbroideryOrderStatus_order on Order {
      id
      canViewerCompleteEmbroidery
      canViewerCompleteTrimming
      canViewerPlaceOnHold
      canViewerRemoveHold
      canViewerStartEmbroidery
      embroideryStatus
    }
  `
};
