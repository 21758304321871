// @flow

import { fragments as customizationAreaListFragments } from "../../CustomizationAreaList/graph";
import { fragments as designProofListFragments } from "../../../../../../components/DesignProofList/graph";
import { gql } from "@apollo/client";
import { fragments as productQuantitySummaryFragments } from "../../../components/ProductQuantitySummary/graph";

export default gql`
  query OrderProductionJob($orderProductionJobId: ID!) {
    orderProductionJob: node(id: $orderProductionJobId) {
      id
      ... on OrderProductionJob {
        customizationAreas {
          ...CustomizationAreaList_customizationAreas
        }
        label
        filteredProofs {
          ...DesignProofList_proofs
        }
      }
      ...ProductQuantitySummary_productionJob
    }
  }
  ${productQuantitySummaryFragments.productionJob}
  ${customizationAreaListFragments.customizationAreas}
  ${designProofListFragments.proofs}
`;
