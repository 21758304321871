// @flow

import { fragments as designProofListItemFragments } from "../components/DesignProofListItem/graph";
import { gql } from "@apollo/client";

export default {
  proofs: gql`
    fragment DesignProofList_proofs on Proof {
      id
      ...DesignProofListItem_proof
    }
    ${designProofListItemFragments.proof}
  `
};
