// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";

import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +action: string,
    +contactColumn: string,
    +contactWrapper: string,
    +dateColumn: string,
    +screensMadeColumn: string
  |},
  +loadingOrderIds: $ReadOnlyArray<string>,
  +onRequestMakeScreen: (orderId: string) => void,
  +onRequestSort: (orderSortFilter: string) => void,
  +onScrolledToBottom: () => void,
  +orders: $ReadOnlyArray<{|
    +id: string,
    +orderNumber: string,
    +primaryCustomer: {|
      +name: string
    |},
    +priority: ?string,
    +productionDate: ?string
  |}>
|};

const styles = theme => ({
  action: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2)
  },

  contactWrapper: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  contactColumn: {
    width: 150,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  screensMadeColumn: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0
    }
  },

  dateColumn: {
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ScreensMadeTable"),

  withHandlers({
    handleMakeScreenButtonClick: ({ onRequestMakeScreen }) => event => {
      onRequestMakeScreen(event.currentTarget.id);
    },

    handleSortRequest: ({ onRequestSort }) => event => {
      onRequestSort(event.currentTarget.id);
    }
  })
);

const ScreensMadeTable = ({
  classes,
  handleMakeScreenButtonClick,
  handleSortRequest,
  loadingOrderIds,
  onScrolledToBottom,
  orders
}) => {
  const isScrolledToBottom = useScrollPosition().isScrolledToBottom;
  React.useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell className={classes.contactColumn}>
              <div className={classes.contactWrapper}>
                <Button id="CUSTOMER_NAME" onClick={handleSortRequest}>
                  <Typography color="textSecondary" variant="overline">
                    Customer/Order #
                  </Typography>
                </Button>
              </div>
            </CustomTableCell>
            <CustomTableCell align="center" className={classes.dateColumn}>
              <Button id="PRODUCTION_DATE" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  Production Date
                </Typography>
              </Button>
            </CustomTableCell>
            <CustomTableCell
              align="center"
              className={classes.screensMadeColumn}
            >
              <Typography color="textSecondary" variant="overline">
                Screens Made
              </Typography>
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell className={classes.contactColumn} scope="row">
                <div className={classes.contactWrapper}>
                  <Typography variant="h6">
                    {order.primaryCustomer.name}
                  </Typography>
                  <Typography color="textSecondary">
                    {order.orderNumber}
                  </Typography>
                </div>
              </TableCell>
              <TableCell align="center" className={classes.dateColumn}>
                {order.productionDate && (
                  <PriorityChip
                    date={order.productionDate}
                    priority={order.priority}
                  />
                )}
              </TableCell>
              <TableCell
                align="center"
                className={classNames(
                  classes.action,
                  classes.screensMadeColumn
                )}
              >
                {loadingOrderIds.includes(order.id) ? (
                  <CircularProgress />
                ) : (
                  <Button
                    id={order.id}
                    variant="contained"
                    color="primary"
                    onClick={handleMakeScreenButtonClick}
                  >
                    Made
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default withStyles(styles)(enhancer(ScreensMadeTable));
