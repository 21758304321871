// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as notesWrapperOrderFragment } from "../../components/NotesWrapper/graph";

const addProductionNoteToOrder: any = graphql(
  gql`
    mutation($orderId: ID!, $note: String!) {
      addProductionNoteToOrder(orderId: $orderId, note: $note) {
        errors {
          orderId
          note
        }
        updatedOrder {
          ...NotesWrapper_order
        }
        succeeded
      }
    }
    ${notesWrapperOrderFragment.order}
  `,
  {
    name: "addProductionNoteToOrder"
  }
);

export default addProductionNoteToOrder;
