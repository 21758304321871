// @flow

import * as React from "react";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import {
  formatProductCustomizationMethod,
  formatRectangularGeometricArea
} from "./helpers";
import { fragments as inkColorDetailsFragments } from "../../../../../../../components/InkColorDetails/graph";
import InkColorDetails from "../../../../../../../components/InkColorDetails";
import NotesCollectionBreakdown from "../../../../../../../components/NotesCollectionBreakdown";

import type {
  ProductCustomizationMethod,
  RectangularGeometricArea
} from "./types";

type UnformattedNoteType = {|
  +__typename: "UnformattedNote",
  +note: string
|};

type FormattedNoteType = {|
  +__typename: "FormattedNote",
  +author: string,
  +createdAt: string,
  +note: string
|};

type NoteType = FormattedNoteType | UnformattedNoteType;

type Props = {|
  +classes: {|
    +descriptionTag: string,
    +inkColorListItem: string,
    +list: string,
    +listDescription: string
  |},
  +customizationArea: {|
    +id: string,
    +inkColors: $ReadOnlyArray<{|
      +id: string,
      +inkColor: ?{||},
      +pantoneColor: ?{||}
    |}>,
    +location: string,
    +method: ProductCustomizationMethod,
    +notesCollection: $ReadOnlyArray<NoteType>,
    +shape: RectangularGeometricArea
  |},
  +index: string
|};

const styles = theme => ({
  descriptionTag: {
    fontWeight: theme.typography.fontWeightMedium
  },

  inkColorListItem: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  listDescription: {
    margin: 0
  }
});

const CustomizationAreaListItem = ({
  classes,
  customizationArea: { inkColors, location, method, notesCollection, shape },
  index
}: Props) => (
  <div>
    <Typography variant="caption" color="inherit">
      Print Area {index}
    </Typography>
    <Typography variant="h6" color="textSecondary">
      {location}
    </Typography>
    <Typography variant="caption" color="inherit">
      {formatRectangularGeometricArea(shape)}
    </Typography>
    <dl>
      <Typography
        className={classes.descriptionTag}
        component="dt"
        variant="body2"
      >
        Print Method(s):
      </Typography>
      <Typography
        component="dd"
        className={classes.listDescription}
        variant="body2"
        color="textSecondary"
      >
        {formatProductCustomizationMethod(method)}
      </Typography>
    </dl>
    <dl>
      <Typography
        className={classes.descriptionTag}
        component="dt"
        variant="body2"
      >
        Ink Color(s):
      </Typography>
      <Typography
        component="dd"
        className={classes.listDescription}
        variant="body2"
        color="textSecondary"
      >
        {inkColors.length === 0 ? (
          <span>No colors specified</span>
        ) : (
          <ul className={classes.list}>
            {inkColors.map(({ id, inkColor, pantoneColor }) => (
              <li className={classes.inkColorListItem} key={id}>
                <InkColorDetails
                  inkColor={
                    inkColor
                      ? filter(inkColorDetailsFragments.inkColor, inkColor)
                      : null
                  }
                  pantoneColor={
                    pantoneColor
                      ? filter(
                          inkColorDetailsFragments.pantoneColor,
                          pantoneColor
                        )
                      : null
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </Typography>
    </dl>
    <dl>
      <Typography
        className={classes.descriptionTag}
        component="dt"
        variant="body2"
      >
        Print Area Notes:
      </Typography>
      <NotesCollectionBreakdown notesCollection={notesCollection} />
    </dl>
  </div>
);

export default withStyles(styles)(CustomizationAreaListItem);
