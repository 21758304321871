// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from "@apollo/client/react/hoc";
import { markOrderProductionJobAsStockPulled } from "../../../graph";
import { fragments as pullFromStockTableFragments } from "./components/PullFromStockTable/graph";
import { query } from "./graph";
import { withRouter } from "found";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../../components/CenteredSpinner";
import ExpeditingLabelViewer from "../../../components/ExpeditingLabelViewer";
import MenuItem from "@mui/material/MenuItem";
import PendingStatusView from "../../../components/PendingStatusView";
import PullFromStockDetailView from "./components/PullFromStockDetailView";
import PullFromStockTable from "./components/PullFromStockTable";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withDrawer from "../../../components/withDrawer";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {||};

type State = {|
  hasNextPage: boolean,
  isLoadingMore: boolean,
  loadingProductionJobIds: Array<string>,
  searchQuery: ?string,
  selectedProductionDate: ?string,
  selectedProductionJobId: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string,
  showAllSwitchValue: boolean
|};

const ORDERS_PER_PAGE = 20;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("PullFromStockApp"),

  withRouter,

  withSnackbar,

  markOrderProductionJobAsStockPulled(ORDERS_PER_PAGE),

  withStateHandlers(
    ({
      hasNextPage: true,
      isLoadingMore: false,
      searchQuery: null,
      selectedProductionDate: null,
      loadingProductionJobIds: [],
      selectedProductionJobId: null,
      selectedSortBy: null,
      showAllSwitchValue: false,
      selectedSortDirection: null
    }: State),
    {
      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedProductionJobId: () => (selectedProductionJobId: ?string) => ({
        selectedProductionJobId
      }),

      setSelectedProductionDate: () => (selectedProductionDate: ?string) => ({
        selectedProductionDate
      }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      addLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => ({
          loadingProductionJobIds: [...loadingProductionJobIds, productionJobId]
        }),

      removeLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => {
          const indexToRemove =
            loadingProductionJobIds.indexOf(productionJobId);
          return {
            loadingProductionJobIds: [
              ...loadingProductionJobIds.slice(0, indexToRemove),
              ...loadingProductionJobIds.slice(
                indexToRemove + 1,
                loadingProductionJobIds.length
              )
            ]
          };
        },

      setShowAllSwitchValue: () => (showAllSwitchValue: boolean) => ({
        showAllSwitchValue
      })
    }
  ),

  graphql(query, {
    options: ({
      searchQuery,
      selectedProductionDate,
      selectedSortDirection,
      selectedSortBy,
      showAllSwitchValue
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          includePulled: showAllSwitchValue,
          productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection
        }
      }
    })
  }),

  withHandlers({
    handleRequestPullFromStock:
      ({
        addLoadingProductionJobId,
        markOrderProductionJobAsStockPulled,
        removeLoadingProductionJobId,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (orderProductionJobId: string) => {
        addLoadingProductionJobId(orderProductionJobId);
        markOrderProductionJobAsStockPulled({
          variables: { orderProductionJobId }
        })
          .then(
            ({
              data: {
                markOrderProductionJobAsStockPulled: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Order status has been updated.");
              } else {
                showErrorSnackbar(errors.orderProductionJobId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingProductionJobId(orderProductionJobId));
      }
  }),

  withDrawer(
    "Job Details",
    ({
      selectedProductionJobId,
      handleRequestPullFromStock,
      loadingProductionJobIds,
      setDisplayState
    }) => (
      <PullFromStockDetailView
        productionJobId={selectedProductionJobId}
        onRequestPullFromStock={(selectedProductionJobId: string) => {
          setDisplayState("closed");
          handleRequestPullFromStock(selectedProductionJobId);
        }}
        isPullingFromStock={loadingProductionJobIds.includes(
          selectedProductionJobId
        )}
      />
    )
  ),

  withHandlers(() => {
    let printRef;
    return {
      handlePrintRef: () => ref => {
        printRef = ref;
      },

      handlePullFromStockTableRowClicked:
        ({ setDisplayState, setSelectedProductionJobId }) =>
        (productionJobId: string) => {
          setSelectedProductionJobId(productionJobId);
          setDisplayState("open-partial");
        },

      handleAppHeaderRequestBack:
        ({ router }) =>
        () => {
          router.push("/apps");
        },

      handleAppHeaderTabSelected:
        ({
          data,
          setHasNextPage,
          setSelectedProductionDate,
          showAllSwitchValue
        }) =>
        (selectedProductionDate: string) => {
          setHasNextPage(true);
          setSelectedProductionDate(selectedProductionDate);
          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includePulled: showAllSwitchValue
            }
          });
        },

      handleSearch:
        ({ setSearchQuery, setSelectedProductionJobId }) =>
        searchQuery => {
          setSelectedProductionJobId(null);
          setSearchQuery(searchQuery);
        },

      handleShowAllSwitchChanged:
        ({
          setShowAllSwitchValue,
          data,
          selectedProductionDate,
          setHasNextPage
        }) =>
        (event, showAll: boolean) => {
          setShowAllSwitchValue(showAll);
          setHasNextPage(true);

          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includePulled: showAll
            }
          });
        },

      handlePrintLabelButtonClick:
        () => (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          if (printRef) {
            printRef();
          }
        },

      handlePullFromStockTableScrolledToBottom:
        ({
          data,
          hasNextPage,
          isLoadingMore,
          selectedProductionDate,
          setHasNextPage,
          setIsLoadingMore,
          showAllSwitchValue
        }) =>
        () => {
          if (isLoadingMore || !hasNextPage) {
            return;
          }

          setIsLoadingMore(true);
          data
            .fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges =
                  fetchMoreResult.orderProductionJobsToBePulled.edges;
                const pageInfo =
                  fetchMoreResult.orderProductionJobsToBePulled.pageInfo;
                setHasNextPage(pageInfo.hasNextPage);
                return newEdges.length
                  ? {
                      orderProductionJobsToBePulled: {
                        pageInfo,
                        __typename:
                          previousResult.orderProductionJobsToBePulled
                            .__typename,
                        edges: [
                          ...previousResult.orderProductionJobsToBePulled.edges,
                          ...newEdges
                        ],
                        productionDates:
                          fetchMoreResult.orderProductionJobsToBePulled
                            .productionDates
                      }
                    }
                  : previousResult;
              },
              variables: {
                after: data.orderProductionJobsToBePulled.pageInfo.endCursor,
                filters: {
                  productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
                  includePulled: showAllSwitchValue
                }
              }
            })
            .finally(() => setIsLoadingMore(false));
        },

      handleSort:
        ({
          selectedSortBy,
          setSortDirection,
          setSortBy,
          selectedSortDirection
        }) =>
        (newSelectedSortBy: string) => {
          setSortBy(newSelectedSortBy);
          if (selectedSortBy === newSelectedSortBy) {
            selectedSortDirection === "ASCENDING"
              ? setSortDirection("DESCENDING")
              : setSortDirection("ASCENDING");
          } else {
            setSortDirection("ASCENDING");
          }
        }
    };
  }),

  withProps(({ data }) => {
    if (data.orderProductionJobsToBePulled) {
      let formattedProductionDates =
        data.orderProductionJobsToBePulled.productionDates.map(( productionDate ) => {
          return {
            value: productionDate,
            label: DateTime.fromISO(productionDate).toLocaleString(DateTime.DATE_SHORT)
          };
        });
      formattedProductionDates = [{value: "All", label: "All"}].concat(formattedProductionDates);

      return {
        formattedProductionDates
      };
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { data, setSelectedProductionJobId, setDisplayState } = this.props;
      if (!data.loading && !data.error) {
        if (
          data.orderProductionJobsToBePulled.edges.length === 1 &&
          data.orderProductionJobsToBePulled.edges[0].node.id !==
            prevProps.selectedProductionJobId
        ) {
          setSelectedProductionJobId(
            data.orderProductionJobsToBePulled.edges[0].node.id
          );
          setDisplayState("open-partial");
        }
      }
    }
  })
);

const PullFromStockApp = ({
  data,
  formattedProductionDates,
  handleAppHeaderRequestBack,
  handleAppHeaderTabSelected,
  handleRequestPullFromStock,
  handlePrintRef,
  handlePrintLabelButtonClick,
  handlePullFromStockTableRowClicked,
  handlePullFromStockTableScrolledToBottom,
  handleSearch,
  handleSort,
  handleShowAllSwitchChanged,
  hasNextPage,
  isLoadingMore,
  loadingProductionJobIds,
  showAllSwitchValue
}) => {
  return (
    <div>
      <TabbedAppBar
        title="Pull From Stock"
        onRequestBack={handleAppHeaderRequestBack}
        tabItems={
          data && data.orderProductionJobsToBePulled
            ? formattedProductionDates
            : []
        }
        onSearch={handleSearch}
        onTabSelected={handleAppHeaderTabSelected}
        menuItems={[
          <MenuItem>
            Show All
            <Switch
              checked={showAllSwitchValue}
              onChange={handleShowAllSwitchChanged}
              color="primary"
            />
          </MenuItem>,
          <MenuItem>
            <Button
              onClick={handlePrintLabelButtonClick}
              color="primary"
              style={{ width: "100%" }}
            >
              Print All
            </Button>
          </MenuItem>
        ]}
      />
      {data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography variant="body2" color="error">
          {data.error.message}
        </Typography>
      ) : (
        <div>
          {data && data.orderProductionJobsToBePulled && (
            <ExpeditingLabelViewer
              productionJobs={filter(
                pullFromStockTableFragments.productionJob,
                data.orderProductionJobsToBePulled.edges.map(edge => ({
                  ...edge.node
                }))
              )}
              printRef={handlePrintRef}
              hidden={true}
              shipMissCosts={[]}
            />
          )}
          <PullFromStockTable
            loadingProductionJobIds={loadingProductionJobIds}
            onScrolledToBottom={handlePullFromStockTableScrolledToBottom}
            productionJobs={filter(
              pullFromStockTableFragments.productionJob,
              data.orderProductionJobsToBePulled.edges.map(edge => ({
                ...edge.node
              }))
            )}
            onRequestPullFromStock={handleRequestPullFromStock}
            onRequestSort={handleSort}
            onRowClicked={handlePullFromStockTableRowClicked}
          />
          {isLoadingMore && hasNextPage && <CenteredSpinner />}
        </div>
      )}
    </div>
  );
};
export default enhancer(PullFromStockApp);
