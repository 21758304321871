// @flow

import * as React from "react";
import { availableCustomizationAreaMethods } from "../../types/ProductionMethodOption";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { query } from "./graph";
import { updateOrderProductionJobCustomizationArea } from "../../graph";
import Button from "@mui/material/Button";
import CustomizationAreaForm from "../CustomizationAreaForm";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../PendingStatusView";
import Typography from "@mui/material/Typography";

import type { CustomizationArea } from "../../types/CustomizationArea";
import type { CustomizationAreaFormInputErrors } from "../../types/CustomizationAreaFormInputErrors";
import type { HOC } from "recompose";
import type { VinylConfigurationInputErrors } from "../../types/VinylConfigurationInputErrors";

type Props = {|
  +customizationArea: CustomizationArea,
  +isOpen: boolean,
  +onRequestClose: () => void,
  +orderProductionJobId: string
|};

const defaultCustomizationAreaFormInputErrors: CustomizationAreaFormInputErrors = {
  autobase: [],
  colorCount: [],
  colors: [],
  colorsInput: [],
  customizationAreaTemplateId: [],
  distressed: [],
  flatten: [],
  halftones: [],
  imageRepair: [],
  method: [],
  note: [],
  orderProductionJobId: [],
  recreate: [],
  splitIntoColors: [],
  sponsorback: [],
  vinylConfiguration: []
};

const defaultVinylConfigurationInputErrors: VinylConfigurationInputErrors = {
  names: [],
  namesColorId: [],
  namesFontId: [],
  numbers: [],
  numbersColorId: [],
  numbersFontId: [],
  size: []
};

type State = {|
  customizationAreaFormInputErrors: CustomizationAreaFormInputErrors,
  customizationAreaToUpdate: {},
  generalErrors: $ReadOnlyArray<string>,
  isUpdatingCustomizationArea: boolean,
  vinylConfigurationInputErrors: VinylConfigurationInputErrors
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("UpdateCustomizationAreaModal"),

  updateOrderProductionJobCustomizationArea,

  graphql(query, {
    skip: ({ isOpen }) => !isOpen
  }),

  withStateHandlers(
    (props): State => {
      return {
        customizationAreaFormInputErrors: defaultCustomizationAreaFormInputErrors,
        customizationAreaToUpdate: { ...props.customizationArea },
        generalErrors: [],
        isUpdatingCustomizationArea: false,
        vinylConfigurationInputErrors: defaultVinylConfigurationInputErrors
      };
    },
    {
      setIsUpdatingCustomizationArea: () => (
        isUpdatingCustomizationArea: boolean
      ) => ({
        isUpdatingCustomizationArea
      }),

      setCustomizationAreaFormInputErrors: () => (
        customizationAreaFormInputErrors: CustomizationAreaFormInputErrors
      ) => ({ customizationAreaFormInputErrors }),

      setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
        generalErrors
      }),

      setVinylConfigurationInputErrors: () => (
        vinylConfigurationInputErrors: VinylConfigurationInputErrors
      ) => ({ vinylConfigurationInputErrors })
    }
  ),

  withHandlers({
    handleCancelButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleCustomizationAreaFormUpdated: ({
      customizationAreaToUpdate
    }) => (update: {}) => {
      Object.assign(customizationAreaToUpdate, update);
    },

    handleModalRequestClose: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleCustomizationAreaFormSubmit: ({
      customizationAreaToUpdate,
      onRequestClose,
      setCustomizationAreaFormInputErrors,
      setGeneralErrors,
      setIsUpdatingCustomizationArea,
      setVinylConfigurationInputErrors,
      updateOrderProductionJobCustomizationArea
    }) => () => {
      let hasErrors = false;
      let vinylConfigurationErrors = {
        ...defaultVinylConfigurationInputErrors
      };
      let customizationAreaErrors = {
        ...defaultCustomizationAreaFormInputErrors
      };

      const isVinylConfigured =
        customizationAreaToUpdate.vinylConfiguration &&
        (customizationAreaToUpdate.vinylConfiguration.names ||
          customizationAreaToUpdate.vinylConfiguration.numbers);

      const vinylConfiguration = customizationAreaToUpdate.vinylConfiguration;
      if (vinylConfiguration) {
        if (vinylConfiguration.names) {
          if (vinylConfiguration.namesColorId === null) {
            vinylConfigurationErrors.namesColorId = ["is required"];
            hasErrors = true;
          }

          if (vinylConfiguration.namesFontId === null) {
            vinylConfigurationErrors.namesFontId = ["is required"];
            hasErrors = true;
          }
        }

        if (vinylConfiguration.numbers) {
          if (vinylConfiguration.numbersColorId === null) {
            vinylConfigurationErrors.numbersColorId = ["is required"];
            hasErrors = true;
          }

          if (vinylConfiguration.numbersFontId === null) {
            vinylConfigurationErrors.numbersFontId = ["is required"];
            hasErrors = true;
          }
        }
      }

      if (customizationAreaToUpdate.template.id === null) {
        customizationAreaErrors.customizationAreaTemplateId = ["is required"];
        hasErrors = true;
      }

      setVinylConfigurationInputErrors(vinylConfigurationErrors);
      setCustomizationAreaFormInputErrors(customizationAreaErrors);

      if (hasErrors) {
        return;
      }

      const variables = {
        autobase: customizationAreaToUpdate.autobase,
        colorCount: customizationAreaToUpdate.colorCount,
        customizationAreaTemplateId: customizationAreaToUpdate.template.id,
        distressed: customizationAreaToUpdate.distressed,
        flatten: customizationAreaToUpdate.flatten,
        halftones: customizationAreaToUpdate.halftones,
        imageRepair: customizationAreaToUpdate.imageRepair,
        method: customizationAreaToUpdate.method,
        orderProductionJobCustomizationAreaId: customizationAreaToUpdate.id,
        recreate: customizationAreaToUpdate.recreate,
        splitIntoColors: customizationAreaToUpdate.splitIntoColors,
        sponsorback: customizationAreaToUpdate.sponsorback,
        vinylConfiguration: isVinylConfigured
          ? customizationAreaToUpdate.vinylConfiguration
          : null
      };

      setIsUpdatingCustomizationArea(true);
      updateOrderProductionJobCustomizationArea({ variables })
        .then(
          ({
            data: {
              updateOrderProductionJobCustomizationArea: { errors, succeeded }
            }
          }) => {
            if (succeeded) {
              onRequestClose();
            } else {
              setCustomizationAreaFormInputErrors(errors);
            }
          }
        )
        .catch(e => {
          setGeneralErrors([e.message]);
        })
        .finally(() => setIsUpdatingCustomizationArea(false));
    }
  })
);

const UpdateCustomizationAreaModal = ({
  customizationArea,
  customizationAreaFormInputErrors,
  data,
  generalErrors,
  handleCancelButtonClick,
  handleCustomizationAreaFormSubmit,
  handleCustomizationAreaFormUpdated,
  handleModalRequestClose,
  isOpen,
  isUpdatingCustomizationArea,
  vinylConfigurationInputErrors
}) => {
  return (
    <Dialog
      aria-describedby="Add Customization Area Modal"
      aria-labelledby="AddCustomizationAreaModal"
      open={isOpen}
      onClose={handleModalRequestClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Update Customization Area</DialogTitle>
      {!data ? (
        <PendingStatusView status="Waiting" />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <div>
          <Typography variant="body2">{data.error.message}</Typography>
        </div>
      ) : (
        <DialogContent>
          {generalErrors && generalErrors.length > 0 ? (
            <Typography color="error">{generalErrors.join(", ")}</Typography>
          ) : isUpdatingCustomizationArea ? (
            <PendingStatusView status="Loading" />
          ) : (
            <CustomizationAreaForm
              availableCustomizationAreaTemplates={
                data.orderProductionJob.availableCustomizationAreaTemplates
              }
              availableCustomizationAreaMethods={
                availableCustomizationAreaMethods
              }
              customizationArea={customizationArea}
              inputErrors={customizationAreaFormInputErrors}
              onCustomizationAreaUpdated={handleCustomizationAreaFormUpdated}
              vinylColors={data.vinylColors}
              vinylConfigurationInputErrors={vinylConfigurationInputErrors}
              vinylFonts={data.vinylFonts}
            />
          )}
          <DialogActions>
            <Button
              color="secondary"
              disabled={isUpdatingCustomizationArea}
              onClick={handleCancelButtonClick}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isUpdatingCustomizationArea}
              onClick={handleCustomizationAreaFormSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default enhancer(UpdateCustomizationAreaModal);
