// @flow

import * as React from "react";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import { fragments as inkColorDetailsFragments } from "../../../../../../components/InkColorDetails/graph";
import EmployeeChip from "../../../../../../components/EmployeeChip";
import InkColorDetails from "../../../../../../components/InkColorDetails";

type Props = {|
  +classes: {|
    +customBulletListItem: string,
    +descriptionList: string,
    +descriptionTag: string,
    +employeeChipListItem: string,
    +inkColorListItem: string,
    +list: string
  |},
  +index: string,
  +productionJob: {|
    +customizationAreas: $ReadOnlyArray<{|
      +id: string,
      +location: string
    |}>,
    +id: string,
    +label: string,
    +mediumColors: $ReadOnlyArray<{|
      +inkColor: {||},
      +pantoneColor: {||}
    |}>
  |},
  +productionStationAssignments: $ReadOnlyArray<{|
    +employee: {|
      +name_employeeChipModule: string
    |},
    +id: string,
    +productionStation: {|
      +name: string
    |}
  |}>
|};

const styles = theme => ({
  customBulletListItem: {
    display: "inline",
    "&::after": {
      content: '" \\2022  "'
    },
    "&:last-child:after": {
      content: '""'
    }
  },

  descriptionList: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  descriptionTag: {
    fontWeight: theme.typography.fontWeightMedium
  },

  employeeChipListItem: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0
    }
  },

  inkColorListItem: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  }
});

const OrderAppProductionJobListItem = ({
  classes,
  index,
  productionJob,
  productionStationAssignments
}: Props) => {
  return (
    <React.Fragment>
      <Typography variant="subtitle2">Production Job {index}</Typography>
      <Typography variant="h6" color="textSecondary">
        {productionJob.label}
      </Typography>
      <Typography component="div" variant="body2">
        <ul className={classes.list}>
          {productionJob.customizationAreas.map(({ id, location }) => (
            <li className={classes.customBulletListItem} key={id}>
              {location}
            </li>
          ))}
        </ul>
      </Typography>
      <Typography component="div" variant="body2" color="textSecondary">
        {productionStationAssignments.length > 0 && (
          <ul className={classes.list}>
            {productionStationAssignments.map(({ id, employee }) => (
              <li key={id} className={classes.employeeChipListItem}>
                <EmployeeChip employee={employee} />
              </li>
            ))}
          </ul>
        )}
      </Typography>
      <dl className={classes.descriptionList}>
        <Typography
          className={classes.descriptionTag}
          component="dt"
          variant="body2"
        >
          Inks ({productionJob.mediumColors.length})
        </Typography>
        <Typography component="dd" variant="body2" color="textSecondary">
          {productionJob.mediumColors.length > 0 && (
            <ul className={classes.list}>
              {productionJob.mediumColors.map(
                ({ inkColor, pantoneColor }, index) => (
                  <li className={classes.inkColorListItem} key={index}>
                    <InkColorDetails
                      inkColor={
                        inkColor
                          ? filter(inkColorDetailsFragments.inkColor, inkColor)
                          : null
                      }
                      pantoneColor={
                        pantoneColor
                          ? filter(
                              inkColorDetailsFragments.pantoneColor,
                              pantoneColor
                            )
                          : null
                      }
                    />
                  </li>
                )
              )}
            </ul>
          )}
        </Typography>
      </dl>
    </React.Fragment>
  );
};

export default withStyles(styles)(OrderAppProductionJobListItem);
