// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { compose, setDisplayName, withProps } from "recompose";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +container: string,
    +descriptionList: string,
    +descriptionTag: string,
    +divider: string,
    +list: string,
    +listItem: string,
    +title: string
  |},
  +order: {|
    +dueBy: {|
      +date: string,
      +dateTime: string
    |},
    +productionDate: string,
    +salesRepresentatives: $ReadOnlyArray<{|
      +id: string,
      +name_orderSummaryModule: string
    |}>
  |},
  +title: string | React.Element<any>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderFulfillmentSummary"),
  withProps(({ order: { dueBy, productionDate } }: Props) => {
    const dueAt = getFormattedDueDate(dueBy.date, dueBy.dateTime);

    const formattedProductionDate: ?string = productionDate
      ? DateTime.fromISO(productionDate).toLocaleString(DateTime.DATE_FULL)
      : null;

    return {
      dueAt,
      formattedProductionDate
    };
  })
);

const getFormattedDueDate = (date, dateTime) => {
  let formattedDate;
  if (dateTime) {
    const dateWithTime = DateTime.fromISO(dateTime, { setZone: true });
    formattedDate = `${dateWithTime.toLocaleString(
      DateTime.DATE_FULL
    )} at ${dateWithTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else {
    formattedDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  }
  return formattedDate;
};

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(2)
  },

  descriptionList: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between"
  },

  descriptionTag: {
    fontWeight: theme.typography.fontWeightMedium
  },

  list: {
    margin: 0,
    padding: 0
  },

  listItem: {
    listStyle: "none"
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const OrderFulfillmentSummary = ({
  order: { salesRepresentatives, isInHouse, vendor },
  classes,
  dueAt,
  formattedProductionDate,
  title
}) => (
  <React.Fragment>
    {isInHouse && <Typography variant="h6"> In-House </Typography>}
    {vendor && (
      <Typography variant="h6">
        {" "}
        Contracted to {vendor.organization.name}
      </Typography>
    )}
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h6">{title}</Typography>
      </div>
      <Card>
        <CardContent>
          <dl className={classes.descriptionList}>
            <Typography
              component="dt"
              variant="body2"
              className={classes.descriptionTag}
            >
              Due Date
            </Typography>
            <Typography component="dd" variant="body2" color="textSecondary">
              {dueAt}
            </Typography>
          </dl>
          <dl className={classes.descriptionList}>
            <Typography
              component="dt"
              variant="body2"
              className={classes.descriptionTag}
            >
              Production Date
            </Typography>
            <Typography component="dd" variant="body2" color="textSecondary">
              {formattedProductionDate ? (
                formattedProductionDate
              ) : (
                <span>N/A</span>
              )}
            </Typography>
          </dl>
          <dl className={classes.descriptionList}>
            <Typography
              component="dt"
              variant="body2"
              className={classes.descriptionTag}
            >
              Sales Rep
            </Typography>
            {salesRepresentatives.length === 0 ? (
              <Typography component="dd" variant="body2" color="textSecondary">
                N/A
              </Typography>
            ) : (
              <Typography component="dd">
                <ul className={classes.list}>
                  {salesRepresentatives.map(representative => (
                    <li className={classes.listItem} key={representative.id}>
                      <Typography variant="body2" color="textSecondary">
                        {representative.name_orderSummaryModule}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Typography>
            )}
          </dl>
        </CardContent>
      </Card>
    </div>
  </React.Fragment>
);

export default withStyles(styles)(enhancer(OrderFulfillmentSummary));
