// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import ColorLens from "@mui/icons-material/ColorLens";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import type { HOC } from "recompose";

type Props = {|
  +errorText: $ReadOnlyArray<string>,
  +label: string,
  +onSelect: (selection: any) => void,
  +options: $ReadOnlyArray<{
    +color?: ?{
      +formatted: string
    },
    +id: string,
    +label: string,
    +value: number
  }>
|};

type State = {|
  inputValue: string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("VinylCombobox"),

  withStateHandlers(
    () =>
      ({
        inputValue: ""
      }: State),
    {
      setInputValue: () => inputValue => ({ inputValue })
    }
  ),

  withHandlers({
    handleComboboxSelect: ({ setInputValue, onSelect }) => event => {
      onSelect(event.target.value);
      setInputValue(event.target.value);
    }
  })
);

const VinylCombobox = ({
  errorText,
  handleComboboxSelect,
  inputValue,
  label,
  options
}) => {
  const inputLabel = React.useRef(null);
  return (
    <FormControl fullWidth error={errorText.length > 0}>
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        labelid="vinyl-size-select"
        id="vinyl-size-select"
        value={inputValue}
        onChange={handleComboboxSelect}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option.id} value={option.value}>
              {option.color && (
                <ColorLens
                  style={{
                    fill: option.color ? option.color.formatted : "fff",
                    marginRight: 8
                  }}
                />
              )}
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{errorText.join(", ")}</FormHelperText>
    </FormControl>
  );
};

export default enhancer(VinylCombobox);
