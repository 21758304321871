// @flow

import { createTheme } from "@mui/material/styles";
import theme from "./theme";

let darkTheme = createTheme({
  appColors: {
    priority: {
      low: "#ff9800",
      medium: "#ffd600",
      high: "#8bc34a",
      urgent: "#e91e63"
    },
    screensMade: {
      palette: {
        primary: {
          light: "#63ccff",
          main: "#f57c00",
          dark: "#006db3"
        },
        secondary: {
          light: "#FAFAFA",
          main: "#795548"
        }
      }
    },
    pullFromStock: {
      palette: {
        primary: {
          light: "#63ccff",
          main: "#1b5e20",
          dark: "#006db3"
        },
        secondary: {
          light: "#FAFAFA",
          main: "#263238"
        }
      }
    }
  },
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  palette: {
    background: {
      default: "#303030"
    },
    mode: "dark",
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3"
    },
    secondary: {
      light: "#FAFAFA",
      main: "#e0e0e0"
    }
  },
  shape: {
    borderRadius: 8
  }
});

darkTheme = {
  ...darkTheme,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: darkTheme.palette.primary.main
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none"
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#3e3e3e",
          color: "#fff"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingBottom: darkTheme.spacing(0.5),
          paddingLeft: darkTheme.spacing(3),
          paddingRight: darkTheme.spacing(7),
          paddingTop: darkTheme.spacing(0.5)
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: darkTheme.spacing(1)
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: darkTheme.palette.primary.main
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          margin: "0 16px",
          minWidth: 0,
          [darkTheme.breakpoints.up("md")]: {
            minWidth: 0
          }
        },
        labelContainer: {
          padding: 0,
          [darkTheme.breakpoints.up("md")]: {
            padding: 0
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: darkTheme.spacing(1)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: `${theme.spacing(2)} !important`
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 1199
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: darkTheme.typography.fontWeightMedium
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          marginRight: 0,
          "& svg": {
            fontSize: 20
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorError: {
          backgroundColor: "#B00020"
        },
        colorPrimary: {
          backgroundColor: "#00C853"
        },
        colorSecondary: {
          backgroundColor: "#2962FF"
        }
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...darkTheme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

const screensMadeTheme = {
  ...darkTheme,
  components: {
    ...darkTheme.components,
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: darkTheme.appColors.screensMade.palette.primary.main
        }
      }
    }
  }
};

const pullFromStockTheme = {
  ...darkTheme,
  components: {
    ...darkTheme.components,
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor:
            darkTheme.appColors.pullFromStock.palette.primary.main
        }
      }
    }
  }
};

const colors = {
  customer_status: {
    active: "#2196F3",
    black_listed: "#B00020",
    blind_shipped: "#CFD8DC",
    complicated: "#F8E71C",
    high_value: "#7ED321"
  }
};

export default darkTheme;
export { colors, pullFromStockTheme, screensMadeTheme };
