import { Rnd } from "react-rnd";
import React, { useState } from "react";

export default ({ enabled, onChange, alignment, src, color }) => {
  const [highlight, setHighlight] = useState(false);
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `solid 1px ${highlight ? "#49fb35" : "transparent"}`,
    ...(enabled ? {} : { pointerEvents: "none" })
  };
  return (
    <Rnd
      style={style}
      enableResizing={enabled}
      disableDragging={!enabled}
      bounds="parent"
      //scale={1}
      onMouseEnter={() => {
        setHighlight(true);
      }}
      onMouseLeave={() => {
        setHighlight(false);
      }}
      onDragStart={() => {
        setHighlight(true);
      }}
      onDragStop={(event, position) => {
        setHighlight(false);
        if (onChange) {
          onChange({ position });
        }
      }}
      onResize={(event, direction, ref, delta, position) => {
        if (onChange) {
          onChange({
            position,
            size: {
              width: ref.offsetWidth,
              height: ref.offsetHeight
            }
          });
        }
      }}
      {...(alignment || {})}
    >
      {color ? (
        <div
          style={{
            display: "inline-block",
            width: "100%",
            height: 118,
            WebkitMask: `url("${src}") center/contain`,
            mask: `url("${src}") center/contain`,
            background: color
          }}
        />
      ) : (
        <img width="100%" src={src} />
      )}
    </Rnd>
  );
};
