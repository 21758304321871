// @flow

import { gql } from "@apollo/client";

export default gql`
  query FixPieceRequestDetail($fixPieceRequestId: ID!) {
    fixPieceRequest: node(id: $fixPieceRequestId) {
      ... on FixPieceRequest {
        canViewerCancel,
        canViewerPurchase,
        id
        description
        hasBeenOrdered
        items{
          id
          inventoryItem {
            id
            description
          }
          quantity
          reason {
            id
            name
          }
        }
        orderedAt
        orderProductionJob {
          label
          order {
            dueBy {
              dateTime
            }
            id
            orderNumber
            priority
            productionDate
            productionNotesCollection {
              ... on FormattedNote {
                author
                createdAt
                note
              }
              ... on UnformattedNote {
                note
              }
            }
            totalProductVariantQuantity
          }
        }
        requestedByEmployee{
          name(format:LAST_NAME_FIRST_INITIAL)
        }
      }
    }
  }
`;
