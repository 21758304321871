// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { withStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import ColorLens from "@mui/icons-material/ColorLens";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import type { HOC } from "recompose";

type Option = {|
  +color: ?{|
    +formatted: string
  |},
  +id: string,
  +label: string
|};

type Props = {|
  +colorOptions: $ReadOnlyArray<Option>,
  +errorText?: ?string,
  +multiple?: ?boolean,
  +onSelect: (colorIds: $ReadOnlyArray<string>) => void,
  +placeholder: string
|};

type State = {|
  inputValue: $ReadOnlyArray<string>,
  selectionValue: Array<string>
|};

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0.5)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("SearchableColorCombobox"),

  withStyles(styles),

  withStateHandlers(
    () =>
      ({
        inputValue: [],
        selectionValue: []
      }: State),
    {
      resetInputValue: () => () => ({ inputValue: [] }),

      setInputValue: () => inputValue => ({ inputValue })
    }
  ),
  withHandlers({
    handleChange: ({
      setInputValue,
      selectionValue,
      colorOptions,
      onSelect
    }) => event => {
      setInputValue(event.target.value);

      const intersection = colorOptions.filter(({ label }) =>
        event.target.value.includes(label)
      );

      intersection.map(({ id }) => selectionValue.push(id));

      onSelect(selectionValue);
    }
  })
);

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const SearchableColorCombobox = ({
  classes,
  colorOptions,
  handleChange,
  inputValue,
  multiple,
  placeholder
}) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{placeholder}</InputLabel>
      <Select
        multiple={multiple}
        value={inputValue}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map((value, index) => (
              <Chip key={index} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {colorOptions.map(option => (
          <MenuItem key={option.id} value={option.label}>
            <ColorLens
              style={{
                fill: option.color ? option.color.formatted : "fff",
                marginRight: 8
              }}
            />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default enhancer(SearchableColorCombobox);
