// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const markCustomsShipmentAsShipped: any = graphql(
  gql`
    mutation ($customsShipmentId: ID!) {
      markCustomsShipmentAsShipped(customsShipmentId: $customsShipmentId) {
        errors {
          customsShipmentId
        }
        updatedCustomsShipment {
          id
          canonicalId
          createdAt
          shippedOn
          archivedOn
          controlNumber
          canViewerMarkAsShipped
          canViewerMarkAsArchived
          canViewerSetControlNumber
        }
        succeeded
      }
    }
  `,
  {
    name: "markCustomsShipmentAsShipped"
  }
);

export default markCustomsShipmentAsShipped;
