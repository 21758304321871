// @flow

type VinylSize = "SMALL" | "LARGE";

export type ComboboxOption = {|
  id: VinylSize,
  label: string,
  value: number
|};

const vinylSizeOptions: $ReadOnlyArray<ComboboxOption> = [
  { id: "LARGE", label: "Large", value: 8 },
  { id: "SMALL", label: "Small", value: 4 }
];

export { vinylSizeOptions };
