// @flow

import * as React from "react";
import { branch, compose, renderNothing, setDisplayName } from "recompose";
import { fragments as customizationAreaListItemFragments } from "./components/CustomizationAreaListItem/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CustomizationAreaListItem from "./components/CustomizationAreaListItem";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +customizationListAreaItemCard: string,
    +list: string,
    +listItemWrapper: string,
    +titleWrapper: string
  |},
  +customizationAreas: $ReadOnlyArray<{|
    +id: string
  |}>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaList"),
  branch(
    ({ customizationAreas }) => customizationAreas.length === 0,
    renderNothing
  )
);

const styles = theme => ({
  customizationListAreaItemCard: {
    padding: theme.spacing(2)
  },

  list: {
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  listItemWrapper: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(1),
    "&:last-child": {
      borderBottomStyle: "none"
    }
  },

  titleWrapper: {
    marginBottom: theme.spacing(0.5)
  }
});

const CustomizationAreaList = ({ classes, customizationAreas }) => (
  <div>
    <div className={classes.titleWrapper}>
      <Typography variant="h6" color="inherit">
        Customization Area(s)
      </Typography>
    </div>
    <Card className={classes.customizationListAreaItemCard}>
      <ul className={classes.list}>
        {customizationAreas.map((customizationArea, index) => (
          <li className={classes.listItemWrapper} key={customizationArea.id}>
            <CustomizationAreaListItem
              customizationArea={filter(
                customizationAreaListItemFragments.customizationArea,
                customizationArea
              )}
              index={`${index + 1}`}
            />
          </li>
        ))}
      </ul>
    </Card>
  </div>
);

export default withStyles(styles)(enhancer(CustomizationAreaList));
