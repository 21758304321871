// @flow

import * as React from "react";
import { compose, withHandlers } from "recompose";
import { fragments as customerArchiveListItemFragments } from "../CustomerArchiveListItem/graph";
import { filter } from "graphql-anywhere";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CustomerArchiveListItem from "../CustomerArchiveListItem";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +buttonContainer: string,
    +item: string,
    +list: string
  |},
  +onRequestLoadMore: () => void,
  +orders: $ReadOnlyArray<{|
    +id: string
  |}>
|};

const enhancer: HOC<*, Props> = compose(
  withHandlers({
    handleLoadMoreButtonClick: ({ onRequestLoadMore }) => (): void => {
      onRequestLoadMore();
    }
  })
);

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2)
  },

  item: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },

  list: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none"
  }
});

const CustomerArchiveList = ({
  classes,
  handleLoadMoreButtonClick,
  orders
}) => (
  <div>
    <ul className={classes.list}>
      {orders.map(order => (
        <li className={classes.item} key={order.id}>
          <CustomerArchiveListItem
            order={filter(customerArchiveListItemFragments.order, order)}
          />
        </li>
      ))}
    </ul>
    <div className={classes.buttonContainer}>
      <Button onClick={handleLoadMoreButtonClick} variant="outlined">
        Load More
      </Button>
    </div>
  </div>
);

export default withStyles(styles)(enhancer(CustomerArchiveList));
