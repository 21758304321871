// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +children: React.Node,
  +classes?: {|
    +content: string,
    +fixedHeader: string,
    +headerContent: string,
    +icon: string,
    +paper: string,
    +wrapper: string
  |},
  +displayState: "closed" | "open-partial" | "open-full",
  +onRequestClose: () => void,
  +onRequestOpenFull: () => void,
  +title: string
|};

const styles = theme => ({
  wrapper: {
    position: "relative",
    overflow: "auto"
  },

  content: {
    padding: theme.spacing(1),
    zIndex: "-110"
  },

  fixedHeader: {
    display: "flex",
    flexDirection: "column",
    left: 0,
    position: "sticky",
    right: 0,
    top: 0,
    zIndex: "10"
  },

  headerContent: {
    backgroundColor: theme.palette.mode === "dark" ? "#424242" : "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2)
  },

  icon: {
    fontSize: 30
  },

  paper: {
    [theme.breakpoints.down("md")]: {
      transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) !important",
      height: "calc(var(--height))"
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(var(--width))"
    }
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ResponsiveDrawer"),

  withStyles(styles),

  withHandlers({
    handleCloseButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleExpandButtonClick: ({ onRequestOpenFull }) => () => {
      onRequestOpenFull();
    },

    isMobile: () => () => {
      return false; // TODO: width === "sm" || width === "xs";
    }
  })
);

const ResponsiveDrawer = ({
  children,
  classes,
  displayState,
  handleCloseButtonClick,
  handleExpandButtonClick,
  isMobile,
  title
}) => (
  <Drawer
    anchor={isMobile() ? "bottom" : "right"}
    open={displayState !== "closed"}
    onClose={handleCloseButtonClick}
    classes={{
      paper: classes.paper
    }}
    style={
      displayState === "open-partial"
        ? {
            "--height": isMobile() ? "50%" : "100%",
            "--width": isMobile() ? "100%" : "30%"
          }
        : displayState === "open-full"
        ? {
            "--height": "100%",
            "--width": isMobile() ? "100%" : "30%"
          }
        : {
            "--height": "0%",
            "--width": "0%"
          }
    }
  >
    <div className={classes.wrapper}>
      <div className={classes.fixedHeader}>
        <div className={classes.headerContent}>
          <Typography variant="h6">{title}</Typography>
          {isMobile() ? (
            displayState === "open-full" ? (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={handleCloseButtonClick}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton
                key="open"
                aria-label="Open"
                color="inherit"
                onClick={handleExpandButtonClick}
              >
                <ExpandLessIcon className={classes.icon} />
              </IconButton>
            )
          ) : (
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleCloseButtonClick}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          )}
        </div>
        <Divider light={true} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  </Drawer>
);

export default enhancer(ResponsiveDrawer);
