// @flow

import { fragments as artOrderStatusFragments } from "../../application/apps/ArtApp/components/ArtOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderAsNoLongerOnArtworkHold: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsNoLongerOnArtworkHold(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...ArtOrderStatus_order
        }
        succeeded
      }
    }
    ${artOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsNoLongerOnArtworkHold"
  }
);

export default markOrderAsNoLongerOnArtworkHold;
