// @flow

import { gql } from "@apollo/client";

export default gql`
  query OrderProductionJobDetail($orderProductionJobId: ID!) {
    orderProductionJob: node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        canViewerRequestFixPieces
        label
        lineItemBreakdownByProduct {
          product{
            id
            primaryImage {
              asset {
                url {
                formatted}
              }
            }
            style
          }
          lineItems {
            color {
              name
            }
            id
            sizesInclusive {
              ... on OrderProductionJobLineItemSize {
                id
              }
              quantity
              size {
                id
                code
              }
            }
          }
        }
        order {
          dueBy {
            dateTime
          }
          id
          orderNumber
          priority
          productionDate
          totalProductVariantQuantity
        }
      }
    }
    fixPieceReasons {
      id
      name
    }
  }
`;
