// @flow

import { gql } from "@apollo/client";

export default gql`
  query viewer {
    viewer {
      id
      isAuthenticated
      me {
        id
        name(format: FIRST_NAME_LAST_NAME)
      }
    }
  }
`;
