// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { query } from "./graph";
import PendingStatusView from "../../../../../components/PendingStatusView";

import type { HOC } from "recompose";

type Props = {|
  +classes: {|
    +list: string
  |},
  +isOpen: boolean,
  +onRequestClose: () => void,
  +orderProductionJobId: ?string
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomerArtworkModal"),
  graphql(query, {
    skip: ({ isOpen, orderProductionJobId }) => !isOpen || !orderProductionJobId
  }),
  withHandlers({
    handleCloseButtonClick: ({ onRequestClose }) => () => {
      onRequestClose();
    },

    handleDialogClose: ({ onRequestClose }) => () => {
      onRequestClose();
    }
  })
);

const styles = {
  list: {
    listStyle: "none",
    padding: 0
  }
};

const CustomerArtworkModal = ({
  classes,
  data,
  handleCloseButtonClick,
  handleDialogClose,
  isOpen
}) => (
  <Dialog
    aria-describedby="Customer Artwork Modal"
    aria-labelledby="Print Area Originals"
    className={classes.dialog}
    open={isOpen}
    onClose={handleDialogClose}
    maxWidth="md"
  >
    <DialogTitle>Print Area Originals</DialogTitle>
    <DialogContent>
      {!data ? (
        <PendingStatusView status="Waiting" />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <div>
          <Typography variant="body2">
            There was an error while attempting to load customer artwork
          </Typography>
        </div>
      ) : !data.orderProductionJob ? (
        <Typography variant="body2">
          Unfortunately, that order could not be located
        </Typography>
      ) : (
        <ul className={classes.list}>
          {data.orderProductionJob.customizationAreas.length === 0 ? (
            <Typography variant="body2">No customization areas</Typography>
          ) : (
            data.orderProductionJob.customizationAreas.map(
              ({ id, location, customerArtwork }) => (
                <li key={id}>
                  <Typography variant="body2">{location}</Typography>
                  {customerArtwork.length === 0 ? (
                    <Typography variant="body2">No artwork</Typography>
                  ) : (
                    <ul className={classes.list}>
                      {customerArtwork.map(({ file, id }) => (
                        <li key={id}>
                          <img
                            alt="Original Artwork"
                            src={file.asset.url.formatted}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            )
          )}
        </ul>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseButtonClick} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(enhancer(CustomerArtworkModal));
