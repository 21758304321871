// @flow

import { gql } from "@apollo/client";
import { fragments as orderFulfillmentSummaryFragments } from "../../../../../../components/OrderFulfillmentSummary/graph";
import { fragments as orderPricingSummaryFragments } from "../../../../../../components/OrderPricingSummary/graph";

export default {
  order: gql`
    fragment CustomerArchiveOrderSummary_order on Order {
      id
      orderNumber
      ...OrderFulfillmentSummary_order
      ...OrderPricingSummary_order
    }
    ${orderFulfillmentSummaryFragments.order}
    ${orderPricingSummaryFragments.order}
  `
};
