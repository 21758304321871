// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import AutomatedNotesBreakdown from "./components/AutomatedNotesBreakdown";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

type CountValidatedNote = {|
  +__typename: "CountValidatedNote",
  +author: string,
  +createdAt: string,
  +label: string
|};

type ProductionFileLocationNote = {|
  +__typename: "ProductionFileLocationNote",
  +author: string,
  +createdAt: string,
  +filePath: string
|};

type ScreensMadeNote = {|
  +__typename: "ScreensMadeNote",
  +author: string,
  +createdAt: string
|};

type StationAssignmentNote = {|
  +__typename: "StationAssignmentNote",
  +author: string,
  +createdAt: string,
  +employeeName: string,
  +station: string
|};

type StockReceivedNote = {|
  +__typename: "StockReceivedNote",
  +author: string,
  +createdAt: string,
  +label: string
|};

type StockStagedNote = {|
  +__typename: "StockStagedNote",
  +author: string,
  +createdAt: string,
  +labels: string
|};

type NoteType =
  | CountValidatedNote
  | ProductionFileLocationNote
  | ScreensMadeNote
  | StationAssignmentNote
  | StockReceivedNote
  | StockStagedNote;

type Props = {|
  +classes: {|
    +divider: string,
    +noteContainer: string,
    +noteTitle: string
  |},
  +order: {|
    +automatedNotesCollection: $ReadOnlyArray<NoteType>
  |}
|};

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  noteContainer: {
    padding: theme.spacing(2)
  },

  noteTitle: {
    marginBottom: theme.spacing(2)
  }
});

const AutomatedNotesWrapper = ({
  classes,
  order: { automatedNotesCollection }
}: Props) => (
  <div className={classes.noteContainer}>
    <Typography className={classes.noteTitle} variant="h6">
      Automated Notes
    </Typography>
    <AutomatedNotesBreakdown notesCollection={automatedNotesCollection} />
    <Divider className={classes.divider} light />
  </div>
);

export default withStyles(styles)(AutomatedNotesWrapper);
