// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForTransfersGlitterStatus } from "../../../../../helpers";
import {
  markOrderAsTransfersGlitterManualPrintingComplete,
  markOrderAsTransfersGlitterManualPrintingPaused,
  markOrderAsTransfersGlitterManualPrintingStarted,
  markOrderAsTransfersGlitterManualRippingComplete,
  markOrderAsTransfersGlitterManualRippingStarted,
} from "../../../../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

console.log("markOrderAsTransfersGlitterManualRippingComplete", markOrderAsTransfersGlitterManualRippingComplete);

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +canViewerCompleteHighDefinitionDigital: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +canViewerStartHighDefinitionDigital: boolean,
    +highDefinitionDigitalStatus: string,
    +id: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean
|};

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TransfersGlitterOrderStatus"),

  markOrderAsTransfersGlitterManualRippingComplete,

  markOrderAsTransfersGlitterManualRippingStarted,

  markOrderAsTransfersGlitterManualPrintingComplete,

  markOrderAsTransfersGlitterManualPrintingPaused,

  markOrderAsTransfersGlitterManualPrintingStarted,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(({ isMutatingOrder: false }: State), {
    setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
      isMutatingOrder
    })
  }),

  withHandlers({
    handleStartRippingButtonClick:
      ({
        markOrderAsTransfersGlitterManualRippingStarted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsTransfersGlitterManualRippingStarted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersGlitterManualRippingStarted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleCompleteRippingButtonClick:
      ({
        markOrderAsTransfersGlitterManualRippingCompleted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        console.log("markOrderAsTransfersGlitterManualRippingCompleted", markOrderAsTransfersGlitterManualRippingCompleted);
        markOrderAsTransfersGlitterManualRippingCompleted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersGlitterManualRippingCompleted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleStartPrintingButtonClick:
        ({
          markOrderAsTransfersGlitterManualPrintingStarted,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersGlitterManualPrintingStarted({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersGlitterManualPrintingStarted: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

      handlePausePrintingButtonClick:
        ({
          markOrderAsTransfersGlitterManualPrintingPaused,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersGlitterManualPrintingPaused({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersGlitterManualPrintingStarted: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

        handleCompletePrintingButtonClick:
            ({
              markOrderAsTransfersGlitterManualPrintingComplete,
              order: { id: orderId },
              setIsMutatingOrder,
              showErrorSnackbar,
              showSuccessSnackbar
            }) =>
            () => {
              setIsMutatingOrder(true);
              markOrderAsTransfersGlitterManualPrintingComplete({ variables: { orderId } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersGlitterManualPrintingComplete: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(`The order has been updated`);
                    } else {
                      showErrorSnackbar(
                        `Something went wrong: ${errors.orderId.join(", ")}`
                      );
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(`Something went wrong: ${e.message}`);
                })
                .finally(() => setIsMutatingOrder(false));
            },
  })
);

const TransfersGlitterOrderStatus = ({
  classes,
  handleCompleteRippingButtonClick,
  handleStartRippingButtonClick,
  handleCompletePrintingButtonClick,
  handlePausePrintingButtonClick,
  handleStartPrintingButtonClick,
  isMutatingOrder,
  order: {
    transfersGlitterStatus,
    canViewerStartTransfersGlitterManualRipping,
    canViewerCompleteTransfersGlitterManualRipping,
    canViewerStartTransfersGlitterManualPrinting,
    canViewerPauseTransfersGlitterManualPrinting,
    canViewerCompleteTransfersGlitterManualPrinting
  }
}) => (
  <div>
    <div className={classes.spaceBetweenContainer}>
      <JobStatusIcon status={transfersGlitterStatus} />
      <div className={classes.jobStatus}>
        <Typography variant="h6">Transfers - Glitter</Typography>
        <Typography variant="body2">
          {getLabelForTransfersGlitterStatus(transfersGlitterStatus)}
        </Typography>
      </div>
    </div>
    {isMutatingOrder ? (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    ) : (
      <div className={classes.buttonContainer}>
        {canViewerStartTransfersGlitterManualRipping && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartRippingButtonClick}
          >
            Start Ripping
          </Button>
        )}
        {canViewerCompleteTransfersGlitterManualRipping && ( // TODO: Fix this bit in graph
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompleteRippingButtonClick}
          >
            Complete Ripping
          </Button>
        )}
        {canViewerStartTransfersGlitterManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartPrintingButtonClick}
          >
            Start Printing
          </Button>
        )}
        {canViewerPauseTransfersGlitterManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handlePausePrintingButtonClick}
          >
            Pause Printing
          </Button>
        )}
        {canViewerCompleteTransfersGlitterManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompletePrintingButtonClick}
          >
            Complete Printing
          </Button>
        )}
      </div>
    )}
  </div>
);

export default enhancer(TransfersGlitterOrderStatus);
