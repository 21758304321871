// @flow

import { useRef } from "react";

const useDeferredPromise = () => {
  const deferRef = useRef(null);

  const defer = () => {
    const deferred = {};

    const promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    deferred.promise = promise;
    deferRef.current = deferred;
    return deferRef.current;
  };

  return { defer, deferRef: deferRef.current };
};

export default useDeferredPromise;
