// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForArtworkStatus } from "../../../../../helpers/getLabelForArtworkStatus";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import {
  markOrderAsArtworkApproved,
  markOrderAsArtworkChangeRequested,
  markOrderAsArtworkReviewed,
  markOrderAsArtworkStarted,
  markOrderAsNoLongerOnArtworkHold,
  markOrderAsNoLongerOnHold,
  markOrderAsOnArtworkHold,
  markOrderAsProofCompleted,
  setOrderAutoApproved
} from "../../../../../graph";
import { red } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PlaceOrderOnHoldModal from "../../../../../components/PlaceOrderOnHoldModal";
import RequestArtworkChangeModal from "../../../../../components/RequestArtworkChangeModal";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";
import type { OrderStatus } from "../../../../../types/OrderStatus";

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +artworkStatus: OrderStatus,
    +autoApproved: boolean,
    +canViewerCompleteProof: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +canViewerStartArtwork: boolean,
    +id: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean,
  isRequestingArtworkChange: boolean,
  isSettingAutoApproved: ?boolean
|};

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[300],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {}
})(Switch);

const RedSwitch = withStyles({
  switchBase: {
    color: grey[300],
    "&$checked": {
      color: red[500]
    }
  },
  checked: {}
})(Switch);

const styles = theme => ({
  autoApprovedContainer: {
    display: "flex",
    alignItems: "center"
  },

  statusHeader: {
    marginRight: theme.spacing(1)
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  iconSwitchContainer: {
    display: "flex",
    alignItems: "center"
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ArtOrderStatus"),

  markOrderAsArtworkApproved,

  markOrderAsArtworkStarted,

  markOrderAsOnArtworkHold,

  markOrderAsNoLongerOnArtworkHold,

  markOrderAsProofCompleted,

  markOrderAsNoLongerOnHold,

  markOrderAsArtworkChangeRequested,

  markOrderAsArtworkReviewed,

  setOrderAutoApproved,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(
    ({
      isMutatingOrder: false,
      isPlacingOrderOnHold: false,
      isRequestingArtworkChange: false,
      isSettingAutoApproved: null
    }: State),
    {
      setIsSettingAutoApproved: () => (isSettingAutoApproved: ?boolean) => ({
        isSettingAutoApproved
      }),

      setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
        isMutatingOrder
      }),

      setIsPlacingOrderOnHold: () => (isPlacingOrderOnHold: boolean) => ({
        isPlacingOrderOnHold
      }),

      setIsRequestingArtworkChange: () => (
        isRequestingArtworkChange: boolean
      ) => ({
        isRequestingArtworkChange
      })
    }
  ),

  withHandlers({
    handleHoldToggleSwitch: ({ setIsPlacingOrderOnHold }) => () => {
      setIsPlacingOrderOnHold(true);
    },

    handlePlaceOrderOnHoldModalRequestClose: ({
      setIsPlacingOrderOnHold
    }) => () => {
      setIsPlacingOrderOnHold(false);
    },

    handleAutoApprovedToggleSwitch: ({
      setOrderAutoApproved,
      order: { autoApproved, id: orderId },
      setIsSettingAutoApproved,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsSettingAutoApproved(true);
      setOrderAutoApproved({
        variables: { autoApproved: !autoApproved, orderId }
      })
        .then(({ data: { setOrderAutoApproved: { succeeded, errors } } }) => {
          if (succeeded) {
            showSuccessSnackbar(`The order has been updated`);
          } else {
            showErrorSnackbar(
              `Something went wrong: ${errors.orderId.join(", ")}`
            );
          }
        })
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsSettingAutoApproved(false));
    },

    handleRemoveHoldToggleSwitch: ({
      markOrderAsNoLongerOnHold,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsNoLongerOnHold({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsNoLongerOnHold: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleApproveArtworkButtonClick: ({
      markOrderAsArtworkApproved,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsArtworkApproved({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsArtworkApproved: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleReviewArtworkButtonClick: ({
      markOrderAsArtworkReviewed,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsArtworkReviewed({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsArtworkReviewed: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handlePlaceOnArtworkHoldButtonClick: ({
      markOrderAsOnArtworkHold,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsOnArtworkHold({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsOnArtworkHold: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleRemoveArtworkHoldButtonClick: ({
      markOrderAsNoLongerOnArtworkHold,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsNoLongerOnArtworkHold({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsNoLongerOnArtworkHold: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleRequestArtworkChangeButtonClick: ({
      setIsRequestingArtworkChange
    }) => () => {
      setIsRequestingArtworkChange(true);
    },

    handleRequestArtworkChangeModalRequestClose: ({
      setIsRequestingArtworkChange
    }) => () => {
      setIsRequestingArtworkChange(false);
    },

    handleStartButtonClick: ({
      markOrderAsArtworkStarted,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsArtworkStarted({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsArtworkStarted: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    },

    handleProofCompletedButtonClick: ({
      markOrderAsProofCompleted,
      order: { id: orderId },
      setIsMutatingOrder,
      showErrorSnackbar,
      showSuccessSnackbar
    }) => () => {
      setIsMutatingOrder(true);
      markOrderAsProofCompleted({ variables: { orderId } })
        .then(
          ({
            data: {
              markOrderAsProofCompleted: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              showSuccessSnackbar(`The order has been updated`);
            } else {
              showErrorSnackbar(
                `Something went wrong: ${errors.orderId.join(", ")}`
              );
            }
          }
        )
        .catch(e => {
          showErrorSnackbar(`Something went wrong: ${e.message}`);
        })
        .finally(() => setIsMutatingOrder(false));
    }
  })
);

const ArtOrderStatus = ({
  classes,
  handleApproveArtworkButtonClick,
  handleAutoApprovedToggleSwitch,
  handleHoldToggleSwitch,
  handlePlaceOnArtworkHoldButtonClick,
  handlePlaceOrderOnHoldModalRequestClose,
  handleRequestArtworkChangeModalRequestClose,
  handleProofCompletedButtonClick,
  handleRemoveArtworkHoldButtonClick,
  handleRemoveHoldToggleSwitch,
  handleRequestArtworkChangeButtonClick,
  handleReviewArtworkButtonClick,
  handleStartButtonClick,
  isMutatingOrder,
  isSettingAutoApproved,
  isPlacingOrderOnHold,
  isRequestingArtworkChange,
  order: {
    artworkStatus,
    autoApproved,
    canViewerApproveArtwork,
    canViewerCompleteProof,
    canViewerPlaceOnArtworkHold,
    canViewerPlaceOnHold,
    canViewerRemoveArtworkHold,
    canViewerRemoveHold,
    canViewerRequestArtworkChange,
    canViewerReviewArtwork,
    canViewerStartArtwork,
    id: orderId
  }
}) => {
  const isOrderOnHold = artworkStatus === "ORDER_ON_HOLD";
  return (
    <div>
      <div className={classes.spaceBetweenContainer}>
        <JobStatusIcon status={artworkStatus} />
        <div className={classes.jobStatus}>
          <div className={classes.autoApprovedContainer}>
            <Typography className={classes.statusHeader} variant="h6">
              Art
            </Typography>
            <FormControlLabel
              control={
                isSettingAutoApproved ? (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <GreenSwitch
                    checked={autoApproved}
                    onChange={handleAutoApprovedToggleSwitch}
                  />
                )
              }
              label={autoApproved ? "ART AUTO APPROVED" : "AUTO APPROVE ORDER"}
            />
          </div>
          <Typography variant="body2">
            {getLabelForArtworkStatus(artworkStatus)}
          </Typography>
        </div>
      </div>
      {isMutatingOrder ? (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          {canViewerStartArtwork && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handleStartButtonClick}
            >
              Start
            </Button>
          )}
          {canViewerPlaceOnArtworkHold && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handlePlaceOnArtworkHoldButtonClick}
            >
              Place On Artwork Hold
            </Button>
          )}
          {canViewerRemoveArtworkHold && (
            <Button
              className={classes.spacerRight}
              variant="contained"
              onClick={handleRemoveArtworkHoldButtonClick}
            >
              Remove Artwork Hold
            </Button>
          )}
          {canViewerApproveArtwork && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handleApproveArtworkButtonClick}
            >
              Approve
            </Button>
          )}
          {canViewerReviewArtwork && (
            <Button
              className={classes.spacerRight}
              variant="contained"
              onClick={handleReviewArtworkButtonClick}
            >
              Review
            </Button>
          )}
          {canViewerRequestArtworkChange && (
            <Button
              className={classes.spacerRight}
              variant="contained"
              onClick={handleRequestArtworkChangeButtonClick}
            >
              Request Changes
            </Button>
          )}
          {canViewerCompleteProof && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handleProofCompletedButtonClick}
            >
              Proof Complete
            </Button>
          )}
        </div>
      )}
      <div>
        {(canViewerPlaceOnHold || canViewerRemoveHold) && (
          <div className={classes.iconSwitchContainer}>
            <div className={classes.spacerRight}>
              {isOrderOnHold ? <LockIcon /> : <LockOpenIcon />}
            </div>
            <FormControlLabel
              control={
                isPlacingOrderOnHold ? (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <RedSwitch
                    checked={isOrderOnHold}
                    onChange={
                      isOrderOnHold
                        ? handleRemoveHoldToggleSwitch
                        : handleHoldToggleSwitch
                    }
                  />
                )
              }
              label={
                isOrderOnHold ? "ORDER LOCKED" : "LOCK ORDER ON PRODUCTION"
              }
            />
          </div>
        )}
      </div>
      <RequestArtworkChangeModal
        isOpen={isRequestingArtworkChange}
        onRequestClose={handleRequestArtworkChangeModalRequestClose}
        orderId={orderId}
      />
      <PlaceOrderOnHoldModal
        isOpen={isPlacingOrderOnHold}
        onRequestClose={handlePlaceOrderOnHoldModalRequestClose}
        orderId={orderId}
      />
    </div>
  );
};

export default enhancer(ArtOrderStatus);
