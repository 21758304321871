// @flow

import { fragments as artOrderStatusFragments } from "../../application/apps/ArtApp/components/ArtOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const setOrderAutoApproved: any = graphql(
  gql`
    mutation($autoApproved: Boolean!, $orderId: ID!) {
      setOrderAutoApproved(autoApproved: $autoApproved, orderId: $orderId) {
        errors {
          orderId
          autoApproved
        }
        updatedOrder {
          id
          autoApproved
        }
        succeeded
      }
    }
    ${artOrderStatusFragments.order}
  `,
  {
    name: "setOrderAutoApproved"
  }
);

export default setOrderAutoApproved;
