import { Document } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";

const PrintablePDFDocument = forwardRef(
  ({ children, label, onRender, classes = {} }, ref) => {
    const viewerRef = useRef(null);
    const [rendered, setRendered] = useState(false);
    const [printOnRender, setPrintOnRender] = useState(false);
    useImperativeHandle(ref, () => ({
      print: () => {
        console.log("printing!!!");
        viewerRef.current?.focus();
        (
          viewerRef.current?.contentWindow || viewerRef.current?.contentDocument
        ).print();
      }
    }));

    const print = () => {
      viewerRef.current?.focus();
      (
        viewerRef.current?.contentWindow || viewerRef.current?.contentDocument
      ).print();
    };

    const handleRender = () => {
      console.log("handleRender");
      console.log("printOnRender");
      console.log(printOnRender);
      setRendered(true);
      if (printOnRender) {
        print();
      }
      if (onRender) {
        onRender();
      }
    };

    const handlePrintClick = event => {
      event.stopPropagation();
      if (rendered) {
        print();
      } else {
        setPrintOnRender(true);
      }
    };

    return (
      <>
        <Button
          variant="contained"
          onClick={handlePrintClick}
          classes={classes}
        >
          {label || "Print"}
        </Button>
        <PDFViewer
          innerRef={viewerRef}
          style={{ display: "none" }}
          width="1000"
          height="1000"
        >
          <Document onRender={handleRender}>{children}</Document>
        </PDFViewer>
      </>
    );
  }
);

export default PrintablePDFDocument;
