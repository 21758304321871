// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";

type Props = {|
  +classes: {|
    +HIGH: string,
    +LOW: string,
    +MEDIUM: string,
    +URGENT: string
  |},
  +date: string,
  +priority: "URGENT" | "LOW" | "MEDIUM" | "HIGH"
|};

const styles = theme => ({
  LOW: {
    backgroundColor: theme.appColors.priority.low,
    color: theme.palette.getContrastText(theme.appColors.priority.low)
  },
  MEDIUM: {
    backgroundColor: theme.appColors.priority.medium,
    color: theme.palette.getContrastText(theme.appColors.priority.medium)
  },
  HIGH: {
    backgroundColor: theme.appColors.priority.high,
    color: theme.palette.getContrastText(theme.appColors.priority.high)
  },
  URGENT: {
    backgroundColor: theme.appColors.priority.urgent,
    color: theme.palette.getContrastText(theme.appColors.priority.urgent)
  }
});

const PriorityChip = ({ classes, date, priority }: Props) => {
  return (
    <Chip
      className={classes[priority]}
      label={DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)}
    />
  );
};

export default withStyles(styles)(PriorityChip);
