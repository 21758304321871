// @flow

import { gql } from "@apollo/client";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../../../../components/StyleSubstitutionLineItemBreakdown/graph";

export default {
  productionJobLineItemBreakdown: gql`
    fragment ProductionJobLineItemBreakdown_productionJobLineItemBreakdown on OrderProductionJobLineItemBreakdown {
      lineItems {
        ...StyleSubstitutionLineItemBreakdown_lineItems
      }
      product {
        id
        fullDisplayName
        style
        isStyleSubstitution
      }
      totalProductVariantQuantity
    }
    ${styleSubstitutionLineItemBreakdownFragments.lineItems}
  `
};
