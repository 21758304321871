// @flow

import { gql } from "@apollo/client";

export default gql`
  query stockContainerStatusCheck($id: ID!) {
    node(id: $id) {
      __typename
      ... on StockContainer {
        id
        latestLocation {
          id
          location
        }
        orderProductionJob {
          id
          order {
            id
            artworkStatus
          }
        }
      }
      ... on OrderProductionJob {
        id
        order {
          id
          artworkStatus
        }
      }
    }
  }
`;
