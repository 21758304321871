// @flow

import { fragments as artOrderStatusFragments } from "../../application/apps/ArtApp/components/ArtOrderStatus/graph";
import { fragments as dtgOrderStatusFragments } from "../../application/apps/DTGApp/components/DTGOrderStatus/graph";
import { fragments as embroideryOrderStatusFragments } from "../../application/apps/EmbroideryApp/components/EmbroideryOrderStatus/graph";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as hddOrderStatusFragments } from "../../application/apps/HDDPressApp/components/HDDOrderStatus/graph";
import { fragments as screenPrintingOrderStatusFragments } from "../../application/apps/ScreenPrintingApp/components/ScreenPrintingOrderStatus/graph";
import { fragments as vinylOrderStatusFragments } from "../../application/apps/VinylApp/components/VinylOrderStatus/graph";

const markOrderAsNoLongerOnHold: any = graphql(
  gql`
    mutation ($orderId: ID!) {
      markOrderAsNoLongerOnHold(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...ArtOrderStatus_order
          ...DTGOrderStatus_order
          ...EmbroideryOrderStatus_order
          ...HDDOrderStatus_order
          ...VinylOrderStatus_order
          ...ScreenPrintingOrderStatus_order
        }
        succeeded
      }
    }
    ${artOrderStatusFragments.order}
    ${dtgOrderStatusFragments.order}
    ${embroideryOrderStatusFragments.order}
    ${hddOrderStatusFragments.order}
    ${screenPrintingOrderStatusFragments.order}
    ${vinylOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsNoLongerOnHold"
  }
);

export default markOrderAsNoLongerOnHold;
