import { Rnd } from "react-rnd";
import React, { useState } from "react";

export default ({ alignment, enabled, children, onChange }) => {
  const [highlight, setHighlight] = useState(false);
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `solid 1px ${highlight ? "#49fb35" : "transparent"}`,
    ...(enabled ? {} : { pointerEvents: "none" }),
  };
  return (
    <Rnd
      style={style}
      enableResizing={enabled}
      disableDragging={!enabled}
      //scale={1}
      onMouseEnter={() => {
        setHighlight(true);
      }}
      onMouseLeave={() => {
        setHighlight(false);
      }}
      onDragStart={() => {
        setHighlight(true);
      }}
      onDragStop={(event, position) => {
        setHighlight(false);
        if (onChange) {
          onChange({ position });
        }
      }}
      onResize={(event, direction, ref, delta, position) => {
        if (onChange) {
          onChange({
            position,
            size: {
              width: ref.offsetWidth,
              height: ref.offsetHeight,
            },
          });
        }
      }}
      {...(alignment || {})}
    >
      {children}
    </Rnd>
  );
};
