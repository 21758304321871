// @flow

import { gql } from "@apollo/client";
import { fragments as receivingTableFragments } from "../components/ReceivingTable/graph";

export default gql`
  query orderProductionJobsToBeReceived(
    $first: Int
    $after: String
    $filters: OrderProductionJobsToBeReceivedFilters
  ) {
    orderProductionJobsToBeReceived(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...ReceivingTable_orderProductionJob
        }
      }
      poStartingCharacters(filters: $filters)
    }
  }
  ${receivingTableFragments.order}
`;
