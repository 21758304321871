// @flow

import { gql } from "@apollo/client";
import { fragments as sidebarListItemFragments } from "../../../../components/Sidebar/SidebarListItem/graph";

export default gql`
  query ordersToBeScreenPrinted(
    $first: Int!
    $after: String
    $filters: OrdersToBeScreenPrintedFilters
    $type: ProductionStationType
  ) {
    productionStations: productionStations(type: $type) {
      id
      name
      type
    }
    ordersConnection: ordersToBeScreenPrinted(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...SidebarListItem_order
        }
      }
    }
  }
  ${sidebarListItemFragments.order}
`;
