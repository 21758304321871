// @flow

import { gql } from "@apollo/client";
import { fragments as sceensMadeTableFragments } from "../components/ScreensMadeTable/graph";

export default gql`
  query ordersToBeScreened(
    $first: Int!
    $after: String
    $filters: OrdersToBeScreenedFilters
  ) {
    ordersToBeScreened(first: $first, after: $after, filters: $filters) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ScreensMadeTable_order
        }
      }
    }
  }
  ${sceensMadeTableFragments.order}
`;
