// @flow

import { compose, mapProps, setDisplayName } from "recompose";
import type { HOC } from "recompose";
import type { HighDefinitionDigitalStatus } from "../../types/HighDefinitionDigitalStatus";
import type { OrderMethodStatus } from "../../types/OrderMethodStatus";

type Props = {|
  +order: {|
    +artworkStatus: OrderMethodStatus,
    +directToGarmentStatus: OrderMethodStatus,
    +embroideryStatus: OrderMethodStatus,
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +screenPrintingStatus: OrderMethodStatus,
    +shippingStatus: OrderMethodStatus,
    +vinylStatus: OrderMethodStatus
  |}
|};

const NOT_APPLICABLE = "NOT_APPLICABLE";

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionStatusLabel"),

  mapProps(
    ({
      order: {
        artworkStatus,
        directToGarmentStatus,
        embroideryStatus,
        highDefinitionDigitalStatus,
        transfersStandardStatus,
        transfersGlitterStatus,
        transfersUvStatus,
        screenPrintingStatus,
        shippingStatus,
        vinylStatus
      }
    }) => {
      return {
        isBlank:
          directToGarmentStatus === NOT_APPLICABLE &&
          embroideryStatus === NOT_APPLICABLE &&
          screenPrintingStatus === NOT_APPLICABLE &&
          vinylStatus === NOT_APPLICABLE &&
          highDefinitionDigitalStatus === NOT_APPLICABLE &&
          transfersStandardStatus === NOT_APPLICABLE &&
          transfersGlitterStatus === NOT_APPLICABLE &&
          transfersUvStatus === NOT_APPLICABLE,
        isProductionReady: artworkStatus === "APPROVED",
        isScreenPrintingInProgress: screenPrintingStatus === "IN_PROGRESS",
        isEmbroideryInProgress: embroideryStatus === "IN_PROGRESS",
        isDTGInProgress: directToGarmentStatus === "IN_PROGRESS",
        isHighDefinitionDigitalInProgress:
          highDefinitionDigitalStatus === "IN_PROGRESS",
        isTransfersStandardInProgress:
          transfersStandardStatus !== "READY" &&
          transfersStandardStatus !== "COMPLETE",
        isTransfersGlitterInProgress:
          transfersGlitterStatus !== "READY" &&
          transfersGlitterStatus !== "COMPLETE",
        isTransfersUVInProgress:
          transfersUvStatus !== "READY" &&
          transfersUvStatus !== "COMPLETE",
        needsEmbroidery:
          embroideryStatus !== NOT_APPLICABLE &&
          embroideryStatus !== "COMPLETE",
        needsScreenPrinting:
          screenPrintingStatus !== NOT_APPLICABLE &&
          screenPrintingStatus !== "COMPLETE",
        needsDTG:
          directToGarmentStatus !== NOT_APPLICABLE &&
          directToGarmentStatus !== "COMPLETE",
        needsHighDefinitionDigital:
          highDefinitionDigitalStatus !== NOT_APPLICABLE &&
          highDefinitionDigitalStatus !== "COMPLETE",
        needsTranfersStandard:
          transfersStandardStatus !== NOT_APPLICABLE &&
          transfersStandardStatus !== "COMPLETE",
        needsTranfersGlitter:
          transfersGlitterStatus !== NOT_APPLICABLE &&
          transfersGlitterStatus !== "COMPLETE",
        needsTranfersUV:
          transfersUvStatus !== NOT_APPLICABLE &&
          transfersUvStatus !== "COMPLETE",
        needsVinyl:
          vinylStatus !== NOT_APPLICABLE && vinylStatus !== "COMPLETE",
        isShipped: shippingStatus === "SHIPPED",
        isPartiallyShipped: shippingStatus === "PARTIALLY_SHIPPED",
        isReadyToShip:
          (directToGarmentStatus === NOT_APPLICABLE ||
            directToGarmentStatus === "COMPLETE") &&
          (embroideryStatus === NOT_APPLICABLE ||
            embroideryStatus === "COMPLETE") &&
          (screenPrintingStatus === NOT_APPLICABLE ||
            screenPrintingStatus === "COMPLETE") &&
          (vinylStatus === NOT_APPLICABLE || vinylStatus === "COMPLETE")
      };
    }
  )
);

const ProductionStatusLabel = ({
  isBlank,
  isProductionReady,
  isScreenPrintingInProgress,
  isEmbroideryInProgress,
  isDTGInProgress,
  isHighDefinitionDigitalInProgress,
  isTransfersStandardInProgress,
  isTransfersGlitterInProgress,
  isTransfersUVInProgress,
  needsEmbroidery,
  needsHighDefinitionDigital,
  needsTranfersStandard,
  needsScreenPrinting,
  needsTranfersGlitter,
  needsTranfersUV,
  needsDTG,
  needsVinyl,
  isShipped,
  isPartiallyShipped,
  isReadyToShip
}) => {
  if (isBlank) {
    return "Blank Shirts";
  } else if (!isProductionReady) {
    return "Not Ready for Production";
  } else if (isScreenPrintingInProgress) {
    return "Screen Printing In Progress";
  } else if (isEmbroideryInProgress) {
    return "Embroidery In Progress";
  } else if (isDTGInProgress) {
    return "DTG In Progress";
  } else if (isHighDefinitionDigitalInProgress) {
    return "HDD In Progress";
  } else if (isTransfersStandardInProgress) {
    return "Transfers - Standard In Progress";
  } else if (isTransfersGlitterInProgress) {
    return "Transfers - Glitter In Progress";
  } else if (isTransfersUVInProgress) {
    return "Transfers - UV In Progress";
  } else if (needsEmbroidery) {
    return "Needs Embroidery";
  } else if (needsScreenPrinting) {
    return "Needs Screen Printing";
  } else if (needsDTG) {
    return "Needs DTG";
  } else if (needsHighDefinitionDigital) {
    return "Needs HDD";
  } else if (needsTranfersStandard) {
    return "Needs Transfers - Standard";
  }  else if (needsTranfersGlitter) {
    return "Needs Transfers - Glitter";
  }  else if (needsTranfersUV) {
    return "Needs Transfers - UV";
  }  else if (needsVinyl) {
    return "Needs Vinyl";
  } else if (isShipped) {
    return "Shipped";
  } else if (isPartiallyShipped) {
    return "Partially Shipped";
  } else if (isReadyToShip) {
    return "Ready To Ship";
  }
  return "Undefined";
};

export default enhancer(ProductionStatusLabel);
