// @flow

import * as React from "react";
import { compose, lifecycle, setDisplayName, withState } from "recompose";
import { withStyles } from "@mui/styles";
import QRCode from "qrcode";

import type { HOC } from "recompose";

type Props = {|
  +customizationAreaId: string
|};

const styles = () => ({
  barcode: {
    marginTop: 20,
    width: 100,
    height: 100
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaBarcode"),
  withStyles(styles),
  withState("barcode", "setBarcode", null),
  lifecycle({
    componentDidMount() {
      const { customizationAreaId, setBarcode } = this.props;
      QRCode.toDataURL(
        JSON.stringify({
          variant: "OBJECT",
          type: "OrderProductionJobCustomizationArea",
          id: customizationAreaId
        })
      ).then(barcode => {
        setBarcode(barcode);
      });
    }
  })
);

const CustomizationAreaBarcode = ({ classes, barcode }) => (
  <React.Fragment>
    {barcode && <img alt={`CustomizationAreaBarcode`} src={barcode} className={classes.barcode} />}
  </React.Fragment>
);

export default enhancer(CustomizationAreaBarcode);
