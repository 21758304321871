// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForHighDefinitionDigitalStatus } from "../../../../../helpers";
import {
  markOrderAsHighDefinitionDigitalCompleted,
  markOrderAsHighDefinitionDigitalPaused,
  markOrderAsHighDefinitionDigitalStarted
} from "../../../../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +canViewerCompleteHighDefinitionDigital: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +canViewerStartHighDefinitionDigital: boolean,
    +highDefinitionDigitalStatus: string,
    +id: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean
|};

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("HDDOrderStatus"),

  markOrderAsHighDefinitionDigitalCompleted,

  markOrderAsHighDefinitionDigitalPaused,

  markOrderAsHighDefinitionDigitalStarted,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(({ isMutatingOrder: false }: State), {
    setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
      isMutatingOrder
    })
  }),

  withHandlers({
    handleCompleteButtonClick:
      ({
        markOrderAsHighDefinitionDigitalCompleted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsHighDefinitionDigitalCompleted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsHighDefinitionDigitalCompleted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handlePauseButtonClick:
      ({
        markOrderAsHighDefinitionDigitalPaused,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsHighDefinitionDigitalPaused({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsHighDefinitionDigitalPaused: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleStartButtonClick:
      ({
        markOrderAsHighDefinitionDigitalStarted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsHighDefinitionDigitalStarted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsHighDefinitionDigitalStarted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      }
  })
);

const HDDOrderStatus = ({
  classes,
  handleCompleteButtonClick,
  handlePauseButtonClick,
  handleStartButtonClick,
  isMutatingOrder,
  order: {
    canViewerCompleteHighDefinitionDigital,
    canViewerPauseHighDefinitionDigital,
    canViewerStartHighDefinitionDigital,
    highDefinitionDigitalStatus
  }
}) => (
  <div>
    <div className={classes.spaceBetweenContainer}>
      <JobStatusIcon status={highDefinitionDigitalStatus} />
      <div className={classes.jobStatus}>
        <Typography variant="h6">HDD</Typography>
        <Typography variant="body2">
          {getLabelForHighDefinitionDigitalStatus(highDefinitionDigitalStatus)}
        </Typography>
      </div>
    </div>
    {isMutatingOrder ? (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    ) : (
      <div className={classes.buttonContainer}>
        {canViewerStartHighDefinitionDigital && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartButtonClick}
          >
            Start
          </Button>
        )}
        {canViewerCompleteHighDefinitionDigital && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompleteButtonClick}
          >
            HDD Complete
          </Button>
        )}
        {canViewerPauseHighDefinitionDigital && (
          <Button
            color="secondary"
            onClick={handlePauseButtonClick}
            variant="contained"
          >
            Pause
          </Button>
        )}
      </div>
    )}
  </div>
);

export default enhancer(HDDOrderStatus);
