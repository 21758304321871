// @flow

import type { TransfersStandardStatus } from "../types/TransfersStandardStatus";

const getLabelForTransfersStandardStatus = (status: TransfersStandardStatus): string => {
  switch (status) {
    case "READY_TO_RIP":
      return "Ready to Rip";
    case "AUTO_RIPPING_IN_PROGRESS":
      return "Auto-Ripping in Progress";
    case "MANUAL_RIPPING_IN_PROGRESS":
      return "Manual Ripping in Progress";
    case "READY_TO_PRINT":
      return "Ready to Print";
    case "AUTO_PRINTING_IN_PROGRESS":
      return "Auto-Printing in Progress";
    case "MANUAL_PRINTING_IN_PROGRESS":
      return "Manual Printing in Progress";
    case "TRIMMING_NEEDED":
      return "Trimming Needed";
    case "NOT_READY":
      return "Not Ready";
    case "NOT_APPLICABLE":
      return "Not Applicable";
    case "COMPLETE":
      return "Complete";
    case "PRINTING_PAUSED":
      return "Printing Paused";
    // TODO: Rip failed?
    default:
      return status;
  }
};

export { getLabelForTransfersStandardStatus };
