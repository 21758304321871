// @flow

import TableCell from "@mui/material/TableCell";
import withStyles from '@mui/styles/withStyles';

const CustomTableCell = withStyles(theme => ({
  head: {
    position: "sticky",
    backgroundColor: theme.palette.background.default,
    top: 0,
    zIndex: 10
  }
}))(TableCell);

export default CustomTableCell;
