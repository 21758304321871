// @flow

import type {
  GeometricLength,
  ProductCustomizationMethod,
  RectangularGeometricArea
} from "./types";

const formatGeometricLength = ({ value }: GeometricLength): string =>
  `${value}″`;

const formatProductCustomizationMethod = (
  method: ProductCustomizationMethod
): string => {
  switch (method) {
    case "CUT_VINYL":
      return "Cut vinyl";
    case "DIRECT_TO_GARMENT":
      return "Direct to garment";
    case "EMBROIDERY":
      return "Embroidery";
    case "HEAT_PRESS":
      return "Heat press";
    case "PRINTED_VINYL":
      return "Printed vinyl";
    case "SCREEN_PRINTING":
      return "Screen printing";
    case "SUBLIMATION":
      return "Sublimation";
    case "TACKLE_TWILL":
      return "Tackle twill";
    default:
      return "";
  }
};

const formatRectangularGeometricArea = ({
  height,
  width
}: RectangularGeometricArea): string =>
  `${formatGeometricLength(width)} W × ${formatGeometricLength(height)} H`;

export { formatProductCustomizationMethod, formatRectangularGeometricArea };
