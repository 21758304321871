// @flow

import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';

const markOrderProductionJobAsCountValidated: any = graphql(
  gql`
    mutation($orderProductionJobId: ID!) {
      markOrderProductionJobAsCountValidated(orderProductionJobId: $orderProductionJobId) {
        errors {
          orderProductionJobId
        }
        updatedOrder {
          id
        }
        succeeded
      }
    }
  `,
  {
    name: "markOrderProductionJobAsCountValidated"
  }
);

export default markOrderProductionJobAsCountValidated;

