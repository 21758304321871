// @flow

import * as React from "react";
import { compose, setDisplayName, withProps } from "recompose";
import { withStyles } from "@mui/styles";
import ProductionMethodIconSet from "../../../../../../../components/ProductionMethodIconSet";
import Typography from "@mui/material/Typography";
import type { HOC } from "recompose";

const pluralize = require("pluralize");

type Props = {|
  +classes?: {|
    +imageContainer: string,
    +labelIconContainer: string,
    +lineItemTotalProductVariantQuantity: string,
    +proofsContainer: string,
    +topContainer: string
  |},
  +index: number,
  +productionJob: {|
    +filteredProofs: $ReadOnlyArray<{|
      +asset: ?{|
        +url: {|
          +formatted: string
        |}
      |},
      +id: string
    |}>,
    +id: string,
    +label: string,
    +totalProductVariantQuantity: number
  |}
|};

const styles = theme => ({
  topContainer: {
    display: "flex",
    flexBasis: "100%"
  },

  imageContainer: {
    alignSelf: "flex-end",
    borderRadius: 5,
    height: 60,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    objectFit: "cover",
    width: 80
  },

  labelIconContainer: {
    display: "flex",
    alignItems: "center"
  },

  lineItemTotalProductVariantQuantity: {
    color: theme.palette.grey[700]
  },

  proofsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobListItem"),

  withStyles(styles),

  withProps(({ productionJob }) => ({
    productionJob: {
      ...productionJob,
      proofs: productionJob.filteredProofs
    }
  }))
);

const ProductionJobListItem = ({
  classes,
  index,
  productionJob,
  productionJob: {
    label,
    proofs,
    totalProductVariantQuantity
  }
}) => {
  return (
    <React.Fragment>
      <div className={classes.topContainer}>
        <Typography style={{ paddingRight: "5px" }} variant="subtitle2">
          {index + 1}
        </Typography>
        <div className={classes.labelIconContainer}>
          <Typography variant="h6">
            {label}
            <ProductionMethodIconSet productionMethodStatuses={productionJob} />
            <Typography
              className={classes.lineItemTotalProductVariantQuantity}
              variant="body1"
            >
              {pluralize("piece", totalProductVariantQuantity, true)}
            </Typography>
          </Typography>
        </div>
        <div className={classes.proofsContainer}>
          {proofs.length > 0 &&
            proofs.map(({ asset }, index) =>
              asset ? (
                <img
                  className={classes.imageContainer}
                  key={index}
                  src={asset.url.formatted}
                  alt="artwork"
                />
              ) : (
                <img
                  className={classes.imageContainer}
                  key={index}
                  src={"https://dummyimage.com/100x85/7a7a7a/fff.png&text=FPO"}
                  alt="artwork"
                />
              )
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default enhancer(ProductionJobListItem);
