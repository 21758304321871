// @flow

import * as React from "react";

import { addCustomizationAreaToOrderProductionJob } from "../../graph";
import { availableCustomizationAreaMethods } from "../../types/ProductionMethodOption";
import {
  compose,
  setDisplayName,
  withHandlers,
  withProps,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { getIconForCustomizationAreaTemplate } from "../../helpers/getIconForCustomizationAreaTemplate";
import { getIconForProductionJobMethod } from "../../helpers/getIconForProductionJobMethod";
import { graphql } from '@apollo/client/react/hoc';
import { fragments as inkColorDetailsFragments } from "../InkColorDetails/graph";
import { query } from "./graph";
import { vinylSizeOptions } from "../../types/VinylSizeOptions";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CustomizationAreaRadioList from "../CustomizationAreaForm/components/CustomizationAreaRadioList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InkColorDetails from "../InkColorDetails";
import InkColorModal from "../InkColorModal";
import PendingStatusView from "../PendingStatusView";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VinylCombobox from "../CustomizationAreaForm/components/VinylCombobox";

import type { ColorSelection } from "../../types/Color";
import type { HOC } from "recompose";
import type { InkColor } from "../../types/Color";
import type { InkColorInputErrors } from "../../types/InkColorInputErrors";
import type { PantoneColor } from "../../types/Color";

type Props = {|
  +isOpen: boolean,
  +onRequestClose: () => void,
  +orderProductionJobId: string
|};

const styles = theme => ({
  addColorsButton: { marginBottom: theme.spacing(2) },

  colorCount: {
    marginBottom: theme.spacing(2)
  },

  printAreaMethodAttributesContainer: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  areaContainer: {
    flex: 1
  },

  methodContainer: {
    flex: 1
  },

  list: {
    listStyle: "none",
    marginTop: 0,
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },

  listItem: {
    marginBottom: theme.spacing(1)
  },

  attributesContainer: {
    flex: 0.6
  },

  inkColorContainer: {
    display: "flex",
    flexDirection: "column"
  },

  splitIntoColorsCheckbox: {
    width: "150px"
  }
});

type InputErrors = {|
  autobase: $ReadOnlyArray<string>,
  colors: $ReadOnlyArray<string>,
  distressed: $ReadOnlyArray<string>,
  flatten: $ReadOnlyArray<string>,
  halftones: $ReadOnlyArray<string>,
  imageRepair: $ReadOnlyArray<string>,
  note: $ReadOnlyArray<string>,
  recreate: $ReadOnlyArray<string>,
  splitIntoColors: $ReadOnlyArray<string>,
  sponsorback: $ReadOnlyArray<string>,
  vinylConfiguration: $ReadOnlyArray<string>,
  vinylConfigurationInput: {|
    names: $ReadOnlyArray<string>,
    namesColorId: $ReadOnlyArray<string>,
    namesFontId: $ReadOnlyArray<string>,
    numbers: $ReadOnlyArray<string>,
    numbersColorId: $ReadOnlyArray<string>,
    numbersFontId: $ReadOnlyArray<string>,
    size: $ReadOnlyArray<string>
  |}
|};

const defaultInkColorInputErrors: InkColorInputErrors = {
  colors: [],
  colorsInput: {
    inkColorId: [],
    pantoneColorId: []
  },
  orderProductionJobCustomizationAreaInkColorId: []
};

const defaultInputErrors: InputErrors = {
  autobase: [],
  colors: [],
  distressed: [],
  flatten: [],
  halftones: [],
  imageRepair: [],
  note: [],
  recreate: [],
  sponsorback: [],
  splitIntoColors: [],
  vinylConfiguration: [],
  vinylConfigurationInput: {
    names: [],
    namesColorId: [],
    namesFontId: [],
    numbers: [],
    numbersColorId: [],
    numbersFontId: [],
    size: []
  }
};

type State = {|
  autobaseCheckboxChecked: boolean,
  colorCountInputValue: string,
  colorSelections: $ReadOnlyArray<ColorSelection>,
  customizationAreaNote: string,
  customizationAreaTemplateSelectedRadioValue: ?string,
  distressedCheckboxChecked: boolean,
  flattenCheckboxChecked: boolean,
  generalErrors: $ReadOnlyArray<string>,
  halftonesCheckboxChecked: boolean,
  imageRepairCheckboxChecked: boolean,
  inkColorSelectionInputErrors: InkColorInputErrors,
  inputErrors: InputErrors,
  isAddingColors: boolean,
  isAddingCustomizationArea: boolean,
  productCustomizationMethodComboboxSelection: string,
  recreateCheckboxChecked: boolean,
  splitIntoColorsCheckboxChecked: boolean,
  sponsorbackCheckboxChecked: boolean,
  vinylColorSelection: ?string,
  vinylFontSelection: ?string,
  vinylNamesCheckboxChecked: boolean,
  vinylNumbersCheckboxChecked: boolean,
  vinylSizeComboboxSelection: number
|};

const defaultState: State = {
  inputErrors: defaultInputErrors,
  isAddingCustomizationArea: false,
  isAddingColors: false,
  colorSelections: [],
  colorCountInputValue: "0",
  customizationAreaNote: "",
  generalErrors: [],
  customizationAreaTemplateSelectedRadioValue:
    "Q3VzdG9taXphdGlvbkFyZWFUZW1wbGF0ZTox",
  productCustomizationMethodComboboxSelection: "SCREEN_PRINTING",
  distressedCheckboxChecked: false,
  autobaseCheckboxChecked: false,
  flattenCheckboxChecked: false,
  halftonesCheckboxChecked: false,
  imageRepairCheckboxChecked: false,
  inkColorSelectionInputErrors: defaultInkColorInputErrors,
  recreateCheckboxChecked: false,
  splitIntoColorsCheckboxChecked: true,
  sponsorbackCheckboxChecked: false,
  vinylNamesCheckboxChecked: false,
  vinylColorSelection: "",
  vinylFontSelection: "",
  vinylNumbersCheckboxChecked: false,
  vinylSizeComboboxSelection: 8
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("AddCustomizationAreaModal"),

  graphql(query, {
    skip: ({ isOpen }) => !isOpen
  }),

  addCustomizationAreaToOrderProductionJob,

  withStyles(styles),

  withStateHandlers(() => defaultState, {
    addColorsSelection: () => (
      newColorSelections: $ReadOnlyArray<ColorSelection>
    ) => ({
      colorSelections: [...newColorSelections]
    }),

    resetForm: () => () => defaultState,

    resetGeneralErrors: () => () => ({ generalErrors: [] }),

    resetInputErrors: () => () => ({
      inputErrors: defaultInputErrors
    }),

    setCustomizationAreaRadioListValue: () => (
      customizationAreaTemplateSelectedRadioValue: ?string
    ) => ({ customizationAreaTemplateSelectedRadioValue }),

    setInkColorSelectionInputErrors: () => (
      inkColorSelectionInputErrors: InkColorInputErrors
    ) => ({ inkColorSelectionInputErrors }),

    setIsAddingCustomizationArea: () => (
      isAddingCustomizationArea: boolean
    ) => ({ isAddingCustomizationArea }),

    setProductCustomizationMethodComboboxSelection: () => productCustomizationMethodComboboxSelection => ({
      productCustomizationMethodComboboxSelection
    }),

    setDistressedCheckboxChecked: () => (
      distressedCheckboxChecked: boolean
    ) => ({ distressedCheckboxChecked }),

    setColorCountInputValue: () => (colorCountInputValue: string) => ({
      colorCountInputValue
    }),

    setIsAddingColors: () => (isAddingColors: boolean) => ({ isAddingColors }),

    setAutobaseCheckboxChecked: () => (autobaseCheckboxChecked: boolean) => ({
      autobaseCheckboxChecked
    }),

    setCustomizationAreaNote: () => (customizationAreaNote: string) => ({
      customizationAreaNote
    }),

    setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
      generalErrors
    }),

    setFlattenCheckboxChecked: () => (flattenCheckboxChecked: boolean) => ({
      flattenCheckboxChecked
    }),

    setHalftonesCheckboxChecked: () => (halftonesCheckboxChecked: boolean) => ({
      halftonesCheckboxChecked
    }),

    setImageRepairCheckboxChecked: () => (
      imageRepairCheckboxChecked: boolean
    ) => ({ imageRepairCheckboxChecked }),

    setInputErrors: () => (inputErrors: InputErrors) => ({ inputErrors }),

    setRecreateCheckboxChecked: () => (recreateCheckboxChecked: boolean) => ({
      recreateCheckboxChecked
    }),

    setSplitIntoColorsCheckboxChecked: () => (
      splitIntoColorsCheckboxChecked: boolean
    ) => ({
      splitIntoColorsCheckboxChecked
    }),

    setSponsorbackCheckboxChecked: () => (
      sponsorbackCheckboxChecked: boolean
    ) => ({ sponsorbackCheckboxChecked }),

    setVinylConfigurationNamesColorIdError: ({
      inputErrors,
      inputErrors: { vinylConfigurationInput }
    }) => (vinylConfigurationNamesColorIdError: string) => ({
      inputErrors: {
        ...inputErrors,
        vinylConfigurationInput: {
          ...vinylConfigurationInput,
          namesColorId: [vinylConfigurationNamesColorIdError]
        }
      }
    }),

    setVinylConfigurationNamesFontIdError: ({
      inputErrors,
      inputErrors: { vinylConfigurationInput }
    }) => (vinylConfigurationNamesFontIdError: string) => ({
      inputErrors: {
        ...inputErrors,
        vinylConfigurationInput: {
          ...vinylConfigurationInput,
          namesFontId: [vinylConfigurationNamesFontIdError]
        }
      }
    }),

    setVinylConfigurationNumbersColorIdError: ({
      inputErrors,
      inputErrors: { vinylConfigurationInput }
    }) => (vinylConfigurationNumbersColorIdError: string) => ({
      inputErrors: {
        ...inputErrors,
        vinylConfigurationInput: {
          ...vinylConfigurationInput,
          numbersColorId: [vinylConfigurationNumbersColorIdError]
        }
      }
    }),

    setVinylConfigurationNumbersFontIdError: ({
      inputErrors,
      inputErrors: { vinylConfigurationInput }
    }) => (vinylConfigurationNumbersFontIdError: string) => ({
      inputErrors: {
        ...inputErrors,
        vinylConfigurationInput: {
          ...vinylConfigurationInput,
          numbersFontId: [vinylConfigurationNumbersFontIdError]
        }
      }
    }),

    setVinylNamesCheckboxChecked: () => (
      vinylNamesCheckboxChecked: boolean
    ) => ({ vinylNamesCheckboxChecked }),

    setVinylFontSelection: () => (vinylFontSelection: string) => ({
      vinylFontSelection
    }),

    setVinylColorSelection: () => (vinylColorSelection: string) => ({
      vinylColorSelection
    }),

    setVinylNumbersCheckboxChecked: () => (
      vinylNumbersCheckboxChecked: boolean
    ) => ({ vinylNumbersCheckboxChecked }),

    setVinylSizeComboboxSelection: () => (
      vinylSizeComboboxSelection: number
    ) => ({ vinylSizeComboboxSelection })
  }),

  withProps(props => {
    const productCustomizationMethodComboboxSelection =
      props.productCustomizationMethodComboboxSelection;

    const isCutVinyl =
      productCustomizationMethodComboboxSelection === "CUT_VINYL";

    const isPrintedVinyl =
      productCustomizationMethodComboboxSelection === "PRINTED_VINYL";

    const isDTG =
      productCustomizationMethodComboboxSelection === "DIRECT_TO_GARMENT";

    const isEmbroidery =
      productCustomizationMethodComboboxSelection === "EMBROIDERY";

    const isScreenPrinting =
      productCustomizationMethodComboboxSelection === "SCREEN_PRINTING";

    const isSublimation =
      productCustomizationMethodComboboxSelection === "SUBLIMATION";

    const isTackleTwill =
      productCustomizationMethodComboboxSelection === "TACKLE_TWILL";

    return {
      ...props,
      isCutVinyl,
      colorOptionIsAvailable: isScreenPrinting || isEmbroidery || isTackleTwill,
      distressedOptionIsAvailable: isScreenPrinting || isDTG || isSublimation,
      halftonesOptionIsAvailable: isScreenPrinting,
      flattenOptionIsAvailable:
        isScreenPrinting ||
        isCutVinyl ||
        isPrintedVinyl ||
        isEmbroidery ||
        isDTG,
      recreateOptionIsAvailable:
        isScreenPrinting ||
        isCutVinyl ||
        isPrintedVinyl ||
        isEmbroidery ||
        isDTG,
      sponsorbackOptionIsAvailable: isScreenPrinting || isDTG,
      autobaseOptionIsAvailable: isScreenPrinting,
      imageRepairOptionIsAvailable:
        isScreenPrinting || isCutVinyl || isPrintedVinyl || isDTG
    };
  }),

  withHandlers({
    handleModalRequestClose: ({
      onRequestClose,
      resetGeneralErrors,
      resetInputErrors
    }) => () => {
      onRequestClose();
      resetGeneralErrors();
      resetInputErrors();
    },

    handleCancelButtonClick: ({
      onRequestClose,
      resetForm,
      resetGeneralErrors,
      resetInputErrors
    }) => () => {
      onRequestClose();
      resetForm();
      resetGeneralErrors();
      resetInputErrors();
    },

    handleSplitIntoColorsCheckboxChange: ({
      setSplitIntoColorsCheckboxChecked
    }) => (checked: boolean) => {
      setSplitIntoColorsCheckboxChecked(checked);
    },

    handleFormSubmit: ({
      addCustomizationAreaToOrderProductionJob,
      autobaseCheckboxChecked,
      autobaseOptionIsAvailable,
      colorOptionIsAvailable,
      colorCountInputValue,
      colorSelections,
      customizationAreaNote,
      customizationAreaTemplateSelectedRadioValue,
      distressedCheckboxChecked,
      distressedOptionIsAvailable,
      flattenCheckboxChecked,
      flattenOptionIsAvailable,
      halftonesCheckboxChecked,
      halftonesOptionIsAvailable,
      imageRepairCheckboxChecked,
      imageRepairOptionIsAvailable,
      isCutVinyl,
      onRequestClose,
      productCustomizationMethodComboboxSelection,
      orderProductionJobId,
      recreateCheckboxChecked,
      recreateOptionIsAvailable,
      resetForm,
      resetGeneralErrors,
      resetInputErrors,
      setGeneralErrors,
      setInputErrors,
      setIsAddingCustomizationArea,
      sponsorbackCheckboxChecked,
      sponsorbackOptionIsAvailable,
      splitIntoColorsCheckboxChecked,
      vinylNamesCheckboxChecked,
      vinylNumbersCheckboxChecked,
      vinylColorSelection,
      setVinylConfigurationNamesColorIdError,
      setInkColorSelectionInputErrors,
      vinylFontSelection,
      setVinylConfigurationNamesFontIdError,
      vinylSizeComboboxSelection,
      setVinylConfigurationNumbersColorIdError,
      setVinylConfigurationNumbersFontIdError
    }) => (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      let vinylConfigurationInputValues = null;

      if (
        isCutVinyl &&
        (vinylNamesCheckboxChecked || vinylNumbersCheckboxChecked)
      ) {
        vinylConfigurationInputValues = {
          names: vinylNamesCheckboxChecked,
          namesColorId: null,
          namesFontId: null,
          numbers: vinylNumbersCheckboxChecked,
          numbersColorId: null,
          numbersFontId: null,
          size: vinylSizeComboboxSelection
        };

        if (vinylNamesCheckboxChecked) {
          if (vinylColorSelection === "") {
            setVinylConfigurationNamesColorIdError("is required");
            return;
          } else {
            vinylConfigurationInputValues.namesColorId = vinylColorSelection;
          }

          if (vinylSizeComboboxSelection) {
            vinylConfigurationInputValues.size = vinylSizeComboboxSelection;
          }

          if (vinylFontSelection === "") {
            setVinylConfigurationNamesFontIdError("is required");
            return;
          } else {
            vinylConfigurationInputValues.namesFontId = vinylFontSelection;
          }
        }

        if (vinylNumbersCheckboxChecked) {
          if (vinylColorSelection === "") {
            setVinylConfigurationNumbersColorIdError("is required");
            return;
          } else {
            vinylConfigurationInputValues.numbersColorId = vinylColorSelection;
          }

          if (vinylFontSelection === "") {
            setVinylConfigurationNumbersFontIdError("is required");
            return;
          } else {
            vinylConfigurationInputValues.numbersFontId = vinylFontSelection;
          }
        }
      }

      setIsAddingCustomizationArea(true);
      resetGeneralErrors([]);
      resetInputErrors();

      const variables = {
        customizationAreaTemplateId: customizationAreaTemplateSelectedRadioValue,
        method: productCustomizationMethodComboboxSelection,
        note: customizationAreaNote,
        orderProductionJobId: orderProductionJobId,
        distressed: distressedOptionIsAvailable && distressedCheckboxChecked,
        halftones: halftonesOptionIsAvailable && halftonesCheckboxChecked,
        flatten: flattenOptionIsAvailable && flattenCheckboxChecked,
        recreate: recreateOptionIsAvailable && recreateCheckboxChecked,
        sponsorback: sponsorbackOptionIsAvailable && sponsorbackCheckboxChecked,
        autobase: autobaseOptionIsAvailable && autobaseCheckboxChecked,
        imageRepair: imageRepairOptionIsAvailable && imageRepairCheckboxChecked,
        vinylConfiguration: isCutVinyl && vinylConfigurationInputValues,
        splitIntoColors:
          colorOptionIsAvailable && splitIntoColorsCheckboxChecked,
        colorCount:
          colorOptionIsAvailable && splitIntoColorsCheckboxChecked
            ? 0
            : parseInt(colorCountInputValue, 10),
        colors:
          colorOptionIsAvailable && splitIntoColorsCheckboxChecked
            ? colorSelections.map(colorSelection => ({
                inkColorId:
                  colorSelection.inkColor && colorSelection.inkColor.id,
                pantoneColorId:
                  colorSelection.pantoneColor && colorSelection.pantoneColor.id
              }))
            : []
      };
      addCustomizationAreaToOrderProductionJob({
        variables
      })
        .then(
          ({
            data: {
              addCustomizationAreaToOrderProductionJob: { succeeded, errors }
            }
          }) => {
            if (succeeded) {
              resetInputErrors();
              resetGeneralErrors();
              onRequestClose();
              resetForm();
            } else {
              setInputErrors(errors);
              setInkColorSelectionInputErrors([...errors.colors]);
            }
          }
        )
        .catch(e => setGeneralErrors([e.message]))
        .finally(() => setIsAddingCustomizationArea(false));
    },

    handleCustomizationAreaRadioListSelect: ({
      setCustomizationAreaRadioListValue
    }) => (customizationAreaTemplateId: string) => {
      setCustomizationAreaRadioListValue(customizationAreaTemplateId);
    },

    handleAddColorsModalRequestClose: ({ setIsAddingColors }) => () => {
      setIsAddingColors(false);
    },

    handleAddColorsButtonClick: ({ setIsAddingColors }) => () => {
      setIsAddingColors(true);
    },

    handleColorCountInputChange: ({ setColorCountInputValue }) => (
      event: SyntheticInputEvent<HTMLInputElement>
    ) => {
      if (
        !(
          parseInt(event.target.value, 10) > 20 ||
          parseInt(event.target.value, 10) < 0
        )
      ) {
        setColorCountInputValue(event.target.value);
      }
    },

    handleAddColorsModalColorsAdded: ({
      addColorsSelection,
      setIsAddingColors
    }) => (
      inkColors: $ReadOnlyArray<InkColor>,
      pantoneColors: $ReadOnlyArray<PantoneColor>
    ) => {
      setIsAddingColors(true);
      addColorsSelection([
        ...inkColors.map(inkColor => ({
          inkColor
        })),
        ...pantoneColors.map(pantoneColor => ({
          pantoneColor
        }))
      ]);
      setIsAddingColors(false);
    },

    handleProductCustomizationMethodComboboxSelect: ({
      setProductCustomizationMethodComboboxSelection
    }) => productCustomizationMethod => {
      setProductCustomizationMethodComboboxSelection(
        productCustomizationMethod
      );
    },

    handleAutobaseCheckboxChange: ({ setAutobaseCheckboxChecked }) => (
      checked: boolean
    ) => {
      setAutobaseCheckboxChecked(checked);
    },

    handleCustomizationAreaNoteChange: ({ setCustomizationAreaNote }) => (
      event: SyntheticInputEvent<HTMLInputElement>
    ) => {
      setCustomizationAreaNote(event.target.value);
    },

    handleDistressedCheckboxChange: ({ setDistressedCheckboxChecked }) => (
      checked: boolean
    ) => {
      setDistressedCheckboxChecked(checked);
    },

    handleFlattenCheckboxChange: ({ setFlattenCheckboxChecked }) => (
      checked: boolean
    ) => {
      setFlattenCheckboxChecked(checked);
    },

    handleHalftonesCheckboxChange: ({ setHalftonesCheckboxChecked }) => (
      checked: boolean
    ) => {
      setHalftonesCheckboxChecked(checked);
    },

    handleImageRepairCheckboxChange: ({ setImageRepairCheckboxChecked }) => (
      checked: boolean
    ) => {
      setImageRepairCheckboxChecked(checked);
    },

    handleRecreateCheckboxChange: ({ setRecreateCheckboxChecked }) => (
      checked: boolean
    ) => {
      setRecreateCheckboxChecked(checked);
    },

    handleSponsorbackCheckboxChange: ({ setSponsorbackCheckboxChecked }) => (
      checked: boolean
    ) => {
      setSponsorbackCheckboxChecked(checked);
    },

    handleVinylColorSelectorSelect: ({ setVinylColorSelection }) => (
      colorId: ?string
    ) => {
      setVinylColorSelection(colorId);
    },

    handleVinylFontSelectorSelect: ({ setVinylFontSelection }) => (
      fontId: ?string
    ) => {
      setVinylFontSelection(fontId);
    },

    handleVinylSizeComboboxSelect: ({ setVinylSizeComboboxSelection }) => (
      selection: number
    ) => {
      setVinylSizeComboboxSelection(selection);
    },

    handleVinylNamesCheckboxChange: ({ setVinylNamesCheckboxChecked }) => (
      checked: boolean
    ) => {
      setVinylNamesCheckboxChecked(checked);
    },

    handleVinylNumbersCheckboxChange: ({ setVinylNumbersCheckboxChecked }) => (
      checked: boolean
    ) => {
      setVinylNumbersCheckboxChecked(checked);
    }
  })
);

const AddCustomizationAreaModal = ({
  classes,
  colorSelections,
  customizationAreaNote,
  data,
  distressedOptionIsAvailable,
  distressedCheckboxChecked,
  generalErrors,
  isOpen,
  inputErrors: {
    distressed,
    halftones,
    flatten,
    note,
    recreate,
    sponsorback,
    autobase,
    imageRepair,
    splitIntoColors,
    vinylConfigurationInput: {
      names,
      namesColorId,
      namesFontId,
      numbers,
      numbersColorId,
      numbersFontId,
      size
    }
  },
  isCutVinyl,
  colorOptionIsAvailable,
  isAddingColors,
  autobaseCheckboxChecked,
  flattenCheckboxChecked,
  halftonesCheckboxChecked,
  imageRepairCheckboxChecked,
  recreateCheckboxChecked,
  sponsorbackCheckboxChecked,
  halftonesOptionIsAvailable,
  flattenOptionIsAvailable,
  recreateOptionIsAvailable,
  sponsorbackOptionIsAvailable,
  autobaseOptionIsAvailable,
  imageRepairOptionIsAvailable,
  inkColorSelectionInputErrors,
  isAddingCustomizationArea,
  handleColorCountInputChange,
  handleAddColorsButtonClick,
  handleVinylColorSelectorSelect,
  handleVinylFontSelectorSelect,
  handleAddColorsModalColorsAdded,
  handleAddColorsModalRequestClose,
  handleCancelButtonClick,
  handleAutobaseCheckboxChange,
  handleSplitIntoColorsCheckboxChange,
  handleCustomizationAreaNoteChange,
  handleDistressedCheckboxChange,
  handleFlattenCheckboxChange,
  handleHalftonesCheckboxChange,
  handleImageRepairCheckboxChange,
  handleRecreateCheckboxChange,
  handleSponsorbackCheckboxChange,
  handleFormSubmit,
  handleModalRequestClose,
  handleCustomizationAreaRadioListSelect,
  handleProductCustomizationMethodComboboxSelect,
  handleVinylSizeComboboxSelect,
  handleVinylNamesCheckboxChange,
  handleVinylNumbersCheckboxChange,
  productCustomizationMethodComboboxSelection,
  customizationAreaTemplateSelectedRadioValue,
  splitIntoColorsCheckboxChecked,
  vinylNamesCheckboxChecked,
  vinylNumbersCheckboxChecked
}) => {
  return (
    <Dialog
      aria-describedby="Add Customization Area Modal"
      aria-labelledby="AddCustomizationAreaModal"
      open={isOpen}
      onClose={handleModalRequestClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Add Customization Area</DialogTitle>
      {!data ? (
        <PendingStatusView status="Waiting" />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <div>
          <Typography variant="body2">{data.error.message}</Typography>
        </div>
      ) : (
        <DialogContent>
          {generalErrors && generalErrors.length > 0 ? (
            <Typography color="error">{generalErrors.join(", ")}</Typography>
          ) : isAddingCustomizationArea ? (
            <PendingStatusView status="Loading" />
          ) : (
            <React.Fragment>
              <div className={classes.printAreaMethodAttributesContainer}>
                <div className={classes.areaContainer}>
                  <Typography variant="subtitle2">Print Area</Typography>
                  <CustomizationAreaRadioList
                    iconMethod={getIconForCustomizationAreaTemplate}
                    options={
                      data.orderProductionJob
                        .availableCustomizationAreaTemplates &&
                      data.orderProductionJob.availableCustomizationAreaTemplates.map(
                        ({ id, description }) => ({
                          id,
                          label: description,
                          icon: description
                        })
                      )
                    }
                    onSelect={handleCustomizationAreaRadioListSelect}
                    selectionId={customizationAreaTemplateSelectedRadioValue}
                  />
                </div>
                <div className={classes.methodContainer}>
                  <Typography variant="subtitle2">Print Method</Typography>
                  <CustomizationAreaRadioList
                    iconMethod={getIconForProductionJobMethod}
                    options={
                      availableCustomizationAreaMethods &&
                      availableCustomizationAreaMethods.map(
                        ({ id, label }) => ({
                          id,
                          label,
                          icon: id
                        })
                      )
                    }
                    onSelect={handleProductCustomizationMethodComboboxSelect}
                    selectionId={productCustomizationMethodComboboxSelection}
                  />
                </div>
                <div className={classes.attributesContainer}>
                  <Typography variant="subtitle2">Attributes</Typography>
                  <FormControl>
                    {distressedOptionIsAvailable && (
                      <FormControlLabel
                        error={distressed.join(", ")}
                        control={
                          <Checkbox
                            checked={distressedCheckboxChecked}
                            onChange={() =>
                              handleDistressedCheckboxChange(
                                !distressedCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Distressed"
                      />
                    )}
                    {halftonesOptionIsAvailable && (
                      <FormControlLabel
                        error={halftones.join(", ")}
                        control={
                          <Checkbox
                            checked={halftonesCheckboxChecked}
                            onChange={() =>
                              handleHalftonesCheckboxChange(
                                !halftonesCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Halftones"
                      />
                    )}
                    {flattenOptionIsAvailable && (
                      <FormControlLabel
                        error={flatten.join(", ")}
                        control={
                          <Checkbox
                            checked={flattenCheckboxChecked}
                            onChange={() =>
                              handleFlattenCheckboxChange(
                                !flattenCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Flatten"
                      />
                    )}
                    {recreateOptionIsAvailable && (
                      <FormControlLabel
                        error={recreate.join(", ")}
                        control={
                          <Checkbox
                            checked={recreateCheckboxChecked}
                            onChange={() =>
                              handleRecreateCheckboxChange(
                                !recreateCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Recreate"
                      />
                    )}
                    {sponsorbackOptionIsAvailable && (
                      <FormControlLabel
                        error={sponsorback.join(", ")}
                        control={
                          <Checkbox
                            checked={sponsorbackCheckboxChecked}
                            onChange={() =>
                              handleSponsorbackCheckboxChange(
                                !sponsorbackCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Sponsorback"
                      />
                    )}
                    {autobaseOptionIsAvailable && (
                      <FormControlLabel
                        error={autobase.join(", ")}
                        control={
                          <Checkbox
                            checked={autobaseCheckboxChecked}
                            onChange={() =>
                              handleAutobaseCheckboxChange(
                                !autobaseCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Autobase"
                      />
                    )}
                    {imageRepairOptionIsAvailable && (
                      <FormControlLabel
                        error={imageRepair.join(", ")}
                        control={
                          <Checkbox
                            checked={imageRepairCheckboxChecked}
                            onChange={() =>
                              handleImageRepairCheckboxChange(
                                !imageRepairCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="ImageRepair"
                      />
                    )}
                  </FormControl>
                </div>
              </div>
              {isCutVinyl ? (
                <React.Fragment>
                  <div>
                    <Typography variant="subtitle2">
                      Vinyl Configuration
                    </Typography>
                    <div>
                      <FormControlLabel
                        error={names.join(", ")}
                        control={
                          <Checkbox
                            checked={vinylNamesCheckboxChecked}
                            onChange={() =>
                              handleVinylNamesCheckboxChange(
                                !vinylNamesCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Names"
                      />
                      <FormControlLabel
                        error={numbers.join(", ")}
                        control={
                          <Checkbox
                            checked={vinylNumbersCheckboxChecked}
                            onChange={() =>
                              handleVinylNumbersCheckboxChange(
                                !vinylNumbersCheckboxChecked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Numbers"
                      />
                      {(vinylNamesCheckboxChecked ||
                        vinylNumbersCheckboxChecked) && (
                        <React.Fragment>
                          <VinylCombobox
                            label="Vinyl Size"
                            options={vinylSizeOptions.map(option => ({
                              color: null,
                              id: option.id,
                              label: option.label,
                              value: option.value
                            }))}
                            errorText={size}
                            onSelect={handleVinylSizeComboboxSelect}
                          />
                          <VinylCombobox
                            label="Vinyl Color"
                            options={
                              data &&
                              data.vinylColors.map(vinylColor => ({
                                color: vinylColor.inkColor.color,
                                id: vinylColor.id,
                                label: vinylColor.inkColor.name,
                                value: vinylColor.id
                              }))
                            }
                            errorText={[...namesColorId, ...numbersColorId]}
                            onSelect={handleVinylColorSelectorSelect}
                          />
                          <VinylCombobox
                            label="Vinyl Font"
                            options={
                              data &&
                              data.vinylFonts.map(vinylFont => ({
                                color: null,
                                id: vinylFont.id,
                                label: vinylFont.alias,
                                value: vinylFont.id
                              }))
                            }
                            errorText={[...namesFontId, ...numbersFontId]}
                            onSelect={handleVinylFontSelectorSelect}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : colorOptionIsAvailable ? (
                <React.Fragment>
                  <div className={classes.inkColorContainer}>
                    <Typography variant="subtitle2">Colors</Typography>
                    <FormControlLabel
                      className={classes.splitIntoColorsCheckbox}
                      error={splitIntoColors.join(", ")}
                      control={
                        <Checkbox
                          checked={splitIntoColorsCheckboxChecked}
                          onChange={() =>
                            handleSplitIntoColorsCheckboxChange(
                              !splitIntoColorsCheckboxChecked
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Split into colors"
                    />
                  </div>
                  {splitIntoColorsCheckboxChecked ? (
                    <React.Fragment>
                      {colorSelections.length > 0 && (
                        <ul className={classes.list}>
                          {colorSelections.map(
                            ({ inkColor, pantoneColor }, index) => (
                              <li className={classes.listItem} key={index}>
                                <InkColorDetails
                                  inkColor={
                                    inkColor
                                      ? filter(
                                          inkColorDetailsFragments.inkColor,
                                          inkColor
                                        )
                                      : null
                                  }
                                  pantoneColor={
                                    pantoneColor
                                      ? filter(
                                          inkColorDetailsFragments.pantoneColor,
                                          pantoneColor
                                        )
                                      : null
                                  }
                                />
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      <Button
                        className={classes.addColorsButton}
                        color="secondary"
                        variant="contained"
                        onClick={handleAddColorsButtonClick}
                      >
                        {colorSelections.length > 0
                          ? "Update Colors"
                          : "Add Colors"}
                      </Button>
                      <InkColorModal
                        isOpen={isAddingColors}
                        inputErrors={inkColorSelectionInputErrors}
                        onRequestClose={handleAddColorsModalRequestClose}
                        onColorsAdded={handleAddColorsModalColorsAdded}
                        title="Add Ink Colors"
                      />
                    </React.Fragment>
                  ) : (
                    <div>
                      <TextField
                        className={classes.colorCount}
                        id="Color Count Input"
                        label="Color Count"
                        type="number"
                        onChange={handleColorCountInputChange}
                        variant="outlined"
                      />
                    </div>
                  )}
                </React.Fragment>
              ) : null}
              <FormControl fullWidth={true}>
                <TextField
                  helperText={note}
                  fullWidth={true}
                  label="Customization Area Notes"
                  onChange={handleCustomizationAreaNoteChange}
                  multiline={true}
                  variant="outlined"
                  value={customizationAreaNote}
                />
              </FormControl>
            </React.Fragment>
          )}
          <DialogActions>
            <Button
              color="secondary"
              disabled={isAddingCustomizationArea}
              onClick={handleCancelButtonClick}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isAddingCustomizationArea}
              onClick={handleFormSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default enhancer(AddCustomizationAreaModal);
