// @flow

const getAbbreviationForProductionJobMethod = method => {
  switch (method) {
    case "CUT_VINYL":
      return "VIN";
    case "PRINTED_VINYL":
      return "PVN";
    case "HEAT_PRESS":
      return "HEP";
    case "DIRECT_TO_GARMENT":
      return "DTG";
    case "HIGH_DEFINITION_DIGITAL":
      return "HDD";
    case "EMBROIDERY":
      return "EMB";
    case "TACKLE_TWILL":
      return "TAC";
    case "SCREEN_PRINTING":
      return "SCR";
    case "SUBLIMATION":
      return "SUB";
    default:
      return "";
  }
};

export { getAbbreviationForProductionJobMethod };
