// @flow

const getAbbreviationForProductionJobMethodLocation = method => {
  switch (method) {
    case "Front":
      return "F";
    case "Back":
      return "B";
    case "Hat Front":
      return "F";
    case "Inside Tag Line":
      return "T";
    case "Front-Left Chest":
      return "FLC";
    case "Front-Right Chest":
      return "FRC";
    case "Back Neckline":
      return "BN";
    case "Sleeve Left":
      return "SL";
    case "Sleeve Right":
      return "SR";
    case "Hat Back":
      return "HB";
    case "Side Left":
      return "LS";
    case "Side Right":
      return "RS";
    default:
      return "";
  }
};

export { getAbbreviationForProductionJobMethodLocation };
