// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { Image, Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import CustomsLabel from "../../../CustomsLabel";
import NinjaCustomsLogo from "../../../../assets/brand-logos/ninjacustoms-logo.png";
import NinjaPodLogo from "../../../../assets/brand-logos/ninjapod-logo.png";
import RushOrderTeesLogo from "../../../../assets/brand-logos/rushordertees-logo.png";
import SouthBySeaLogo from "../../../../assets/brand-logos/southbysea-logo.png";
import pluralize from "pluralize";

const isRushOrderTees = "rushordertees.com";
const isSouthBySea = "southbysea.com";
const isNinjaCustoms = "ninjacustom.com";
const isNinjaPod = "ninjapod.com";

const formatCustomerInitials = name => {
  // TODO: Move me
  return name.match(/\b(\w)/g).join("");
};

const styles = StyleSheet.create({
  barcode: {
    width: "1.25in",
    height: "1.25in"
  },
  barcodeWrapper: {
    flex: 2
  },
  brandLogo: {
    width: 50
  },
  cartons: {
    marginBottom: 4,
    fontSize: 10,
    textAlign: "center"
  },
  customizationAreaMethodLocation: {
    fontSize: 10
  },
  checkmarkIcon: {
    width: "0.25in",
    height: "0.25in"
  },
  expressIcon: {
    width: "0.5in",
    height: "0.5in"
  },
  expressText: {
    fontSize: 12,
    textAlign: "left",
    fontWeight: "bold",
    position: "absolute",
    bottom: 20,
    right: 20
  },
  expressTextLine: {
    fontSize: 12,
    fontWeight: "bold"
  },
  priorityIcon: {
    width: "0.25in",
    height: "0.25in"
  },
  resolution: {
    fontSize: 10,
    fontWeight: "bold",
    display: "block"
  },
  badgeContainer: {
    display: "flex",
    flexDirection: "column"
  },
  date: {
    fontSize: 11,
    lineHeight: 1.1
  },
  dateContainer: {
    marginBottom: 4
  },
  dateMethodLocationContainer: {
    flex: 1.3
  },
  label: {
    fontSize: 12
  },
  methodLocations: {
    display: "flex",
    flexDirection: "row"
  },
  page: {
    backgroundColor: "#ffffff",
    padding: 8
  },
  primaryCustomer: {
    fontSize: 12
  },
  primaryCustomerCheckmarkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  primaryCustomerCheckmarkLabelContainer: {
    flex: 2
  },
  primaryCustomerInitials: {
    fontSize: 34,
    textTransform: "uppercase"
  },
  quantitySizeContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 10,
    marginRight: 4,
    marginBottom: 8
  },
  quantitySizeWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 8
  },
  lastSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 2
  },
  styleColorContainer: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  totalProductVariantQuantity: {
    marginBottom: 4,
    fontSize: 10
  }
});

const ReceivingLabel = ({
  express,
  productionJob,
  customerName,
  isResolution,
  priority,
  isArtworkApproved,
  orderNumber,
  barcode,
  productionDate,
  dueBy,
  totalProductionJobs,
  productionStationAssignment,
  lineItems
}) => {
  const cartonCount =
    productionJob.inventoryOrderCartons &&
    productionJob.inventoryOrderCartons.length > 0
      ? [
          ...new Set(
            productionJob.inventoryOrderCartons.map(({ name }) => name)
          )
        ].length
      : 0;
  return (
    <View style={styles.page}>
      {cartonCount > 0 && (
        <Text style={styles.cartons}>
          {`Carton 1 of ${cartonCount} ${
            cartonCount === 1
              ? "Listo por HDD Cubes"
              : "Count & Return to Receiving"
          }`}
        </Text>
      )}
      <View style={styles.section}>
        <View style={styles.primaryCustomerCheckmarkLabelContainer}>
          <View style={styles.primaryCustomerCheckmarkContainer}>
            <Text style={styles.primaryCustomerInitials}>
              {formatCustomerInitials(customerName)}
            </Text>
            <View style={styles.badgeContainer}>
              {express ? ( // Express
                <Svg style={styles.expressIcon} viewBox="0 0 600 600">
                  <Path
                    d="M150.463,109.521h150.512c3.955,0,7.16-3.206,7.16-7.161c0-3.955-3.205-7.161-7.16-7.161H150.463
      c-3.955,0-7.161,3.206-7.161,7.161C143.302,106.315,146.508,109.521,150.463,109.521z"
                    fill="black"
                  />
                  <Path
                    d="M15.853,179.537h150.511c3.955,0,7.161-3.206,7.161-7.161s-3.206-7.16-7.161-7.16H15.853
      c-3.955,0-7.161,3.205-7.161,7.16S11.898,179.537,15.853,179.537z"
                    fill="black"
                  />
                  <Path
                    d="M56.258,253.214c0,3.955,3.206,7.162,7.161,7.162H213.93c3.955,0,7.161-3.207,7.161-7.162s-3.206-7.16-7.161-7.16H63.419
      C59.464,246.054,56.258,249.259,56.258,253.214z"
                    fill="black"
                  />
                  <Path
                    d="M142.396,336.44H7.161C3.206,336.44,0,339.645,0,343.6s3.206,7.161,7.161,7.161h135.235c3.955,0,7.161-3.206,7.161-7.161
      S146.351,336.44,142.396,336.44z"
                    fill="black"
                  />
                  <Path
                    d="M385.729,154.418c21.6,0,39.111-17.513,39.111-39.114s-17.512-39.113-39.111-39.113
      c-21.605,0-39.119,17.513-39.119,39.113C346.609,136.905,364.123,154.418,385.729,154.418z"
                    fill="black"
                  />
                  <Path
                    d="M450.066,143.155c-22.459,31.459-52.533,35.102-84.895,15.89c-2.203-1.306-11.977-6.691-14.141-7.977
      c-52.061-30.906-104.061-18.786-138.934,30.05c-14.819,20.771,19.455,40.459,34.108,19.93
      c18.018-25.232,40.929-32.533,65.986-24.541c-12.83,22.27-24.047,44.405-39.875,75.853
      c-15.832,31.448-50.787,56.562-84.374,36.92c-24.235-14.165-46.09,20.651-21.928,34.772
      c45.854,26.799,99.619,10.343,127.066-24.493c0.952,0.509,1.958,0.968,3.062,1.354c22.422,7.812,51.814,28.61,60.77,35.981
      c8.953,7.371,24.336,44.921,33.471,63.788c11.082,22.893,46.871,6.219,35.748-16.771c-10.355-21.406-27.736-64.129-41.293-74.938
      c-10.875-8.669-31.988-24.803-49.895-33.956c12.115-23.466,24.729-46.679,38.008-69.491
      c42.328,12.969,82.561-2.308,111.215-42.446C498.996,142.312,464.73,122.624,450.066,143.155z"
                    fill="black"
                  />
                </Svg>
              ) : null}
              {isResolution && <Text style={styles.resolution}>RESO</Text>}
              {priority ? (
                <Svg style={styles.priorityIcon} viewBox="0 0 250 250">
                  <Path
                    d="m55,237 74-228 74,228L9,96h240"
                    fill="red"
                    stroke="red"
                  />
                </Svg>
              ) : null}
              {isArtworkApproved ? (
                <Svg style={styles.checkmarkIcon} viewBox="0 0 600 600">
                  <Path
                    d="M 25.257828,388.61333 L 150.45625,554.27704 C 187.80366,592.27704 230.15106,592.27704 244.49846,554.27704 C 304.95799,413.59515 467.18948,152.15947 574.3879,75.207333 C 603.02112,44.393193 556.09708,2.2137629 520.0424,22.567643 C 442.55598,59.297633 219.55415,375.7357 190.73563,465.21994 L 95.906641,334.51412 C 61.357035,284.54719 -7.1925678,340.58026 25.257828,388.61333 z "
                    fill="green"
                  />
                </Svg>
              ) : null}
            </View>
          </View>
          <Text>#{orderNumber}</Text>
          <Text style={styles.primaryCustomer}>{customerName}</Text>
          <Text style={styles.label}>{productionJob.label}</Text>
          {productionJob.order.primaryCustomer.businessUnit.name ===
            isRushOrderTees && (
            <Image style={styles.brandLogo} src={RushOrderTeesLogo} />
          )}
          {productionJob.order.primaryCustomer.businessUnit.name ===
            isSouthBySea && (
            <Image style={styles.brandLogo} src={SouthBySeaLogo} />
          )}
          {productionJob.order.primaryCustomer.businessUnit.name ===
            isNinjaCustoms && (
            <Image style={styles.brandLogo} src={NinjaCustomsLogo} />
          )}
          {productionJob.order.primaryCustomer.businessUnit.name ===
            isNinjaPod && (
            <Image style={styles.brandLogo} src={NinjaPodLogo} />
          )}
        </View>
        <View style={styles.barcodeWrapper}>
          <Image src={barcode} style={styles.barcode} />
        </View>
        <View style={styles.dateMethodLocationContainer}>
          <View style={styles.dateContainer}>
            {productionDate && (
              <Text style={styles.date}>
                {`p ${DateTime.fromISO(productionDate).toLocaleString({
                  month: "numeric",
                  day: "numeric",
                  year: "2-digit"
                })}`}
              </Text>
            )}
            {dueBy && (
              <Text style={styles.date}>
                {`d ${DateTime.fromISO(dueBy).toLocaleString({
                  month: "numeric",
                  day: "numeric",
                  year: "2-digit"
                })}`}
              </Text>
            )}
          </View>
          <Text style={styles.totalProductVariantQuantity}>
            {`Tab ${productionJob.index} of ${
              totalProductionJobs
                ? totalProductionJobs
                : productionJob.order.productionJobs.length
            } (${pluralize(
              "pc",
              productionJob.totalProductVariantQuantity,
              true
            )})`}
          </Text>

          <View style={styles.customizationAreaMethodLocation}>
            {productionJob.printLocationsByMethod.map(
              ({ method, locations }, index) => (
                <View key={index} style={styles.methodLocations}>
                  <Text>{`${method}: `}</Text>
                  <Text>
                    {locations.map((location, index) => (
                      <Text key={index} style={styles.location}>
                        {location}
                        {index < locations.length - 1 ? "," : ""}
                      </Text>
                    ))}
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
      </View>
      <View style={styles.lastSection}>
        {productionStationAssignment && (
          <View>
            <Text style={styles.primaryCustomer}>
              Station: {productionStationAssignment}
            </Text>
          </View>
        )}
      </View>
      {lineItems && lineItems.length > 0 && <CustomsLabel data={lineItems} />}
    </View>
  );
};

export default ReceivingLabel;
