// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as localInventoryTableFragments } from "./components/LocalInventoryTable/graph";
import { markOrderProductionJobAsLocalInventoryReceived } from "../../../graph";
import { playAudioForReturn } from "../../../helpers/playAudioForReturn";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { query } from "./graph";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../../components/CenteredSpinner";
import ExpeditingLabelViewer from "../../../components/ExpeditingLabelViewer";
import LocalInventoryDetailView from "./components/LocalInventoryDetailView";
import LocalInventoryTable from "./components/LocalInventoryTable";
import MenuItem from "@mui/material/MenuItem";
import PendingStatusView from "../../../components/PendingStatusView";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withDrawer from "../../../components/withDrawer";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {||};

type State = {|
  hasNextPage: boolean,
  isLoading: boolean,
  isLoadingMore: boolean,
  loadingProductionJobIds: Array<string>,
  searchQuery: ?string,
  selectedProductionDate: ?string,
  selectedProductionJobId: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string,
  showAllSwitchValue: boolean
|};

const ORDERS_PER_PAGE = 20;

const enhancer: HOC<*, Props> = compose(
  setDisplayName("LocalInventoryApp"),

  withRouter,

  withSnackbar,

  markOrderProductionJobAsLocalInventoryReceived,

  withStateHandlers(
    ({
      businessUnitId: null,
      currentLocation: null,
      customizationMethod: null,
      hasNextPage: true,
      isLoading: false,
      isLoadingMore: false,
      loadingProductionJobIds: [],
      rushOrdersSwitchValue: false,
      searchQuery: null,
      selectedProductionDate: null,
      selectedProductionJobId: null,
      selectedSortBy: null,
      selectedSortDirection: null,
      showAllSwitchValue: false
    }: State),
    {
      setCurrentLocation: () => (currentLocation: string) => ({
        currentLocation
      }),

      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedProductionJobId: () => (selectedProductionJobId: ?string) => ({
        selectedProductionJobId
      }),

      setSelectedProductionDate: () => (selectedProductionDate: ?string) => ({
        selectedProductionDate
      }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      addLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => ({
          loadingProductionJobIds: [...loadingProductionJobIds, productionJobId]
        }),

      removeLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => {
          const indexToRemove =
            loadingProductionJobIds.indexOf(productionJobId);
          return {
            loadingProductionJobIds: [
              ...loadingProductionJobIds.slice(0, indexToRemove),
              ...loadingProductionJobIds.slice(
                indexToRemove + 1,
                loadingProductionJobIds.length
              )
            ]
          };
        },

      setShowAllSwitchValue: () => (showAllSwitchValue: boolean) => ({
        showAllSwitchValue
      }),

      setRushOrdersSwitchValue: () => (rushOrdersSwitchValue: boolean) => ({
        rushOrdersSwitchValue
      }),

      setCustomizationMethod: () => (customizationMethod: string) => ({
        customizationMethod
      }),

      setBusinessUnitId: () => (businessUnitId: string) => ({
        businessUnitId
      })
    }
  ),

  graphql(query, {
    options: ({
      businessUnitId,
      customizationMethod,
      searchQuery,
      selectedProductionDate,
      selectedSortDirection,
      selectedSortBy,
      showAllSwitchValue,
      rushOrdersSwitchValue,
      filters = {}
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          ...filters,
          businessUnitId: businessUnitId,
          customizationMethod: customizationMethod,
          includeReceived: showAllSwitchValue,
          productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection,
          ...(rushOrdersSwitchValue ? { paidShipping: true } : {})
        }
      }
    })
  }),

  withHandlers({
    receiveLocalInventoryForProductionJob:
      ({
        currentLocation,
        markOrderProductionJobAsLocalInventoryReceived,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        if (!currentLocation) {
          showErrorSnackbar("No Location Set");
          playAudioForReturn();
          return;
        }
        return markOrderProductionJobAsLocalInventoryReceived({
          variables: {
            orderProductionJobId,
            location: currentLocation
          }
        }).then(
          ({
            data: {
              markOrderProductionJobAsLocalInventoryReceived: {
                succeeded,
                errors,
                updatedOrderProductionJob
              }
            }
          }) => {
            if (succeeded) {
              window.analytics.track("Local Inventory Received", {
                orderProductionJobId,
                canonicalOrderProductionJobId:
                  atob(orderProductionJobId).split(":")[1]
              });
              showSuccessSnackbar(
                `Order production job ${updatedOrderProductionJob.label} has been received & order has been staged`
              );
              playAudioForSuccess();
            } else {
              showErrorSnackbar(
                [...errors.orderProductionJobId, ...errors.location].join(", ")
              );
              playAudioForReturn();
            }
          }
        );
      }
  }),

  withHandlers({
    handleRequestLocalInventory:
      ({
        addLoadingProductionJobId,
        receiveLocalInventoryForProductionJob,
        removeLoadingProductionJobId
      }) =>
      (orderProductionJobId: string) => {
        addLoadingProductionJobId(orderProductionJobId);
        receiveLocalInventoryForProductionJob(orderProductionJobId);
        removeLoadingProductionJobId(orderProductionJobId);
      }
  }),

  withDrawer(
    "Job Details",
    ({
      selectedProductionJobId,
      handleRequestLocalInventory,
      loadingProductionJobIds,
      setDisplayState
    }) => (
      <LocalInventoryDetailView
        productionJobId={selectedProductionJobId}
        onRequestLocalInventory={(selectedProductionJobId: string) => {
          setDisplayState("closed");
          handleRequestLocalInventory(selectedProductionJobId);
        }}
        isPullingFromStock={loadingProductionJobIds.includes(
          selectedProductionJobId
        )}
      />
    )
  ),

  withHandlers(() => {
    let printRef;
    return {
      handlePrintRef: () => ref => {
        printRef = ref;
      },

      handleLocalInventoryTableRowClicked:
        ({ setDisplayState, setSelectedProductionJobId }) =>
        (productionJobId: string) => {
          setSelectedProductionJobId(productionJobId);
          setDisplayState("open-partial");
        },

      handleAppHeaderRequestBack:
        ({ router, backUrl }) =>
        () => {
          router.push(backUrl ? backUrl : "/apps");
        },

      handleAppHeaderTabSelected:
        ({
          businessUnitId,
          customizationMethod,
          data,
          setHasNextPage,
          setSelectedProductionDate,
          showAllSwitchValue,
          rushOrdersSwitchValue,
          filters = {}
        }) =>
        (selectedProductionDate: string) => {
          setHasNextPage(true);
          setSelectedProductionDate(selectedProductionDate);
          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              ...filters,
              businessUnitId: businessUnitId,
              customizationMethod: customizationMethod,
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includeReceived: showAllSwitchValue,
              ...(rushOrdersSwitchValue ? { paidShipping: true } : {})
            }
          });
        },

      handleSearch:
        ({ setSearchQuery, setSelectedProductionJobId }) =>
        searchQuery => {
          setSelectedProductionJobId(null);
          setSearchQuery(searchQuery);
        },

      handleShowAllSwitchChanged:
        ({
          businessUnitId,
          setShowAllSwitchValue,
          customizationMethod,
          rushOrdersSwitchValue,
          data,
          selectedProductionDate,
          setHasNextPage,
          filters = {}
        }) =>
        (event, showAll: boolean) => {
          setShowAllSwitchValue(showAll);
          setHasNextPage(true);

          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              ...filters,
              businessUnitId: businessUnitId,
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includeReceived: showAll,
              customizationMethod: customizationMethod,
              ...(rushOrdersSwitchValue ? { paidShipping: true } : {})
            }
          });
        },

      handleRushOrdersSwitchChanged:
        ({
          businessUnitId,
          setRushOrdersSwitchValue,
          customizationMethod,
          data,
          selectedProductionDate,
          setHasNextPage,
          showAllSwitchValue,
          filters = {}
        }) =>
        (event, rushOrders: boolean) => {
          setRushOrdersSwitchValue(rushOrders);
          setHasNextPage(true);

          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              ...filters,
              businessUnitId: businessUnitId,
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includeReceived: showAllSwitchValue,
              customizationMethod: customizationMethod,
              ...(rushOrders ? { paidShipping: true } : {})
            }
          });
        },

      handleBusinessUnitIdSelectChanged:
        ({
          businessUnitId,
          setBusinessUnitId,
          data,
          selectedProductionDate,
          setHasNextPage,
          showAllSwitchValue,
          filters = {}
         }) => ({ target: { value } }) => {
        setBusinessUnitId(value);
        setHasNextPage(true);

        data.refetch({
          first: ORDERS_PER_PAGE,
          filters: {
            ...filters,
            businessUnitId: businessUnitId,
            productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
            includeReceived: showAllSwitchValue,
            customizationMethod: value
          }
        });
      },

      handleCustomizationMethodSelectChanged:
        ({
          businessUnitId,
          setCustomizationMethod,
          data,
          selectedProductionDate,
          setHasNextPage,
          showAllSwitchValue,
          filters = {}
        }) =>
        ({ target: { value } }) => {
          setCustomizationMethod(value);
          setHasNextPage(true);

          data.refetch({
            first: ORDERS_PER_PAGE,
            filters: {
              ...filters,
              businessUnitId: businessUnitId,
              productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
              includeReceived: showAllSwitchValue,
              customizationMethod: value
            }
          });
        },

      handlePrintLabelButtonClick:
        () => (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          // TODO: Might have to get cute about printing all of these
          if (printRef) {
            printRef();
          }
        },

      handleBarcodeError:
        ({ showErrorSnackbar, setIsLoading }) =>
        () => {
          showErrorSnackbar("Error scanning barcode");
          setIsLoading(false);
        },

      handleBarcodeScan:
        ({
          currentLocation,
          receiveLocalInventoryForProductionJob,
          setCurrentLocation,
          showErrorSnackbar
        }) =>
        barcode => {
          try {
            const data = JSON.parse(barcode);
            const { variant, type, id, name } = data;
            if (
              variant === "LOCATION" &&
              type.toLowerCase() === "box" &&
              name
            ) {
              setCurrentLocation(name);
            } else if (
              variant === "OBJECT" &&
              type === "OrderProductionJob" &&
              id
            ) {
              if (!currentLocation) {
                showErrorSnackbar("No Location Set");
                return;
              }
              receiveLocalInventoryForProductionJob(id);
            } else {
              console.log(barcode); // eslint-disable-line no-console
              showErrorSnackbar(`Scanned invalid type: ${type}`);
            }
          } catch (xxx) {
            console.log(xxx); // eslint-disable-line no-console
            showErrorSnackbar("Error Scanning Barcode");
          }
        },

      handleLocalInventoryTableScrolledToBottom:
        ({
          businessUnitId,
          data,
          hasNextPage,
          isLoadingMore,
          selectedProductionDate,
          setHasNextPage,
          setIsLoadingMore,
          showAllSwitchValue,
          customizationMethod,
          filters = {}
        }) =>
        () => {
          if (isLoadingMore || !hasNextPage) {
            return;
          }

          setIsLoadingMore(true);
          data
            .fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges =
                  fetchMoreResult
                    .orderProductionJobsToBeReceivedFromLocalInventory.edges;
                const pageInfo =
                  fetchMoreResult
                    .orderProductionJobsToBeReceivedFromLocalInventory.pageInfo;
                setHasNextPage(pageInfo.hasNextPage);
                return newEdges.length
                  ? {
                      orderProductionJobsToBeReceivedFromLocalInventory: {
                        pageInfo,
                        __typename:
                          previousResult
                            .orderProductionJobsToBeReceivedFromLocalInventory
                            .__typename,
                        edges: [
                          ...previousResult
                            .orderProductionJobsToBeReceivedFromLocalInventory
                            .edges,
                          ...newEdges
                        ],
                        productionDates:
                          fetchMoreResult
                            .orderProductionJobsToBeReceivedFromLocalInventory
                            .productionDates
                      }
                    }
                  : previousResult;
              },
              variables: {
                after:
                  data.orderProductionJobsToBeReceivedFromLocalInventory
                    .pageInfo.endCursor,
                filters: {
                  ...filters,
                  businessUnitId: businessUnitId,
                  productionDate: selectedProductionDate === "All" ? null : selectedProductionDate,
                  includeReceived: showAllSwitchValue,
                  customizationMethod: customizationMethod
                }
              }
            })
            .finally(() => setIsLoadingMore(false));
        },

      handleSort:
        ({
          selectedSortBy,
          setSortDirection,
          setSortBy,
          selectedSortDirection
        }) =>
        (newSelectedSortBy: string) => {
          setSortBy(newSelectedSortBy);
          if (selectedSortBy === newSelectedSortBy) {
            selectedSortDirection === "ASCENDING"
              ? setSortDirection("DESCENDING")
              : setSortDirection("ASCENDING");
          } else {
            setSortDirection("ASCENDING");
          }
        }
    };
  }),

  withProps(({ data }) => {
    if (data.orderProductionJobsToBeReceivedFromLocalInventory) {
      let formattedProductionDates =
        data.orderProductionJobsToBeReceivedFromLocalInventory.productionDates.map(( productionDate ) => {
          return {
            value: productionDate,
            label: DateTime.fromISO(productionDate).toLocaleString(DateTime.DATE_SHORT)
          };
        });
      formattedProductionDates = [{value: "All", label: "All"}].concat(formattedProductionDates);

      return {
        formattedProductionDates
      };
    }
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { data, setSelectedProductionJobId, setDisplayState } = this.props;
      if (!data.loading && !data.error) {
        if (
          data.orderProductionJobsToBeReceivedFromLocalInventory.edges
            .length === 1 &&
          data.orderProductionJobsToBeReceivedFromLocalInventory.edges[0].node
            .id !== prevProps.selectedProductionJobId
        ) {
          setSelectedProductionJobId(
            data.orderProductionJobsToBeReceivedFromLocalInventory.edges[0].node
              .id
          );
          setDisplayState("open-partial");
        }
      }
    }
  })
);

const LocalInventoryApp = ({
  businessUnitId,
  customizationMethod,
  data,
  formattedProductionDates,
  handleAppHeaderRequestBack,
  handleAppHeaderTabSelected,
  handleBarcodeError,
  handleBarcodeScan,
  handleRequestLocalInventory,
  handlePrintRef,
  handlePrintLabelButtonClick,
  handleLocalInventoryTableRowClicked,
  handleLocalInventoryTableScrolledToBottom,
  handleSearch,
  handleSort,
  handleShowAllSwitchChanged,
  handleCustomizationMethodSelectChanged,
  handleBusinessUnitIdSelectChanged,
  hasNextPage,
  isLoadingMore,
  isLoading,
  loadingProductionJobIds,
  showAllSwitchValue,
  currentLocation,
  appBarBackgroundColor,
  handleRushOrdersSwitchChanged,
  rushOrdersSwitchValue
}) => {
  return (
    <div>
      <BarcodeReader onError={handleBarcodeError} onScan={handleBarcodeScan} />
      <TabbedAppBar
        title={`Local Inventory${
          currentLocation
            ? ` - Current Location: ${currentLocation}`
            : " - Location Not Set"
        }`}
        onRequestBack={handleAppHeaderRequestBack}
        appBarBackgroundColor={appBarBackgroundColor}
        tabItems={
          data && data.orderProductionJobsToBeReceivedFromLocalInventory
            ? formattedProductionDates
            : []
        }
        onSearch={handleSearch}
        onTabSelected={handleAppHeaderTabSelected}
        menuItems={[
          <MenuItem>
            Show All
            <Switch
              checked={showAllSwitchValue}
              onChange={handleShowAllSwitchChanged}
              color="primary"
            />
          </MenuItem>,
          <MenuItem>
            Rush Orders Only
            <Switch
              checked={rushOrdersSwitchValue}
              onChange={handleRushOrdersSwitchChanged}
              color="primary"
            />
          </MenuItem>,
          <MenuItem>
            <Select
              value={customizationMethod}
              label="Customization Method"
              onChange={handleCustomizationMethodSelectChanged}
              fullWidth={true}
              placeholder="Customization Method"
              size="small"
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={"CUT_VINYL"}>Cut vinyl</MenuItem>
              <MenuItem value={"DIRECT_TO_GARMENT"}>Direct-to-garment</MenuItem>
              <MenuItem value={"EMBROIDERY"}>Embroidery</MenuItem>
              <MenuItem value={"HEAT_PRESS"}>Heat press</MenuItem>
              <MenuItem value={"HIGH_DEFINITION_DIGITAL"}>
                High Definition Digital
              </MenuItem>
              <MenuItem value={"SCREEN_PRINTING"}>Screen printing</MenuItem>
              <MenuItem value={"TACKLE_TWILL"}>Tackle twill</MenuItem>
            </Select>
          </MenuItem>,
          <MenuItem>
            <Select
              fullWidth={true}
              size="small"
              placeholder="Business Unit"
              label="Business Unit"
              onChange={handleBusinessUnitIdSelectChanged}
              value={businessUnitId}
            >
              {data && data.businessUnits && (
                data.businessUnits.map(({id, name}) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))
              )}
            </Select>
          </MenuItem>,
          <MenuItem>
            <Button
              onClick={handlePrintLabelButtonClick}
              color="primary"
              style={{ width: "100%" }}
            >
              Print All
            </Button>
          </MenuItem>
        ]}
      />
      {data.loading || isLoading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography variant="body2" color="error">
          {data.error.message}
        </Typography>
      ) : (
        <div>
          {data && data.orderProductionJobsToBeReceivedFromLocalInventory && (
            <ExpeditingLabelViewer
              productionJobs={
                data.orderProductionJobsToBeReceivedFromLocalInventory.edges.map(
                  edge => ({
                    ...edge.node
                  })
                )
              }
              printRef={handlePrintRef}
              hidden={true}
              shipMissCosts={[]}
            />
          )}
          <LocalInventoryTable
            loadingProductionJobIds={loadingProductionJobIds}
            onScrolledToBottom={handleLocalInventoryTableScrolledToBottom}
            productionJobs={filter(
              localInventoryTableFragments.productionJob,
              data.orderProductionJobsToBeReceivedFromLocalInventory.edges.map(
                edge => ({
                  ...edge.node
                })
              )
            )}
            onRequestLocalInventory={handleRequestLocalInventory}
            onRequestSort={handleSort}
            onRowClicked={handleLocalInventoryTableRowClicked}
          />
          {isLoadingMore && hasNextPage && <CenteredSpinner />}
        </div>
      )}
    </div>
  );
};

export default enhancer(LocalInventoryApp);
