// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  setDisplayName,
  withHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FixPieceRequestItemTable from "../FixPieceRequestItemTable";
import NotesCollectionBreakdown from "../../../../../components/NotesCollectionBreakdown";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +autoPrint: boolean,
  +classes?: {|
    +button: string,
    +descriptionList: string,
    +descriptionTag: string,
    +descriptionWrapper: string,
    +divider: string,
    +labelWrapper: string,
    +loadingWrapper: string,
    +orderNumberTotalBlindShippedWrapper: string,
    +productionDueDateWrapper: string,
    +productionJobLineItemBreakdownTable: string,
    +requestedByEmployeeInfoWrapper: string,
    +requestedByEmployeeName: string,
    +wrapper: string
  |},
  +fixPieceRequestId: ?string,
  +isOrderingGoods: boolean,
  +onCanceled: (fixPieceRequestId: string) => void,
  +onGoodsOrdered: (fixPieceRequestId: string) => void,
  +onRegisterManualOrder: (fixPieceRequestId: string) => void
|};

const styles = theme => ({
  button: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },

  descriptionList: {
    alignItems: "center",
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  labelWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  requestedByEmployeeInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },

  requestedByEmployeeName: {
    marginRight: theme.spacing(1)
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  productionJobLineItemBreakdownTable: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  wrapper: {
    padding: theme.spacing(2)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("FixPieceRequestDetailView"),

  withSnackbar,

  withStyles(styles),

  withHandlers(() => {
    return {
      handleCancelButtonClick:
        ({ onCanceled }) =>
        (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          onCanceled(event.currentTarget.id);
        },
      handleOrderGoodsButtonClick:
        ({ onGoodsOrdered }) =>
        (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          onGoodsOrdered(event.currentTarget.id);
        },
      handleRegisterManualOrderButtonClicked:
        ({ onRegisterManualOrder }) =>
        (event: SyntheticInputEvent<HTMLInputElement>) => {
          event.stopPropagation();
          onRegisterManualOrder(event.currentTarget.id);
        },
      };
    }),

  graphql(query, {
    skip: ({ fixPieceRequestId }) => !fixPieceRequestId
  })
);

const pluralize = require("pluralize");

const FixPieceRequestDetailView = ({
  classes,
  data,
  handleRegisterManualOrderButtonClicked,
  handleCancelButtonClick,
  handleOrderGoodsButtonClick,
  isManuallyOrderingFixPieceRequestId,
  isOrderingGoods
}) => {
  return (
    <div>
      {!data ? (
        <div />
      ) : data.loading ? (
        <PendingStatusView status="Loading" />
      ) : data.error ? (
        <Typography variant="body2" color="error">
          {data.error.message}
        </Typography>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.labelWrapper}>
            <Typography
              className={classes.label}
              variant="body1"
            >
              {data.fixPieceRequest.orderProductionJob.label}
            </Typography>
          </div>

          <div className={classes.descriptionWrapper}>
            <Typography color="textSecondary" variant="subtitle2">
              {data.fixPieceRequest.description}
            </Typography>
          </div>

          <div>
            <div className={classes.requestedByEmployeeInfoWrapper}>
              <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Requested By:
              </Typography>
              <Typography
                className={classes.requestedByEmployeeName}
                variant="body1"
              >
                {data.fixPieceRequest.requestedByEmployee && (data.fixPieceRequest.requestedByEmployee.name)}
              </Typography>
            </div>
          </div>

          {data.fixPieceRequest.items.length > 0 && (
            <React.Fragment>
              <div
                className={classes.productionJobLineItemBreakdownTable}
              >
                <FixPieceRequestItemTable
                  lineItems={data.fixPieceRequest.items}
                />
              </div>
            </React.Fragment>
          )}

          {isOrderingGoods ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <React.Fragment>
              <Button
                className={classes.button}
                disabled={data.fixPieceRequest.hasBeenOrdered || !data.fixPieceRequest.canViewerPurchase}
                id={data.fixPieceRequest.id}
                variant="contained"
                color="primary"
                onClick={handleOrderGoodsButtonClick}
              >
                Order Goods
              </Button>
              {isManuallyOrderingFixPieceRequestId ? (
                <div className={classes.loadingWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  className={classes.button}
                  disabled={data.fixPieceRequest.hasBeenOrdered || !data.fixPieceRequest.canViewerPurchase}
                  id={data.fixPieceRequest.id}
                  variant="contained"
                  color="primary"
                  onClick={handleRegisterManualOrderButtonClicked}
                >
                  Register Manual Order
                </Button>
              )}
              <Button
                className={classes.button}
                disabled={data.fixPieceRequest.hasBeenOrdered || !data.fixPieceRequest.canViewerCancel}
                id={data.fixPieceRequest.id}
                variant="contained"
                color="primary"
                onClick={handleCancelButtonClick}
              >
                Cancel Request
              </Button>
            </React.Fragment>
          )}

          <Divider light={true} className={classes.divider} />

          <div className={classes.orderNumberTotalBlindShippedWrapper}>
            <div>
              <Typography color="textSecondary" variant="subtitle2">
                {data.fixPieceRequest.orderProductionJob.order.orderNumber}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {`${pluralize(
                  "Piece",
                  data.fixPieceRequest.orderProductionJob.order.totalProductVariantQuantity,
                  true
                )}`}
              </Typography>
            </div>
          </div>

          <div className={classes.productionDueDateWrapper}>
            {data.fixPieceRequest.orderProductionJob.order.productionDate && (
              <div className={classes.descriptionList}>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Production Date:
                </Typography>
                <PriorityChip
                  date={data.fixPieceRequest.orderProductionJob.order.productionDate}
                  priority={data.fixPieceRequest.orderProductionJob.order.priority}
                />
              </div>
            )}
            {data.fixPieceRequest.orderProductionJob.order.dueBy.dateTime && (
              <div className={classes.descriptionList}>
                <Typography
                  className={classes.descriptionTag}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Due Date:
                </Typography>
                <Typography variant="subtitle2">
                  {DateTime.fromISO(
                    data.fixPieceRequest.orderProductionJob.order.dueBy.dateTime
                  ).toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
              </div>
            )}
          </div>
          <Divider light={true} className={classes.divider} />
          <Typography color="textSecondary" variant="subtitle2">
            Production Notes:
          </Typography>
          <NotesCollectionBreakdown
            notesCollection={data.fixPieceRequest.orderProductionJob.order.productionNotesCollection}
          />
        </div>
      )}
    </div>
  );
};

export default enhancer(FixPieceRequestDetailView);
