// @flow

import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

type Props = {|
  +author: string,
  +classes: {|
    +noteAuthorStyles: string,
    +noteContent: string,
    +noteSpacing: string
  |},
  +createdAt: string,
  +note: string
|};
const styles = theme => ({
  noteContent: {
    marginBottom: theme.spacing(1)
  },
  noteAuthorStyles: {
    fontSize: "0.650rem",
    color: "grey"
  },
  noteSpacing: {
    marginBottom: theme.spacing(3)
  }
});

const FormattedNote = ({ author, createdAt, note, classes }: Props) => (
  <div className={classes.noteSpacing}>
    {note.split("\n").map((line, index) => (
      <Typography key={index} className={classes.noteContent}>
        {line}
      </Typography>
    ))}
    <Typography className={classes.noteAuthorStyles}>
      {`-${author} (${DateTime.fromISO(createdAt, {
        setZone: true
      }).toLocaleString(DateTime.DATETIME_SHORT)})`}
    </Typography>
  </div>
);

export default withStyles(styles)(FormattedNote);
