// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { createManualInventoryOrderForFixPieceRequest } from "../../../../../graph";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +cell: string,
    +descriptionList: string,
    +descriptionTag: string,
    +descriptionWrapper: string,
    +divider: string,
    +labelWrapper: string,
    +orderNumberTotalBlindShippedWrapper: string,
    +productionDueDateWrapper: string,
    +quantityCell: string,
    +requestedByEmployeeInfoWrapper: string,
    +requestedByEmployeeName: string
  |},
  +fixPieceRequestId: ?string,
  +isOpen: boolean,
  +onComplete: () => void,
  +onRequestClose: () => void
|};

const styles = theme => ({
  cell: {
    padding: 5,
    "padding-top": 15,
    "vertical-align": "top"
  },

  descriptionList: {
    alignItems: "center",
    display: "flex"
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  labelWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  quantityCell: {
    padding: 5,
    width: "150px"
  },

  requestedByEmployeeInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },

  requestedByEmployeeName: {
    marginRight: theme.spacing(1)
  }
});

type InputErrors = {|
  lineItems: $ReadOnlyArray<string>,
  lineItemsInput: {|
    fixPieceRequestItemId: $ReadOnlyArray<string>,
    quantity: $ReadOnlyArray<string>
  |},
  purchaseOrderNumber: $ReadOnlyArray<string>,
  vendorId: $ReadOnlyArray<string>
|};

const defaultInputErrors: InputErrors = {
  lineItems: [],
  lineItemsInput: {
    fixPieceRequestItemId: [],
    quantity: []
  },
  purchaseOrderNumber: [],
  vendorId: []
};

type State = {|
  generalErrors: $ReadOnlyArray<string>,
  inputErrors: InputErrors,
  isCreatingManualInventoryOrder: boolean,
  lineItemInputValues: ?object,
  poNumberInputValue: string,
  vendorIdSelection: ?string
|};

const defaultState: State = {
  inputErrors: {
    ...defaultInputErrors
  },
  isCreatingManualInventoryOrder: false,
  generalErrors: [],
  lineItemInputValues: {},
  poNumberInputValue: "",
  vendorIdSelection: null
};

const buildDefaultState = () => {
  return  {
    ...defaultState,
    inputErrors: {
      ...defaultInputErrors
    },
    lineItemInputValues: {}
  };
}

const enhancer: HOC<*, Props> = compose(
  setDisplayName("RegisterManualOrderModal"),

  graphql(query, {
    skip: ({ fixPieceRequestId, isOpen }) => !isOpen || !fixPieceRequestId
  }),

  createManualInventoryOrderForFixPieceRequest,

  withStyles(styles),

  withStateHandlers(() => buildDefaultState(), {
    resetForm: () => () => {
      return buildDefaultState();
    },

    resetGeneralErrors: () => () => ({ generalErrors: [] }),

    resetInputErrors: () => () => ({
      inputErrors: {
        ...defaultInputErrors
      }
    }),

    setIsCreatingManualInventoryOrder: () => (
      isCreatingManualInventoryOrder: boolean
    ) => ({ isCreatingManualInventoryOrder }),

    setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
      generalErrors
    }),

    setInputErrors: () => (inputErrors: InputErrors) => ({ inputErrors }),

    setPoNumberInputValue: () => (poNumberInputValue: string) => ({
      poNumberInputValue
    }),

    setLineItemInputValues: ({
      lineItemInputValues
    }) => (id: string, quantity: number) => {
      lineItemInputValues[id] = quantity;
      return {
        lineItemInputValues: lineItemInputValues
      };
    },

    setPurchaseOrderNumberError: ({
      inputErrors
    }) => (error: string) => ({
      inputErrors: {
        ...inputErrors,
        purchaseOrderNumber: [error]
      }
    }),

    setVendorIdError: ({
      inputErrors
    }) => (error: string) => ({
      inputErrors: {
        ...inputErrors,
        vendorId: [error]
      }
    }),

    setVendorIdSelection: () => (vendorIdSelection: ?string) => ({
      vendorIdSelection
    })
  }),

  withHandlers({
    handleCancelButtonClick: ({ onRequestClose }) => () => {
      if (onRequestClose) {
        onRequestClose();
      }
    },

    handleLineItemQuantityChange: ({
      setLineItemInputValues
    }) => (event: SyntheticEvent<HTMLFormElement>) => {
      setLineItemInputValues(event.target.id, event.target.value);
    },

    handlePoNumberInputValueChange: ({ setPoNumberInputValue }) => (
      event: SyntheticEvent<HTMLInputElement>
    ) => {
      setPoNumberInputValue(event.target.value);
    },

    handleSubmitButtonClick: ({
      createManualInventoryOrderForFixPieceRequest,
      data,
      fixPieceRequestId,
      lineItemInputValues,
      onComplete,
      onRequestClose,
      poNumberInputValue,
      resetForm,
      resetGeneralErrors,
      resetInputErrors,
      setGeneralErrors,
      setInputErrors,
      setIsCreatingManualInventoryOrder,
      setPurchaseOrderNumberError,
      setVendorIdError,
      vendorIdSelection
    }) => (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      resetGeneralErrors([]);
      resetInputErrors();

      let failedRequiredFieldValidation = false;

      if(vendorIdSelection == null || vendorIdSelection === "") {
        setVendorIdError("is required");
        failedRequiredFieldValidation = true;
      }
      if(poNumberInputValue == null || poNumberInputValue === "") {
        setPurchaseOrderNumberError("is required");
        failedRequiredFieldValidation = true;
      }

      if (failedRequiredFieldValidation)
        return;

      setIsCreatingManualInventoryOrder(true);

      let lineItemInputs = []
      for(var i = 0; i < data.fixPieceRequest.itemBreakdown.length; i++) {
        const id = data.fixPieceRequest.itemBreakdown[i].inventoryItem.id;
        lineItemInputs.push({
          inventoryItemId: id,
          quantity: (id in lineItemInputValues) ? parseInt(lineItemInputValues[id]) : 0
        });
      }

      const variables = {
        purchaseOrderNumber: poNumberInputValue,
        vendorId: vendorIdSelection,
        fixPieceRequestId: fixPieceRequestId,
        lineItems: lineItemInputs
      };

      createManualInventoryOrderForFixPieceRequest({
        variables
      })
        .then(
          ({
            data: {
              createManualInventoryOrderForFixPieceRequest: { createdInventoryOrder, succeeded, errors }
            }
          }) => {
            if (succeeded) {
              resetGeneralErrors();
              resetInputErrors();
              resetForm();

              if (onComplete) {
                onComplete({ hasBeenOrdered: createdInventoryOrder.fixPieceRequest.hasBeenOrdered });
              } else if (onRequestClose) {
                onRequestClose();
              }
            } else {
              setInputErrors(errors);
            }
          }
        )
        .catch(e => {
          console.log(e);
          setGeneralErrors([e.message])
        })
        .finally(() => setIsCreatingManualInventoryOrder(false));
    },

    handleVendorIdSelect: ({ setVendorIdSelection }) => (
      event: SyntheticEvent<HTMLInputElement>
    ) => {
      setVendorIdSelection(event.target.value);
    }
  })
);

const RegisterManualOrderModal = ({
  classes,
  isOpen,
  onRequestClose,
  data,
  generalErrors,
  handleCancelButtonClick,
  handleLineItemQuantityChange,
  handlePoNumberInputValueChange,
  handleSubmitButtonClick,
  handleVendorIdSelect,
  inputErrors: {
    lineItems,
    lineItemsInput,
    purchaseOrderNumber,
    vendorId
  },
  isCreatingManualInventoryOrder,
  lineItemInputValues,
  poNumberInputValue,
  vendorIdSelection
}) => {
  return (
    <Dialog
      aria-describedby="Register Manual Order"
      aria-labelledby="Register Manual Order Modal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle>Register Manual Order</DialogTitle>
      <DialogContent style={{ minHeight: 250 }}>
        {generalErrors && generalErrors.length > 0 ? (
          <Typography color="error">{generalErrors.join(", ")}</Typography>
        ) : !data ? (
          <PendingStatusView status="Waiting" />
        ) : data.loading || isCreatingManualInventoryOrder ? (
          <PendingStatusView status="Loading" />
        ) : data.error ? (
          <div>
            <Typography variant="body2">
              There was an error while attempting to load the fix piece request
            </Typography>
          </div>
        ) : !data.fixPieceRequest ? (
          <Typography variant="body2">
            Unfortunately, that fix piece request could not be located
          </Typography>
        ) : (
          <div>
            <div className={classes.labelWrapper}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                {data.fixPieceRequest.orderProductionJob.label}
              </Typography>
            </div>

            <div className={classes.descriptionWrapper}>
              <Typography color="textSecondary" variant="subtitle2">
                {data.fixPieceRequest.description}
              </Typography>
            </div>

            <div>
              <div className={classes.requestedByEmployeeInfoWrapper}>
                <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Requested By:
                </Typography>
                <Typography
                  className={classes.requestedByEmployeeName}
                  variant="body1"
                >
                  {data.fixPieceRequest.requestedByEmployee && (data.fixPieceRequest.requestedByEmployee.name)}
                </Typography>
              </div>
            </div>

            <Divider light={true} className={classes.divider} />

            <div className={classes.labelWrapper}>
              <Typography color="textSecondary" variant="subtitle2">
                {data.fixPieceRequest.orderProductionJob.order.orderNumber}
              </Typography>
            </div>

            <div className={classes.productionDueDateWrapper}>
              {data.fixPieceRequest.orderProductionJob.order.productionDate && (
                <div className={classes.descriptionList}>
                  <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Production Date:
                  </Typography>
                  <PriorityChip
                    date={data.fixPieceRequest.orderProductionJob.order.productionDate}
                    priority={data.fixPieceRequest.orderProductionJob.order.priority}
                  />
                </div>
              )}

              {data.fixPieceRequest.orderProductionJob.order.dueBy.dateTime && (
                <div className={classes.descriptionList}>
                  <Typography
                    className={classes.descriptionTag}
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Due Date:
                  </Typography>
                  <Typography variant="subtitle2">
                    {DateTime.fromISO(
                      data.fixPieceRequest.orderProductionJob.order.dueBy.dateTime
                    ).toLocaleString(DateTime.DATE_SHORT)}
                  </Typography>
                </div>
              )}
            </div>

            <Divider light={true} className={classes.divider} />

            <FormControl
              error={(vendorId.length > 0)}
              fullWidth
            >
              <InputLabel>Vendor</InputLabel>
              <Select
                labelId="vendor-label"
                id="vendor"
                label="Vendor"
                value={vendorIdSelection || ""}
                onChange={handleVendorIdSelect}
              >
                <MenuItem key="vendor-blank" value="" />
                {data.manualOrderVendors && data.manualOrderVendors.map(manualOrderVendor => (
                    <MenuItem
                      key={manualOrderVendor.id}
                      value={manualOrderVendor.id}
                    >
                      {manualOrderVendor.organization.name}
                    </MenuItem>
                ))}
              </Select>
              <FormHelperText>{vendorId.length ? vendorId.join(', ') : " "}</FormHelperText>
            </FormControl>

            <FormControl
              error={(purchaseOrderNumber.length > 0)}
              fullWidth
              variant="outlined"
            >
              <InputLabel>PO Number</InputLabel>
              <OutlinedInput
                type="text"
                value={poNumberInputValue}
                onChange={handlePoNumberInputValueChange}
                label="PO Number"
                fullWidth
              />
              <FormHelperText>{purchaseOrderNumber.length ? purchaseOrderNumber.join(', ') : " "}</FormHelperText>
            </FormControl>

            <div className={classes.tableWrapper}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.cell}>Product</TableCell>
                    <TableCell align="right" className={classes.cell} size="small">Quantity</TableCell>
                    <TableCell align="right" size="small" className={classes.cell}>
                      Quantity Remaining
                    </TableCell>
                    <TableCell align="right" className={classes.cell}>
                      Quantity Ordered
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.fixPieceRequest.itemBreakdown.map((inventoryItemBreakdown, index) => (
                    <TableRow key={inventoryItemBreakdown.inventoryItem.id}>
                      <TableCell scope="row" className={classes.cell}>
                        {inventoryItemBreakdown.inventoryItem.description}
                      </TableCell>
                      <TableCell align="right" className={classes.cell}>
                        {inventoryItemBreakdown.quantityRequested}
                      </TableCell>
                      <TableCell align="right" className={classes.cell}>
                        {inventoryItemBreakdown.quantityNeeded}
                      </TableCell>
                      <TableCell align="right" className={classes.quantityCell}>
                        <FormControl
                          size="small"
                          variant="outlined"
                          fullWidth
                          error={(lineItemsInput.length > index && lineItemsInput[index].quantity.length > 0)}
                        >
                          <InputLabel>Quantity</InputLabel>
                          <OutlinedInput
                            size="small"
                            type="number"
                            label="QTY"
                            id={inventoryItemBreakdown.inventoryItem.id}
                            inputProps={{index: index}}
                            onChange={handleLineItemQuantityChange}
                            value={inventoryItemBreakdown.inventoryItem.id in lineItemInputValues ? lineItemInputValues[inventoryItemBreakdown.inventoryItem.id] : ''}
                          />
                          <FormHelperText>{lineItemsInput.length > index ? lineItemsInput[index].quantity.join(', ') : ""}</FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <FormHelperText error>{lineItems.length ? lineItems.join(', ') : " "}</FormHelperText>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelButtonClick}
          color="primary"
          disabled={isCreatingManualInventoryOrder}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmitButtonClick}
          color="primary"
          disabled={isCreatingManualInventoryOrder || (data && data.fixPieceRequest && !data.fixPieceRequest.canViewerPurchase)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default enhancer(RegisterManualOrderModal);
