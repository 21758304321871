// @flow

import * as React from "react";
import {
  compose,
  lifecycle,
  setDisplayName,
  withStateHandlers
} from "recompose";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PubNub from "pubnub";
import config from "../../application/config";

import type { HOC } from "recompose";

type Props = {|
  +channel: string,
  +viewer: {|
    +me: {| +id: string, +name: string |}
  |}
|};

type State = {|
  viewers: $ReadOnlyArray<{|
    name: string
  |}>
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CurrentViewers"),

  withStateHandlers(
    () =>
      ({
        viewers: []
      }: State),
    {
      setViewers: () => viewers => ({
        viewers
      })
    }
  ),

  lifecycle({
    componentWillMount() {
      const { viewer } = this.props;
      const uuid = viewer.me.id.substr(viewer.me.id.length - 16);
      this._pubnub = new PubNub({
        ...config.getPubNubKeys(),
        uuid: uuid
      });
    },
    componentDidMount() {
      const { _pubnub } = this;
      const { channel, setViewers, viewer } = this.props;
      const channels = [channel];
      const refresh = () => {
        _pubnub.hereNow(
          {
            channels: channels,
            includeUUIDs: true,
            includeState: true
          },
          (status, response) => {
            if (!response) {
              return;
            }
            const occupants = response.channels[channel].occupants
              .map(({ state }) => state)
              .filter(({ id }) => id !== viewer.me.id);
            setViewers(occupants);
          }
        );
      };
      _pubnub.addListener({
        presence: () => {
          refresh();
        },
        message: () => {
          refresh();
        },
        signal: () => {
          refresh();
        }
      });

      _pubnub.setState({
        state: {
          name: viewer.me.name,
          id: viewer.me.id
        },
        channels: channels
      });

      _pubnub.subscribe({
        channels: channels,
        withPresence: true
      });

      _pubnub.publish({
        message: "here",
        channel: channel
      });
      refresh();
    },
    componentWillUnmount() {
      const { _pubnub } = this;
      const { channel } = this.props;
      const channels = [channel];
      _pubnub.unsubscribe({
        channels: channels
      });
    }
  })
);

const CurrentViewers = ({ viewers }) => (
  <List dense={false}>
    {viewers.map(({ name }, index) => (
      <ListItem key={index}>
        <ListItemText primary={name} secondary={null} />
      </ListItem>
    ))}
  </List>
);

export default enhancer(CurrentViewers);
