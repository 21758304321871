// @flow

import {
  branch,
  compose,
  mapProps,
  renderComponent,
  setDisplayName
} from "recompose";
import PersonImageAvatar from "./PersonImageAvatar";
import PersonInitialsAvatar from "./PersonInitialsAvatar";
import invariant from "invariant";

import type { HOC } from "recompose";
import type { PersonAvatarProps } from "./";

const hasPhotographUrl = ({ photographUrl }): boolean => photographUrl != null;

const enhancer: HOC<*, PersonAvatarProps> = compose(
  setDisplayName("PersonAvatar"),
  branch(
    hasPhotographUrl,
    renderComponent(
      mapProps(({ photographUrl, ...props }) => {
        invariant(photographUrl != null, "photographUrl should not be null");
        return { ...props, photographUrl };
      })(PersonImageAvatar)
    )
  ),
  mapProps(({ name, size }) => ({ name, size }))
);

export default enhancer(PersonInitialsAvatar);
