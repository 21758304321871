// @flow

import * as React from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

type Props = {|
  +classes: {|
    +figure: string,
    +image: string,
    +popper: string,
    +tooltip: string,
    +zoomedImage: string
  |},
  +proof: {|
    +asset: {|
      +url: {|
        +formatted: string
      |}
    |},
    +originalFilename: ?string
  |}
|};

const styles = theme => ({
  figure: {
    marginTop: theme.spacing(2),
    marginRight: 0,
    marginBottom: theme.spacing(2),
    marginLeft: 0
  },

  image: {
    width: "100%",
    height: "auto",
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid"
  },

  flip: {
    transform: "rotate(180deg)"
  },

  tooltip: {
    backgroundColor: "#ffffff",
    padding: 0
  },

  popper: { opacity: 1 },

  zoomedImage: {
    width: "auto",
    maxHeight: "900px"
  }
});

const DesignProofListItem = ({
  classes,
  proof: {
    originalFilename,
    asset: {
      url: { formatted }
    }
  },
  flipArtwork
}: Props) => (
  <Tooltip
    classes={{
      popper: classes.popper,
      tooltip: classes.tooltip
    }}
    placement="right-start"
    title={
      <img
        className={classes.zoomedImage}
        src={formatted}
        alt={
          originalFilename
            ? `Design proof for ${originalFilename}`
            : "Design proof preview"
        }
      />
    }
  >
    <figure className={classes.figure}>
      <a href={formatted} target="_blank" rel="noopener noreferrer">
        <img
          className={`${classes.image} ${flipArtwork ? classes.flip : ""}`}
          src={formatted}
          alt={
            originalFilename
              ? `Design proof for ${originalFilename}`
              : "Design proof preview"
          }
        />
      </a>
      <figcaption>
        <Typography variant="caption" color="textSecondary">
          {originalFilename ? originalFilename : "N/A"}
        </Typography>
      </figcaption>
    </figure>
  </Tooltip>
);

export default withStyles(styles)(DesignProofListItem);
