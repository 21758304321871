// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import SidebarListItem from "../../SidebarListItem";

import type { HOC } from "recompose";

type Props = {|
  +children?: React.ChildrenArray<React.Element<typeof SidebarListItem>>,
  +classes?: {|
    +list: string
  |},
  +onItemSelected: (index: number) => void
|};

const styles = () => ({
  list: {
    width: 400
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("SidebarList"),
  withStyles(styles),
  withHandlers({
    handleItemClicked:
      ({ onItemSelected }) =>
      (index: number) => {
        onItemSelected(index);
      }
  })
);

const SidebarList = ({ classes, children, handleItemClicked }) => (
  <List className={classes.list}>
    {React.Children.toArray(children).map((item, index) =>
      React.cloneElement(item, {
        key: index,
        index,
        onListItemSelected: handleItemClicked
      })
    )}
  </List>
);

export default enhancer(SidebarList);
