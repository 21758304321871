// @flow

import * as React from "react";

import {
  compose,
  setDisplayName,
  withHandlers,
  withProps,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { getIconForCustomizationAreaTemplate } from "../../helpers/getIconForCustomizationAreaTemplate";
import { getIconForProductionJobMethod } from "../../helpers/getIconForProductionJobMethod";
import { getProductionMethodOptionAvailability } from "../../helpers/getProductionMethodOptionAvailability";
import { fragments as inkColorDetailsFragments } from "../InkColorDetails/graph";
import { updateOrderProductionJobCustomizationAreaColors } from "../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CustomizationAreaRadioList from "./components/CustomizationAreaRadioList";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InkColorDetails from "../InkColorDetails";
import InkColorModal from "../InkColorModal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VinylConfigurationSelector from "./components/VinylConfigurationSelector";
import withSnackbar from "../../components/withSnackbar";

import type { ColorSelection } from "../../types/Color";
import type { CustomizationArea } from "../../types/CustomizationArea";
import type { CustomizationAreaFormInputErrors } from "../../types/CustomizationAreaFormInputErrors";
import type { HOC } from "recompose";
import type { InkColor } from "../../types/Color";
import type { InkColorInputErrors } from "../../types/InkColorInputErrors";
import type { PantoneColor } from "../../types/Color";
import type { ProductCustomizationMethod } from "../../types/ProductCustomizationMethod";
import type { VinylConfigurationInputErrors } from "../../types/VinylConfigurationInputErrors";

type Props = {|
  +availableCustomizationAreaMethods: $ReadOnlyArray<{|
    +id: string,
    +label: string
  |}>,
  +availableCustomizationAreaTemplates: $ReadOnlyArray<{|
    +description: string,
    +id: string
  |}>,
  +customizationArea: CustomizationArea,
  +inputErrors: CustomizationAreaFormInputErrors,
  +onCustomizationAreaUpdated: ({}) => void,
  +vinylColors: $ReadOnlyArray<{
    +id: string,
    +inkColor: {|
      +color: {|
        +formatted: string
      |},
      +name: string
    |}
  }>,
  +vinylConfigurationInputErrors: VinylConfigurationInputErrors,
  +vinylFonts: $ReadOnlyArray<{
    +alias: string,
    +id: string
  }>
|};

const styles = theme => ({
  addColorsButton: { marginBottom: theme.spacing(2) },

  colorCount: {
    marginBottom: theme.spacing(2)
  },

  printAreaMethodAttributesContainer: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  areaContainer: {
    flex: 1
  },

  methodContainer: {
    flex: 1
  },

  list: {
    listStyle: "none",
    marginTop: 0,
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },

  listItem: {
    marginBottom: theme.spacing(1)
  },

  attributesContainer: {
    flex: 0.6
  },

  inkColorContainer: {
    display: "flex",
    flexDirection: "column"
  },

  splitIntoColorsCheckbox: {
    width: "150px"
  }
});
type State = {|
  autobaseCheckboxChecked: boolean,
  colorCountInputValue: string,
  colorSelections: $ReadOnlyArray<ColorSelection>,
  customizationAreaTemplateSelectedRadioValue: ?string,
  distressedCheckboxChecked: boolean,
  flattenCheckboxChecked: boolean,
  generalErrors: $ReadOnlyArray<string>,
  halftonesCheckboxChecked: boolean,
  imageRepairCheckboxChecked: boolean,
  inkColorSelectionInputErrors: InkColorInputErrors,
  isAddingColors: boolean,
  productCustomizationMethodComboboxSelection: ProductCustomizationMethod,
  recreateCheckboxChecked: boolean,
  splitIntoColorsCheckboxChecked: boolean,
  sponsorbackCheckboxChecked: boolean,
  vinylConfigurationToUpdate: ?{|
    names: boolean,
    namesColorId: ?string,
    namesFontId: ?string,
    numbers: boolean,
    numbersColorId: ?string,
    numbersFontId: ?string,
    size: number
  |}
|};

const defaultInkColorInputErrors: InkColorInputErrors = {
  colors: [],
  colorsInput: {
    inkColorId: [],
    pantoneColorId: []
  },
  orderProductionJobCustomizationAreaInkColorId: []
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("CustomizationAreaForm"),

  updateOrderProductionJobCustomizationAreaColors,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(
    ({
      customizationArea: {
        autobase,
        colorCount,
        distressed,
        flatten,
        halftones,
        imageRepair,
        inkColors,
        method,
        recreate,
        splitIntoColors,
        sponsorback,
        template,
        vinylConfiguration
      }
    }) =>
      ({
        isAddingColors: false,
        autobaseCheckboxChecked: autobase,
        customizationAreaTemplateSelectedRadioValue: template.id,
        colorCountInputValue: `${colorCount}`,
        colorSelections: inkColors,
        productCustomizationMethodComboboxSelection: method,
        distressedCheckboxChecked: distressed,
        flattenCheckboxChecked: flatten,
        generalErrors: [],
        halftonesCheckboxChecked: halftones,
        imageRepairCheckboxChecked: imageRepair,
        inkColorSelectionInputErrors: defaultInkColorInputErrors,
        recreateCheckboxChecked: recreate,
        splitIntoColorsCheckboxChecked: splitIntoColors,
        sponsorbackCheckboxChecked: sponsorback,
        vinylConfigurationToUpdate: {
          names:
            !!(vinylConfiguration &&
              (vinylConfiguration.namesColor || vinylConfiguration.namesFont)),
          numbers:
            !!(vinylConfiguration &&
              (vinylConfiguration.numbersColor || vinylConfiguration.numbersFont)),
          size: vinylConfiguration ? vinylConfiguration.size : 8,
          namesColorId:
            vinylConfiguration && vinylConfiguration.namesColor
              ? vinylConfiguration.namesColor.id
              : null,
          namesFontId:
            vinylConfiguration && vinylConfiguration.namesFont
              ? vinylConfiguration.namesFont.id
              : null,
          numbersColorId:
            vinylConfiguration && vinylConfiguration.numbersColor
              ? vinylConfiguration.numbersColor.id
              : null,
          numbersFontId:
            vinylConfiguration && vinylConfiguration.numbersFont
              ? vinylConfiguration.numbersFont.id
              : null
        }
      }: State),
    {
      addColorsSelection: () => (
        newColorSelections: $ReadOnlyArray<ColorSelection>
      ) => ({
        colorSelections: [...newColorSelections]
      }),

      resetGeneralErrors: () => () => ({
        generalErrors: []
      }),

      setGeneralErrors: () => (generalErrors: $ReadOnlyArray<string>) => ({
        generalErrors
      }),

      setIsAddingColors: () => (isAddingColors: boolean) => ({
        isAddingColors
      }),

      setInkColorSelectionInputErrors: () => (
        inkColorSelectionInputErrors: InkColorInputErrors
      ) => ({ inkColorSelectionInputErrors }),

      setCustomizationAreaTemplateSelectedRadioValue: () => (
        customizationAreaTemplateSelectedRadioValue: ?string
      ) => ({ customizationAreaTemplateSelectedRadioValue }),

      setProductCustomizationMethodComboboxSelection: () => productCustomizationMethodComboboxSelection => ({
        productCustomizationMethodComboboxSelection
      }),

      setDistressedCheckboxChecked: () => (
        distressedCheckboxChecked: boolean
      ) => ({ distressedCheckboxChecked }),

      setHalftonesCheckboxChecked: () => (
        halftonesCheckboxChecked: boolean
      ) => ({ halftonesCheckboxChecked }),

      setFlattenCheckboxChecked: () => (flattenCheckboxChecked: boolean) => ({
        flattenCheckboxChecked
      }),

      setRecreateCheckboxChecked: () => (recreateCheckboxChecked: boolean) => ({
        recreateCheckboxChecked
      }),

      setSponsorbackCheckboxChecked: () => (
        sponsorbackCheckboxChecked: boolean
      ) => ({ sponsorbackCheckboxChecked }),

      setSplitIntoColorsCheckboxChecked: () => (
        splitIntoColorsCheckboxChecked: boolean
      ) => ({
        splitIntoColorsCheckboxChecked
      }),

      setAutobaseCheckboxChecked: () => (autobaseCheckboxChecked: boolean) => ({
        autobaseCheckboxChecked
      }),

      setColorCountInputValue: () => (colorCountInputValue: string) => ({
        colorCountInputValue
      }),

      setImageRepairCheckboxChecked: () => (
        imageRepairCheckboxChecked: boolean
      ) => ({ imageRepairCheckboxChecked })
    }
  ),

  withProps(props => {
    const selectedMethod =
      props.productCustomizationMethodComboboxSelection === ""
        ? props.customizationArea.method
        : props.productCustomizationMethodComboboxSelection;

    const {
      autobaseOptionIsAvailable,
      colorOptionIsAvailable,
      distressedOptionIsAvailable,
      flattenOptionIsAvailable,
      halftonesOptionIsAvailable,
      imageRepairOptionIsAvailable,
      recreateOptionIsAvailable,
      sponsorbackOptionIsAvailable,
      isCutVinyl
    } = getProductionMethodOptionAvailability(selectedMethod);

    return {
      ...props,
      autobaseOptionIsAvailable,
      colorOptionIsAvailable,
      distressedOptionIsAvailable,
      flattenOptionIsAvailable,
      halftonesOptionIsAvailable,
      imageRepairOptionIsAvailable,
      isCutVinyl,
      recreateOptionIsAvailable,
      sponsorbackOptionIsAvailable
    };
  }),

  withHandlers({
    handleCustomizationAreaTemplateRadioButtonSelect: ({
      onCustomizationAreaUpdated,
      setCustomizationAreaTemplateSelectedRadioValue
    }) => (customizationAreaTemplateId: ?string) => {
      setCustomizationAreaTemplateSelectedRadioValue(
        customizationAreaTemplateId
      );
      onCustomizationAreaUpdated({
        template: { id: customizationAreaTemplateId }
      });
    },

    handleAddColorsButtonClick: ({ setIsAddingColors }) => () => {
      setIsAddingColors(true);
    },

    handleAddColorsModalRequestClose: ({ setIsAddingColors }) => () => {
      setIsAddingColors(false);
    },

    handleInkColorSelectionUpdate: ({
      addColorsSelection,
      customizationArea: { id },
      updateOrderProductionJobCustomizationAreaColors,
      resetGeneralErrors,
      setGeneralErrors,
      setIsAddingColors,
      setInkColorSelectionInputErrors,
      showSuccessSnackbar
    }) => (
      inkColors: $ReadOnlyArray<InkColor>,
      pantoneColors: $ReadOnlyArray<PantoneColor>
    ) => {
      resetGeneralErrors();
      setIsAddingColors(true);
      addColorsSelection([
        ...inkColors.map(inkColor => ({
          inkColor
        })),
        ...pantoneColors.map(pantoneColor => ({
          pantoneColor
        }))
      ]);

      updateOrderProductionJobCustomizationAreaColors({
        variables: {
          colors: [
            ...inkColors.map(({ id }) => ({
              inkColorId: id
            })),
            ...pantoneColors.map(({ id }) => ({
              pantoneColorId: id
            }))
          ],
          orderProductionJobCustomizationAreaId: id
        }
      })
        .then(
          ({
            data: {
              updateOrderProductionJobCustomizationAreaColors: {
                succeeded,
                errors
              }
            }
          }) => {
            succeeded
              ? showSuccessSnackbar("Colors successfully updated")
              : setInkColorSelectionInputErrors(...errors);
          }
        )
        .catch(error => {
          setGeneralErrors([error.message]);
        })
        .finally(() => setIsAddingColors(false));
    },

    handleProductCustomizationMethodComboboxSelect: ({
      onCustomizationAreaUpdated,
      setProductCustomizationMethodComboboxSelection,
      vinylConfigurationToUpdate,
      autobaseCheckboxChecked,
      distressedCheckboxChecked,
      flattenCheckboxChecked,
      halftonesCheckboxChecked,
      imageRepairCheckboxChecked,
      recreateCheckboxChecked,
      sponsorbackCheckboxChecked
    }) => (method: ProductCustomizationMethod) => {
      setProductCustomizationMethodComboboxSelection(method);
      const {
        autobaseOptionIsAvailable,
        distressedOptionIsAvailable,
        flattenOptionIsAvailable,
        halftonesOptionIsAvailable,
        imageRepairOptionIsAvailable,
        recreateOptionIsAvailable,
        sponsorbackOptionIsAvailable,
        isCutVinyl
      } = getProductionMethodOptionAvailability(method);
      onCustomizationAreaUpdated({
        method,
        vinylConfiguration: isCutVinyl ? vinylConfigurationToUpdate : null,
        autobase: autobaseOptionIsAvailable && autobaseCheckboxChecked,
        distressed: distressedOptionIsAvailable && distressedCheckboxChecked,
        flatten: flattenOptionIsAvailable && flattenCheckboxChecked,
        halftones: halftonesOptionIsAvailable && halftonesCheckboxChecked,
        imageRepair: imageRepairOptionIsAvailable && imageRepairCheckboxChecked,
        recreate: recreateOptionIsAvailable && recreateCheckboxChecked,
        sponsorback: sponsorbackOptionIsAvailable && sponsorbackCheckboxChecked
      });
    },

    handleDistressedCheckboxChange: ({
      onCustomizationAreaUpdated,
      setDistressedCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setDistressedCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ distressed: event.target.checked });
    },

    handleHalftonesCheckboxChange: ({
      onCustomizationAreaUpdated,
      setHalftonesCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setHalftonesCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ halftones: event.target.checked });
    },

    handleFlattenCheckboxChange: ({
      onCustomizationAreaUpdated,
      setFlattenCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setFlattenCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ flatten: event.target.checked });
    },

    handleRecreateCheckboxChange: ({
      onCustomizationAreaUpdated,
      setRecreateCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setRecreateCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ recreate: event.target.checked });
    },

    handleSponsorbackCheckboxChange: ({
      onCustomizationAreaUpdated,
      setSponsorbackCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setSponsorbackCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ sponsorback: event.target.checked });
    },

    handleSplitIntoColorsCheckboxChange: ({
      setSplitIntoColorsCheckboxChecked
    }) => (checked: boolean) => {
      setSplitIntoColorsCheckboxChecked(checked);
    },

    handleAutobaseCheckboxChange: ({
      onCustomizationAreaUpdated,
      setAutobaseCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setAutobaseCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ autobase: event.target.checked });
    },

    handleImageRepairCheckboxChange: ({
      onCustomizationAreaUpdated,
      setImageRepairCheckboxChecked
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      setImageRepairCheckboxChecked(event.target.checked);
      onCustomizationAreaUpdated({ imageRepair: event.target.checked });
    },

    handleVinylConfigurationSelectorUpdated: ({
      onCustomizationAreaUpdated,
      vinylConfigurationToUpdate
    }) => (update: {}) => {
      Object.assign(vinylConfigurationToUpdate, update);
      onCustomizationAreaUpdated({
        vinylConfiguration: vinylConfigurationToUpdate
      });
    },

    handleColorCountInputChange: ({
      onCustomizationAreaUpdated,
      setColorCountInputValue
    }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
      const colorCount = event.target.value;
      setColorCountInputValue(colorCount);
      onCustomizationAreaUpdated({ colorCount: parseInt(colorCount, 10) });
    }
  })
);

const CustomizationAreaForm = ({
  autobaseCheckboxChecked,
  autobaseOptionIsAvailable,
  availableCustomizationAreaMethods,
  availableCustomizationAreaTemplates,
  classes,
  colorOptionIsAvailable,
  colorSelections,
  customizationAreaTemplateSelectedRadioValue,
  distressedCheckboxChecked,
  distressedOptionIsAvailable,
  flattenCheckboxChecked,
  flattenOptionIsAvailable,
  halftonesCheckboxChecked,
  halftonesOptionIsAvailable,
  handleAddColorsButtonClick,
  handleAddColorsModalRequestClose,
  handleAutobaseCheckboxChange,
  handleColorCountInputChange,
  handleCustomizationAreaTemplateRadioButtonSelect,
  handleDistressedCheckboxChange,
  handleFlattenCheckboxChange,
  handleHalftonesCheckboxChange,
  handleImageRepairCheckboxChange,
  handleInkColorSelectionUpdate,
  handleProductCustomizationMethodComboboxSelect,
  handleRecreateCheckboxChange,
  handleSplitIntoColorsCheckboxChange,
  handleSponsorbackCheckboxChange,
  handleVinylConfigurationSelectorUpdated,
  imageRepairCheckboxChecked,
  imageRepairOptionIsAvailable,
  inkColorSelectionInputErrors,
  inputErrors,
  inputErrors: {
    autobase: autobaseErrors,
    colorCount: colorCountErrors,
    distressed: distressedErrors,
    flatten: flattenErrors,
    halftones: halftonesErrors,
    imageRepair: imageRepairErrors,
    recreate: recreateErrors,
    sponsorback: sponsorbackErrors,
    vinylConfiguration: vinylConfigurationErrors
  },
  isAddingColors,
  isCutVinyl,
  productCustomizationMethodComboboxSelection,
  recreateCheckboxChecked,
  recreateOptionIsAvailable,
  splitIntoColorsCheckboxChecked,
  sponsorbackCheckboxChecked,
  sponsorbackOptionIsAvailable,
  vinylColors,
  vinylConfigurationInputErrors,
  vinylConfigurationToUpdate,
  vinylFonts
}) => {
  return (
    <React.Fragment>
      <div className={classes.printAreaMethodAttributesContainer}>
        <div className={classes.areaContainer}>
          <Typography variant="subtitle2">Print Area</Typography>
          <CustomizationAreaRadioList
            iconMethod={getIconForCustomizationAreaTemplate}
            options={
              availableCustomizationAreaTemplates &&
              availableCustomizationAreaTemplates.map(
                ({ id, description }) => ({
                  id,
                  label: description,
                  icon: description
                })
              )
            }
            onSelect={handleCustomizationAreaTemplateRadioButtonSelect}
            selectionId={customizationAreaTemplateSelectedRadioValue}
          />
        </div>
        <div className={classes.methodContainer}>
          <Typography variant="subtitle2">Print Method</Typography>
          <CustomizationAreaRadioList
            iconMethod={getIconForProductionJobMethod}
            options={
              availableCustomizationAreaMethods &&
              availableCustomizationAreaMethods.map(({ id, label }) => ({
                id,
                label,
                icon: id
              }))
            }
            onSelect={handleProductCustomizationMethodComboboxSelect}
            selectionId={productCustomizationMethodComboboxSelection}
          />
        </div>
        <div className={classes.attributesContainer}>
          <Typography variant="subtitle2">Attributes</Typography>
          <FormControl>
            {distressedOptionIsAvailable && (
              <FormControlLabel
                error={distressedErrors.join(", ")}
                control={
                  <Checkbox
                    checked={distressedCheckboxChecked}
                    onChange={handleDistressedCheckboxChange}
                    color="primary"
                  />
                }
                label="Distressed"
              />
            )}
            {halftonesOptionIsAvailable && (
              <FormControlLabel
                error={halftonesErrors.join(", ")}
                control={
                  <Checkbox
                    checked={halftonesCheckboxChecked}
                    onChange={handleHalftonesCheckboxChange}
                    color="primary"
                  />
                }
                label="Halftones"
              />
            )}
            {flattenOptionIsAvailable && (
              <FormControlLabel
                error={flattenErrors.join(", ")}
                control={
                  <Checkbox
                    checked={flattenCheckboxChecked}
                    onChange={handleFlattenCheckboxChange}
                    color="primary"
                  />
                }
                label="Flatten"
              />
            )}
            {recreateOptionIsAvailable && (
              <FormControlLabel
                error={recreateErrors.join(", ")}
                control={
                  <Checkbox
                    checked={recreateCheckboxChecked}
                    onChange={handleRecreateCheckboxChange}
                    color="primary"
                  />
                }
                label="Recreate"
              />
            )}
            {sponsorbackOptionIsAvailable && (
              <FormControlLabel
                error={sponsorbackErrors.join(", ")}
                control={
                  <Checkbox
                    checked={sponsorbackCheckboxChecked}
                    onChange={handleSponsorbackCheckboxChange}
                    color="primary"
                  />
                }
                label="Sponsorback"
              />
            )}
            {autobaseOptionIsAvailable && (
              <FormControlLabel
                error={autobaseErrors.join(", ")}
                control={
                  <Checkbox
                    checked={autobaseCheckboxChecked}
                    onChange={handleAutobaseCheckboxChange}
                    color="primary"
                  />
                }
                label="Autobase"
              />
            )}
            {imageRepairOptionIsAvailable && (
              <FormControlLabel
                error={imageRepairErrors.join(", ")}
                control={
                  <Checkbox
                    checked={imageRepairCheckboxChecked}
                    onChange={handleImageRepairCheckboxChange}
                    color="primary"
                  />
                }
                label="ImageRepair"
              />
            )}
          </FormControl>
        </div>
      </div>
      {isCutVinyl && vinylConfigurationToUpdate ? (
        <React.Fragment>
          {vinylConfigurationErrors && vinylConfigurationErrors.length > 0 && (
            <Typography color="error">
              {`The vinyl configuration ${vinylConfigurationErrors.join(
                ", "
              )}.`}
            </Typography>
          )}
          <VinylConfigurationSelector
            onVinylConfigurationUpdated={
              handleVinylConfigurationSelectorUpdated
            }
            vinylConfiguration={vinylConfigurationToUpdate}
            vinylConfigurationInputErrors={vinylConfigurationInputErrors}
            vinylColors={vinylColors.map(vinylColor => ({
              color: vinylColor.inkColor.color,
              id: vinylColor.id,
              label: vinylColor.inkColor.name,
              value: vinylColor.id
            }))}
            vinylFonts={vinylFonts.map(vinylFont => ({
              id: vinylFont.id,
              label: vinylFont.alias,
              value: vinylFont.id
            }))}
          />
        </React.Fragment>
      ) : colorOptionIsAvailable ? (
        <React.Fragment>
          <div className={classes.inkColorContainer}>
            <Typography variant="subtitle2">Colors</Typography>
            <FormControlLabel
              className={classes.splitIntoColorsCheckbox}
              error={inputErrors.splitIntoColors.join(", ")}
              control={
                <Checkbox
                  checked={splitIntoColorsCheckboxChecked}
                  onChange={() =>
                    handleSplitIntoColorsCheckboxChange(
                      !splitIntoColorsCheckboxChecked
                    )
                  }
                  color="primary"
                />
              }
              label="Split into colors"
            />
          </div>
          {splitIntoColorsCheckboxChecked ? (
            <React.Fragment>
              {colorSelections.length > 0 && (
                <ul className={classes.list}>
                  {colorSelections.map(({ inkColor, pantoneColor }, index) => (
                    <li className={classes.listItem} key={index}>
                      <InkColorDetails
                        inkColor={
                          inkColor
                            ? filter(
                                inkColorDetailsFragments.inkColor,
                                inkColor
                              )
                            : null
                        }
                        pantoneColor={
                          pantoneColor
                            ? filter(
                                inkColorDetailsFragments.pantoneColor,
                                pantoneColor
                              )
                            : null
                        }
                      />
                    </li>
                  ))}
                </ul>
              )}
              <Button
                className={classes.addColorsButton}
                color="secondary"
                variant="contained"
                onClick={handleAddColorsButtonClick}
              >
                {colorSelections.length > 0 ? "Update Colors" : "Add Colors"}
              </Button>
              <InkColorModal
                inputErrors={inkColorSelectionInputErrors}
                isOpen={isAddingColors}
                onRequestClose={handleAddColorsModalRequestClose}
                onColorsAdded={handleInkColorSelectionUpdate}
                title="Add Ink Colors"
              />
            </React.Fragment>
          ) : (
            <div>
              <TextField
                error={colorCountErrors.join(", ")}
                className={classes.colorCount}
                id="Color Count Input"
                label="Color Count"
                type="number"
                onChange={handleColorCountInputChange}
                variant="outlined"
              />
            </div>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default enhancer(CustomizationAreaForm);
