// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { markOrderAsHighDefinitionDigitalLocated } from "../../../graph";
import { markOrderAsHighDefinitionDigitalStaged } from "../../../graph";
import { markOrderAsHighDefinitionDigitalTrimmed } from "../../../graph";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { recordOrderProductionJobBoxLocation } from "../../../graph";
import { recordOrderProductionJobScreenLocation } from "../../../graph";
import { recordStockContainerLocation } from "../../../graph";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

type State = {|
  currentLocation: string,
  isLoading: boolean
|};

const enhancer: HOC<*, *> = compose(
  setDisplayName("HDDReadyApp"),

  withRouter,

  withSnackbar,

  markOrderAsHighDefinitionDigitalLocated,

  markOrderAsHighDefinitionDigitalStaged,

  markOrderAsHighDefinitionDigitalTrimmed,

  recordStockContainerLocation,

  recordOrderProductionJobBoxLocation,

  recordOrderProductionJobScreenLocation,

  withStateHandlers(
    ({
      isLoading: false,
      currentLocation: null
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),
      setCurrentLocation: () => (currentLocation: string) => ({
        currentLocation
      })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl ? backUrl : "/apps");
      },

    handleBarcodeError:
      ({ showErrorSnackbar, setIsLoading }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
        setIsLoading(false);
      },

    handleBarcodeScan:
      ({
        markOrderAsHighDefinitionDigitalLocated,
        markOrderAsHighDefinitionDigitalStaged,
        markOrderAsHighDefinitionDigitalTrimmed,
        recordStockContainerLocation,
        recordOrderProductionJobBoxLocation,
        recordOrderProductionJobScreenLocation,
        setIsLoading,
        showErrorSnackbar,
        showSuccessSnackbar,
        setCurrentLocation,
        currentLocation
      }) =>
      barcode => {
        (async props => {
          console.log("props");
          console.log(props);
          const {
            barcode, // eslint-disable-next-line no-unused-vars
            markOrderAsHighDefinitionDigitalLocated, // eslint-disable-next-line no-unused-vars
            markOrderAsHighDefinitionDigitalStaged, // eslint-disable-next-line no-unused-vars
            markOrderAsHighDefinitionDigitalTrimmed, // eslint-disable-next-line no-unused-vars
            recordStockContainerLocation, // eslint-disable-next-line no-unused-vars
            recordOrderProductionJobBoxLocation, // eslint-disable-next-line no-unused-vars
            recordOrderProductionJobScreenLocation, // eslint-disable-next-line no-unused-vars
            setIsLoading,
            showErrorSnackbar,
            showSuccessSnackbar,
            setCurrentLocation,
            currentLocation
          } = props;

          console.log("recordOrderProductionJobBoxLocation - 1");
          console.log(recordOrderProductionJobBoxLocation);
          try {
            console.log("recordOrderProductionJobBoxLocation - 3");
            console.log(recordOrderProductionJobBoxLocation);
            const data = JSON.parse(barcode);
            const { variant, type, id, name } = data;
            if (
              variant === "LOCATION" &&
              type.toLowerCase() === "box" &&
              name
            ) {
              setCurrentLocation(name);
            } else if (
              variant === "OBJECT" &&
              (type === "StockContainer" || type === "OrderProductionJob") &&
              id
            ) {
              if (!currentLocation) {
                showErrorSnackbar("No Location Set");
                return;
              }
              setIsLoading(true);

              let succeeded = false;
              let highDefinitionDigitalStatus = null;
              let orderId = null;
              let orderProductionJobId = null;

              const handleErrors = errors => {
                console.log("errors"); // eslint-disable-line no-console
                console.log(errors); // eslint-disable-line no-console
                if (!errors) {
                  // TODO:
                } else {
                  if (errors.stockContainerId) {
                    showErrorSnackbar(errors.stockContainerId.join());
                  } else if (errors.orderProductionJobId) {
                    showErrorSnackbar(errors.orderProductionJobId.join());
                  } else if (errors.orderId) {
                    showErrorSnackbar(errors.orderId.join());
                  } else {
                    // TODO:
                  }
                }
              };

              if (type === "StockContainer") {
                const { data } = await recordStockContainerLocation({
                  variables: { location: currentLocation, stockContainerId: id }
                });
                succeeded = data.recordStockContainerLocation.succeeded;
                if (succeeded) {
                  const {
                    updatedStockContainer: {
                      orderProductionJob,
                      orderProductionJob: { order }
                    }
                  } = data.recordStockContainerLocation;
                  orderId = order.id;
                  orderProductionJobId = orderProductionJob.id;
                  highDefinitionDigitalStatus =
                    order.highDefinitionDigitalStatus;
                } else {
                  return handleErrors(data.recordStockContainerLocation.errors);
                }
              } else if (type === "OrderProductionJob") {
                console.log("recordOrderProductionJobBoxLocation - 2");
                console.log(recordOrderProductionJobBoxLocation);
                const { data } = await recordOrderProductionJobBoxLocation({
                  variables: {
                    location: currentLocation,
                    orderProductionJobId: id
                  }
                });
                succeeded = data.recordOrderProductionJobBoxLocation.succeeded;
                if (succeeded) {
                  const {
                    updatedOrderProductionJob: { order }
                  } = data.recordOrderProductionJobBoxLocation;
                  orderId = order.id;
                  orderProductionJobId = id;
                  highDefinitionDigitalStatus =
                    order.highDefinitionDigitalStatus;
                } else {
                  return handleErrors(
                    data.recordOrderProductionJobBoxLocation.errors
                  );
                }
              }

              if (orderProductionJobId) {
                await recordOrderProductionJobScreenLocation({
                  variables: {
                    location: "HDD FILMS STAGED WITH GOODS",
                    orderProductionJobId
                  }
                });
              }

              if (highDefinitionDigitalStatus === "QUEUED" || highDefinitionDigitalStatus === "FILM_PRINTED") {
                let { data } = await markOrderAsHighDefinitionDigitalTrimmed({
                  variables: { orderId }
                });
                succeeded =
                  data.markOrderAsHighDefinitionDigitalTrimmed.succeeded;
                if (succeeded) {
                  const { updatedOrder: order } =
                    data.markOrderAsHighDefinitionDigitalTrimmed;
                  orderId = order.id;
                  highDefinitionDigitalStatus =
                    order.highDefinitionDigitalStatus;
                } else if (
                  !succeeded &&
                  data.markOrderAsHighDefinitionDigitalTrimmed.errors
                    .orderId[0] === "can not perform this action"
                ) {
                  // TODO: Remove me sometime later
                } else {
                  return handleErrors(
                    data.markOrderAsHighDefinitionDigitalTrimmed.errors
                  );
                }
              }

              if (highDefinitionDigitalStatus !== "LOCATED") {
                let { data } = await markOrderAsHighDefinitionDigitalLocated({
                  variables: { orderId }
                });
                succeeded =
                  data.markOrderAsHighDefinitionDigitalLocated.succeeded;
                if (succeeded) {
                  const { updatedOrder: order } =
                    data.markOrderAsHighDefinitionDigitalLocated;
                  orderId = order.id;
                  highDefinitionDigitalStatus =
                    order.highDefinitionDigitalStatus;
                } else {
                  return handleErrors(
                    data.markOrderAsHighDefinitionDigitalLocated.errors
                  );
                }
              }

              let { data } = await markOrderAsHighDefinitionDigitalStaged({
                variables: { orderId }
              });
              succeeded = data.markOrderAsHighDefinitionDigitalStaged.succeeded;
              if (succeeded) {
                showSuccessSnackbar("Box location has been recorded.");
                playAudioForSuccess();
              } else {
                return handleErrors(
                  data.markOrderAsHighDefinitionDigitalStaged.errors
                );
              }
            }
          } catch (xxx) {
            console.log(xxx); // eslint-disable-line no-console
            showErrorSnackbar("Error Scanning Barcode");
          } finally {
            setIsLoading(false);
          }
        })({
          barcode,
          markOrderAsHighDefinitionDigitalLocated,
          markOrderAsHighDefinitionDigitalStaged,
          markOrderAsHighDefinitionDigitalTrimmed,
          recordStockContainerLocation,
          recordOrderProductionJobBoxLocation,
          recordOrderProductionJobScreenLocation,
          setIsLoading,
          showErrorSnackbar,
          showSuccessSnackbar,
          setCurrentLocation,
          currentLocation
        });
      }
  })
);

const HDDReadyApp = ({
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  isLoading,
  currentLocation,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="HDD Merging"
      onRequestBack={handleAppHeaderRequestBack}
      appBarBackgroundColor={appBarBackgroundColor}
    />
    {isLoading ? (
      <PendingStatusView status="Loading" />
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        {false && <CenteredSpinner />}
        {currentLocation && (
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ flexGrow: 1, marginTop: "10em" }}
          >
            Current Location: {currentLocation}
            <br />
            Scan Container or new location
          </Typography>
        )}
        {!currentLocation && (
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ flexGrow: 1, marginTop: "10em" }}
          >
            Scan Location
          </Typography>
        )}
      </div>
    )}
  </div>
);

export default enhancer(HDDReadyApp);
