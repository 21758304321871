// @flow

import { gql } from "@apollo/client";

export default gql`
  query OrderByCanonicalId($canonicalId: Int!) {
    orderByCanonicalId(canonicalId: $canonicalId) {
      ... on Order {
        id
        productionJobs {
          id
        }
      }
    }
  }
`;



