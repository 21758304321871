// @flow

import { gql } from "@apollo/client";

export default gql`
  query customsShipmentPallet($id: ID!) {
    customsShipmentPallet: node(id: $id) {
      ... on CustomsShipmentPallet {
        id
        createdAt
        lineItems {
          identifier
          countryOfOrigin
          embroidery
          quantity
          description
        }
        shipment {
          id
          createdAt
        }
        cartons {
          id
          inventoryOrderCartonNumber
          lineItems {
            id
            identifier
            countryOfOrigin
            embroidery
            quantity
            description
          }
          stockContainer {
            id
            orderProductionJob {
              id
              label
              index
              totalProductVariantQuantity
              order {
                id
                orderNumber
                isArtworkApproved
                isResolution
                dueBy {
                  dateTime
                }
                priority
                productionDate
                primaryCustomer {
                  id
                  name(format: FIRST_NAME_LAST_NAME)
                }
                productionStationAssignments {
                  id
                  productionStation {
                    id
                    name
                  }
                }
                productionJobs {
                  id
                }
              }
              customizationAreas {
                id
                location
                method
              }
            }
          }
        }
      }
    }
  }
`;
