// @flow

import { gql } from "@apollo/client";

export default gql`
  query customsOrderProductionJobsToBeReceivedList($labelStartsWith: String!) {
    orderProductionJobsToBeManuallyReceivedList(
      labelStartsWith: $labelStartsWith
    ) {
      id
      label
      order {
        id
        includeCatalogInShipment
      }
      customizationAreas {
        method
      }
      lineItemBreakdownByProduct {
        product {
          id
          style
        }
        lineItems {
          id
          color {
            id
            name
          }
          sizes {
            id
            quantity
            size {
              id
              code
            }
            gtin
            countryOfOrigin
          }
        }
      }
    }
  }
`;
