// @flow

import * as React from "react";
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { graphql } from "@apollo/client/react/hoc";
import { markInventoryOrderCartonAsReceived } from "../../../graph";
import { markOrderProductionJobAsStockReceived } from "../../../graph";
import { playAudioForProductionDateScan } from "../../../helpers/playAudioForProductionDateScan";
import { query } from "./graph";
import { fragments as receivingTableFragments } from "./components/ReceivingTable/graph";
import { withRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import Button from "@mui/material/Button";
import CenteredSpinner from "../../../components/CenteredSpinner";
import MenuItem from "@mui/material/MenuItem";
import PendingStatusView from "../../../components/PendingStatusView";
import ReceiveCartonModal from "./components/ReceiveCartonModal";
import ReceivingDetailView from "./components/ReceivingDetailView";
import ReceivingTable from "./components/ReceivingTable";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withDrawer from "../../../components/withDrawer";
import withSnackbar from "../../../components/withSnackbar";

import type { HOC } from "recompose";

const {
  REACT_APP_INVENTORY_MANAGEMENT_TOKEN,
  REACT_APP_INVENTORY_MANAGEMENT_URL
} = process.env;

const styles = StyleSheet.create({
  returnText: {
    fontSize: 46,
    textAlign: "center",
    fontWeight: "bold",
    margin: 20
  },
  localInventoryText: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    margin: 20
  },
  page: {
    backgroundColor: "#ffffff",
    padding: 8
  }
});

type State = {|
  autoPrint: boolean,
  hasNextPage: boolean,
  isLoadingMore: boolean,
  loadingProductionJobIds: Array<string>,
  scannedCartonNumber: ?string,
  searchQuery: ?string,
  selectedProductionJobId: ?string,
  selectedSortBy: ?string,
  selectedSortDirection: ?string,
  selectedStartingCharacter: ?string,
  selectedStockContainerId: ?string,
  showAllSwitchValue: boolean,
  showRecieveCartonModal: boolean
|};

const ORDERS_PER_PAGE = 20;

const ALL_LABEL = "All";

const enhancer: HOC<*, *> = compose(
  setDisplayName("ReceivingApp"),

  withRouter,

  withSnackbar,

  markInventoryOrderCartonAsReceived,

  markOrderProductionJobAsStockReceived(ORDERS_PER_PAGE),

  withStateHandlers(
    ({
      autoPrint: false,
      hasNextPage: true,
      isLoadingMore: false,
      loadingProductionJobIds: [],
      scannedCartonNumber: null,
      searchQuery: null,
      selectedProductionJobId: null,
      selectedSortBy: "CUSTOMER_NAME",
      selectedSortDirection: "ASCENDING",
      selectedStartingCharacter: null,
      selectedStockContainerId: null,
      showAllSwitchValue: false,
      showRecieveCartonModal: false
    }: State),
    {
      setAutoPrint: () => (autoPrint: boolean) => ({ autoPrint }),

      setHasNextPage: () => (hasNextPage: boolean) => ({ hasNextPage }),

      setIsLoadingMore: () => (isLoadingMore: boolean) => ({ isLoadingMore }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedProductionJobId:
        () =>
        (
          selectedProductionJobId: ?string,
          selectedStockContainerId: ?string = null
        ) => ({
          selectedProductionJobId,
          selectedStockContainerId
        }),

      setSortBy: () => (selectedSortBy: string) => ({
        selectedSortBy
      }),

      setSelectedStartingCharacter:
        () => (selectedStartingCharacter: ?string) => ({
          selectedStartingCharacter
        }),

      setShowAllSwitchValue: () => (showAllSwitchValue: boolean) => ({
        showAllSwitchValue
      }),

      setShowRecieveCartonModal: () => (showRecieveCartonModal: boolean) => ({
        showRecieveCartonModal
      }),

      setScannedCartonNumber: () => (scannedCartonNumber: string) => ({
        scannedCartonNumber
      }),

      setSortDirection: () => (selectedSortDirection: string) => ({
        selectedSortDirection
      }),

      addLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => ({
          loadingProductionJobIds: [...loadingProductionJobIds, productionJobId]
        }),

      removeLoadingProductionJobId:
        ({ loadingProductionJobIds }) =>
        (productionJobId: string) => {
          const indexToRemove =
            loadingProductionJobIds.indexOf(productionJobId);
          return {
            loadingProductionJobIds: [
              ...loadingProductionJobIds.slice(0, indexToRemove),
              ...loadingProductionJobIds.slice(
                indexToRemove + 1,
                loadingProductionJobIds.length
              )
            ]
          };
        }
    }
  ),

  withHandlers({
    processOrderReceived:
      ({
        addLoadingProductionJobId,
        markOrderProductionJobAsStockReceived,
        removeLoadingProductionJobId,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (orderProductionJobId: string) => {
        addLoadingProductionJobId(orderProductionJobId);
        markOrderProductionJobAsStockReceived({
          variables: { orderProductionJobId }
        })
          .then(
            ({
              data: {
                markOrderProductionJobAsStockReceived: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Order status has been received.");
              } else {
                showErrorSnackbar(errors.orderProductionJobId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => removeLoadingProductionJobId(orderProductionJobId));
      }
  }),

  withDrawer(
    "Job Details",
    ({
      autoPrint,
      selectedProductionJobId,
      processOrderReceived,
      loadingProductionJobIds,
      selectedStockContainerId,
      setDisplayState
    }) =>
      selectedProductionJobId ? (
        <ReceivingDetailView
          autoPrint={autoPrint}
          isReceivingFromStock={loadingProductionJobIds.includes(
            selectedProductionJobId
          )}
          stockContainerId={selectedStockContainerId}
          productionJobId={selectedProductionJobId}
          onRequestOrderReceived={(selectedProductionJobId: string) => {
            setDisplayState("closed");
            processOrderReceived(selectedProductionJobId);
          }}
        />
      ) : null
  ),

  graphql(query, {
    options: ({
      searchQuery,
      selectedSortBy,
      selectedSortDirection,
      selectedStartingCharacter,
      showAllSwitchValue,
      filters = {}
    }) => ({
      variables: {
        first: ORDERS_PER_PAGE,
        filters: {
          ...filters,
          includeReceived: searchQuery ? true : showAllSwitchValue,
          poStartsWith:
            selectedStartingCharacter === ALL_LABEL
              ? null
              : selectedStartingCharacter,
          searchQuery: searchQuery,
          sortBy: selectedSortBy,
          sortDirection: selectedSortDirection
        }
      }
    })
  }),

  withHandlers(() => {
    let returnPdfRef;
    let localInventoryPdfRef;

    const printReturn = () => {
      if (returnPdfRef) {
        returnPdfRef.focus();
        (returnPdfRef.contentWindow || returnPdfRef.contentDocument).print();
      }
    };

    const printLocalInventory = () => {
      if (localInventoryPdfRef) {
        localInventoryPdfRef.focus();
        (
          localInventoryPdfRef.contentWindow ||
          localInventoryPdfRef.contentDocument
        ).print();
      }
    };

    return {
      handleReturnPdfRef: () => ref => {
        returnPdfRef = ref;
      },

      handleLocalInventoryPdfRef: () => ref => {
        localInventoryPdfRef = ref;
      },

      handleAppHeaderRequestBack:
        ({ router, backUrl }) =>
        () => {
          router.push(backUrl ? backUrl : "/apps");
        },

      handleAppHeaderTabSelected:
        ({ setHasNextPage, setSelectedStartingCharacter }) =>
        (selectedStartingCharacter: string) => {
          setHasNextPage(true);
          setSelectedStartingCharacter(selectedStartingCharacter);
        },

      handleSearch:
        ({ setSearchQuery, setSelectedProductionJobId }) =>
        searchQuery => {
          setSelectedProductionJobId(null);
          setSearchQuery(searchQuery);
        },

      handleBarcodeError:
        ({ showErrorSnackbar }) =>
        () => {
          showErrorSnackbar("Error scanning barcode");
        },

      handleBarcodeScan:
        ({
          markInventoryOrderCartonAsReceived,
          setDisplayState,
          setScannedCartonNumber,
          setSelectedProductionJobId,
          setShowRecieveCartonModal,
          showErrorSnackbar,
          showSuccessSnackbar,
          showWarningSnackbar
        }) =>
        inventoryOrderCartonIdentifier => {
          setSelectedProductionJobId(null, null);
          markInventoryOrderCartonAsReceived({
            variables: { inventoryOrderCartonIdentifier }
          })
            .then(
              ({
                data: {
                  markInventoryOrderCartonAsReceived: {
                    succeeded,
                    updatedInventoryOrderCartons,
                    errorStatus
                  }
                }
              }) => {
                if (succeeded) {
                  const {
                    orderProductionJob: {
                      id: productionJobId,
                      order: { productionDate }
                    },
                    stockContainer: { id: stockContainerId }
                  } = updatedInventoryOrderCartons[0];
                  setSelectedProductionJobId(productionJobId, stockContainerId);
                  setDisplayState("open-partial");
                  showSuccessSnackbar("Order status has been received.");
                  playAudioForProductionDateScan(productionDate);
                } else {
                  showErrorSnackbar(
                    `No carton with identifier ${inventoryOrderCartonIdentifier} exists.`
                  );

                  setScannedCartonNumber(inventoryOrderCartonIdentifier);

                  /*
                  if (!showRecieveCartonModal) {
                    setShowRecieveCartonModal(true);
                    // TODO: Launch Manually Receive Carton Dialog with inventoryOrderCartonIdentifier as the carton identifier prefilled.
                  }*/
                }
                if (errorStatus === "INVENTORY_ORDER_CARTON_NOT_FOUND") {
                  return fetch(
                    `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory/api/supplier-carton`,
                    {
                      method: "POST",
                      body: JSON.stringify({
                        trackingNumber: inventoryOrderCartonIdentifier
                      }),
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${REACT_APP_INVENTORY_MANAGEMENT_TOKEN}`
                      }
                    }
                  )
                    .then(response => {
                      if (response.ok) {
                        return response.json();
                      } else {
                        showWarningSnackbar(
                          "No Carton found. Please lookup the order manually and add a box."
                        );
                        return { data: null };
                      }
                    })
                    .then(({ data }) => {
                      if (data != null) {
                        printLocalInventory();
                      } else {
                        setShowRecieveCartonModal(true);
                      }
                    });
                } else if (errorStatus === "INVENTORY_ORDER_CANCELED") {
                  showWarningSnackbar("RETURN: Order Canceled!");
                  printReturn();
                } else if (errorStatus === "INVENTORY_ORDER_VENDOR_ASSIGNED") {
                  showWarningSnackbar("RETURN: Assigned to subcontractor!");
                  printReturn();
                } else if (errorStatus) {
                  setShowRecieveCartonModal(true);
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(e.message);
            });
        },

      handleShowAllSwitchChanged:
        ({ setShowAllSwitchValue, setHasNextPage }) =>
        (event, showAll: boolean) => {
          setShowAllSwitchValue(showAll);
          setHasNextPage(true);
        },

      handleReceivingTableRowClicked:
        ({ setAutoPrint, setSelectedProductionJobId, setDisplayState }) =>
        (productionJobId: string) => {
          setAutoPrint(false);
          setSelectedProductionJobId(productionJobId);
          setDisplayState("open-partial");
        },

      handleSort:
        ({
          selectedSortBy,
          setSortDirection,
          setSortBy,
          selectedSortDirection
        }) =>
        (newSelectedSortBy: string) => {
          setSortBy(newSelectedSortBy);
          if (selectedSortBy === newSelectedSortBy) {
            selectedSortDirection === "ASCENDING"
              ? setSortDirection("DESCENDING")
              : setSortDirection("ASCENDING");
          } else {
            setSortDirection("ASCENDING");
          }
        },

      handleReceivingTableScrolledToBottom:
        ({
          data,
          hasNextPage,
          isLoadingMore,
          selectedStartingCharacter,
          setHasNextPage,
          setIsLoadingMore,
          showAllSwitchValue,
          filters = {}
        }) =>
        () => {
          if (isLoadingMore || !hasNextPage) {
            return;
          }

          setIsLoadingMore(true);
          data
            .fetchMore({
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges =
                  fetchMoreResult.orderProductionJobsToBeReceived.edges;
                const pageInfo =
                  fetchMoreResult.orderProductionJobsToBeReceived.pageInfo;
                setHasNextPage(pageInfo.hasNextPage);
                return newEdges.length
                  ? {
                      orderProductionJobsToBeReceived: {
                        pageInfo,
                        __typename:
                          previousResult.orderProductionJobsToBeReceived
                            .__typename,
                        edges: [
                          ...previousResult.orderProductionJobsToBeReceived
                            .edges,
                          ...newEdges
                        ],
                        poStartingCharacters:
                          fetchMoreResult.orderProductionJobsToBeReceived
                            .poStartingCharacters
                      }
                    }
                  : previousResult;
              },
              variables: {
                after: data.orderProductionJobsToBeReceived.pageInfo.endCursor,
                filters: {
                  ...filters,
                  includeReceived: showAllSwitchValue,
                  poStartsWith:
                    selectedStartingCharacter === ALL_LABEL
                      ? null
                      : selectedStartingCharacter
                }
              }
            })
            .finally(() => setIsLoadingMore(false));
        },
      handleReceiveCartonButtonClick:
        ({ setScannedCartonNumber, setShowRecieveCartonModal }) =>
        () => {
          setScannedCartonNumber(null);
          setShowRecieveCartonModal(true);
        },
      handleReceiveCartonModalRequestClose:
        ({ setShowRecieveCartonModal }) =>
        () => {
          setShowRecieveCartonModal(false);
        }
    };
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        data,
        setAutoPrint,
        setSelectedProductionJobId,
        setDisplayState
      } = this.props;
      if (!data.loading && !data.error) {
        if (
          data.orderProductionJobsToBeReceived.edges.length === 1 &&
          data.orderProductionJobsToBeReceived.edges[0].node.id !==
            prevProps.selectedProductionJobId
        ) {
          setAutoPrint(false);
          setSelectedProductionJobId(
            data.orderProductionJobsToBeReceived.edges[0].node.id
          );
          setDisplayState("open-partial");
        }
      }
    }
  })
);

const ReceivingApp = ({
  data,
  handleAppHeaderRequestBack,
  handleAppHeaderTabSelected,
  handleBarcodeError,
  handleBarcodeScan,
  handleReturnPdfRef,
  handleLocalInventoryPdfRef,
  handleReceiveCartonButtonClick,
  handleReceiveCartonModalRequestClose,
  handleReceivingTableRowClicked,
  handleReceivingTableScrolledToBottom,
  handleSearch,
  handleShowAllSwitchChanged,
  handleSort,
  hasNextPage,
  isLoadingMore,
  loadingProductionJobIds,
  processOrderReceived,
  scannedCartonNumber,
  showAllSwitchValue,
  showRecieveCartonModal,
  appBarBackgroundColor
}) => (
  <div>
    <TabbedAppBar
      title="Receiving"
      onSearch={handleSearch}
      onRequestBack={handleAppHeaderRequestBack}
      tabItems={
        data && data.orderProductionJobsToBeReceived
          ? [
              ALL_LABEL,
              ...data.orderProductionJobsToBeReceived.poStartingCharacters
            ]
          : []
      }
      onTabSelected={handleAppHeaderTabSelected}
      menuItems={[
        <MenuItem>
          Show All
          <Switch
            checked={showAllSwitchValue}
            onChange={handleShowAllSwitchChanged}
            color="primary"
          />
        </MenuItem>,
        <MenuItem>
          <Button onClick={handleReceiveCartonButtonClick}>
            <Typography variant="body2">Receive Carton</Typography>
          </Button>
        </MenuItem>
      ]}
      appBarBackgroundColor={appBarBackgroundColor}
    />
    {data.loading ? (
      <PendingStatusView status="Loading" />
    ) : data.error ? (
      <Typography variant="body2" color="error">
        {data.error.message}
      </Typography>
    ) : (
      <div>
        <BarcodeReader
          onError={handleBarcodeError}
          onScan={handleBarcodeScan}
        />
        <ReceivingTable
          loadingProductionJobIds={loadingProductionJobIds}
          onScrolledToBottom={handleReceivingTableScrolledToBottom}
          orderProductionJobs={filter(
            receivingTableFragments.order,
            data.orderProductionJobsToBeReceived.edges.map(edge => ({
              ...edge.node
            }))
          )}
          onRequestSort={handleSort}
          onRequestOrderReceived={processOrderReceived}
          onRowClicked={handleReceivingTableRowClicked}
        />
        {isLoadingMore && hasNextPage && <CenteredSpinner />}
      </div>
    )}
    <PDFViewer
      innerRef={handleReturnPdfRef}
      style={{ display: "none" }}
      width="100%"
      height="1000"
    >
      <Document>
        <Page size={[288, 166.5]} style={styles.page}>
          <View style={styles.returnText}>
            <Text style={styles.returnText}>RETURN</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
    <PDFViewer
      innerRef={handleLocalInventoryPdfRef}
      style={{ display: "none" }}
      width="100%"
      height="1000"
    >
      <Document>
        <Page size={[288, 166.5]} style={styles.page}>
          <View style={styles.localInventoryText}>
            <Text style={styles.localInventoryText}>LOCAL INVENTORY</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
    {showRecieveCartonModal && (
      <ReceiveCartonModal
        isOpen={showRecieveCartonModal}
        onRequestClose={handleReceiveCartonModalRequestClose}
        scannedCartonNumber={scannedCartonNumber}
      />
    )}
  </div>
);

export default enhancer(ReceivingApp);
