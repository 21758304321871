// @flow

import * as React from "react";
import { compose, withHandlers, withStateHandlers } from "recompose";
import ResponsiveDrawer from "./ResponsiveDrawer";

import type { HOC } from "recompose";

type State = {|
  displayState: string
|};

const enhancer: HOC<*, *> = compose(
  withStateHandlers(
    ({
      displayState: "closed"
    }: State),
    {
      setDisplayState: () => (displayState: string) => ({
        displayState
      })
    }
  ),

  withHandlers({
    handleDrawerRequestOpenFull:
      ({ setDisplayState }) =>
      () => {
        setDisplayState("open-full");
      },

    handleDrawerRequestClose:
      ({ setDisplayState }) =>
      () => {
        setDisplayState("closed");
      }
  })
);

const withDrawer =
  (title: string, content: (props: any) => React.ChildrenArray<any>) =>
  (WrappedComponent: React.ComponentType<any>) =>
    enhancer(
      ({
        displayState,
        handleDrawerRequestOpenFull,
        handleDrawerRequestClose,
        ...props
      }) => (
        <React.Fragment>
          <WrappedComponent {...props} />
          <ResponsiveDrawer
            displayState={displayState}
            onRequestOpenFull={handleDrawerRequestOpenFull}
            onRequestClose={handleDrawerRequestClose}
            title={title}
          >
            {content(props)}
          </ResponsiveDrawer>
        </React.Fragment>
      )
    );

export default withDrawer;
