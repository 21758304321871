// @flow

import * as React from "react";
import { compose, setDisplayName, withHandlers } from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as productionJobListItemFragments } from "./components/ProductionJobListItem/graph";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import ProductionJobListItem from "./components/ProductionJobListItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import type { HOC } from "recompose";
type Props = {|
  +classes?: {|
    +divider: string,
    +list: string,
    +productionJob: string,
    +productionJobListItemWrapper: string,
    +radioWrapper: string,
    +selectedProductionJob: string
  |},
  +onProductionJobSelected: (productionJobId: string) => void,
  +orderProductionJobId: ?string,
  +productionJobs: $ReadOnlyArray<{|
    +id: string
  |}>,
  +selectedIndex: number
|};

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2)
  },

  list: {
    listStyle: "none",
    margin: 0,
    paddingLeft: 0
  },

  productionJob: {
    padding: theme.spacing(1)
  },

  productionJobListItemWrapper: {
    float: "right",
    width: 350
  },

  radioWrapper: {
    width: 400
  },

  selectedProductionJob: {
    backgroundColor: theme.palette.mode === "dark" ?  "#424242" : theme.palette.grey[200],
    padding: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ProductionJobList"),
  withHandlers({
    handleRadioButtonSelected: ({ onProductionJobSelected }) => event => {
      event.stopPropagation();
      onProductionJobSelected(event.currentTarget.value);
    }
  }),
  withStyles(styles)
);

const ProductionJobList = ({
  classes,
  handleRadioButtonSelected,
  orderProductionJobId,
  productionJobs
}) => (
  <FormControl>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={orderProductionJobId}
      name="radio-buttons-group"
      onChange={handleRadioButtonSelected}>
      {productionJobs.map((productionJob, index) => (
        <label key={productionJob.id}>
          <div className={classes.radioWrapper}>
            <Radio value={productionJob.id}/>
            <div className={classes.productionJobListItemWrapper}>
              <ProductionJobListItem
                  index={index}
                  productionJob={filter(
                    productionJobListItemFragments.productionJob,
                    productionJob
                  )}
              />
              <Divider className={classes.divider} light />
            </div>
            <div style={{clear: "right"}}></div>
          </div>
        </label>
      ))}
    </RadioGroup>
  </FormControl>
)

export default enhancer(ProductionJobList);
