// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const recordOrderProductionJobScreenLocation: any = graphql(
  gql`
    mutation ($location: String!, $orderProductionJobId: ID!) {
      recordOrderProductionJobScreenLocation(
        location: $location
        orderProductionJobId: $orderProductionJobId
      ) {
        errors {
          orderProductionJobId
        }
        updatedOrderProductionJob {
          id
          highDefinitionDigitalStatus
          latestScreenLocation {
            id
            location
          }
          order {
            id
          }
          screenLocationHistory {
            id
            createdAt
            createdBy {
              id
              name(format: FIRST_NAME_LAST_NAME)
            }
            location
          }
        }
        succeeded
      }
    }
  `,
  {
    name: "recordOrderProductionJobScreenLocation"
  }
);

export default recordOrderProductionJobScreenLocation;
