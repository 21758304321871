// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { withStyles } from "@mui/styles";
import CompleteIcon from "@mui/icons-material/Done";
import InProgressIcon from "@mui/icons-material/Autorenew";
import OnHoldIcon from "@mui/icons-material/Warning";
import ReadyIcon from "@mui/icons-material/Subscriptions";

import type { HOC } from "recompose";
import type { OrderStatus } from "../../types/OrderStatus";

type Props = {|
  +classes?: {|
    +COMPLETE: string,
    +IN_PROGRESS: string,
    +ON_HOLD: string,
    +READY: string
  |},
  +status: OrderStatus
|};

const iconBackgroundSize = 45;

const sharedStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: iconBackgroundSize,
  height: iconBackgroundSize
};

const styles = () => ({
  COMPLETE: {
    ...sharedStyles,
    backgroundColor: "#BBF5CB"
  },

  IN_PROGRESS: {
    ...sharedStyles,
    backgroundColor: "#2278CF"
  },

  ON_HOLD: {
    ...sharedStyles,
    backgroundColor: "#FDCDD1"
  },

  READY: {
    ...sharedStyles,
    backgroundColor: "#ECEFF1"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("JobStatusIcon"),
  withStyles(styles)
);

const getIconForStatus = status => {
  switch (status) {
    case "COMPLETE": {
      return <CompleteIcon style={{ color: "#30C85D" }} />;
    }
    case "IN_PROGRESS": {
      return <InProgressIcon style={{ color: "FFF" }} />;
    }
    case "ON_HOLD": {
      return <OnHoldIcon style={{ color: "#AE0C25" }} />;
    }
    case "READY": {
      return <ReadyIcon style={{ color: "#6C6D6E" }} />;
    }
    default: {
      break;
    }
  }
};

const JobStatusIcon = ({ classes, status }) => {
  const statusIcon = getIconForStatus(status);
  return <div className={classes[status]}>{statusIcon}</div>;
};

export default enhancer(JobStatusIcon);
